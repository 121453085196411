'use strict';
import store from '../store';
import firebase from './firebase';

export const getBaseUrl = (local) => {
  if (local) return 'http://localhost:3001';
  const { projectId } = firebase.app().options;
  let baseUrl = '';
  switch (projectId) {
    case 'condomi-prod':
      baseUrl = 'https://api-gw-b21lg2hn.uc.gateway.dev';
      break;
    case 'condomi-stg':
      baseUrl = 'https://api-gw-stg-d9660lmp.uc.gateway.dev';
      break;
    case 'condomi-dev':
      baseUrl = 'https://api-gw-dev-9zehbpvg.uc.gateway.dev';
      break;
    case 'condomi-dev2':
      baseUrl = 'https://api-gw-dev2-cgu312f3.uc.gateway.dev';
      break;
  }
  return baseUrl;
};

export const getApi = async ({ path, local = false, apiVersion = 'v1' }) => {
  let headers;

  const {
    settings: {
      enterprise: { id: eid },
    },
  } = store.getState();

  let baseUrl = getBaseUrl(local);
  const token = await firebase.auth().currentUser.getIdToken();
  const bearer = `Bearer ${token}`;

  if (local) {
    headers = {
      'X-Apigateway-Api-Userinfo': token.split('.')[1],
    };
  } else {
    headers = {
      Authorization: bearer,
    };
  }
  const url = `${baseUrl}${local ? '' : `/${apiVersion}`}${path}`;
  return { url, headers: { ...headers, 'X-Zeta-Eid': eid }, timeout: 50000 };
};
