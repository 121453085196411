/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  clsProgressPanel: {
    borderRadius: '0.5em',
    backgroundColor: theme.palette.background.field,
    color: theme.palette.text.secondary,
    height: '76px',
  },
  clsProgressBar: {
    width: '95%',
  },
  clsHidden: {
    display: 'none',
  },
}));

function MailingManual() {
  return (
    <>
      <Box>
        <Typography variant="body1">Em construção</Typography>
      </Box>
    </>
  );
}

MailingManual.propTypes = {};
export default MailingManual;
