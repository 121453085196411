import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Typography from '@mui/material/Typography';

import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';

import 'moment/locale/pt-br';

import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import ConfirmationDialog from '../../components/ConfirmationDialog.jsx';
import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormContent from '../../components/EditForm/EditFormContent.jsx';
import EditFormField from '../../components/EditForm/EditFormField.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';
import EditFormMessage from '../../components/EditForm/EditFormMessage.jsx';
import CustomField from './CustomField';
import { journeysActions } from './journeys-slice.js';
import { getFields } from './message-fields.js';

// const useStyles = makeStyles((_theme) => ({
//   tooltip: {
//     maxWidth: 500,
//     // backgroundColor: theme.palette.background.default,
//   },
// }));

function EditJourney({ onClose }) {
  const {
    app: { isLoading },
    auth: { userData },
    settings: { enterprise },
    journeys: { selected, metadata },
  } = useSelector((state) => state);
  const theme = useTheme();

  const [globalFields, setGlobalFields] = useState(selected.globalFields ?? {});

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm();

  const items = getFields(userData, enterprise);

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    React.useState(false);
  const dispatch = useDispatch();

  const guideId = watch('guideId', selected.guideId);
  const testMode = watch('testMode', selected.testMode);
  // const testModeBotWait = watch('testModeBotWait', selected.testModeBotWait);
  // const invoiceDiscount = watch('invoiceDiscount', selected.invoiceDiscount);

  // console.log('testMode: ', { testMode });
  // console.log('testModeBotWait: ', { testModeBotWait });
  // console.log('invoiceDiscount: ', { invoiceDiscount });
  const { properties } = metadata[guideId] || {};

  const guideProperties =
    guideId && metadata
      ? Object.keys(properties).reduce((acc, p) => {
          const property = properties[p];
          const journeyFields =
            property.type === 'message'
              ? Object.keys(properties)
                  .filter(
                    (key) =>
                      properties[key].type !== 'boolean' &&
                      properties[key].type !== 'message' &&
                      properties[key].type !== 'select'
                  )
                  .reduce((acc, p) => {
                    acc[p] = properties[p];
                    return acc;
                  }, {})
              : undefined;

          acc.push({
            key: p,
            name: p,
            ...property,
            customFields: {
              ...items,
              journeyData: { label: 'Dados da jornada', items: journeyFields },
            },
          });
          return acc;
        }, [])
      : undefined;
  if (guideProperties)
    guideProperties.sort((a, b) => a.showOrder - b.showOrder);

  const onSubmit = (formData) => {
    const { id } = selected;
    const dataToSave = {
      id,
      ...formData,
      globalFields: _.pickBy(globalFields, (v) => v),
    };
    // console.log(dataToSave);
    dispatch(journeysActions.save(_.omitBy(dataToSave, _.isNaN)));
  };
  const handleCloseClick = () => {
    if (!isDirty) onClose(false);
    else setIsCloseConfirmationOpen(true);
  };

  const handleGlobalFieldClick = (fieldname, checked) => {
    setGlobalFields({ ...globalFields, ...{ [fieldname]: checked } });
  };

  return (
    <EditForm>
      <EditFormHeader title="Detalhes" onCloseClick={handleCloseClick} />
      <EditFormContent overflow="auto">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Dados Gerais
                </Typography>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'name',
                      label: 'Nome',
                      type: 'text',
                    }}
                    defaultValue={selected.name ?? ''}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 4, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'description',
                      label: 'Descrição',
                      type: 'text',
                    }}
                    defaultValue={selected.description ?? ''}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: {
                        value: 4,
                        message: 'Digite uma descrição válida',
                      },
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'guideId',
                      label: 'Classe',
                      type: 'select',
                      options: Object.keys(metadata).map((id) => ({
                        value: id,
                        label: metadata[id].name,
                      })),
                    }}
                    defaultValue={selected.guideId ?? ''}
                    control={control}
                    rules={{
                      required: 'Campo obrigatório',
                    }}
                  />
                </Grid>
                {guideProperties && (
                  <Grid item md={8} sm={6} xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={<InfoIcon color="primary" />}
                        title={metadata[guideId].description}
                      />
                    </Card>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <EditFormField
                    field={{
                      name: 'testMode',
                      label: 'Modo Teste',
                      type: 'boolean',
                    }}
                    defaultValue={selected.testMode ?? false}
                    control={control}
                  />
                </Grid>
                {testMode && (
                  <>
                    <Grid item xs={3}>
                      <EditFormField
                        field={{
                          name: 'testModeBotWait',
                          label: 'Espera Bot',
                          type: 'integer',
                          inputAdornment: {
                            position: 'end',
                            text: 'seg',
                          },
                        }}
                        defaultValue={selected.testModeBotWait ?? 600}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <EditFormField
                        field={{
                          name: 'testModeJourneyWait',
                          label: 'Espera Jornada',
                          type: 'integer',
                          inputAdornment: {
                            position: 'end',
                            text: 'seg',
                          },
                        }}
                        defaultValue={selected.testModeJourneyWait ?? 60}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <EditFormField
                        field={{
                          name: 'testModePixExpiration',
                          label: 'Expiração Pix',
                          type: 'integer',
                          inputAdornment: {
                            position: 'end',
                            text: 'seg',
                          },
                        }}
                        defaultValue={selected.testModePixExpiration ?? 1200}
                        control={control}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {guideProperties && (
              <>
                <Box marginTop={2} marginBottom={2}>
                  <Divider />
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" paddingRight={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        Configurações adicionais
                      </Typography>
                      <Box flexGrow={1} />
                      <Tooltip
                        //classes={{ tooltip: classes.tooltip }}
                        title={
                          <Box padding={2}>
                            <Typography variant="body1">
                              Marque quais serão os valores globais (que serão
                              aplicados a todos os clientes)
                            </Typography>
                            <Typography variant="body1">
                              Os demais campos deverão ser informados via
                              arquivo durante o envio.
                            </Typography>
                          </Box>
                        }
                      >
                        <HelpIcon color="action" />
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid container item spacing={2}>
                    {guideProperties.map((field) => (
                      <Grid
                        key={field.key}
                        item
                        md={
                          field.type === 'message' || field.type === 'file'
                            ? 12
                            : 6
                        }
                        sm={12}
                      >
                        <Paper
                          variant="outlined"
                          sx={{ height: '100%', padding: theme.spacing(2) }}
                        >
                          <CustomField
                            field={field}
                            defaultValue={selected[field.name]}
                            control={control}
                            onGlobalFieldClick={(v) =>
                              handleGlobalFieldClick(field.name, v)
                            }
                            isGlobalField={!!globalFields[field.name]}
                          />
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </form>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormMessage>
          {Object.keys(errors).length > 0 && (
            <Typography color="error" variant="caption">
              Os erros devem ser corrigidos antes de salvar
            </Typography>
          )}
        </EditFormMessage>
        <EditFormAction>
          <ButtonWithProgress
            // type="submit"
            tooltipTitle="Salvar"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            disabled={!isDirty || isLoading}
            aria-label="salvar"
            startIcon={<SaveIcon />}
          >
            Salvar
          </ButtonWithProgress>
        </EditFormAction>
      </EditFormFooter>
      <ConfirmationDialog
        open={isCloseConfirmationOpen}
        title="Existem dados não salvos"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsCloseConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sair sem Salvar',
            buttonProps: {
              onClick: () => onClose(false),
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <SaveIcon />,
              onClick: handleSubmit(onSubmit),
            },
          },
        ]}
      >
        Alguns dados foram modificados e ainda não foram salvos.
        <br />
        <br />
        Deseja salvá-los antes de fechar a janela?
      </ConfirmationDialog>
    </EditForm>
  );
}

EditJourney.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditJourney;
