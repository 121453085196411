import moment from 'moment';
import { Fragment } from 'react';
import firebase from '../../helpers/firebase';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';

import GetAppIcon from '@mui/icons-material/GetApp';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import 'moment/locale/pt-br';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      textTransform: 'capitalize',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const getDownloadLink = ({ id, folder }) => {
  const { projectId } = firebase.app().options;
  let baseUrl = '';
  if (projectId === 'condomi-prod') baseUrl = 'https://condomi.zetaone.com.br';
  else baseUrl = `https://${projectId}.zetaone.com.br`;
  return `${baseUrl}/fn-adm-download/${id}/${folder}`;
};

export default function BackgroundTaskDetail({ task, onClose }) {
  const classes = useStyles();
  if (task) {
    const { title, content, downloadLink, createdAt } = task;

    let dialogContent;
    if (Array.isArray(content)) {
      dialogContent = content.map((item, key) => {
        if (typeof item === 'string')
          return (
            <Box key={key} paddingBottom={1}>
              <Typography variant="body1" color="textPrimary">
                {item}
              </Typography>
            </Box>
          );
        if (typeof item === 'object') {
          const { type, data, properties } = item;
          switch (type) {
            case 'object':
              return (
                <Fragment key={key}>
                  {properties.map(({ id, label }, key2) => (
                    <Box key={key2} marginTop={1}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {label} :
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        color="textPrimary"
                      >
                        {' '}
                        {data[id]}
                      </Typography>
                    </Box>
                  ))}
                </Fragment>
              );
            case 'table':
              return;
          }
        }
      });
    } else {
      dialogContent = content;
    }
    return (
      <>
        <DialogTitle className={classes.title}>
          <div>
            <Typography variant="h5" component="span">
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          {dialogContent}
          {downloadLink && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Button
                component={Link}
                href={getDownloadLink(downloadLink)}
                color="primary"
                variant="contained"
                target="_blank"
                rel="noopener"
                startIcon={<GetAppIcon />}
              >
                BAIXAR
              </Button>
              <Box marginLeft={2}>
                <Typography>{downloadLink.name}</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.flexGrow} />
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </>
    );
  } else return <div />;
}

BackgroundTaskDetail.propTypes = {
  task: PropTypes.object,
  onClose: PropTypes.func,
};
