'use strict';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { DefaultElement, Editable, ReactEditor, useSlate } from 'slate-react';
import CustomRichTextEditor from '../../helpers/custom-richtext-editor';
import FieldElement from './FieldElement';

const Leaf = (props) => (
  <span
    {...props.attributes}
    style={{
      fontWeight: props.leaf.bold ? 'bold' : 'normal',
      fontStyle: props.leaf.italic ? 'italic' : 'normal',
      textDecoration: props.leaf.strike ? 'line-through' : undefined,
    }}
  >
    {props.children}
  </span>
);
Leaf.propTypes = {
  attributes: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  leaf: PropTypes.object.isRequired,
};

const Element = (props) => {
  const { element } = props;
  switch (element.type) {
    case 'field':
      return <FieldElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};
Element.propTypes = {
  attributes: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
};

const SlateInput = React.forwardRef((props, ref) => {
  const editor = useSlate();
  const { /*inputRef,*/ value, onChange: onChangeProp, ...other } = props;

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderElement = useCallback((props) => <Element {...props} />, []);

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      ReactEditor.focus(editor);
    },
    value: CustomRichTextEditor.serialize({ value }),
    onChange: onChangeProp,
  }));

  return (
    <Editable
      {...other}
      renderLeaf={renderLeaf}
      renderElement={renderElement}
      onKeyDown={(e) => {
        const fieldCurrent = CustomRichTextEditor.getFieldCurrent(editor);

        if (e.key === 'Backspace' && fieldCurrent) {
          Transforms.move(editor);
        }
        if (e.key === 'Delete' && fieldCurrent) {
          Transforms.move(editor, { distance: 1, reverse: true });
        }

        if (e.metaKey) {
          switch (e.key) {
            case 'b': {
              e.preventDefault();
              CustomRichTextEditor.toggleBoldMark(editor);
              break;
            }
            case 'i': {
              e.preventDefault();
              CustomRichTextEditor.toggleItalicMark(editor);
              break;
            }
            case 's': {
              e.preventDefault();
              CustomRichTextEditor.toggleStrikeMark(editor);
              break;
            }
          }
        }
      }}
    />
  );
});
SlateInput.propTypes = {
  editor: PropTypes.object.isRequired,
  // inputRef: PropTypes.func.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectedFieldId: PropTypes.string,
};
SlateInput.muiName = 'Input';
SlateInput.displayName = 'SlateInput';
export default SlateInput;
