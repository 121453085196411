import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
// import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

// import { makeStyles } from '@mui/styles';

import { useSelector } from 'react-redux';
import { formatValue } from '../../helpers/formatter';
// import clsx from 'clsx';

// const useStyles = makeStyles((theme) => ({
//   current: {
//     // '& .MuiPaper-outlined': {
//     borderColor: theme.palette.primary.main,
//     //},
//   },
//   detail: {
//     minWidth: '10rem',
//     height: '100%',
//   },
//   tooltip: {
//     maxWidth: 900,
//     // backgroundColor: theme.palette.background.default,
//   },
//   cardContent: {
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//   },
// }));

function NextBotDetail({ botId, scheduledStartAt }) {
  const {
    app: { isSecretActive },
  } = useSelector((state) => state);
  // const classes = useStyles();
  return (
    <Chip
      label={`${
        isSecretActive ? `${botId} - ` : ''
      }Próximo lembrete em: ${formatValue(scheduledStartAt, 'dateTime')}`}
      variant="outlined"
      color="primary"
    />

    // <Card className={clsx(classes.current, classes.detail)} variant="outlined">
    //   <CardContent className={classes.cardContent}>
    //     <Typography variant="body1" component="p">
    //       {botId}
    //     </Typography>
    //     <Typography variant="caption" color="textSecondary" component="p">
    //       Próximo lembrete agendado para:
    //     </Typography>
    //     <Typography variant="body1" component="p">
    //       {formatValue(scheduledStartAt, 'dateTime')}
    //     </Typography>
    //     <Box width={1} flexGrow={1}>
    //       <Box
    //         width={1}
    //         height={1}
    //         display="flex"
    //         alignItems="center"
    //         justifyContent="center"
    //       >
    //         <HourglassEmptyIcon fontSize="large" />
    //       </Box>
    //     </Box>
    //   </CardContent>
    //   <CardActions></CardActions>
    // </Card>
  );
}

NextBotDetail.propTypes = {
  botId: PropTypes.string.isRequired,
  scheduledStartAt: PropTypes.number.isRequired,
};
export default NextBotDetail;
