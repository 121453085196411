'use strict';

module.exports = {
  levels: [
    {
      role: 'dev',
      label: 'Dev (ZetaOne)',
      level: 0,
    },
    {
      role: 'superadm',
      label: 'Super Administrador (ZetaOne)',
      level: 1,
    },
    {
      role: 'admz',
      label: 'Administrador (ZetaOne)',
      level: 1,
    },
    {
      role: 'own',
      label: 'Proprietário',
      level: 2,
    },
    {
      role: 'adm',
      label: 'Administrador',
      level: 3,
    },
    {
      role: 'fin',
      label: 'Financeiro',
      level: 4,
    },
    {
      role: 'sup',
      label: 'Supervisor',
      level: 4,
    },
    {
      role: 'ate',
      label: 'Atendimento',
      level: 5,
    },
  ],
  permissions: {
    'cfg-ent': 'Nome da empresa e do robô',
    'cfg-not': 'Número para notificação',
    'cfg-tag': 'Gerenciar marcadores',
    'cfg-api': 'Habilitar API',
    'cfg-msg': 'Conta WhatsApp',
    'cfg-pix': 'Conta PIX',
    'cfg-act': 'Ativar empresa',
    'cfg-int': 'Gerenciar integrações',
    'cfg-adm': 'Configurações administrativas (internas)',
    'cus-cpc': 'Alterar situação CPC (dev)',
    'cus-add': 'Cadastrar clientes',
    'cus-del': 'Excluir clientes',
    'jrn-add': 'Criar e alterar jornadas',
    'jrn-snd': 'Disparar jornadas',
    'jrn-del': 'Excluir jornadas',
    'jrn-int': 'Interromper jornadas',
    'iss-slv': 'Visualizar e resolver pendências',
    'pix-sim': 'Simular pagamento (dev)',
    'pix-del': 'Excluir cobrança (dev)',
    'pix-upd': 'Alterar pix',
    'pix-can': 'Cancelar pix',
    'pix-snd': 'Enviar msg',
    'pix-rst': 'Reenviar lembretes',
    'pix-pho': 'Trocar telefones',
    'rpt-pay': 'Pagamentos',
    'rpt-cnb': 'Exportar CNAB',
    'rpt-sta': 'Situação',
    'rpt-msg': 'Mensagens',
    'msg-qrc': 'QRCode',
    'usr-add': 'Criar convites',
    'usr-prm': 'Alterar permissão',
    'ent-add': 'Alterar Empresas',
    wal: 'Carteira',
    vrs: 'Verificar Versões',
    hlp: 'Ver Ajuda',
    ins: 'Ativar inspetor',
    dbg: 'Ativar debug',
    'lgc-mon': 'Monitoramento antigo',
  },
  dev: {
    'cfg-ent': true,
    'cfg-not': true,
    'cfg-tag': true,
    'cfg-api': true,
    'cfg-msg': true,
    'cfg-pix': true,
    'cfg-act': true,
    'cfg-int': true,
    'cfg-adm': true,
    'cus-cpc': true,
    'cus-add': true,
    'cus-del': true,
    'jrn-add': true,
    'jrn-snd': true,
    'jrn-del': true,
    'jrn-int': true,
    'iss-slv': true,
    'pix-sim': true,
    'pix-del': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,
    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    'rpt-msg': true,
    'msg-qrc': true,
    'usr-add': true,
    'usr-prm': true,
    'ent-add': true,
    vrs: true,
    hlp: true,
    ins: true,
    dbg: true,
    'lgc-mon': true,
    wal: true,
  },
  superadm: {
    'cfg-ent': true,
    'cfg-not': true,
    'cfg-tag': true,
    'cfg-api': true,
    'cfg-msg': true,
    'cfg-pix': true,
    'cfg-act': true,
    'cus-add': true,
    'cus-del': true,

    'jrn-add': true,
    'jrn-snd': true,
    'jrn-del': true,
    'iss-slv': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,
    'pix-sim': true,

    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    'rpt-msg': true,

    'msg-qrc': true,

    'usr-add': false,
    'usr-prm': false,

    'ent-add': false,

    vrs: false,

    hlp: true,

    ins: false,

    dbg: false,

    'lgc-mon': true,
    wal: true,
  },
  admz: {
    'cfg-ent': true,
    'cfg-not': true,
    'cfg-tag': true,
    'cfg-api': true,
    'cfg-msg': true,
    'cfg-pix': true,
    'cfg-act': true,
    'cus-add': true,
    'cus-del': true,

    'jrn-add': true,
    'jrn-snd': true,
    'jrn-del': true,
    'iss-slv': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,
    'pix-sim': true,

    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    'rpt-msg': true,

    'msg-qrc': true,

    'usr-add': false,
    'usr-prm': false,

    'ent-add': false,

    vrs: false,

    hlp: true,

    ins: false,

    dbg: false,

    'lgc-mon': false,
    wal: true,
  },
  own: {
    'cfg-ent': true,
    'cfg-not': true,
    'cfg-tag': true,

    'cus-add': true,
    'cus-del': true,

    // 'jrn-add': true,
    'jrn-snd': true,
    // 'jrn-del': true,
    'iss-slv': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,
    'pix-sim': true,
    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    wal: true,
    hlp: true,
  },
  adm: {
    'cfg-ent': true,
    'cfg-not': true,
    'cfg-tag': true,

    // 'jrn-add': true,
    'jrn-snd': true,
    // 'jrn-del': true,
    'iss-slv': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,

    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    wal: false,
    hlp: true,
  },
  fin: {
    'jrn-snd': true,
    'iss-slv': true,
    'pix-upd': true,
    'pix-can': true,
    'pix-snd': true,
    'pix-rst': true,
    'pix-pho': true,

    'rpt-pay': true,
    'rpt-cnb': true,
    'rpt-sta': true,
    wal: true,
    hlp: true,
  },
  sup: { 'pix-rst': true, 'pix-pho': true, 'iss-slv': true, hlp: true },
  ate: { 'pix-rst': true, 'pix-pho': true, hlp: true },
};
