'use strict';
import ky from 'ky';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import pixAccountsDao from '../../dao/pix-accounts';
import waSessionsDao from '../../dao/wa-sessions';
import { getApi } from '../../helpers/api';
import firebase from '../../helpers/firebase';
import { upload } from '../../helpers/upload';
import rpcCall from '../../sagas/rpc';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { settingsActions } from './settings-slice';

function* watchInit() {
  while (true) {
    yield take(settingsActions.init);
    const theme = localStorage.getItem('br.com.condomi.theme');
    const localApi = localStorage.getItem('br.com.condomi.localApi');
    let newSettings = {};
    if (theme !== undefined && theme !== null)
      newSettings = { ...newSettings, theme };
    if (localApi !== undefined && localApi !== null)
      newSettings = {
        ...newSettings,
        localApi: localApi.toLowerCase() === 'true',
      };
    yield put(settingsActions.initSuccess(newSettings));
  }
}

function* watchLoadEnterprise() {
  const db = firebase.firestore();

  while (true) {
    const { payload: eid } = yield take(settingsActions.loadEnterprise);
    yield put(appActions.startLoading());
    try {
      let docRef = yield db.collection('enterprises').doc(eid).get();
      if (!docRef.exists) throw new Error(`Enterprise ${eid} not found !`);
      const enterprise = docRef.data();

      const { pixAccount: pixAccountId } = enterprise;

      yield put(
        settingsActions.loadEnterpriseSuccess({
          id: docRef.id,
          ...enterprise,
        })
      );

      const pixAccountDoc = yield db
        .collection('pix-accounts')
        .doc(pixAccountId)
        .get();
      if (!pixAccountDoc.exists) yield put(settingsActions.setPixAccount({}));
      // throw new Error(`Pix account ${pixAccountId} not found !`);
      else
        yield put(
          settingsActions.setPixAccount({
            id: pixAccountDoc.id,
            ...pixAccountDoc.data(),
          })
        );

      yield put(appActions.finishLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(settingsActions.loadEnterpriseFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveEnterprise() {
  while (true) {
    const { payload } = yield take(settingsActions.saveEnterprise);

    try {
      yield put(appActions.startLoading());
      const { id } = payload;

      const { socialContractFile, ...dataToSave } = payload;

      if (socialContractFile) {
        const socialContractId = payload.socialContractFile
          ? yield upload(payload.socialContractFile)
          : undefined;

        dataToSave.socialContractId = socialContractId;
      }

      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/enterprise/${id}`,
        local: localApi,
        apiVersion: 'v2',
      });
      yield ky.patch(url, {
        json: dataToSave,
        headers,
        ...opts,
      });

      // console.log('saving enterprise', payload);
      // yield db.collection('enterprises').doc(id).update(payload);

      // const { localApi } = yield select((state) => state.settings);
      // const { url, headers, ...opts } = yield getApi({
      //   path: '/journey/update-enterprise-information',
      //   local: localApi,
      // });
      // const { error } = yield rpcCall({
      //   url,
      //   method: 'post',
      //   headers,
      //   ...opts,
      //   payload: { eid: id },
      // });
      // if (error) {
      //   throw new Error(error);
      // } else {
      yield put(settingsActions.saveEnterpriseSuccess(payload));
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(settingsActions.closeSettings());
      yield put(appActions.finishLoading());
      // }
    } catch (e) {
      // console.log('Erro ao executar watchSaveEnterprise');
      yield put(settingsActions.saveEnterpriseError(e.message));
      // console.error(e);
      const notification = {
        title: 'Erro na aplicação',
        content: e.message,
        severity: 'error',
      };
      yield put(notifierActions.createPersistentNotification(notification));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetWASessions() {
  while (true) {
    yield take(settingsActions.getWASessions);
    try {
      yield put(appActions.startLoading());
      const data = yield waSessionsDao.get();
      yield put(settingsActions.getWASessionsSuccess(data));
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchGetWASessions');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(settingsActions.getWASessionsFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetPixAccounts() {
  while (true) {
    yield take(settingsActions.getPixAccounts);
    try {
      yield put(appActions.startLoading());
      const data = yield pixAccountsDao.get();
      yield put(settingsActions.getPixAccountsSuccess(data));

      const {
        enterprise: { id: eid },
      } = yield select((state) => state.settings);
      const [pixSubaccount] = data.filter(({ id }) => id.split('-')[1] === eid);
      if (pixSubaccount) {
        yield put(settingsActions.getPixSubaccount(pixSubaccount.id));
      }

      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchGetPixAccounts');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(settingsActions.getPixAccountsFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchChangeSettings() {
  while (true) {
    const {
      payload: { theme, localApi },
    } = yield take(settingsActions.changeSettings);
    if (theme !== undefined)
      localStorage.setItem('br.com.condomi.theme', theme);
    if (localApi !== undefined)
      localStorage.setItem('br.com.condomi.localApi', localApi);
  }
}

function createPixAccountChannel(pixAccountId) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const pixDoc = db.collection('pix-accounts').doc(pixAccountId);
    const pixUnsubscribe = pixDoc.onSnapshot((doc) => emitter(doc));
    return () => {
      pixUnsubscribe();
    };
  });
}

function* watchPixSubaccountUpdates(pixAccountId) {
  const chan = yield call(createPixAccountChannel, pixAccountId);
  try {
    while (true) {
      const doc = yield take(chan);
      yield put(
        settingsActions.setPixSubaccount({ id: doc.id, ...doc.data() })
      );
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
      // console.log('Closed updates channel');
    }
  }
}

function* watchGetPixSubaccount() {
  while (true) {
    const { payload: pixAccountId } = yield take(
      settingsActions.getPixSubaccount
    );
    yield put(appActions.startLoading());

    const pixAccountUpdatesTask = yield fork(
      watchPixSubaccountUpdates,
      pixAccountId
    );
    yield take(settingsActions.closeSettings);
    yield cancel(pixAccountUpdatesTask);
  }
}

function* watchGetPixAccount() {
  const db = firebase.firestore();

  while (true) {
    yield take(settingsActions.getPixAccount);
    try {
      yield put(appActions.startLoading());
      const {
        enterprise: { pixAccount: pixAccountId },
      } = yield select((state) => state.settings);

      const pixAccountDoc = yield db
        .collection('pix-accounts')
        .doc(pixAccountId)
        .get();
      if (!pixAccountDoc.exists) {
        yield put(settingsActions.setPixAccount({}));
      } else {
        yield put(
          settingsActions.setPixAccount({
            id: pixAccountDoc.id,
            ...pixAccountDoc.data(),
          })
        );
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(appActions.setError(e?.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCreateSubaccount() {
  while (true) {
    const { payload: accountData } = yield take(
      settingsActions.createSubaccount
    );
    yield put(appActions.startLoading());
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: '/wallet/create-subaccount',
        local: localApi,
      });
      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        ...opts,
        payload: { accountData },
      });
      if (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(appActions.setError(error));
        yield put(settingsActions.createSubaccountFail());
        yield put(appActions.finishLoading());
      } else {
        yield put(settingsActions.createSubaccountSuccess());
        yield put(appActions.finishLoading());
        yield put(
          notifierActions.enqueue({
            message: 'Subconta criada com sucesso !',
            options: {
              variant: 'success',
            },
          })
        );
        yield put(settingsActions.setIsSubaccountFormOpen(false));
        yield put(settingsActions.closeSettings());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(settingsActions.createSubaccountFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetSubaccountDocumentStatus() {
  while (true) {
    yield take(settingsActions.getSubaccountDocumentStatus);
    yield put(appActions.startLoading());
    try {
      const { localApi, pixSubaccount } = yield select(
        (state) => state.settings
      );
      const { url, headers, ...opts } = yield getApi({
        path: `/wallet/${encodeURI(pixSubaccount.id)}/document-status`,
        local: localApi,
      });
      const { error, status } = yield rpcCall({
        url,
        method: 'get',
        headers,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(settingsActions.getSubaccountDocumentStatusFail());
      } else {
        yield put(settingsActions.getSubaccountDocumentStatusSuccess(status));
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(settingsActions.getSubaccountDocumentStatusFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchActivateSubaccountPixKey() {
  while (true) {
    yield take(settingsActions.activateSubaccountPixKey);
    yield put(appActions.startLoading());
    try {
      const { localApi, pixSubaccount } = yield select(
        (state) => state.settings
      );
      const { url, headers, ...opts } = yield getApi({
        path: `/wallet/${encodeURI(pixSubaccount.id)}/create-pix-key`,
        local: localApi,
      });
      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        ...opts,
      });
      if (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(appActions.setError(error));
        yield put(settingsActions.activateSubaccountPixKeyFail());
        yield put(appActions.finishLoading());
      } else {
        yield put(settingsActions.activateSubaccountPixKeySuccess());
        yield put(appActions.finishLoading());
        yield put(
          notifierActions.enqueue({
            message: 'Chave pix ativada com sucesso !',
            options: {
              variant: 'success',
            },
          })
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(settingsActions.createSubaccountFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchUpdateSubaccount() {
  while (true) {
    const { payload: accountData } = yield take(
      settingsActions.updateSubaccount
    );
    yield put(appActions.startLoading());
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: '/wallet/update-subaccount',
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        ...opts,
        payload: { accountData },
      });
      if (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        yield put(appActions.setError(error));
        yield put(settingsActions.updateSubaccountFail());
        yield put(appActions.finishLoading());
      } else {
        yield put(settingsActions.updateSubaccountSuccess());
        yield put(appActions.finishLoading());
        yield put(
          notifierActions.enqueue({
            message: 'Subconta alterada com sucesso !',
            options: {
              variant: 'success',
            },
          })
        );
        yield put(settingsActions.setIsSubaccountEditFormOpen(false));
        yield put(settingsActions.closeSettings());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(settingsActions.updateSubaccountFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetDownloadLink() {
  while (true) {
    const {
      payload: { id, buttonId },
    } = yield take(settingsActions.getDownloadLink);
    try {
      yield put(appActions.startLoadingButton(buttonId));

      const { url, headers, ...opts } = yield getApi({
        path: `/documents/${id}`,
        apiVersion: 'v2',
      });
      const { url: downloadUrl } = yield ky(url, {
        method: 'GET',
        headers,
        ...opts,
      }).json();

      if (window && window.open) {
        const newTab = window.open(downloadUrl, '_blank');
        if (newTab) newTab.focus();
      }
      yield put(appActions.stopLoadingButton(buttonId));
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(appActions.stopLoadingButton(buttonId));
    }
  }
}

export default [
  watchInit,
  watchLoadEnterprise,
  watchSaveEnterprise,
  watchGetWASessions,
  watchGetPixAccounts,
  watchGetPixAccount,
  watchChangeSettings,
  watchCreateSubaccount,
  watchGetPixSubaccount,
  watchGetSubaccountDocumentStatus,
  watchActivateSubaccountPixKey,
  watchUpdateSubaccount,
  watchGetDownloadLink,
];
