'use strict';
import { makeStyles } from '@mui/styles';

import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import EditFormField from '../../components/EditForm/EditFormField.jsx';
import { monitorActions } from './monitor-slice.js';

const useStyles = makeStyles((theme) => ({
  clsMsgField: {
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(255,255,255,0.8)' : undefined,
  },
}));

function FormSendMsg() {
  const { clsMsgField } = useStyles();

  const dispatch = useDispatch();
  const {
    monitor: { selected, isAwaitingSendMsg },
  } = useSelector((state) => state);

  const [msgToSend, setMsgToSend] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      monitorActions.sendMsg({
        jid: selected.pix.jid,
        msgs: [{ type: 'text', content: msgToSend }],
      })
    );
    setMsgToSend('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box padding={2} display="flex" flexDirection="row">
        <EditFormField
          disabled={isAwaitingSendMsg}
          field={{
            name: 'message',
            label: 'Mensagem',
            type: 'text',
          }}
          value={msgToSend}
          onChange={(v) => setMsgToSend(v)}
          InputProps={{ classes: { root: clsMsgField } }}
        />
        <span>
          <ButtonWithProgress
            tooltipTitle="Enviar mensagem"
            disabled={msgToSend === ''}
            isLoading={isAwaitingSendMsg}
            Component={IconButton}
            color="primary"
            type="submit"
          >
            <SendIcon />
          </ButtonWithProgress>
        </span>
      </Box>
    </form>
  );
}

FormSendMsg.propTypes = {};
export default FormSendMsg;
