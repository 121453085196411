'use strict';
import { Chip, colors, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export default function IssueType({ type }) {
  const theme = useTheme();
  // eslint-disable-next-line prefer-destructuring
  const backgroundColor = colors.red[600];
  const color = theme.palette.getContrastText(backgroundColor);

  return (
    <Chip
      label={type}
      style={{
        backgroundColor,
        color,
      }}
      size="small"
    />
  );
}

IssueType.propTypes = {
  type: PropTypes.string.isRequired,
};
