'use strict';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Chip,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  lighten,
} from '@mui/material';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flexGrow: 1,
  },
  buttonGroup: {
    flexGrow: 0,
  },
  filterButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { isLoading } = useSelector((state) => state.app);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { numSelected, numRecords } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Box flexBasis={0.1} flexGrow={1}>
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selecionado{numSelected > 1 ? 's' : ''}
          </Typography>
        )}
        {numSelected === 0 && props.title && (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.title}
          </Typography>
        )}
      </Box>
      {props.searchField && <Box flexGrow={1}>{props.searchField}</Box>}
      {!props.searchField && props.hasFilter && !!props.filters['keywords'] && (
        <Box flexGrow={1}>
          <TextField
            variant="standard"
            placeholder="Refinar por palavra-chave"
            value={props.keywordSearch}
            onChange={(e) => props.onKeywordSearchChange(e.target.value)}
            fullWidth
            id="keyword-filter"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <Box flexBasis={0.1} flexGrow={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1} />
          {props.hasFilter && numRecords > 0 && (
            <Box paddingRight={1}>
              <Tooltip title="Filtros">
                <Button
                  // className={clsx(
                  //   props.isFilterActive && classes.filterButtonActive
                  // )}
                  onClick={props.onFilterClick}
                  aria-label="filter list"
                  startIcon={<FilterListIcon />}
                >
                  {props.isFilterActive ? (
                    <Chip
                      label={props.numFiltered}
                      color="secondary"
                      onDelete={props.onFilterResetClick}
                    />
                  ) : (
                    'Refinar'
                  )}
                </Button>
              </Tooltip>
            </Box>
          )}
          {numSelected === 0 && props.onNewClick !== undefined && (
            <Tooltip title="Criar novo">
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  aria-label="criar novo"
                  startIcon={<AddIcon />}
                  onClick={props.onNewClick}
                >
                  Novo
                </Button>
              </span>
            </Tooltip>
          )}
          {numSelected > 0 && props.onDeleteClick !== undefined && (
            <Tooltip title="Delete">
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={props.onDeleteClick}
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </span>
            </Tooltip>
          )}
          {numSelected > 0 && props.extraMenu !== undefined && (
            <IconButton
              aria-label="settings"
              onClick={(e) => handleMenuClick(e)}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {props.extraMenu && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {props.extraMenu.items.map(
            ({ handler, Icon, text, confirm }, key) => (
              <MenuItem
                key={key}
                onClick={() => {
                  props.onSubmenuClick(handler, confirm);
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </MenuItem>
            )
          )}
        </Menu>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.node,
  numRecords: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  numFiltered: PropTypes.number.isRequired,
  filters: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onDeactivateClick: PropTypes.func,
  onNewClick: PropTypes.func,
  hasFilter: PropTypes.bool.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  extraMenu: PropTypes.object,
  onSubmenuClick: PropTypes.func,
  keywordSearch: PropTypes.string,
  onKeywordSearchChange: PropTypes.func,
  onFilterResetClick: PropTypes.func,
  searchField: PropTypes.node,
};

export default EnhancedTableToolbar;
