import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';
import { journeysActions } from '../journeys/journeys-slice';
import { settingsActions } from './settings-slice';

const GeneralSettingsForm = ({ control, watchValues: _, ...otherProps }) => {
  const { enterprise, theme } = useSelector((state) => state.settings);
  const { data: journeys } = useSelector((state) => state.journeys);
  const { permissions } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(journeysActions.load());
  }, [dispatch]);
  const handleThemeChange = (checked) => {
    const theme = checked ? 'dark' : 'light';
    dispatch(settingsActions.changeSettings({ theme }));
  };

  return (
    <Grid container spacing={2} {...otherProps}>
      <Grid item xs={12}>
        <EditFormField
          field={{
            name: 'theme',
            label: 'Tema Escuro',
            type: 'boolean',
          }}
          defaultValue={theme === 'dark'}
          onChange={handleThemeChange}
        />
      </Grid>
      {permissions['cfg-adm'] && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EditFormField
              field={{
                name: 'ignoreCustomer',
                label: 'Ignora cadastro de clientes',
                type: 'boolean',
              }}
              defaultValue={enterprise?.ignoreCustomer}
              control={control}
              rules={{}}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'inTrial',
                label: 'Em avaliação',
                type: 'boolean',
              }}
              defaultValue={enterprise?.inTrial}
              control={control}
              rules={{}}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'trialEnd',
                label: 'Término da avaliação',
                type: 'date',
              }}
              defaultValue={enterprise?.trialEnd}
              control={control}
              rules={{ required: 'Obrigatório' }}
            />
          </Grid>
        </>
      )}
      {permissions['cfg-api'] && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EditFormField
              field={{
                name: 'appEnabled',
                label: 'API Habilitada',
                type: 'boolean',
              }}
              defaultValue={enterprise.application?.enabled}
              control={control}
              rules={{}}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'appWebhook',
                label: 'Webhook',
                type: 'text',
              }}
              defaultValue={enterprise.application?.webhook}
              control={control}
              rules={{
                minLength: {
                  value: 4,
                  message: 'Digite uma URL válida',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'appApiKey',
                label: 'Chave API',
                type: 'text',
              }}
              defaultValue={enterprise.application?.apiKey}
              control={control}
              rules={{
                minLength: {
                  value: 1,
                  message: 'Digite uma chave válida',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {journeys.length > 0 && (
              <EditFormField
                field={{
                  name: 'defaultJourneyId',
                  label: 'Jornada padrão',
                  type: 'select',
                  options: [
                    { value: undefined, label: '<Nenhuma>' },
                    ...journeys.map(({ id: value, name }) => ({
                      value,
                      label: name,
                    })),
                  ],
                }}
                defaultValue={enterprise.journeyId ?? ''}
                control={control}
                rules={{
                  required: 'Campo obrigatório',
                }}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
GeneralSettingsForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default GeneralSettingsForm;
