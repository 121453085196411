import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';
import WhatsappDialog from '../../components/WhatsappDialog';

const DigitalAgentForm = ({ control, watchValues }) => {
  const { enterprise } = useSelector((state) => state.settings);
  const [enterpriseName, enterpriseGender, agentName, agentGender] =
    watchValues;

  const exampleText =
    agentName && agentGender
      ? `Olá! Eu sou *${agentGender} ${agentName}*, ${agentGender} agente digital d${enterpriseGender} *${enterpriseName}*.`
      : `Olá! Eu sou _<preencher os campos acima>_ agente digital d${enterpriseGender} *${enterpriseName}*.`;
  const content = {
    contactName: agentName ?? 'Agente Digital',
    items: [
      {
        type: 'text',
        direction: 'in',
        text: exampleText,
        timestamp: Date.now(),
        accent: true,
      },
      {
        type: 'text',
        direction: 'in',
        text: 'Como vai, tudo bem?',
        timestamp: Date.now(),
      },
      {
        type: 'text',
        direction: 'out',
        text: 'Boa noite!',
        timestamp: Date.now(),
      },
      {
        type: 'text',
        direction: 'out',
        text: 'Estou bem, obrigado!',
        timestamp: Date.now(),
      },
    ],
  };

  return (
    <>
      <Typography variant="body2" color="textSecondary" component="p">
        Como o agente digital vai se apresentar.
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'agentName',
                label: 'Nome',
                type: 'text',
              }}
              defaultValue={enterprise?.agent?.name ?? ''}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 2,
                  message: 'Digite um nome válido',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'agentGender',
                label: 'Gênero',
                type: 'select',
                options: [
                  { value: 'a', label: 'Feminino' },
                  { value: 'o', label: 'Masculino' },
                ],
              }}
              defaultValue={enterprise?.agent?.gender ?? ''}
              control={control}
              rules={{
                required: 'Campo obrigatório',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              Exemplo:
            </Typography>
            <WhatsappDialog content={content} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
DigitalAgentForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default DigitalAgentForm;
