'use strict';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import TagList from '../../components/Tags/TagList';

function ApplyTagsForm({ onClose, onApply }) {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      tags: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });
  const handleChange = (checked, name) => {
    if (checked) {
      append({ name });
    } else {
      const indexFind = fields.map(({ name }) => name).indexOf(name);
      remove(indexFind);
    }
  };
  const onSubmit = ({ tags }) => {
    onApply(tags.map(({ name }) => name));
  };

  return (
    <Box display="flex" flexDirection="column">
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box p={2}>
          <TagList
            selected={fields}
            onClose={onClose}
            onChange={handleChange}
            onApply={isDirty ? handleSubmit(onSubmit) : undefined}
          />
        </Box>
      </form>
    </Box>
  );
}

ApplyTagsForm.propTypes = {
  onClose: PropTypes.func,
  onApply: PropTypes.func.isRequired,
};

export default ApplyTagsForm;
