'use strict';
import { all, put, take } from 'redux-saga/effects';
import enterprisesDao from '../../dao/enterprises';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { enterprisesActions } from './enterprises-slice';

function* watchLoadEnterprises() {
  while (true) {
    yield take(enterprisesActions.load);
    try {
      yield put(appActions.startLoading());
      const data = yield enterprisesDao.get();
      yield put(enterprisesActions.loadSuccess(data));
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(enterprisesActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchDeleteEnterprises() {
  while (true) {
    const { payload } = yield take(enterprisesActions.delete);
    try {
      yield put(appActions.startLoading());
      yield all(payload.map((v) => enterprisesDao.delete(v)));
      yield put(
        notifierActions.enqueue({
          message: 'Os registros foram excluídos com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      // yield put(appActions.createNotification(notification));
      yield put(enterprisesActions.deleteSuccess());
      yield put(enterprisesActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(enterprisesActions.deleteFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveEnterprise() {
  while (true) {
    const { payload } = yield take(enterprisesActions.save);

    try {
      yield put(appActions.startLoading());
      const { id } = payload;
      yield enterprisesDao.update(id, payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(enterprisesActions.saveSuccess());
      yield put(enterprisesActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchSaveEnterprise');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(enterprisesActions.saveFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchAddEnterprise() {
  while (true) {
    const { payload } = yield take(enterprisesActions.add);

    try {
      yield put(appActions.startLoading());
      yield enterprisesDao.add(payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(enterprisesActions.saveSuccess());
      yield put(enterprisesActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchAddEnterprise');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(enterprisesActions.saveFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchLoadEnterprises,
  watchDeleteEnterprises,
  watchSaveEnterprise,
  watchAddEnterprise,
];
