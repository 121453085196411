'use strict';

export default (domain) => {
  const domains = {
    'app.zetaone.com.br': {
      gender: 'a',
      name: 'Zeta One',
    },
    'condomi.zetaone.com.br': {
      gender: 'o',
      name: 'Condomi',
    },
    'eduqui.zetaone.com.br': {
      gender: 'o',
      name: 'Eduqui',
    },
    'contabi.zetaone.com.br': {
      gender: 'o',
      name: 'Contabi',
    },
    'winpix.zetaone.com.br': {
      gender: 'o',
      name: 'WinPix',
    },
  };

  return domains[domain.replace(/-dev/g, '').replace(/-stg/g, '')] ??
  domains['app.zetaone.com.br'];
};
