'use strict';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { getFirestoreConsoleLink } from '../helpers/firebase';
import FirebaseIcon from '../icons/FirebaseIcon';

export default function FirebaseConsoleIconLink({ doc }) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={getFirestoreConsoleLink(doc)}
    >
      <FirebaseIcon />
    </Link>
  );
}
FirebaseConsoleIconLink.propTypes = {
  doc: PropTypes.string.isRequired,
};
