import { createSlice } from '@reduxjs/toolkit';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    initialDate: Date.now(),
    finalDate: Date.now(),
    balance: undefined,
    fees: undefined,
    isBalanceLoading: true,
    isTransferLoading: false,
    transactions: [],
    areTransactionsLoading: false,
    selected: undefined,
  },
  reducers: {
    setInitialDate: (state, { payload }) => {
      state.initialDate = payload;
    },
    setFinalDate: (state, { payload }) => {
      state.finalDate = payload;
    },
    getBalance: (state) => {
      state.isBalanceLoading = true;
    },
    getBalanceSuccess: (state, { payload: { balance, fees } }) => {
      state.balance = balance;
      state.fees = fees;
      state.isBalanceLoading = false;
    },
    getBalanceFail: (state) => {
      state.balance = undefined;
      state.isBalanceLoading = false;
    },
    getTransactions: (state) => {
      state.areTransactionsLoading = true;
    },
    getTransactionsSuccess: (state, { payload }) => {
      state.transactions = payload;
      state.areTransactionsLoading = false;
    },
    getTransactionsFail: (state) => {
      state.transactions = [];
      state.areTransactionsLoading = false;
    },
    transfer: (state) => {
      state.isTransferLoading = true;
    },
    transferSuccess: (state) => {
      state.isTransferLoading = false;
    },
    transferFail: (state) => {
      state.isTransferLoading = false;
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
