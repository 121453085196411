import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import TagChip from '../../components/Tags/TagChip';
import { formatCustomFieldValue } from '../../helpers/formatter';
import removeSpecialChars from '../../helpers/remove-special-chars';
import { mailingActions } from './mailing-slice';

const useStylesIcon = makeStyles((theme) => ({
  clsSuccessIcon: {
    color: theme.palette.success.main,
  },
}));
const InvoiceIcon = ({ status }) => {
  const { clsSuccessIcon } = useStylesIcon();
  switch (status) {
    case 'OK':
      return <CheckIcon fontSize="small" className={clsSuccessIcon} />;
    case 'DISABLED':
      return <BlockIcon fontSize="small" color="error" />;
    case 'ERROR':
      return <ErrorIcon fontSize="small" color="error" />;
  }
};
InvoiceIcon.propTypes = {
  status: PropTypes.string.isRequired,
};
const TagComponent = (props) => {
  return <TagChip {...props} size="small" />;
};

const useStyles = makeStyles((theme) => ({
  clsSuccessIcon: {
    color: theme.palette.success.main,
  },
  clsError: {
    color: theme.palette.error.main,
    textDecoration: 'line-through',
  },
  clsErrorCard: {
    borderColor: theme.palette.error.main,
  },
  clsRecordError: {
    color: theme.palette.error.main,
    fontStyle: 'italic',
  },
  clsField: {
    height: '100%',
  },
  clsFieldContent: {
    '&:last-child': {
      padding: theme.spacing(1),
    },
  },
}));

const filters = {
  keywords: {
    type: 'keywords',
    label: 'Palavras-chave',
    currentValue: '',
  },
  cpcStatusWithSort: {
    type: 'boolean',
    label: 'CPC',
    currentValue: { true: true, false: true },
  },
  tags: {
    type: 'tag-list',
    label: 'Marcadores',
    currentValue: [],
  },
  disabled: {
    type: 'boolean',
    label: 'Concluídas',
    currentValue: { true: false, false: true },
  },
};

function MailingPreview() {
  const { clsSuccessIcon, clsError, clsField, clsFieldContent, clsErrorCard } =
    useStyles();
  const dispatch = useDispatch();

  const {
    journeys: { selected, metadata, csvFields, csvFixedFields, sendType },
    mailing: {
      mailingPreview,
      charge: { fileProcessingResult: { errorCount, disabledCount } = {} } = {},
    },
  } = useSelector((state) => state);
  const { properties } = metadata[selected.guideId];
  const [detail, setDetail] = useState();

  const journeyFields = _.pickBy(properties, (_v, key) =>
    Object.keys(selected).find((k) => k === key)
  );
  const completeFields = { ...csvFields, ...journeyFields };

  const formatCustomValueWithError = (v, field) => {
    if (v?.props?.error)
      return (
        <>
          <Typography variant="body2" className={clsError}>
            {v.value ?? v ?? '<vazio>'}
          </Typography>
          <Typography variant="caption" color="error">
            {v.props.error}
          </Typography>
        </>
      );

    if (v?.props?.disabled)
      return (
        <>
          <Typography variant="body2">
            {formatCustomFieldValue(v.value ?? v, field) ?? '<vazio>'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {v.props.disabled}
          </Typography>
        </>
      );

    if (v?.props?.recordError)
      return (
        <>
          <Typography variant="body1" color="error">
            {v.props.recordError}. Conteúdo da linha:
          </Typography>
          <Typography variant="body1" color="error">
            {v.props.raw}
          </Typography>
        </>
      );

    return formatCustomFieldValue(v.value ?? v, field);
  };

  if (!mailingPreview) return <></>;

  const [firstField] = [...Object.keys(csvFixedFields)].sort(
    (a, b) => csvFields[a].showOrder - csvFields[b].showOrder
  );

  const enhancedData = mailingPreview.map(
    ({ lineNumber, disabled, record, errors, raw }, id) => ({
      id,
      lineNumber,
      disabled: (disabled ?? '') !== '',
      ...(errors.recordError
        ? {
            [firstField]: {
              value: errors.recordError,
              props: {
                sortValue: lineNumber,
                filterValue: lineNumber,
                colSpan: errors.recordError
                  ? Object.keys(csvFixedFields).length
                  : 1,
                recordError: errors.recordError,
                raw,
              },
            },
          }
        : {
            ..._.mapValues(record, (value, key) => ({
              value,
              props: {
                sortValue: value,
                filterValue: value,
                error: errors[key],
                hidden: !!errors.recordError,
              },
            })),
            name: {
              value: record.name,
              props: {
                sortValue: record.name,
                filterValue: removeSpecialChars(record.name),
                error: errors.name,
              },
            },
            docNumber: {
              value: record.docNumber,
              props: {
                sortValue: record.docNumber,
                filterValue: record.docNumber.replace(/[^\d]+/g, ''),
                error: errors.docNumber,
              },
            },
            tags: record.tags ? record.tags.split('#') : [],
            keywords: removeSpecialChars(
              [
                record.name,
                record.phone,
                record.docNumber,
                record.invoiceId,
                ...(record.tags ? record.tags.split('#') : []),
              ].join(' ')
            ),
          }),

      status:
        Object.keys(errors).length === 0 && !disabled
          ? 'OK'
          : disabled
          ? 'DISABLED'
          : 'ERROR',
    })
  );

  const columns = [
    {
      id: 'lineNumber',
      numeric: false,
      disablePadding: true,
      label: 'Linha',
      showOrder: -1000,
    },
    ...Object.keys(csvFixedFields)
      .sort((a, b) => csvFields[a].showOrder - csvFields[b].showOrder)
      .map((key) => {
        const field = csvFields[key];
        return {
          id: key,
          numeric: false,
          disablePadding: false,
          label: field.label,
          showOrder: field.showOrder,
          formatter: (v) => formatCustomValueWithError(v, field),
        };
      }),
    {
      id: 'tags',
      numeric: false,
      disablePadding: true,
      align: 'center',
      label: 'Marcadores',
      formatter: (value) => TagComponent({ value }),
    },
    {
      id: 'status',
      label: '',
      showOrder: 9999,
      // eslint-disable-next-line react/display-name
      formatter: (v) => <InvoiceIcon status={v} />,
    },
  ].sort(({ showOrder: a }, { showOrder: b }) => a > b);

  let title;
  if (errorCount > 0)
    title = (
      <Typography variant="h6" color="error">
        {sendType === 'csv' &&
          'Existem erros no arquivo. Verifique e faça o upload novamente.'}
        {sendType === 'db' &&
          'Existem erros no cadastro. Verifique e tente novamente.'}
      </Typography>
    );
  else
    title = (
      <Box paddingTop={disabledCount > 0 ? 1 : 0}>
        <Typography variant="h6">Selecione as cobranças</Typography>
      </Box>
    );

  return (
    <>
      <Box paddingTop={1}>
        <Paper variant="outlined">
          {disabledCount > 0 && (
            <Box m={1} display="flex" flexDirection="row" alignItems="center">
              <Box marginRight={0.5}>
                <BlockIcon fontSize="small" color="error" />
              </Box>
              <Typography variant="caption" color="textSecondary">
                Cobranças concluídas ou em andamento, não é possível enviar
                novamente.
              </Typography>
            </Box>
          )}
          <EnhancedTable
            title={title}
            onRowClick={(d) => {
              setDetail(mailingPreview[d.id]);
            }}
            data={enhancedData}
            columns={columns}
            filters={filters}
            orderBy="lineNumber"
            disableSelection={errorCount > 0}
            onSelectionChange={(s) =>
              dispatch(mailingActions.setSelectedLines(s))
            }
            tableProps={{ size: 'small' }}
            rowsPerPage={100}
            skipUndefinedData={true}
          />
        </Paper>
      </Box>
      <ResponsiveDialog
        disableEscapeKeyDown
        maxWidth="md"
        open={detail !== undefined}
        onClose={() => setDetail()}
      >
        <DialogTitle id="confirmation-dialog-title">Detalhes</DialogTitle>
        <DialogContent>
          {detail && (
            <Grid container spacing={1}>
              {detail.errors?.recordError ? (
                <Grid item xs={12} sm={12}>
                  <Card
                    variant="outlined"
                    className={clsx(clsField, clsErrorCard)}
                  >
                    <CardContent className={clsFieldContent}>
                      <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>
                          <Typography variant="caption">
                            Erro de registro
                          </Typography>
                          <Typography variant="body1" color="primary">
                            <Typography variant="body1" color="error">
                              {detail.errors.recordError}. Conteúdo da linha:
                            </Typography>
                            <Typography variant="body1" color="error">
                              {detail.raw}
                            </Typography>
                          </Typography>
                        </Box>
                        <ErrorIcon fontSize="small" color="error" />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                [...Object.keys(completeFields)]
                  .sort(
                    (a, b) =>
                      completeFields[a].showOrder - completeFields[b].showOrder
                  )
                  .map((key) => (
                    <Grid key={key} item xs={6} sm={4}>
                      <Card
                        variant="outlined"
                        className={clsx(
                          clsField,
                          detail.errors[key] && clsErrorCard
                        )}
                      >
                        <CardContent className={clsFieldContent}>
                          <Box display="flex" flexDirection="row">
                            <Box flexGrow={1}>
                              <Typography variant="caption">
                                {completeFields[key].label}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="primary"
                                className={clsx(detail.errors[key] && clsError)}
                              >
                                {detail.errors[key]
                                  ? `${
                                      detail.record[key] ?? selected[key]
                                        ? detail.record[key].toString()
                                        : '<vazio>'
                                    }`
                                  : formatCustomValueWithError(
                                      detail.record[key] ?? selected[key],
                                      completeFields[key]
                                    )}
                              </Typography>
                              {detail.errors[key] && (
                                <Typography variant="caption" color="error">
                                  {detail.errors[key]}
                                </Typography>
                              )}
                            </Box>
                            {detail.errors[key] ? (
                              <ErrorIcon fontSize="small" color="error" />
                            ) : (
                              <CheckIcon
                                fontSize="small"
                                className={clsSuccessIcon}
                              />
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetail()}>OK</Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
}

MailingPreview.propTypes = {};
export default MailingPreview;
