import InputIcon from '@mui/icons-material/Input';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Popover, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 250,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function NestedList({ header, parents = [], items, onSelect }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e, item) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  const handleSelect = (item) => {
    setAnchorEl(null);
    setOpen('');
    onSelect(item);
  };

  if (Object.keys(items).length === 0)
    return (
      <Box padding={2}>
        <Typography>Nenhum campo disponível</Typography>
      </Box>
    );
  else
    return (
      <>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            header ? (
              <ListSubheader component="div" id="nested-list-subheader">
                {header}
              </ListSubheader>
            ) : null
          }
          className={classes.root}
        >
          {Object.keys(items).map((item) => (
            <ListItem
              key={item}
              button
              onClick={
                items[item].items
                  ? (e) => {
                      handleClick(e, item);
                    }
                  : () =>
                      handleSelect({
                        parent: parents.join('.'),
                        name: item,
                        ...items[item],
                      })
              }
            >
              <ListItemText primary={items[item].label} />
              {!items[item].items ? (
                <Box marginLeft={2}>
                  <InputIcon fontSize="small" />
                </Box>
              ) : open === item ? (
                <NavigateBeforeIcon />
              ) : open === '' ? (
                <NavigateNextIcon />
              ) : null}
            </ListItem>
          ))}
        </List>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          {open !== '' && (
            <NestedList
              parents={[...parents, open]}
              items={items[open].items}
              onSelect={handleSelect}
            />
          )}
        </Popover>
      </>
    );
}

NestedList.propTypes = {
  header: PropTypes.string,
  parents: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default NestedList;
