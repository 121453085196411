import { makeStyles, withStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BuildIcon from '@mui/icons-material/Build';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
  Button,
  Divider,
  IconButton,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ScheduleIcon from '@mui/icons-material/Schedule';

import clsx from 'clsx';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import SendJourneyStep1 from './SendJourneyStep1';
import SendJourneyStep2 from './SendJourneyStep2';
// import SendJourneyStep3 from './SendJourneyStep3';
import SendJourneyStep4 from './SendJourneyStep4';

import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { appActions } from '../app/app-slice';
import { journeysActions } from './journeys-slice';

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.secondary.main,

      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.secondary.main,
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.background.field,
    borderRadius: 0,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.field,
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: theme.shadows[4],
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
    // backgroundImage:
    // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <CallSplitIcon />,
    2: <GroupAddIcon />,
    3: <ScheduleIcon />,
    4: <PlaylistAddCheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  clsButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
}));

function SendJourney({ onClose }) {
  const { clsSecretActive, clsButton } = useStyles();
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();

  const {
    app: { isSecretActive },
    journeys: { sendType, sendState, sendActiveStep },
    mailing: {
      uploadedFiles,
      isProcessingPreview,
      charge: { chid, fileProcessingResult: { errorCount } = {} } = {},
      selectedLines,
    },
    auth: { permissions },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(journeysActions.setSendType(null));
  }, [dispatch]);

  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const isNextDisabled = () => {
    switch (sendActiveStep) {
      case 0:
        return sendType === null;
      case 1:
        // return false;
        return (
          (sendType === 'csv' &&
            (isProcessingPreview ||
              uploadedFiles.length === 0 ||
              (errorCount ?? 1) > 0 ||
              (selectedLines ?? []).length === 0)) ||
          (sendType === 'db' &&
            (isProcessingPreview ||
              chid === undefined ||
              (errorCount ?? 1) > 0 ||
              (selectedLines ?? []).length === 0)) ||
          (sendType === 'custom-integration' &&
            (isProcessingPreview ||
              chid === undefined ||
              (errorCount ?? 1) > 0 ||
              (selectedLines ?? []).length === 0))
        );
      // case 2:
      //   break;
      case 2:
        return sendState !== 'START';
    }
  };

  const handleNext = () => {
    dispatch(journeysActions.setSendActiveStep(sendActiveStep + 1));
  };

  const handleBack = () => {
    dispatch(journeysActions.setSendActiveStep(sendActiveStep - 1));
  };

  const handleScroll = (ref) => {
    setIsAtBottom(ref.scrollHeight - ref.scrollTop === ref.clientHeight);
    setIsAtTop(ref.scrollTop === 0);
  };

  const handleSend = () => {
    dispatch(journeysActions.send());
  };

  return (
    <EditForm>
      <EditFormHeader
        title={
          <Typography variant="h4" component="span">
            Enviar
          </Typography>
        }
        onCloseClick={onClose}
        actions={
          permissions['dbg'] && (
            <IconButton
              onClick={() =>
                dispatch(appActions.setIsSecretActive(!isSecretActive))
              }
              className={clsx(isSecretActive && clsSecretActive)}
              size="large"
            >
              <BuildIcon />
            </IconButton>
          )
        }
      />
      <Stepper
        alternativeLabel={isSmallScreen}
        connector={<ColorlibConnector />}
        activeStep={sendActiveStep}
      >
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {!isSmallScreen && 'Tipo de envio'}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {!isSmallScreen && 'Faturas'}
          </StepLabel>
        </Step>
        {/* <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            Linha do Tempo
          </StepLabel>
        </Step> */}
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {!isSmallScreen && 'Confirmação'}
          </StepLabel>
        </Step>
      </Stepper>
      {!isAtTop && !isSmallScreen && <Divider />}
      <EditFormContent
        overflow="auto"
        onScroll={(e) => {
          handleScroll(e.target);
        }}
      >
        <SendJourneyStep1 visible={sendActiveStep === 0} />
        <SendJourneyStep2 visible={sendActiveStep === 1} />
        {/* <SendJourneyStep3 visible={sendActiveStep === 2} /> */}
        <SendJourneyStep4 visible={sendActiveStep === 2} />
      </EditFormContent>
      {!isAtBottom && <Divider />}
      <EditFormFooter>
        <EditFormMessage></EditFormMessage>
        <EditFormAction>
          <Button
            disabled={sendActiveStep === 0}
            onClick={handleBack}
            className={clsButton}
          >
            Voltar
          </Button>
          {sendActiveStep < 2 && (
            <Button
              disabled={isNextDisabled()}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={clsButton}
            >
              Próximo
            </Button>
          )}
          {sendActiveStep === 2 && (
            <Button
              disabled={isNextDisabled()}
              variant="contained"
              color="primary"
              onClick={handleSend}
              className={clsButton}
            >
              Enviar
            </Button>
          )}
        </EditFormAction>
      </EditFormFooter>
    </EditForm>
  );
}

SendJourney.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default SendJourney;
