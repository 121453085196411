import { Editor, Transforms, Text, Path } from 'slate';

const CustomRichTextEditor = {
  serialize({ value, useExample = false, useLabel = false }) {
    return value
      ? value
          .map((n) => {
            return n.children
              .map(
                ({
                  type,
                  text,
                  bold,
                  italic,
                  strike,
                  name,
                  example,
                  label,
                }) => {
                  let r = '';
                  if (type === 'field')
                    r = useExample
                      ? example
                      : useLabel
                      ? `[${label}]`
                      : `#{${name}}`;
                  else r = text;
                  if (bold) r = `*${r}*`;
                  if (italic) r = `_${r}_`;
                  if (strike) r = `~${r}~`;
                  return r;
                }
              )
              .join('');
          })
          .join('\n')
      : '';
  },
  // deserialize(v) {
  // const replacedContent = r.content.replace(/#\{(.*?)\}/g, (match, group) => {
  //   try {
  //     return variableParser(group, this);
  //   } catch (e) {
  //     this.log(e);
  //     return '????????';
  //   }
  // });
  // },
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true,
    });
    return !!match;
  },
  isItalicMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.italic === true,
      universal: true,
    });
    return !!match;
  },
  isStrikeMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.strike === true,
      universal: true,
    });
    return !!match;
  },
  getFieldCurrent(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === 'field',
    });
    return match;
  },
  getFieldAfter(editor) {
    const [match] = Editor.nodes(editor, {
      at: Editor.after(editor, editor.selection.focus),
      match: (n) => n.field,
    });
    return match;
  },
  getFieldBefore(editor) {
    const [match] = Editor.nodes(editor, {
      at: Editor.before(editor, editor.selection.focus),
      match: (n) => n.field,
    });
    return match;
  },
  toggleBoldMark(editor) {
    const isActive = CustomRichTextEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      {
        match: (n) => Text.isText(n) | (n.type === 'field'),
        split: !CustomRichTextEditor.getFieldCurrent(editor),
      }
    );
  },
  toggleItalicMark(editor) {
    const isActive = CustomRichTextEditor.isItalicMarkActive(editor);
    Transforms.setNodes(
      editor,
      { italic: isActive ? null : true },
      {
        match: (n) => Text.isText(n) | (n.type === 'field'),
        split: !CustomRichTextEditor.getFieldCurrent(editor),
      }
    );
  },
  toggleStrikeMark(editor) {
    const isActive = CustomRichTextEditor.isStrikeMarkActive(editor);
    Transforms.setNodes(
      editor,
      { strike: isActive ? null : true },
      {
        match: (n) => Text.isText(n) | (n.type === 'field'),
        split: !CustomRichTextEditor.getFieldCurrent(editor),
      }
    );
  },
  skipToNextNode(editor, currentNode) {
    const [_node, path] = currentNode;
    const nextPath = Path.next(path);
    const nextNode = Editor.next(editor, { at: path });
    if (!nextNode || nextNode[0].field) {
      Transforms.insertNodes(editor, { text: ' ' }, { at: nextPath });
    }
    Transforms.setSelection(editor, {
      anchor: { offset: 0, path: nextPath },
      focus: { offset: 0, path: nextPath },
    });
  },
  insertNode(editor, node) {
    Transforms.insertNodes(editor, node);
  },
  deleteNode(editor, id) {
    Transforms.removeNodes(editor, {
      at: [],
      match: (n) => {
        return n.id === id;
      },
    });
  },
  togglePreview(editor, isPreview) {
    Transforms.setNodes(
      editor,
      { isPreview },
      {
        at: [],
        match: (n) => {
          return n.type === 'field';
        },
      }
    );
  },
};

export default CustomRichTextEditor;
