'use strict';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import BuildIcon from '@mui/icons-material/Build';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import FirebaseConsoleLink from '../../components/FirebaseConsoleLink';
import ExternalPaymentWizard from './ExternalPaymentWizard';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { appActions } from '../app/app-slice';
import { issuesActions } from './issues-slice';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
}));

export default function FormSolveExternalIssue({ onCancelClick }) {
  const { clsWarning, clsSecretActive } = useStyles();
  const dispatch = useDispatch();
  const [isSolveWithoutActionConfirmOpen, setIsSolveWithoutActionConfirmOpen] =
    useState();
  const {
    auth: { permissions },
    app: { isSecretActive },
    issues: {
      isAwaitingCommand,
      selectedExternalIssue,
      issueSolve: { solveType, solvePath },
    },
  } = useSelector((state) => state);

  if (!selectedExternalIssue) return <></>;
  return (
    <>
      <EditForm>
        <EditFormHeader
          title="Resolver pendência"
          onCloseClick={onCancelClick}
          actions={
            permissions['dbg'] && (
              <IconButton
                onClick={() =>
                  dispatch(appActions.setIsSecretActive(!isSecretActive))
                }
                className={clsx(isSecretActive && clsSecretActive)}
                size="large"
              >
                <BuildIcon />
              </IconButton>
            )
          }
        />
        {isSecretActive && (
          <>
            <Box>
              <FirebaseConsoleLink
                doc={`/external-issues/${selectedExternalIssue.id}`}
              />
            </Box>
          </>
        )}
        {solveType === 'EXTERNAL_PAYMENT' && <ExternalPaymentWizard />}
        <EditFormFooter>
          <EditFormMessage />
          <EditFormAction display="flex" flexDirection="row">
            <Tooltip title="Voltar">
              <Box marginRight={1}>
                <Button
                  disabled={solvePath.length === 1 || isAwaitingCommand}
                  onClick={() => {
                    dispatch(issuesActions.rewindSolvePath());
                  }}
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </Box>
            </Tooltip>
            <ButtonWithProgress
              tooltipTitle="Voltar"
              onClick={() => setIsSolveWithoutActionConfirmOpen(true)}
              variant="contained"
              color="primary"
              disabled={isAwaitingCommand}
              isLoading={isAwaitingCommand}
              startIcon={<CheckIcon />}
            >
              Marcar como Resolvida
            </ButtonWithProgress>
          </EditFormAction>
        </EditFormFooter>
      </EditForm>
      <ConfirmationDialog
        open={!!isSolveWithoutActionConfirmOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsSolveWithoutActionConfirmOpen(false),
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(
                  issuesActions.clearExternalIssue(selectedExternalIssue.id)
                );
                setIsSolveWithoutActionConfirmOpen(false);
              },
              startIcon: <CheckIcon />,
            },
          },
        ]}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <WarningIcon className={clsWarning} />
          <Box marginLeft={1}>
            <Typography component="span">ATENÇÃO !</Typography>
          </Box>
        </Box>
        <br />
        Tem certeza que deseja marcar essa pendência como resolvida?
      </ConfirmationDialog>
    </>
  );
}

FormSolveExternalIssue.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
};
