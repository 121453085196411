'use strict';
import firebase from './firebase';

const getWapiUrl = () => {
  const { projectId } = firebase.app().options;
  if (projectId === 'condomi-prod') return 'https://wapi.zetaone.com.br';
  else return `https://wapi-${projectId.split('-')[1]}.zetaone.com.br`;
};

export default getWapiUrl;
