'use strict';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';

import { Box, Typography } from '@mui/material';

import { formatValue } from '../../helpers/formatter';

import BuildIcon from '@mui/icons-material/Build';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import { pixStatusMap } from '../../helpers/pix-status';
import { appActions } from '../app/app-slice';
import { getMessenger } from '../wa-status/get-messenger';

const useStyles = makeStyles((theme) => ({
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
}));

function RemindersPlan({ onClose }) {
  const {
    auth: {
      userData: { dev },
    },
    app: { isSecretActive },
    monitor: { selected, journey },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { clsSecret, clsSecretActive } = useStyles();

  const { enterprise } = useSelector((state) => state.settings);
  const messenger = getMessenger(enterprise.waSessionId);

  let lastContactDate;
  if (selected.pix.status === pixStatusMap.PAGA)
    lastContactDate = selected.pix.resultAt;
  if (selected.pix.status === pixStatusMap.CANCELADA)
    lastContactDate = selected.pix.cancelledAt;
  if (selected.pix.status === pixStatusMap.EXPIRADA)
    lastContactDate = selected.pix.expiredAt;

  const { remindersPlan } = journey.chargeData.invoices.find(
    ({ txid }) => txid === selected.pix.id
  );
  const contactPlan = remindersPlan.filter(({ contactDate }) => !!contactDate);
  const lastContact =
    selected.pix.status === pixStatusMap.NO_PRAZO ||
    selected.pix.status === pixStatusMap.VENCIDA
      ? contactPlan.length - 1
      : contactPlan.length + 1;
  const enhancedRemindersPlan =
    selected.pix.status === pixStatusMap.NO_PRAZO ||
    selected.pix.status === pixStatusMap.VENCIDA
      ? remindersPlan
      : [
          ...contactPlan,
          { contactDate: lastContactDate, label: selected.pix.status },
        ];
  const filteredRemindersPlan =
    messenger === 'whatsapp'
      ? enhancedRemindersPlan.filter(({ label }) =>
          [
            'Primeiro envio da fatura',
            // 'Lembrete 3 dias antes do vencimento',
            'Lembrete no dia do vencimento',
            'Quebra de acordo - dia seguinte ao vencimento',
            // 'Quebra de acordo - 5 dias após o vencimento',
            'Quebra de acordo - 15 dias após o vencimento',
            // 'Quebra de acordo - 30 dias após vencimento',
            // 'Quebra de acordo - 45 dias após vencimento',
            // 'Quebra de acordo - último dia',
          ].includes(label)
        )
      : enhancedRemindersPlan;

  return (
    <EditForm>
      <EditFormHeader
        title="Régua"
        onCloseClick={onClose}
        actions={
          dev && (
            <IconButton
              onClick={() =>
                dispatch(appActions.setIsSecretActive(!isSecretActive))
              }
              className={clsx(isSecretActive && clsSecretActive)}
              size="large"
            >
              <BuildIcon />
            </IconButton>
          )
        }
      />
      <EditFormContent>
        <Box marginTop={2}>
          {isSecretActive && (
            <Typography
              variant="caption"
              color="textSecondary"
              className={clsSecret}
            >
              {selected.pix.id}
            </Typography>
          )}
          <Stepper activeStep={lastContact} orientation="vertical">
            {filteredRemindersPlan.map(
              ({ scheduledDate, contactDate, label }, index) => (
                <Step key={index}>
                  <StepLabel>
                    {contactDate
                      ? formatValue(contactDate, 'dateTime')
                      : scheduledDate
                      ? formatValue(scheduledDate, 'dateTime')
                      : ''}{' '}
                    - {label}
                  </StepLabel>
                  <StepContent></StepContent>
                </Step>
              )
            )}
          </Stepper>
        </Box>
      </EditFormContent>
      <EditFormFooter paddingTop={2}>
        <EditFormMessage></EditFormMessage>
        <EditFormAction
          display="flex"
          flexDirection="row"
          alignItems="center"
        ></EditFormAction>
      </EditFormFooter>
    </EditForm>
  );
}

RemindersPlan.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default RemindersPlan;
1;
