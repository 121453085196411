import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { customersActions } from '../customers/customers-slice';
import { usersActions } from '../users/users-slice';
import EditEnterprise from './EditEnterprise';
import { enterprisesActions } from './enterprises-slice';

const Check = ({ checked }) => {
  if (checked) return <CheckIcon style={{ color: green[500] }} />;
  else return <CloseIcon color="error" />;
};
Check.propTypes = {
  checked: PropTypes.bool,
};

const columns = [
  {
    id: 'enhancedName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'owner',
    numeric: false,
    disablePadding: true,
    label: 'Proprietário',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Criada em',
    formatter: (v) =>
      v.value ? moment(v.value).format('DD/MM/YYYY HH:mm') : '-',
  },
  {
    id: 'isActiveColumn',
    numeric: false,
    disablePadding: false,
    align: 'center',
    label: 'Ativa',
    formatter: (v) => Check({ checked: v.value }),
  },
  {
    id: 'plan',
    numeric: false,
    disablePadding: false,
    align: 'center',
    label: 'Plano',
  },
];

const filters = {
  keywords: {
    type: 'keywords',
    label: 'Nome',
    currentValue: '',
  },
  isActiveColumn: {
    type: 'boolean',
    label: 'Ativa',
    currentValue: {
      true: true,
      false: false,
    },
  },
};

export default function Enterprises() {
  const { data, selected } = useSelector((state) => state.enterprises);

  const { data: users } = useSelector((state) => state.users);

  const enhancedData = data.map((row) => ({
    ...row,
    owner: {
      value: users
        ? users
            .map(({ id, displayName, email }) => ({
              id,
              formattedName: `${displayName} - ${email}`,
            }))
            .find(({ id }) => id === row.ownerUid)?.formattedName ?? ''
        : '',
      props: {
        sortValue: users
          ? users
              .map(({ id, displayName, email }) => ({
                id,
                formattedName: `${displayName} - ${email}`,
              }))
              .find(({ id }) => id === row.ownerUid)?.formattedName ?? ''
          : '',
      },
    },
    createdAt: {
      value: row.createdAt,
      props: {
        sortValue: row.createdAt ?? 0,
      },
    },
    isActiveColumn: {
      value: !!row.isActive,
      props: {
        sortValue: row.isActive ? 0 : 1,
      },
    },
    enhancedName: `${row.name} (${row.pixKeyDestinationType})`,
    plan: {
      value:
        row.inTrial && row.trialDaysLeft >= 0
          ? `Avaliação - (faltam ${row.trialDaysLeft} dias)`
          : row.inTrial && row.trialDaysLeft < 0
          ? 'Avaliação expirada'
          : 'Completo',
      props: {
        sortValue: row.inTrial ? row.trialDaysLeft : 0,
      },
    },
    keywords: [row.name].join(' '),
  }));

  const dispatch = useDispatch();
  // const classes = useStyles();

  // const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    dispatch(enterprisesActions.load());
    dispatch(usersActions.load());
    dispatch(customersActions.loadMetadata());
  }, [dispatch]);

  const handleClose = (reload) => {
    if (reload) dispatch(enterprisesActions.load());
    dispatch(
      enterprisesActions.selectEnterprise({
        selected: undefined,
        selectedContext: undefined,
      })
    );
  };
  const handleEnterpriseClick = (journey, context) => {
    dispatch(
      enterprisesActions.selectEnterprise({
        selected: journey,
        selectedContext: context,
      })
    );
  };
  const handleDeleteConfirm = (selected) => {
    dispatch(enterprisesActions.delete(selected));
    // dispatch(
    //   enterprisesActions.selectEnterprise({ selected: null, selectedContext: null })
    // );
    // setIsDeleteConfirmationOpen(false);
  };
  // const handleDeleteClick = () => {
  //   setIsDeleteConfirmationOpen(true);
  // };

  if (enhancedData.length > 0) {
    return (
      <div>
        <EnhancedTable
          title="Empresas"
          onRowClick={(d) => handleEnterpriseClick(d, 'edit')}
          data={enhancedData}
          columns={columns}
          filters={filters}
          tableProps={{ size: 'small' }}
          orderBy="name"
          onDeleteConfirm={handleDeleteConfirm}
          onNewClick={() => handleEnterpriseClick({}, 'new')}
          rowsPerPage={50}
        />
        <ResponsiveDialog
          open={selected !== undefined}
          maxWidth="md"
          fullWidth
          disableEscapeKeyDown
        >
          {selected && <EditEnterprise data={selected} onClose={handleClose} />}
        </ResponsiveDialog>
      </div>
    );
  } else return <></>;
}
