import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import WhatsappDialog from '../../components/WhatsappDialog';

const Step2 = ({ control, logoutButton, ...otherProps }) => {
  const [enterpriseName, enterpriseGender, agentName, agentGender] = useWatch({
    control,
    name: ['enterpriseName', 'enterpriseGender', 'agentName', 'agentGender'],
  });
  const exampleText =
    agentName && agentGender
      ? `Olá! Eu sou *${agentGender} ${agentName}*, ${agentGender} agente digital d${enterpriseGender} *${enterpriseName}*.`
      : `Olá! Eu sou _<preencher os campos acima>_ agente digital d${enterpriseGender} *${enterpriseName}*.`;
  const content = {
    contactName: agentName ?? 'Agente Digital',
    items: [
      {
        direction: 'in',
        type: 'text',
        text: exampleText,
        timestamp: Date.now(),
        accent: true,
      },
      {
        direction: 'in',
        type: 'text',
        text: 'Como vai, tudo bem?',
        timestamp: Date.now(),
      },
      {
        direction: 'out',
        type: 'text',
        text: 'Boa noite!',
        timestamp: Date.now(),
      },
      {
        direction: 'out',
        type: 'text',
        text: 'Estou bem, obrigado!',
        timestamp: Date.now(),
      },
    ],
  };

  return (
    <EditForm {...otherProps}>
      <EditFormHeader title="Agente digital" actions={logoutButton} />
      <EditFormContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Agora preciso das informações sobre como o agente digital vai se
          apresentar.
        </Typography>
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'agentName',
                  label: 'Nome',
                  type: 'text',
                }}
                defaultValue={''}
                control={control}
                rules={{
                  required: 'Obrigatório',
                  minLength: {
                    value: 2,
                    message: 'Digite um nome válido',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'agentGender',
                  label: 'Gênero',
                  type: 'select',
                  options: [
                    { value: 'a', label: 'Feminino' },
                    { value: 'o', label: 'Masculino' },
                  ],
                }}
                defaultValue={''}
                control={control}
                rules={{
                  required: 'Campo obrigatório',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                Exemplo:
              </Typography>
              <WhatsappDialog content={content} />
            </Grid>
          </Grid>
        </Box>
      </EditFormContent>
    </EditForm>
  );
};
Step2.propTypes = {
  control: PropTypes.object,
  logoutButton: PropTypes.node,
};
export default Step2;
