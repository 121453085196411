import { Box } from '@mui/material';
import PropTypes from 'prop-types';

function EditFormAction({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

EditFormAction.propTypes = {
  children: PropTypes.node,
};

export default EditFormAction;
