import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';
import WhatsappDialog from '../../components/WhatsappDialog';
import getDomain from '../../helpers/domains';
import { formatValue } from '../../helpers/formatter';
import { enterprisesActions } from '../enterprises/enterprises-slice';

const EnterpriseForm = ({ control, watchValues, ...otherProps }) => {
  const { enterprise } = useSelector((state) => state.settings);
  const { permissions } = useSelector((state) => state.auth);
  const { data: enterprises } = useSelector((state) => state.enterprises);

  const dispatch = useDispatch();
  // const classes = useStyles();
  // const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    dispatch(enterprisesActions.load());
  }, [dispatch]);

  const [enterpriseName, enterpriseGender, _agentName, _agentGender] =
    watchValues;
  const exampleText =
    enterpriseGender && enterpriseName
      ? `Olá aqui é o Agente Digital d${enterpriseGender} *${enterpriseName}*.`
      : 'Olá aqui é o Agente Digital _<preencher os campos acima>_';
  const content = {
    contactName: 'Agente Digital',
    items: [
      {
        direction: 'in',
        type: 'text',
        text: exampleText,
        timestamp: Date.now(),
        accent: true,
      },
      {
        direction: 'in',
        type: 'text',
        text: 'Como vai, tudo bem?',
        timestamp: Date.now(),
      },
      {
        direction: 'out',
        type: 'text',
        text: 'Boa noite!',
        timestamp: Date.now(),
      },
      {
        direction: 'out',
        type: 'text',
        text: 'Estou bem, obrigado!',
        timestamp: Date.now(),
      },
    ],
  };

  const parentEnterpriseOptions = [
    {
      value: '_',
      label: '<Nenhuma>',
    },

    ...[
      ...enterprises
        .filter(({ id, isActive }) => id !== enterprise.id && isActive)
        .map(({ id, name, pixKeyDestinationType }) => ({
          value: id,
          label: `${name} (${pixKeyDestinationType})`,
        })),
    ].sort(({ label: a }, { label: b }) => a.localeCompare(b)),
  ];

  return (
    <>
      <Typography variant="body2" color="textSecondary" component="p">
        N{getDomain(window.location.hostname).gender}{' '}
        {getDomain(window.location.hostname).name} a conta é associada a uma
        empresa. Este será o nome que vai aparecer quando o agente digital se
        apresentar.
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2} {...otherProps}>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'enterpriseName',
                label: 'Nome',
                type: 'text',
              }}
              defaultValue={enterprise.name}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 3,
                  message: 'Digite um nome válido',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'enterpriseGender',
                label: 'Gênero',
                type: 'select',
                options: [
                  { value: 'a', label: 'Feminino' },
                  { value: 'o', label: 'Masculino' },
                ],
              }}
              defaultValue={enterprise.gender}
              control={control}
              rules={{
                required: 'Campo obrigatório',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              Exemplo:
            </Typography>
            <WhatsappDialog content={content} />
          </Grid>
          {permissions['cfg-adm'] && (
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'parentEnterprise',
                  label: 'Empresa principal',
                  type: 'select',
                  options: parentEnterpriseOptions,
                }}
                defaultValue={enterprise?.parentEnterprise}
                control={control}
                rules={{
                  required: 'Obrigatório',
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              Número utilizado para os lembretes:
            </Typography>
            <Typography variant="body1" component="p">
              {formatValue(enterprise.phoneNumber, 'phone')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
EnterpriseForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default EnterpriseForm;
