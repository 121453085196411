'use strict';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useController } from 'react-hook-form';
import RHFRichTextField from '../RHFRichTextField/RHFRichTextField';
import TransferList from './TransferList';

const useStyles = makeStyles((_theme) => ({
  tooltip: {
    maxWidth: 500,
  },
  fieldExample: {
    fontWeight: 'bold',
    borderWidth: '1px',
  },
}));

const parseValue = (value, type) => {
  switch (type) {
    case 'file':
    case 'text':
      return value;
    case 'decimal': {
      let parsed = parseFloat(value);
      return !isNaN(parsed) ? parsed : '';
    }
    case 'integer': {
      let parsed = parseInt(value);
      return !isNaN(parsed) ? parsed : '';
    }
  }
};

function EditFormField({
  rules,
  field,
  control,
  variant,
  InputProps,
  hidden,
  ...props
}) {
  const classes = useStyles();

  const {
    name,
    label,
    description,
    type,
    inputAdornment,
    options,
    tooltipHelpTitle,
    RichTextToolbarComponent,
    RichTextToolbarComponentProps,
  } = field;

  let component;
  let onChange, value, error, otherProps;
  ({ onChange, defaultValue: value, error, ...otherProps } = props);
  if (control) {
    ({
      field: { onChange, value },
      fieldState: { error },
    } = useController({
      name,
      control,
      rules: rules ?? {
        validate:
          type !== 'boolean'
            ? (v) => {
                return v !== undefined || 'Campo obrigatório';
              }
            : undefined,
        valueAsNumber: type === 'decimal' || type === 'integer',
        valueAsBoolean: type === 'boolean',
        pattern:
          type === 'integer'
            ? {
                value: /^[0-9]*$/,
                message: 'Digite apenas números',
              }
            : undefined,
      },
      defaultValue: props.defaultValue,
    }));
  } else {
    ({ onChange, defaultValue: value, error, ...otherProps } = props);
  }

  const inputType =
    type === 'decimal' || type === 'integer' ? 'number' : undefined;

  switch (type) {
    case 'message':
      component = (
        <RHFRichTextField
          name={name}
          label={label}
          error={!!error}
          helperText={error ? error.message : ''}
          value={value}
          onChange={onChange}
          fullWidth
          variant={variant ?? 'filled'}
          ToolbarComponent={RichTextToolbarComponent}
          ToolbarProps={RichTextToolbarComponentProps}
        />
      );
      break;
    case 'phoneList':
    case 'text':
    case 'decimal':
    case 'integer': {
      let inputProps = InputProps;
      if (inputAdornment)
        inputProps = {
          ...InputProps,
          [`${inputAdornment?.position ?? 'start'}Adornment`]: (
            <InputAdornment position={inputAdornment?.position ?? 'start'}>
              {inputAdornment.text}
            </InputAdornment>
          ),
        };
      else inputProps = InputProps;
      component = (
        <TextField
          name={name}
          label={label}
          value={value ?? ''}
          onChange={(e) => {
            onChange(parseValue(e.target.value, type));
          }}
          error={!!error}
          helperText={error ? error.message : ''}
          type={inputType}
          InputProps={inputProps}
          fullWidth
          variant={variant ?? 'filled'}
          {...otherProps}
        />
      );
      break;
    }
    case 'boolean':
      component = (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
              />
            }
            label={label}
          />
        </FormControl>
      );
      break;
    case 'check':
      component = (
        <FormControl fullWidth {...otherProps}>
          <Checkbox
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        </FormControl>
      );
      break;
    case 'futureDate':
    case 'date':
      component = (
        <DatePicker
          sx={{ width: '100%' }}
          clearable
          label={label}
          format="DD/MM/YYYY"
          value={moment(value ?? null)}
          disablePast={type === 'futureDate'}
          onChange={(v) => {
            onChange(v ? v.format('YYYY-MM-DD') : null);
          }}
          slotProps={{
            textField: {
              variant: variant ?? 'filled',
              id: otherProps.id,
              helperText: error ? error.message : '',
              error: !!error,
            },
          }}
          {...otherProps}
        />
      );
      break;
    case 'yearMonth':
      return (
        <DatePicker
          sx={{ width: '100%' }}
          clearable
          label={label}
          format="MM/YYYY"
          value={moment(value ?? null)}
          onChange={(v) => {
            onChange(v ? v.format('YYYY-MM') : null);
          }}
          openTo="month"
          views={['year', 'month']}
          slotProps={{
            textField: {
              variant: variant ?? 'filled',
              id: otherProps.id,
              helperText: error ? error.message : '',
              error: !!error,
            },
          }}
          showTodayButton
          autoOk
          {...otherProps}
        />
      );
    case 'select':
      component = (
        <FormControl error={!!error} variant={variant ?? 'filled'} fullWidth>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            label={label}
            onChange={onChange}
            value={value ?? ''}
            {...otherProps}
            sx={{ maxWidth: '600px' }}
          >
            {options.map((opt, index) => (
              <MenuItem key={index} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      );
      break;
    case 'transfer':
      component = <TransferList />;
      break;
    default:
  }

  if (hidden) return <></>;
  return (
    <Box display="flex" width={1} flexDirection="row" alignItems="center">
      <Box flexGrow={1}>{component}</Box>
      {(description || tooltipHelpTitle) && (
        <Box paddingLeft={1}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={tooltipHelpTitle ?? description}
          >
            <HelpIcon color="action" />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

EditFormField.propTypes = {
  rules: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string.isRequired,
    inputAdornment: PropTypes.shape({
      position: PropTypes.string,
      text: PropTypes.string,
    }),
    options: PropTypes.array,
    tooltipHelpTitle: PropTypes.node,
    RichTextToolbarComponent: PropTypes.elementType,
    RichTextToolbarComponentProps: PropTypes.object,
  }),
  defaultValue: PropTypes.any,
  control: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.object,
  variant: PropTypes.string,
  InputProps: PropTypes.object,
  hidden: PropTypes.bool,
};
export default EditFormField;
