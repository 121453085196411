import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const enterprisesSlice = createSlice({
  name: 'enterprises',
  initialState: {
    data: [],
  },
  reducers: {
    load: () => {},
    loadSuccess: (state, { payload: data }) => {
      state.data = data.map((row) => ({
        ...row,
        trialDaysLeft: row.trialEnd
          ? Math.floor(
              (row.trialEnd - moment(Date.now()).startOf('day')).valueOf() /
                1000 /
                3600 /
                24
            )
          : undefined,
      }));
    },
    loadFail: (state, _action) => {
      state.data = [];
    },
    selectEnterprise: (state, { payload: { selected, selectedContext } }) => {
      state.selected = selected;
      state.selectedContext = selectedContext;
    },
    delete: () => {},
    deleteSuccess: () => {},
    deleteFail: () => {},
    save: () => {},
    saveSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    saveFail: () => {},
    add: () => {},
    addSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    addFail: () => {},
  },
});

export const enterprisesActions = enterprisesSlice.actions;

export default enterprisesSlice.reducer;
