/* eslint-disable no-unused-vars */
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import DisplayFormField from '../../components/EditForm/DisplayFormField';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { settingsActions } from './settings-slice';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));

const documentStatusMap = {
  APPROVED: 'Aprovado',
  PENDING: 'Em aprovação',
  NOT_SENT: 'Não enviado',
};

const SubaccountDocumentForm = ({ onCloseClick }) => {
  const dispatch = useDispatch();
  const { isLoading, isSecretActive } = useSelector((state) => state.app);
  const { subaccountDocumentStatus, pixSubaccount } = useSelector(
    (state) => state.settings
  );
  const { accountStatus } = pixSubaccount ?? {};
  const { general } = accountStatus ?? {};

  const { clsWarning } = useStyles();
  const [copied, setCopied] = useState(false);

  const { data } = subaccountDocumentStatus ?? {};

  useEffect(() => {
    dispatch(settingsActions.getSubaccountDocumentStatus());
  }, [dispatch]);

  const handleCloseClick = async () => {
    onCloseClick();
  };
  const { onboardingUrl } = data
    ? data.find(({ onboardingUrl }) => !!onboardingUrl) ?? {}
    : {};
  return (
    <EditForm>
      <EditFormHeader title="Documentação" onCloseClick={handleCloseClick} />
      <EditFormContent overflow="auto">
        <Box p={1} display="flex" justifyContent="center">
          {isLoading && <CircularProgress />}
          {!isLoading && data && (
            <Grid container spacing={2}>
              {data.map(({ id, description, status, title }) => (
                <Grid item key={id} xs={12}>
                  <Typography variant="body1">
                    {title} - {documentStatusMap[status] ?? status}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {description}
                  </Typography>
                </Grid>
              ))}
              {general !== 'APPROVED' && onboardingUrl && (
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row">
                    <DisplayFormField
                      field={{
                        name: 'link',
                        label: 'Link para onboarding (*)',
                        type: 'text',
                      }}
                      value={onboardingUrl}
                    />
                    <CopyToClipboard
                      text={onboardingUrl}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton size="large">
                        <FileCopyIcon color={copied ? 'primary' : undefined} />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                  <Grid item md={12} sm={12} xs={12}>
                    {copied && (
                      <Typography
                        variant="caption"
                        color="primary"
                        align="center"
                        component="div"
                      >
                        Copiado para a área de transferência
                      </Typography>
                    )}
                    <Box marginTop={2}>
                      <Typography variant="caption" color="textSecondary">
                        (*) Deve ser acessado pelo responsável pela empresa ou
                        proprietário da conta
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </EditFormContent>
    </EditForm>
  );
};
SubaccountDocumentForm.propTypes = {
  onCloseClick: PropTypes.func,
};
export default SubaccountDocumentForm;
