import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditJourney from './EditJourney.jsx';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import SendJourney from './SendJourney.jsx';

import ResponsiveDialog from '../../components/ResponsiveDialog';
import { mailingActions } from '../mailing/mailing-slice';
import { journeysActions } from './journeys-slice';

export default function Journeys() {
  const {
    journeys: { data, selected, selectedContext, metadata },
    app: { isLoading },
    auth: { permissions, userData: { emailVerified, role } = {} },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sendHelpAnchorEl, setSendHelpAnchorEl] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  useEffect(() => {
    dispatch(journeysActions.load());
    dispatch(journeysActions.loadMetadata());
  }, [dispatch]);

  const handleClose = (reload) => {
    if (reload) dispatch(journeysActions.load());
    dispatch(
      journeysActions.selectJourney({ selected: null, selectedContext: null })
    );
    dispatch(mailingActions.reset());
    dispatch(journeysActions.sendReset());
  };
  const handleJourneyClick = (journey, context) => {
    dispatch(
      journeysActions.selectJourney({
        selected: journey,
        selectedContext: context,
      })
    );
  };
  const handleDeleteConfirm = () => {
    dispatch(journeysActions.delete([selected.id]));
    dispatch(
      journeysActions.selectJourney({ selected: null, selectedContext: null })
    );
    setIsDeleteConfirmationOpen(false);
  };
  const handleMenuClick = (event, journey) => {
    dispatch(
      journeysActions.selectJourney({
        selected: journey,
        selectedContext: 'menu',
      })
    );
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    dispatch(
      journeysActions.selectJourney({ selected: null, selectedContext: null })
    );
    setAnchorEl(null);
  };
  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
    setAnchorEl(null);
  };

  return (
    <Box p={2}>
      <Box marginBottom={2} display="flex" flexDirection="row">
        <Typography variant="h5">Jornadas</Typography>
        {permissions['jrn-add'] && (
          <Box marginLeft={2}>
            <Tooltip title="Criar novo">
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={isLoading || !emailVerified || role !== 'dev'}
                  aria-label="criar novo"
                  startIcon={<AddIcon />}
                  onClick={() => handleJourneyClick({}, 'edit')}
                >
                  Nova
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Grid container spacing={2}>
        {[...data]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d, index) => (
            <Grid key={index} item md={4} sm={6} xs={12}>
              <Card variant="outlined" style={{ height: '100%' }}>
                <Box height={1} display="flex" flexDirection="column">
                  <Box flexGrow={1}>
                    <CardActionArea
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                      disabled={!permissions['jrn-add']}
                      onClick={() => handleJourneyClick(d, 'edit')}
                    >
                      <CardHeader
                        title={d.name}
                        subheader={
                          d.guideId ? metadata[d.guideId]?.name ?? '' : ''
                        }
                      ></CardHeader>
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {d.description}
                        </Typography>
                      </CardContent>
                      <Box flexGrow={1} />
                    </CardActionArea>
                  </Box>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon />}
                      disabled={true}
                      onClick={() => handleJourneyClick(d, 'send')}
                    >
                      Enviar
                    </Button>
                    <IconButton
                      color="warning"
                      onClick={(event) =>
                        setSendHelpAnchorEl(event.currentTarget)
                      }
                    >
                      <HelpIcon fontSize="large" />
                    </IconButton>
                    <Box flexGrow={1} />
                    {permissions['jrn-del'] && (
                      <IconButton
                        aria-label="settings"
                        onClick={(e) => handleMenuClick(e, d)}
                        size="large"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsDeleteConfirmationOpen(false),
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: handleDeleteConfirm,
              startIcon: <DeleteIcon />,
            },
          },
        ]}
      >
        Tem certeza que deseja excluir? Essa ação não poderá ser desfeita
      </ConfirmationDialog>
      {selected && selectedContext === 'edit' && (
        <ResponsiveDialog
          open={!!selected}
          maxWidth="lg"
          fullWidth
          disableEscapeKeyDown
          fullHeight
        >
          <EditJourney onClose={handleClose} />
        </ResponsiveDialog>
      )}
      {selected && selectedContext === 'send' && (
        <ResponsiveDialog
          open={!!selected}
          maxWidth="lg"
          fullWidth
          disableEscapeKeyDown
          fullHeight
        >
          <SendJourney onClose={handleClose} />
        </ResponsiveDialog>
      )}
      <Popover
        id="tag-menu"
        anchorEl={sendHelpAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(sendHelpAnchorEl)}
        onClose={() => setSendHelpAnchorEl(null)}
      >
        <Box p={2}>
          <Typography variant="h5">
            Porque este botão está desabilitado?
          </Typography>
          <Typography variant="body1">
            A partir de agora o botão de enviar mudou de lugar. Ficou mais fácil
            de utilizar!
          </Typography>
        </Box>
      </Popover>
      {Boolean(sendHelpAnchorEl) && (
        <Box sx={{ position: 'absolute', bottom: 64, right: 64 }}>
          <SouthEastIcon sx={{ fontSize: 256 }} color="secondary" />
        </Box>
      )}
    </Box>
  );
}
