import { Box } from '@mui/material';
import PropTypes from 'prop-types';
function EditForm({ children, ...otherProps }) {
  return (
    <Box
      width={1}
      height={1}
      p={2}
      display="flex"
      flexDirection="column"
      {...otherProps}
    >
      {children}
    </Box>
  );
}

EditForm.propTypes = {
  children: PropTypes.node,
};

export default EditForm;
