'use strict';
import { all, put, select, take } from 'redux-saga/effects';
import journeysDao from '../../dao/journeys';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { journeysActions } from './journeys-slice';

import ky from 'ky';
import { getApi } from '../../helpers/api';

function* watchLoadJourneys() {
  while (true) {
    yield take(journeysActions.load);
    try {
      yield put(appActions.startLoading());
      const {
        enterprise: { id: eid },
      } = yield select((state) => state.settings);
      const data = yield journeysDao.get(eid);
      yield put(journeysActions.loadSuccess(data));
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(journeysActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchDeleteJourneys() {
  while (true) {
    const { payload } = yield take(journeysActions.delete);
    try {
      yield put(appActions.startLoading());
      const {
        enterprise: { id: eid },
      } = yield select((state) => state.settings);
      yield all(payload.map((v) => journeysDao.delete(eid, v)));
      yield put(
        notifierActions.enqueue({
          message: 'Os registros foram excluídos com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      // yield put(appActions.createNotification(notification));
      yield put(journeysActions.deleteSuccess());
      yield put(journeysActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(journeysActions.deleteFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveJourney() {
  while (true) {
    const { payload } = yield take(journeysActions.save);

    try {
      yield put(appActions.startLoading());
      const {
        enterprise: { id: eid },
      } = yield select((state) => state.settings);
      const { id } = payload;
      yield journeysDao.set(eid, id, payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(journeysActions.saveSuccess());
      yield put(journeysActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchSaveJourney');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(journeysActions.saveFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadJourneyMetadata() {
  const db = firebase.firestore();

  while (true) {
    yield take(journeysActions.loadMetadata);

    try {
      let snapshot = yield db.collection('metadata-journeys').get();
      let data = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = {
          id: doc.id,
          ...doc.data(),
        };
        return acc;
      }, {});

      yield put(journeysActions.loadMetadataSuccess(data));
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(journeysActions.loadMetadataFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSend() {
  while (true) {
    yield take(journeysActions.send);
    const {
      settings: {
        enterprise: { id: eid },
        localApi,
      },
      journeys: { selected },
      mailing: {
        charge: { chid, customerFields },
        selectedLines,
        mailingPreview: { recordCount },
      },
    } = yield select((state) => state);

    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: `/charge/${chid}/send`,
        local: localApi,
      });

      yield ky.post(url, {
        json: {
          journey: selected,
          selectedLines:
            selectedLines.length === recordCount ? [] : selectedLines,
          customerFields,
        },
        headers: { ...headers, 'X-Zeta-Eid': eid },
        ...opts,
      });

      // const bgTask = {
      //   createdAt: Date.now(),
      //   title: `Envio - ${name}`,
      //   progress: 0,
      //   step: 'Aguardando início do envio',
      //   content: `${displayName} iniciou o envio da jornada ${name}`,
      // };
      // // console.log('creating bg-task', bgTask);
      // const bgTaskDocRef = yield db
      //   .collection(`enterprises/${eid}/bg-tasks`)
      //   .add({
      //     ...bgTask,
      //     createdAt: Date.now(),
      //   });

      // const chargeDoc = yield db
      //   .collection(`enterprises/${eid}/charges`)
      //   .add({ ...payload, bgTaskId: bgTaskDocRef.id, createdAt: Date.now() });

      // const { fileId } = payload;
      // /* const { data } = */ yield fnMailingTrigger({
      //   id: fileId,
      //   eid,
      //   chid: chargeDoc.id,
      //   bgTaskId: bgTaskDocRef.id,
      // });
      // console.log('fn-mailing-trigger result: ', data);

      yield put(journeysActions.sendSuccess());
      // yield put(mailingActions.reset());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(journeysActions.sendFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchLoadJourneys,
  watchDeleteJourneys,
  watchSaveJourney,
  watchLoadJourneyMetadata,
  watchSend,
];
