import { createSlice } from '@reduxjs/toolkit';

export const enterpriseSelectSlice = createSlice({
  name: 'enterprise-select',
  initialState: {
    data: [],
  },
  reducers: {
    loadEnterprises: () => {},
    loadEnterprisesSuccess: (state, action) => {
      state.data = action.payload;
    },
    loadEnterprisesFail: (state) => {
      state.data = [];
    },
    getEnterprisesFail: () => {},
    selectEnterprise: () => {},
  },
});

export const enterpriseSelectActions = enterpriseSelectSlice.actions;

export default enterpriseSelectSlice.reducer;
