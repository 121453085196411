import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (document.getElementById('main-div-container'))
      setTimeout(() => {
        document.getElementById('main-div-container').scrollTop = 0;
      }, 100);
  }, [pathname]);

  return null;
}
