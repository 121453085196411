'use strict';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, ButtonGroup, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import CustomRichTextEditor from '../../helpers/custom-richtext-editor';
import NestedList from './NestedList';

const useStyles = makeStyles((_theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  strike: {
    textDecoration: 'line-through',
  },
  buttonGroup: {
    marginTop: '1px',
    minHeight: '31px',
  },
}));

const insertField = (editor, { parent, name, label, example, isPreview }) => {
  CustomRichTextEditor.insertNode(editor, {
    type: 'field',
    name: `${parent}.${name}`,
    label,
    example,
    isPreview,
    children: [{ text: '' }],
  });
};

function FieldToolbar({ items }) {
  const editor = useSlate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [history, setHistory] = useState([]);

  const togglePreview = () => {
    setIsPreview(!isPreview);
    CustomRichTextEditor.togglePreview(editor, !isPreview);
  };

  const addToHistory = (item) => {
    if (!history.find((p) => p.name === item.name)) {
      history.push(item);
      if (history.length > 3) history.shift();
      setHistory(history);
    }
  };

  const handleSelect = (field) => {
    addToHistory(field);
    setAnchorEl(null);
    insertField(editor, { ...field, isPreview });
    Transforms.move(editor);
  };

  useEffect(() => {
    if (anchorEl === null) ReactEditor.focus(editor);
  }, [anchorEl]);

  useEffect(() => {
    CustomRichTextEditor.togglePreview(editor, false);
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box marginBottom={1}>
          <ButtonGroup size="small">
            <Button
              variant={
                CustomRichTextEditor.isBoldMarkActive(editor)
                  ? 'contained'
                  : 'outlined'
              }
              className={classes.bold}
              onClick={() => {
                CustomRichTextEditor.toggleBoldMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              B
            </Button>
            <Button
              variant={
                CustomRichTextEditor.isItalicMarkActive(editor)
                  ? 'contained'
                  : 'outlined'
              }
              className={classes.italic}
              onClick={() => {
                CustomRichTextEditor.toggleItalicMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              I
            </Button>
            <Button
              variant={
                CustomRichTextEditor.isStrikeMarkActive(editor)
                  ? 'contained'
                  : 'outlined'
              }
              className={classes.strike}
              onClick={() => {
                CustomRichTextEditor.toggleStrikeMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              S
            </Button>
          </ButtonGroup>
        </Box>
        <Box marginLeft={1} marginBottom={1}>
          <ButtonGroup size="small" className={classes.buttonGroup}>
            <Button
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <ArrowDropDownIcon fontSize="small" />
            </Button>
            {history.map((item, index) => (
              <Button
                key={index}
                onClick={() => {
                  handleSelect(item);
                }}
              >
                {item.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box flexGrow={1} />
        <Box marginLeft={1} marginBottom={1}>
          <ButtonGroup size="small">
            <Button
              onClick={() => {
                // console.log(
                //   CustomRichTextEditor.serialize({ value: editor.children })
                // );
                // console.log(editor.children);
                ReactEditor.focus(editor);
              }}
            >
              Log
            </Button>
            <Button
              variant={isPreview ? 'contained' : undefined}
              color={isPreview ? 'primary' : undefined}
              onClick={togglePreview}
            >
              {!isPreview && <VisibilityIcon fontSize="small" />}
              {isPreview && <VisibilityOffIcon fontSize="small" />}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NestedList
          header="Campos disponíveis"
          onSelect={handleSelect}
          items={items}
        />
      </Popover>
    </>
  );
}
FieldToolbar.propTypes = {
  items: PropTypes.object.isRequired,
};
export default FieldToolbar;
