'use strict';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { getApi } from '../../helpers/api';
import firebase from '../../helpers/firebase';
import rpcCall from '../../sagas/rpc';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { issuesActions } from './issues-slice';

function createIssuesChannel(eid) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const issuesDoc = db
      .collection('pix')
      .where('eid', '==', eid)
      .where('issue', '!=', null);

    const issuesUnsubscribe = issuesDoc.onSnapshot((snapshot) =>
      emitter(snapshot)
    );
    return () => {
      issuesUnsubscribe();
    };
  });
}

function* watchIssuesUpdates(eid) {
  const chan = yield call(createIssuesChannel, eid);
  try {
    while (true) {
      const snapshot = yield take(chan);
      const changes = snapshot.docChanges();

      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToAdd.length > 0) {
        yield put(issuesActions.addIssues(itemsToAdd));
        yield put(
          notifierActions.enqueue({
            message: 'Atenção! Você possui pendências para verificar !',
            options: {
              variant: 'error',
            },
          })
        );
      }

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToModify.length > 0)
        yield put(issuesActions.modifyIssues(itemsToModify));

      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(issuesActions.removeIssues(itemsToRemove));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

function createExternalIssuesChannel(eid) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const issuesDoc = db.collection('external-issues').where('eid', '==', eid);

    const externalIssuesUnsubscribe = issuesDoc.onSnapshot((snapshot) =>
      emitter(snapshot)
    );
    return () => {
      externalIssuesUnsubscribe();
    };
  });
}

function* watchExternalIssuesUpdates(eid) {
  const chan = yield call(createExternalIssuesChannel, eid);
  try {
    while (true) {
      const snapshot = yield take(chan);
      const changes = snapshot.docChanges();

      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToAdd.length > 0) {
        yield put(issuesActions.addExternalIssues(itemsToAdd));
        yield put(
          notifierActions.enqueue({
            message: 'Atenção! Você possui pendências para verificar !',
            options: {
              variant: 'error',
            },
          })
        );
      }

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToModify.length > 0)
        yield put(issuesActions.modifyExternalIssues(itemsToModify));

      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(issuesActions.removeExternalIssues(itemsToRemove));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

function* watchLoad() {
  while (true) {
    yield take(issuesActions.start);
    const {
      auth: { permissions },
      settings: {
        enterprise: { id: eid },
      },
    } = yield select((state) => state);
    if (!permissions['iss-slv']) continue;
    const issuesUpdateTask = yield fork(watchIssuesUpdates, eid);
    const externalIssuesUpdateTask = yield fork(
      watchExternalIssuesUpdates,
      eid
    );
    yield take(issuesActions.stop);
    yield put(issuesActions.setIssues([]));
    yield put(issuesActions.setExternalIssues([]));
    yield cancel(issuesUpdateTask);
    yield cancel(externalIssuesUpdateTask);
  }
}

function* watchClearIssue() {
  while (true) {
    const { payload: jid } = yield take(issuesActions.clearIssue);
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/journey/${jid}/clear-issue`,
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(issuesActions.clearIssueFail({ error: error.message }));
      } else {
        yield put(issuesActions.clearIssueSuccess());
        yield put(issuesActions.resetSolveIssue());

        yield put(
          notifierActions.enqueue({
            message: 'Pendência marcada como resolvida !',
            options: {
              variant: 'success',
            },
          })
        );
      }
    } catch (e) {
      yield put(issuesActions.clearIssueFail({ error: e.message }));
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchClearExternalIssue() {
  while (true) {
    const { payload: id } = yield take(issuesActions.clearExternalIssue);
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/external-issue/${id}`,
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'DELETE',
        headers,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(
          issuesActions.clearExternalIssueFail({ error: error.message })
        );
      } else {
        yield put(issuesActions.clearExternalIssueSuccess());
        yield put(issuesActions.resetSolveIssue());
        yield put(issuesActions.setSelectedExternalIssue());

        yield put(
          notifierActions.enqueue({
            message: 'Pendência marcada como resolvida !',
            options: {
              variant: 'success',
            },
          })
        );
      }
    } catch (e) {
      yield put(issuesActions.clearExternalIssueFail({ error: e.message }));
      yield put(appActions.setError(e.message));
    }
  }
}

export default [watchLoad, watchClearIssue, watchClearExternalIssue];
