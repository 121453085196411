'use strict';
import ky from 'ky';
import { fork, put, select, take } from 'redux-saga/effects';

import { appActions } from '../app/app-slice';
import { mailingActions } from './mailing-slice';
// import firebase from '../../helpers/firebase';
import { getApi } from '../../helpers/api';
import rpcCall from '../../sagas/rpc';

function* watchGetMailingPreview() {
  // const fnMailingPreview = firebase
  //   .app()
  //   .functions()
  //   .httpsCallable('fn-mailing-preview');

  while (true) {
    const {
      payload: { chid, jid },
    } = yield take(mailingActions.getMailingPreview);
    try {
      yield put(mailingActions.startProcessingPreview());
      const {
        enterprise: { id: eid },
        localApi,
      } = yield select((state) => state.settings);

      const { url, headers, ...opts } = yield getApi({
        path: `/charge/${chid}`,
        local: localApi,
      });

      const response = yield ky(url, {
        method: 'get',
        headers: { ...headers, 'X-Zeta-Eid': eid, 'X-Zeta-jid': jid },
        ...opts,
      });
      const data = yield response.json();

      yield put(mailingActions.getMailingPreviewSuccess(data));

      // const { data } = yield fnMailingPreview({
      //   id,
      //   eid,
      //   jid,
      //   encoding,
      //   delimiter,
      // });
    } catch (e) {
      const { code, message, details } = e;
      yield put(appActions.setError(`saga:getMailingPreview - ${e.message}`));
      yield put(
        mailingActions.getMailingPreviewFail({ code, message, details })
      );
    }
    yield put(mailingActions.finishProcessingPreview());
  }
}

// function retries(chid, retries) {
//   return eventChannel((emitter) => {
//     const iv = setInterval(async () => {
//       retries -= 1;

//       const { url, headers, ...opts } = await getApi({ path: `/charge/${chid}` });
//       console.log({ url, headers });
//       const response = await ky.get(url, { headers }, ...opts);
//       const json = await response.json();
//       console.log(response.status);
//       console.log(json);

//       if (retries > 0) {
//         emitter({ retries, status: response.status });
//       } else {
//         emitter(END);
//       }
//     }, 3000);
//     return () => {
//       clearInterval(iv);
//     };
//   });
// }

// function* waitCreateCharge(location) {
//   const chan = yield call(retries, location, 10);
//   while (true) {
//     let { status } = yield take(chan);
//     if (status === 201) {
//       chan.close();
//       return true;
//     }
//   }
// }

function* createCharge({ jid, ...data }) {
  try {
    yield put(mailingActions.startProcessingPreview());
    const {
      settings: {
        enterprise: { id: eid },
        localApi,
      },
      journeys: { selected },
    } = yield select((state) => state);
    const { url, headers, ...opts } = yield getApi({
      path: '/charge',
      local: localApi,
    });
    // console.log({ url, headers });

    const { error, ...resultData } = yield rpcCall({
      url,
      method: 'post',
      headers: { ...headers, 'X-Zeta-Eid': eid, 'X-Zeta-jid': jid },
      payload: { ...data, journey: selected },
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(`rpc:createCharge - ${error}`));
      yield put(mailingActions.createChargeFail(error));
    } else {
      yield put(mailingActions.createChargeSuccess(resultData));
      yield put(
        mailingActions.getMailingPreview({ chid: resultData.chid, jid })
      );
    }
  } catch (e) {
    yield put(appActions.setError(`saga:createCharge - ${e.message}`));
    yield put(mailingActions.createChargeFail(e));
    yield put(mailingActions.finishProcessingPreview());
  }
}

function* watchCreateCharge() {
  while (true) {
    const { payload } = yield take(mailingActions.createCharge);
    yield fork(createCharge, payload);
  }
}

function* processCharge({ chid, jid, ...data }) {
  try {
    const {
      settings: {
        enterprise: { id: eid },
        localApi,
      },
      journeys: { selected },
    } = yield select((state) => state);
    const { url, headers, ...opts } = yield getApi({
      path: `/charge/${chid}/process`,
      local: localApi,
    });
    // console.log({ url, headers });

    const { error, ...resultData } = yield rpcCall({
      url,
      method: 'post',
      headers: { ...headers, 'X-Zeta-Eid': eid, 'X-Zeta-jid': jid },
      payload: { ...data, journey: selected },
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(`rpc:processCharge - ${error}`));
      yield put(mailingActions.processChargeFail(error));
    } else {
      yield put(mailingActions.processChargeSuccess(resultData));
      yield put(
        mailingActions.getMailingPreview({ chid: resultData.chid, jid })
      );
    }
  } catch (e) {
    yield put(appActions.setError(`saga:processCharge - ${e.message}`));
    yield put(mailingActions.processChargeFail(e));
    yield put(mailingActions.finishProcessingPreview());
  }
}

function* watchProcessCharge() {
  while (true) {
    const { payload } = yield take(mailingActions.processCharge);
    yield fork(processCharge, payload);
  }
}

export default [watchGetMailingPreview, watchCreateCharge, watchProcessCharge];
