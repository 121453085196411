import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import { formatValue } from '../../helpers/formatter';
import { getMessenger } from './get-messenger';
import { waStatusActions } from './wa-status-slice';

const useStylesConnection = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  danger: {
    color: theme.palette.error.main,
  },
  bgWhite: {
    backgroundColor: '#FFFFFF',
  },
  screenshot: {
    maxWidth: '70vw',
  },
  hand: {
    cursor: 'pointer',
  },
}));
const Connection = ({ connection }) => {
  const classes = useStylesConnection();
  switch (connection) {
    case 'CONNECTING':
      return <CircularProgress size="2em" />;
    case 'CONNECTED':
      return <CheckIcon className={classes.success} fontSize="large" />;
    case 'ERROR':
      return <ErrorIcon color="error" fontSize="large" />;
    default:
      return <ErrorIcon color="error" fontSize="large" />;
  }
};
Connection.propTypes = {
  connection: PropTypes.string.isRequired,
};

const Battery = ({ battery, plugged }) => {
  const classes = useStylesConnection();
  let Icon;
  let className;
  if (!battery && !plugged) {
    className = classes.danger;
    Icon = BatteryUnknownIcon;
  } else {
    if (plugged) {
      className = classes.success;
      if (battery <= 20) {
        Icon = BatteryCharging20Icon;
      } else if (battery <= 30) {
        Icon = BatteryCharging30Icon;
      } else if (battery <= 50) {
        Icon = BatteryCharging50Icon;
      } else if (battery <= 60) {
        Icon = BatteryCharging60Icon;
      } else if (battery <= 80) {
        Icon = BatteryCharging80Icon;
      } else if (battery <= 90) {
        Icon = BatteryCharging90Icon;
      } else {
        Icon = BatteryChargingFullIcon;
      }
    } else {
      if (battery <= 10) {
        className = classes.danger;
        Icon = BatteryAlertIcon;
      } else if (battery <= 20) {
        Icon = Battery20Icon;
        className = classes.danger;
      } else if (battery <= 30) {
        Icon = Battery30Icon;
        className = classes.danger;
      } else if (battery <= 50) {
        Icon = Battery50Icon;
        className = classes.warning;
      } else if (battery <= 60) {
        Icon = Battery60Icon;
        className = classes.warning;
      } else if (battery <= 80) {
        Icon = Battery80Icon;
        className = classes.warning;
      } else if (battery <= 90) {
        Icon = Battery90Icon;
      } else {
        Icon = BatteryFullIcon;
      }
    }
  }
  return (
    <Tooltip
      title={`Carga da bateria: ${battery} %${plugged ? ' (Carregando)' : ''}`}
    >
      <Icon className={clsx(className, classes.hand)} fontSize="large" />
    </Tooltip>
  );
};
Battery.propTypes = {
  battery: PropTypes.number,
  plugged: PropTypes.bool,
};

export default function SessionDetail({ session, isConnecting, isLoggingOut }) {
  const dispatch = useDispatch();
  const classes = useStylesConnection();

  const handleConnectClick = (id) => {
    dispatch(
      waStatusActions.connect({
        wid: id,
        disableInbound: session.disableInbound,
      })
    );
    setTimeout(() => dispatch(waStatusActions.stopConnecting(id)), 30000);
  };
  const handleLogoutClick = (id) => {
    dispatch(waStatusActions.logout(id));
    setTimeout(() => dispatch(waStatusActions.stopLoggingOut(id)), 30000);
  };

  // const classes = useStyles();
  const {
    id,
    label,
    phoneNumber,
    whatsapp,
    connection,
    qrcode: { qrcode } = {},
    // batterylevel,
  } = session;

  // Se não está conectado:
  // Se tem qrcode - nenhum botão
  // Se não tem qrcode - conectar
  // Se está conectado
  // Desconectar e Logout

  let img;
  const messenger = getMessenger(id);

  if (messenger === 'whatsapp')
    img = (
      <Box p={2}>
        <WhatsAppIcon sx={{ fontSize: '64px' }} color="success" />
      </Box>
    );
  else if (connection?.status !== 'CONNECTED') {
    if (connection?.status === 'CLOSED' || connection?.status === 'close') {
      img = (
        <Box display="flex" flexDirection="row">
          <ButtonWithProgress
            tooltipTitle="Conectar"
            disabled={isConnecting}
            isLoading={isConnecting}
            variant="contained"
            color="primary"
            onClick={() => handleConnectClick(id)}
          >
            Conectar
          </ButtonWithProgress>
          {isConnecting && (
            <>
              <Box paddingLeft={2}>
                <ButtonWithProgress
                  tooltipTitle="Forçar desconexão"
                  disabled={isLoggingOut}
                  isLoading={isLoggingOut}
                  variant="contained"
                  color="primary"
                  onClick={() => handleLogoutClick(id)}
                >
                  Forçar desconexão
                </ButtonWithProgress>
              </Box>
              {isLoggingOut && (
                <Box paddingLeft={2}>
                  <Typography>
                    Aguarde 30 segundos, limpando conexão...
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      );
    } else {
      img = (
        <Paper variant="outlined">
          <Box p={2}>
            <Box p={2} className={classes.bgWhite}>
              <img src={qrcode} />
            </Box>
          </Box>
        </Paper>
      );
    }
  } else {
    img = (
      <>
        <Box display="flex" flexDirection="row">
          <ButtonWithProgress
            tooltipTitle="Desconectar"
            disabled={isLoggingOut}
            isLoading={isLoggingOut}
            variant="contained"
            color="primary"
            onClick={() => handleLogoutClick(id)}
          >
            Desconectar
          </ButtonWithProgress>
        </Box>
      </>
    );
  }

  //if (connection === 'CONNECTING') {

  // } else if (!!base64Screenshot && base64Screenshot !== '') {
  //   img = (
  //     <Paper variant="outlined">
  //       <Box p={2}>
  //         <img
  //           className={classes.screenshot}
  //           src={`data:image/png;base64,${base64Screenshot}`}
  //         />
  //       </Box>
  //     </Paper>
  //   );
  // } else if (!!base64Qrimg && base64Qrimg !== '') {
  //   img = (
  //     <Paper variant="outlined">
  //       <Box p={2}>
  //         <Box p={2} className={classes.bgWhite}>
  //           <img src={base64Qrimg} />
  //         </Box>
  //       </Box>
  //     </Paper>
  //   );
  // } else {
  //   img = <CircularProgress />;
  // }

  const PhoneIcon = PhoneAndroidIcon;
  return (
    <Box marginBottom={4}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          {messenger === 'api' ? (
            <Typography variant="body1">
              {label} ({id}) {formatValue(phoneNumber, 'phone')}
            </Typography>
          ) : (
            <Typography variant="body1">
              {label} - {formatValue(phoneNumber, 'phone')}
            </Typography>
          )}
          <Box flexGrow={1} />
          {messenger === 'api' ? (
            <>
              {connection?.status === 'CONNECTED' && whatsapp?.id && (
                <>
                  <Tooltip title={`${whatsapp?.id} - ${whatsapp?.name}`}>
                    <PhoneIcon className={classes.hand} />
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          <Connection
            connection={
              messenger === 'api' ? connection?.status ?? '' : 'CONNECTED'
            }
          />
        </Box>
        <Container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item>{img}</Grid>
          </Grid>
        </Container>
      </Paper>
    </Box>
  );
}

SessionDetail.propTypes = {
  session: PropTypes.object,
  isConnecting: PropTypes.bool,
  isLoggingOut: PropTypes.bool,
};
