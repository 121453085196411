/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import ReportTable from '../../components/ReportTable';
import { formatValue } from '../../helpers/formatter';

const useStyles = makeStyles(() => ({
  print: {
    color: 'black',
  },
  nobr: {
    'white-space': 'nowrap',
  },
}));

const Messages = ({ data, currentOrderBy, onOrderByChange }) => {
  const classes = useStyles();

  console.log('data: ', data);

  const enhancedData = data.reduce((acc, cur) => {
    const date = moment(cur.createdAt).startOf('day').valueOf();
    const indexToFind = acc.findIndex((r) => r.date === date);
    if (indexToFind === -1)
      acc.push({
        date,
        totalCreated: 1,
        totalSent: cur.sent ? 1 : 0,
        totalDelivered: cur.delivered ? 1 : 0,
        totalRead: cur.read ? 1 : 0,
      });
    else {
      acc[indexToFind].totalCreated++;
      if (cur.sent) acc[indexToFind].totalSent++;
      if (cur.delivered) acc[indexToFind].totalDelivered++;
      if (cur.read) acc[indexToFind].totalRead++;
    }

    return acc;
  }, []);

  console.log('enhancedData: ', enhancedData);

  const columns = [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: 'Data',
      formatter: (v) => formatValue(v, 'date'),
      align: 'center',
    },
    {
      id: 'totalCreated',
      numeric: false,
      disablePadding: false,
      label: 'Criadas',
      align: 'center',
    },
    {
      id: 'totalSent',
      numeric: false,
      disablePadding: false,
      label: 'Enviadas',
      align: 'center',
    },
    {
      id: 'totalDelivered',
      numeric: false,
      disablePadding: false,
      label: 'Entregues',
      align: 'center',
    },
    {
      id: 'totalRead',
      numeric: false,
      disablePadding: false,
      label: 'Lidas',
      align: 'center',
    },
  ];

  return (
    <Box width={1}>
      <ReportTable
        title="Mensagens"
        data={enhancedData}
        columns={columns}
        orderBy={currentOrderBy ?? { column: 'date' }}
        onOrderByChange={onOrderByChange}
        tableProps={{
          className: classes.table,
          size: 'small',
        }}
      />
      <Box marginTop={2} />
    </Box>
  );
};

Messages.propTypes = {
  data: PropTypes.array,
  currentOrderBy: PropTypes.object,
  onOrderByChange: PropTypes.func,
};

export default Messages;
