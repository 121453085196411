import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    invitations: [],
    enterprises: {},
    selected: undefined,
    selectedInvitation: undefined,
  },
  reducers: {
    load: () => {},
    loadSuccess: (state, action) => {
      state.data = action.payload;
    },
    loadFail: (state) => {
      state.data = [];
    },
    selectUser: (state, { payload: { selected, selectedContext } }) => {
      state.selected = selected;
      state.selectedContext = selectedContext;
    },
    getEnterprises: () => {},
    getEnterprisesSuccess: (state, action) => {
      state.enterprises = action.payload;
    },
    getEnterprisesFail: (state) => {
      state.enterprises = [];
    },
    save: () => {},
    saveSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    saveFail: () => {},
    delete: () => {},
    deleteSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    deleteFail: () => {},
    refreshUserClaimsClick: () => {},
    refreshUserClaimsClickSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    refreshUserClaimsClickFail: () => {},
    refreshUserClaims: () => {},
    refreshUserClaimsSuccess: () => {},
    refreshUserClaimsFail: () => {},
    getAuthUser: () => {},
    getAuthUserSuccess: (state, { payload }) => {
      state.selectedCustomClaims = payload.customClaims;
    },
    getAuthUserFail: () => {},
    newUser: () => {},
    newUserSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    loadInvitations: () => {},
    loadInvitationsSuccess: (state, action) => {
      state.invitations = action.payload;
    },
    loadInvitationsFail: (state) => {
      state.invitations = [];
    },
    selectInvitation: (state, { payload }) => {
      state.selectedInvitation = payload;
    },
    newUserFail: () => {},
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
