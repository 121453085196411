import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initializedAt: Date.now(),
  currentView: 'init',
  isLoading: false,
  isError: false,
  error: '',
  notifications: [],
  backgroundTasks: [],
  isSecretActive: false,
  loadingButtons: {},
};
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    setError: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    setCurrentView: (state, { payload }) => {
      state.currentView = payload;
    },
    createBackgroundTask: () => {},
    createBackgroundTaskSuccess: () => {},
    createBackgroundTaskFail: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
    backgroundTasksCreated: (state, action) => {
      state.backgroundTasks.push(...action.payload);
    },
    backgroundTasksModified: (state, action) => {
      state.backgroundTasks = state.backgroundTasks.map(
        (t) => action.payload.find((data) => data.id === t.id) ?? t
      );
    },
    setIsSecretActive: (state, action) => {
      state.isSecretActive = action.payload;
    },
    loadMainJourney: () => {},
    startLoadingButton: (state, { payload }) => {
      state.loadingButtons[payload] = true;
    },
    stopLoadingButton: (state, { payload }) => {
      state.loadingButtons[payload] = false;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
