'use strict';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Box,
  Card,
  CardActionArea,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { makeStyles } from '@mui/styles';
import { formatValue } from '../../helpers/formatter';
import FormChangeContact from '../monitor/FormChangeContact';
import { monitorActions } from '../monitor/monitor-slice';
import { issueTypeMap } from './issue-type';
import { issuesActions } from './issues-slice';

const useStyles = makeStyles((theme) => ({
  clsSuccessIcon: {
    color: theme.palette.success.main,
  },
}));

export default function CPCWizard() {
  const { clsSuccessIcon } = useStyles();
  const dispatch = useDispatch();
  const {
    settings: {
      enterprise: { ignoreCustomer },
    },
    monitor: {
      journey: {
        jid,
        customerData: { name: customerName = '', phone = '' } = {},
      } = {},
    },
    issues: {
      issueSolve: { solveType, solvePath },
    },
  } = useSelector((state) => state);

  const [isRestartConfirmationOpen, setIsRestartConfirmationOpen] =
    useState(false);

  const currentSolveType = issueTypeMap[solveType];
  const currentSolvePathStep = solvePath.at(-1) ?? '1';

  return (
    <>
      {/* <Box display="flex" flexDirection="column"> */}
      <Stepper alternativeLabel activeStep={solvePath.length - 1}>
        {solvePath.map((step, index) => (
          <Step key={index}>
            <StepLabel>{currentSolveType.paths[step]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {currentSolvePathStep === '1' && (
        <>
          <Typography variant="body2">
            {solveType === 'CPC_INCOMPLETE'
              ? 'O cliente não confirmou a identidade.'
              : 'O cliente respondeu que o telefone não pertence a ele'}
            <br />
            <br />
            O primeiro passo é confirmar se o telefone do cadastro realmente
            pertence a pessoa.
            <br />
            <br />
            Você pode conferir entrando em contato diretamente com o cliente.
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            marginTop={2}
            marginBottom={2}
          >
            <Box flexBasis={0.5} flexGrow={1} marginRight={1}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    dispatch(issuesActions.addSolvePath('1.1'));
                  }}
                >
                  <Box
                    p={2}
                    minHeight="200px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <CheckIcon className={clsSuccessIcon} fontSize="large" />
                    <Typography variant="body2">O telefone</Typography>
                    <Typography variant="h5">
                      {formatValue(phone, 'phone')}
                    </Typography>
                    <Typography variant="body2">pertence a</Typography>
                    <Typography variant="h5">{customerName}</Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
            <Box flexBasis={0.5} flexGrow={1} marginLeft={1}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    dispatch(issuesActions.addSolvePath('1.2'));
                  }}
                >
                  <Box
                    p={2}
                    minHeight="200px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <CloseIcon color="error" fontSize="large" />
                    <Typography variant="body2">O telefone</Typography>
                    <Typography variant="h5">
                      {formatValue(phone, 'phone')}
                    </Typography>
                    <Typography variant="body2">NÃO pertence a</Typography>
                    <Typography variant="h5">{customerName}</Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          </Box>
        </>
      )}
      {currentSolvePathStep === '1.1' && (
        <>
          <Typography variant="body2">
            Nesse caso o cliente só precisa receber novamente o QRCode.
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            marginTop={2}
            marginBottom={2}
          >
            <Box flexBasis="25%" flexGrow={1} />
            <Box flexBasis="50%" flexGrow={1}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    setIsRestartConfirmationOpen(true);
                  }}
                >
                  <Box
                    p={2}
                    minHeight="200px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Typography variant="body2" align="center">
                      Clique aqui para reenviar o QR Code para o telefone
                    </Typography>
                    <Typography variant="h5">
                      {formatValue(phone, 'phone')}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
            <Box flexBasis="25%" flexGrow={1} />
          </Box>
        </>
      )}
      {currentSolvePathStep === '1.2' && (
        <>
          <Typography variant="body2">
            Nesse caso você precisa alterar o telefone do cliente.
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            marginTop={2}
            marginBottom={2}
          >
            <Box flexBasis="50%" flexGrow={1}>
              <Card variant="outlined">
                <FormChangeContact
                  inline
                  onCancelClick={() => {}}
                  onConfirmClick={(newContactData) => {
                    dispatch(
                      monitorActions.changeContact({
                        jid,
                        newContactData,
                        ignoreCustomer,
                      })
                    );
                    dispatch(issuesActions.resetSolveIssue());
                  }}
                />
              </Card>
            </Box>
          </Box>
        </>
      )}
      <Box flexGrow={1} />
      {/* </Box> */}
      <ConfirmationDialog
        open={isRestartConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsRestartConfirmationOpen(false),
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(
                  monitorActions.restartJourney({ jid, confirmCpc: true })
                );
                setIsRestartConfirmationOpen(false);
                dispatch(issuesActions.resetSolveIssue());
              },
              startIcon: <PlayArrowIcon />,
            },
          },
        ]}
      >
        Tem certeza que deseja reenviar os lembretes?
      </ConfirmationDialog>
    </>
  );
}

CPCWizard.propTypes = {};
