import { createSlice } from '@reduxjs/toolkit';

const initialSolve = {
  solveType: undefined,
  solvePath: ['1'],
};
const initialState = {
  issues: [],
  externalIssues: [],
  isLoading: false,
  recordsLimit: 200,
  issueSolve: initialSolve,
  isAwaitingCommand: false,
  selectedExternalIssue: undefined,
};
export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    navigateToIssues: () => {},
    start: () => {},
    stop: () => {},
    exit: () => initialState,
    setIssues: (state, action) => {
      state.issues = action.payload;
    },
    addIssues: (state, { payload: itemsToAdd }) => {
      if (!state.issues) state.issues = [];
      state.issues.push(...itemsToAdd);
    },
    removeIssues: (state, { payload: itemsToRemove }) => {
      if (!state.issues) state.issues = [];
      state.issues = state.issues.filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
    },
    modifyIssues: (state, { payload: itemsToModify }) => {
      if (!state.issues) state.issues = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.issues = state.issues.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.issues.push(...itemsToModify);
    },
    setExternalIssues: (state, action) => {
      state.externalIssues = action.payload;
    },
    addExternalIssues: (state, { payload: itemsToAdd }) => {
      if (!state.externalIssues) state.externalIssues = [];
      state.externalIssues.push(...itemsToAdd);
    },
    removeExternalIssues: (state, { payload: itemsToRemove }) => {
      if (!state.externalIssues) state.externalIssues = [];
      state.externalIssues = state.externalIssues.filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
    },
    modifyExternalIssues: (state, { payload: itemsToModify }) => {
      if (!state.externalIssues) state.externalIssues = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.externalIssues = state.externalIssues.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.externalIssues.push(...itemsToModify);
    },
    loadFail: (state, _action) => {
      state.issues = [];
    },
    setSolveType: (state, { payload }) => {
      state.issueSolve.solveType = payload;
    },
    addSolvePath: (state, { payload }) => {
      state.issueSolve.solvePath.push(payload);
    },
    rewindSolvePath: (state) => {
      state.issueSolve.solvePath.pop();
    },
    resetSolveIssue: (state) => {
      state.issueSolve = initialSolve;
    },
    clearIssue: (state) => {
      state.isAwaitingCommand = true;
    },
    clearIssueSuccess: (state) => {
      state.isAwaitingCommand = false;
    },
    clearIssueFail: (state) => {
      state.isAwaitingCommand = true;
    },
    setSelectedExternalIssue: (state, { payload }) => {
      state.selectedExternalIssue = payload;
    },
    clearExternalIssue: (state) => {
      state.isAwaitingCommand = true;
    },
    clearExternalIssueSuccess: (state) => {
      state.isAwaitingCommand = false;
    },
    clearExternalIssueFail: (state) => {
      state.isAwaitingCommand = true;
    },
  },
});

export const issuesActions = issuesSlice.actions;

export default issuesSlice.reducer;
