import { createSlice } from '@reduxjs/toolkit';

export const newUserSlice = createSlice({
  name: 'new-user',
  initialState: {},
  reducers: {
    newUserWizard: (state, { payload }) => {
      state.authData = payload;
    },
    newUserWizardSuccess: () => {},
    newUserWizardFail: () => {},
    invitationWizard: (state, { payload }) => {
      state.authData = payload;
    },
    invitationWizardSuccess: () => {},
    invitationWizardFail: () => {},
    createUser: () => {},
    createUserSuccess: () => {},
    createUserFail: () => {},
    createEnterprise: () => {},
    createEnterpriseSuccess: () => {},
    createEnterpriseFail: () => {},
  },
});

export const newUserActions = newUserSlice.actions;

export default newUserSlice.reducer;
