'use strict';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';

import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import ConfirmationDialog from '../../components/ConfirmationDialog.jsx';
import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormContent from '../../components/EditForm/EditFormContent.jsx';
import EditFormField from '../../components/EditForm/EditFormField.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';
import EditFormMessage from '../../components/EditForm/EditFormMessage.jsx';
import { usersActions } from './users-slice.js';

import { levels } from '../auth/permissions';

function NewUser({ onClose }) {
  const {
    app: { isLoading },
  } = useSelector((state) => state);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    // console.log(formData);
    setIsSaveConfirmationOpen(false);
    dispatch(usersActions.newUser(formData));
    // const { id } = selected;
    // const enterprisesToSave = formData.enterprises
    //   .filter((v) => !!v)
    //   .map((id) => ({
    //     id,
    //     name: enterprises[id].name,
    //   }));
    // const dataToSave = {
    //   id,
    //   enterpriseId: enterprisesToSave[0].id,
    //   ...formData,
    //   enterprises: enterprisesToSave,
    // };
    // dispatch(usersActions.save(dataToSave));
  };
  const handleCloseClick = () => {
    if (!isDirty) onClose(false);
    else setIsCloseConfirmationOpen(true);
  };

  return (
    <EditForm>
      <EditFormHeader title="Novo usuário" onCloseClick={handleCloseClick} />
      <EditFormContent overflow="auto">
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            setIsSaveConfirmationOpen(true);
          }}
        >
          <Box p={1}>
            <Grid container>
              <Grid container item spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <EditFormField
                    field={{
                      name: 'email',
                      label: 'e-mail',
                      type: 'text',
                    }}
                    defaultValue={''}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: {
                        value: 4,
                        message: 'Digite um e-mail válido',
                      },
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <EditFormField
                    field={{
                      name: 'role',
                      label: 'Perfil de Acesso',
                      type: 'select',
                      options: levels.map(({ role, label }) => ({
                        value: role,
                        label,
                      })),
                    }}
                    defaultValue="adm"
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 3, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormMessage>
          {Object.keys(errors).length > 0 && (
            <Typography color="error" variant="caption">
              Os erros devem ser corrigidos antes de salvar
            </Typography>
          )}
        </EditFormMessage>
        <EditFormAction>
          <Box display="flex" flexDirection="row" alignItems="center">
            <ButtonWithProgress
              tooltipTitle="Enviar convite"
              // type="submit"
              onClick={() => setIsSaveConfirmationOpen(true)}
              variant="contained"
              color="primary"
              disabled={!isDirty || isLoading}
              aria-label="enviar"
              startIcon={<EmailIcon />}
            >
              Criar
            </ButtonWithProgress>
          </Box>
        </EditFormAction>
      </EditFormFooter>
      <ConfirmationDialog
        open={isSaveConfirmationOpen}
        title="Confirmar"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsSaveConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Criar convite',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <EmailIcon />,
              onClick: handleSubmit(onSubmit),
            },
          },
        ]}
      >
        Deseja criar o convite ?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isCloseConfirmationOpen}
        title="Existem dados não salvos"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsCloseConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sair sem criar',
            buttonProps: {
              onClick: () => onClose(false),
            },
          },
          {
            name: 'Criar convite',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <EmailIcon />,
              onClick: handleSubmit(onSubmit),
            },
          },
        ]}
      >
        Alguns dados foram modificados e ainda não foram salvos.
        <br />
        <br />
        Deseja salvá-los antes de fechar a janela?
      </ConfirmationDialog>
    </EditForm>
  );
}

NewUser.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default NewUser;
