'use strict';
import PropTypes from 'prop-types';

import { Link as ReactRouterLink } from 'react-router-dom';

export default function YouTubeEmbed(props) {
  try {
    if (props.href.match(/^(https?:)?\/\//)) {
      const url = new URL(props.href);
      if (
        url.origin.includes('youtube.com') &&
        props.node.position.start.column === 1
      ) {
        return (
          <iframe
            width="100%"
            height="600"
            src={`https://www.youtube.com/embed/${url.searchParams.get('v')}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        );
      }
    } else {
      if (props.href[0] === '#') return <a {...props} />;

      return (
        <ReactRouterLink to={props.href}>{props.children}</ReactRouterLink>
      );
    }
  } catch (e) {
    console.log(e);
  }

  return <a {...props} />;
}

YouTubeEmbed.propTypes = {
  href: PropTypes.string.isRequired,
  node: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};
