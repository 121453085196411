import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useController, useFieldArray } from 'react-hook-form';
import EditFormField from '../../components/EditForm/EditFormField';

import ColorPicker, { colorPalette } from '../../components/ColorPicker';

const ColorPickerField = ({ field, control, ...props }) => {
  const { name } = field;

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: props.defaultValue,
  });

  return <ColorPicker value={value} onChange={onChange} />;
};
ColorPickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  defaultValue: PropTypes.any,
  control: PropTypes.object,
  onChange: PropTypes.func,
};

const TagsForm = ({ control, watchValues: _, ...otherProps }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });

  return (
    <Grid container spacing={2} {...otherProps}>
      <Grid item xs={12}>
        <Typography>Marcadores</Typography>
        {fields.map((tag, index) => (
          <Box key={tag.id} display="flex" flexDirection="row" marginTop={2}>
            <EditFormField
              field={{
                name: `tags.${index}.name`,
                label: 'Nome',
                type: 'text',
              }}
              defaultValue={tag.name}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 4,
                  message: 'Digite uma chave válida',
                },
              }}
            />
            <ColorPickerField
              field={{
                name: `tags.${index}.color`,
              }}
              defaultValue={tag.color ?? colorPalette[index]}
              control={control}
            />
            <IconButton
              label="Excluir"
              variant="outlined"
              onClick={() => remove(index)}
              size="large">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Box width={1} marginTop={2}>
          <Button
            variant="outlined"
            label="Adicionar"
            startIcon={<AddIcon />}
            onClick={() => append({})}
          >
            Adicionar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
TagsForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default TagsForm;
