import { createSlice } from '@reduxjs/toolkit';

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState: {
    visibleNotifications: [],
    notifications: [],
    selected: undefined,
  },
  reducers: {
    enqueue: (state, action) => {
      state.visibleNotifications.push({
        key: new Date().getTime() + Math.random(),
        ...action.payload,
      });
    },
    close: (state, action) => {
      const { payload } = action;
      state.visibleNotifications = state.visibleNotifications.map(
        (notification) =>
          payload.dismissAll || notification.key === payload
            ? { ...notification, dismissed: true }
            : { ...notification }
      );
    },
    remove: (state, action) => {
      const { payload } = action;
      state.visibleNotifications = state.visibleNotifications.filter(
        (notification) => notification.key !== payload
      );
    },
    select: (state, action) => {
      const { payload } = action;
      state.selected = payload;
    },

    createPersistentNotification: () => {},
    createPersistentNotificationSuccess: () => {},
    createPersistentNotificationFail: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
    newNotifications: (state, action) => {
      state.notifications.push(...action.payload);
    },
    modifyNotifications: (state, action) => {
      state.notifications = state.notifications.map(
        (not) => action.payload.find((data) => data.id === not.id) ?? not
      );
    },
    setNotificationIsRead: () => {},
    setNotificationIsReadSuccess: () => {},
    setNotificationIsReadFail: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const notifierActions = notifierSlice.actions;

export default notifierSlice.reducer;
