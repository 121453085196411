'use strict';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

function Protected({ allow, children }) {
  return allow ? children : <Navigate to="/" replace />;
}

Protected.propTypes = {
  allow: PropTypes.bool,
  children: PropTypes.node,
};

export default Protected;
