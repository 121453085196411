'use strict';
import firebase from './firebase';

const getCustomerAppURL = () => {
  const { projectId } = firebase.app().options;
  if (projectId === 'condomi-prod') return 'https://customer.zetaone.com.br';
  else return `https://customer-${projectId.split('-')[1]}.zetaone.com.br`;
};

export default getCustomerAppURL;
