'use strict';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { formatValue } from '../../helpers/formatter';
import SubaccountDisplayForm from './SubaccountDisplayForm';
import SubaccountDocumentForm from './SubaccountDocumentForm';
import SubaccountEditForm from './SubaccountEditForm';
import { settingsActions } from './settings-slice';

const StatusIcon = ({ status }) => {
  switch (status) {
    case 'APPROVED':
      return <CheckIcon />;
    case 'REJECTED':
      return <ThumbDownIcon />;
    default:
      return <HourglassEmptyIcon />;
  }
};
StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

const SubaccountStatus = () => {
  const { isLoading } = useSelector((state) => state.app);
  const { pixAccount, pixSubaccount, isSubaccountEditFormOpen } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();

  const { accountStatus, lastEventAt, accountData, pixKey } =
    pixSubaccount ?? {};

  const { commercialInfo, general } = accountStatus ?? {};
  const lastEventFormatted = lastEventAt
    ? formatValue(lastEventAt, 'dateTime')
    : '-';

  const displayAccountData = {
    ...accountData,
    cpf: accountData.cpfCnpj,
    cnpj: accountData.cpfCnpj,
    subaccountType: accountData.companyType ? 'PJ' : 'PF',
  };

  const [isDocumentFormOpen, setIsDocumentFormOpen] = useState(false);
  let currentStep;

  if (general === 'APPROVED' && pixKey) currentStep = 3;
  else if (general === 'APPROVED' && !pixKey) currentStep = 2;
  else if (general !== 'APPROVED' && commercialInfo === 'APPROVED')
    currentStep = 1;
  else currentStep = 0;

  return (
    <>
      <Grid item xs={6}>
        <Stack direction="column" height="100%">
          <Typography variant="body2" color="textSecondary" component="p">
            Situação da conta
          </Typography>

          <Box marginTop={1}>
            <Stepper activeStep={currentStep} orientation="vertical">
              <Step key="commercialInfo">
                <StepLabel>Dados Cadastrais</StepLabel>
                <StepContent>
                  <Typography variant="caption" color="textSecondary">
                    Aguardando aprovação
                  </Typography>
                </StepContent>
              </Step>
              <Step key="documentation">
                <StepLabel>Documentação</StepLabel>
                <StepContent>
                  <Button
                    variant="outlined"
                    onClick={() => setIsDocumentFormOpen(true)}
                  >
                    Detalhes
                  </Button>
                </StepContent>
              </Step>
              <Step key="pixKey">
                <StepLabel>Chave Pix</StepLabel>
                <StepContent>
                  <Box display="flex" flexDirection="row">
                    <ButtonWithProgress
                      tooltipTitle="Ativar"
                      isLoading={isLoading}
                      disabled={
                        isLoading || !!pixKey?.key || general !== 'APPROVED'
                      }
                      variant="outlined"
                      onClick={() =>
                        dispatch(settingsActions.activateSubaccountPixKey())
                      }
                    >
                      Ativar
                    </ButtonWithProgress>
                  </Box>
                </StepContent>
              </Step>
              <Step key="finish">
                <StepLabel>Ativação</StepLabel>
                <StepContent>Conta ativada e pronta pra uso!</StepContent>
              </Step>
            </Stepper>
          </Box>
          <Box flexGrow={1} />
          <Typography variant="caption" color="textSecondary">
            Id: {pixAccount?.id}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            WalletId: {pixAccount?.walletId}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Id SubConta: {pixSubaccount?.id}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            WalletId SubConta: {pixSubaccount?.walletId}
          </Typography>

          <Typography variant="caption" color="textSecondary">
            Atualizado em {lastEventFormatted}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" color="textSecondary" component="p">
            Dados da conta
          </Typography>
          <IconButton
            onClick={() =>
              dispatch(settingsActions.setIsSubaccountEditFormOpen(true))
            }
          >
            <EditIcon />
          </IconButton>
        </Stack>
        {displayAccountData && (
          <Box marginTop={1}>
            <SubaccountDisplayForm {...displayAccountData} />
          </Box>
        )}
      </Grid>
      <ResponsiveDialog
        open={isDocumentFormOpen}
        onClose={() => setIsDocumentFormOpen(false)}
      >
        <SubaccountDocumentForm
          onCloseClick={() => setIsDocumentFormOpen(false)}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isSubaccountEditFormOpen}
        onClose={() =>
          dispatch(settingsActions.setIsSubaccountEditFormOpen(false))
        }
      >
        <SubaccountEditForm
          onCloseClick={() =>
            dispatch(settingsActions.setIsSubaccountEditFormOpen(false))
          }
        />
      </ResponsiveDialog>
    </>
  );
};

SubaccountStatus.propTypes = {};
export default SubaccountStatus;
