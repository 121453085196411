/* eslint-disable no-unused-vars */
'use strict';
import { put, take } from 'redux-saga/effects';
import usersDao from '../../dao/users';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { newUserActions } from './new-user-slice';

function* watchCreateUser() {
  const db = firebase.firestore();

  while (true) {
    const {
      payload: { uid, ...userData },
    } = yield take(newUserActions.createUser);
    try {
      // yield db.collection('users').doc(uid).set(userData);
      yield usersDao.set(uid, userData);
      yield put(newUserActions.createUserSuccess(userData));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(appActions.setError(e.message));
      yield put(newUserActions.createUserFail());
    }
  }
}

function* watchCreateEnterprise() {
  const db = firebase.firestore();
  // console.log('Iniciando saga watchCreateEnterprise');

  while (true) {
    const { payload: enterpriseData } = yield take(
      newUserActions.createEnterprise
    );
    yield put(appActions.startLoading());
    try {
      // console.log(`Saga de empresa, criando - ${enterprise.name}`);
      let docRef = yield db
        .collection('enterprises')
        .add({ ...enterpriseData, createdAt: Date.now() });
      // console.log('Criado, enterprise id : ', docRef.id);
      // console.log('Associando enterprise id ao usuario');
      // yield db
      //   .collection('users')
      //   .doc(enterprise.ownerUid)
      //   .set({ enterpriseId: docRef.id }, { merge: true });
      yield put(
        newUserActions.createEnterpriseSuccess({
          eid: docRef.id,
          ...enterpriseData,
        })
      );
      yield put(newUserActions.newUserWizardSuccess());
      yield put(appActions.finishLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
      yield put(newUserActions.createEnterpriseFail());
    }
  }
}

export default [watchCreateUser, watchCreateEnterprise];
