import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
}));
function InitScreen() {
  const classes = useStyles();

  return (
    <Container>
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
      >
        <Grid item>
          <Typography variant="h3">Inicializando aplicação</Typography>
        </Grid>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>
  );
}

export default InitScreen;
