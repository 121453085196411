'use strict';
import _ from 'lodash';
import firebase from '../helpers/firebase';
import removeSpecialChars from '../helpers/remove-special-chars';

const getKeywords = (data) => {
  const keywords = [
    data.id,
    data.name,
    data.phone,
    ..._.flatten(
      data.invoices.map((invoice) => [invoice.invoiceId, invoice.docNumber])
    ),
    ...(data.tags ?? []),
  ];
  return removeSpecialChars(keywords.join(' '))
    .toLocaleLowerCase()
    .split(' ')
    .filter((s) => s !== '');
};

const customersDao = {
  get: async (enterprise, key) => {
    const db = firebase.firestore();
    let customersSnapshot = await db
      .collection(`enterprises/${enterprise}/customers`)
      .get(key);
    let customers = customersSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return customers;
  },

  getByPhone: async (enterprise, phone) => {
    const db = firebase.firestore();
    let customersSnapshot = await db
      .collection(`enterprises/${enterprise}/customers`)
      .where('phone', '==', phone)
      .get();
    return customersSnapshot;
  },

  getByCustomerDocNumber: async (enterprise, customerDocNumber) => {
    const db = firebase.firestore();
    let customersSnapshot = await db
      .collection(`enterprises/${enterprise}/customers`)
      .where('customerDocNumber', '==', customerDocNumber)
      .get();
    return customersSnapshot;
  },
  set: (enterprise, key, data) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection(`enterprises/${enterprise}/customers`)
        .doc(key)
        .set({ ...data, keywords: getKeywords({ id: key, ...data }) })
        .then(resolve)
        .catch((e) => reject(e));
    });
  },

  update: async (enterprise, key, data) => {
    const db = firebase.firestore();

    const docRef = await db
      .collection(`enterprises/${enterprise}/customers`)
      .doc(key);

    return db.runTransaction(async (t) => {
      try {
        const doc = await t.get(docRef);
        if (!doc.exists) {
          return { error: 'Customer not found' };
        } else {
          const customerData = doc.data();
          await t.update(docRef, {
            ...data,
            keywords: getKeywords({
              id: key,
              ...customerData,
              ...data,
            }),
          });
          return { msg: 'Customer updated' };
        }
      } catch (e) {
        return { error: e };
      }
    });
  },

  updateInvoice: (eid, key, data) => {
    const db = firebase.firestore();
    const docRef = db.collection(`enterprises/${eid}/customers`).doc(key);

    return db.runTransaction(async (t) => {
      try {
        const doc = await t.get(docRef);
        if (!doc.exists) {
          return { error: 'Customer not found' };
        } else {
          const customerData = doc.data();
          const { invoices } = customerData;
          // console.log('Old invoices: ', invoices);

          const newInvoices = invoices.map((i) => {
            if (
              i.invoiceId === data.invoiceId &&
              i.docNumber === data.docNumber
            )
              return { ...i, ...data };
            else return i;
          });
          // console.log('New invoices: ', newInvoices);

          await t.update(docRef, {
            invoices: newInvoices,
            keywords: getKeywords({
              id: key,
              ...customerData,
              invoices: newInvoices,
            }),
          });
          return { msg: 'Customer updated' };
        }
      } catch (e) {
        return { error: e };
      }
    });
  },

  add: (enterprise, data) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection(`enterprises/${enterprise}/customers`)
        .add({ ...data, keywords: getKeywords(data) })
        .then(resolve)
        .catch((e) => reject(e));
    });
  },

  delete: (enterprise, key) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection(`enterprises/${enterprise}/customers`)
        .doc(key)
        .delete()
        .then(resolve)
        .catch((e) => reject(e));
    });
  },
};
export default customersDao;
