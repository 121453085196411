import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const csvFixedFields = {
  name: {
    csvColumn: 'nome',
    label: 'Nome do cliente',
    showOrder: -9,
    type: 'text',
  },
  customerDocNumber: {
    csvColumn: 'customerDoc',
    label: 'CPF/CNPJ do Responsável',
    type: 'docNumber',
    showOrder: -8,
  },
  docNumber: {
    csvColumn: 'doc',
    label: 'CPF/CNPJ da Fatura',
    type: 'docNumber',
    showOrder: -7,
  },
  phone: {
    csvColumn: 'telefone',
    label: 'Telefone',
    type: 'phone',
    showOrder: -6,
  },

  invoiceId: {
    csvColumn: 'idCobranca',
    label: 'Identificador',
    type: 'text',
    showOrder: -5,
  },
  invoiceValue: {
    csvColumn: 'valorFatura',
    label: 'Valor',
    type: 'decimal',
    showOrder: -4,
  },
  invoiceDate: {
    csvColumn: 'dataVencimento',
    label: 'Vencimento',
    type: 'date',
    showOrder: -3,
  },
};

export const journeysSlice = createSlice({
  name: 'journeys',
  initialState: {
    data: [],
    selected: null,
    selectedContext: null,
    metadata: {},
    csvFields: {},
    csvExtraFields: {},
    csvFixedFields,
    sendType: null,
    sendState: 'START',
    sendActiveStep: 0,
  },
  reducers: {
    load: () => {},
    loadSuccess: (state, action) => {
      state.data = action.payload;
    },
    loadFail: (state, _action) => {
      state.data = [];
    },
    selectJourney: (state, { payload: { selected, selectedContext } }) => {
      state.selected = selected;
      state.selectedContext = selectedContext;
      if (selected?.guideId) {
        state.csvExtraFields = _.omitBy(
          state.metadata[selected.guideId].properties,
          (v, key) =>
            Object.keys(selected).find((k) => k === key) ||
            Object.keys(selected.globalFields ?? []).find((k) => k === key) ||
            !v.allowInCsv
        );

        state.csvFields = {
          ...state.csvExtraFields,
          ...csvFixedFields,
        };
      } else {
        state.csvFields = {};
        state.csvExtraFields = {};
      }
    },
    delete: () => {},
    deleteSuccess: () => {},
    deleteFail: () => {},
    save: () => {},
    saveSuccess: (state) => {
      state.selected = null;
      state.selectedContext = null;
      state.csvFields = {};
      state.csvExtraFields = {};
    },
    saveFail: () => {},
    loadMetadata: () => {},
    loadMetadataSuccess: (state, action) => {
      state.metadata = action.payload;
    },
    loadMetadataFail: (state, _action) => {
      state.metadata = [];
    },
    send: (state) => {
      state.sendState = 'SENDING';
    },
    sendSuccess: (state) => {
      // state.selected = null;
      // state.selectedContext = null;
      // state.csvFields = {};
      // state.csvExtraFields = {};
      state.sendState = 'SUCCESS';
    },
    sendFail: (state) => {
      state.sendState = 'FAIL';
    },
    setSendType: (state, action) => {
      state.sendType = action.payload;
    },
    sendReset: (state) => {
      state.sendState = 'START';
      state.sendActiveStep = 0;
    },
    setSendActiveStep: (state, action) => {
      state.sendActiveStep = action.payload;
    },
  },
});

export const journeysActions = journeysSlice.actions;

export default journeysSlice.reducer;
