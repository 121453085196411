'use strict';
import ky from 'ky';
import { all, put, select, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';

import enterprisesDao from '../../dao/enterprises';
import usersDao from '../../dao/users';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { usersActions } from './users-slice';

import { getApi } from '../../helpers/api';

function* watchLoad() {
  while (true) {
    yield take(usersActions.load);
    try {
      yield put(appActions.startLoading());
      const data = yield usersDao.get();
      yield put(usersActions.loadSuccess(data));
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchload');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetEnterprises() {
  while (true) {
    yield take(usersActions.getEnterprises);
    try {
      yield put(appActions.startLoading());
      const data = yield enterprisesDao.get();
      yield put(
        usersActions.getEnterprisesSuccess(
          data.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {})
        )
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchGetEnterprises');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.getEnterprisesFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadInvitations() {
  const db = firebase.firestore();
  while (true) {
    yield take(usersActions.loadInvitations);
    try {
      yield put(appActions.startLoading());
      const invitations = yield db.collection('invitations').get();
      yield put(
        usersActions.loadInvitationsSuccess(
          invitations.docs.map((invitationDoc) => ({
            id: invitationDoc.id,
            ...invitationDoc.data(),
          }))
        )
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchload');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.loadInvitationsFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchDeleteUser() {
  while (true) {
    const { payload } = yield take(usersActions.delete);
    try {
      yield put(appActions.startLoading());
      yield all(payload.map((v) => usersDao.delete(v)));
      yield put(
        notifierActions.enqueue({
          message: 'Os registros foram excluídos com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      // yield put(appActions.createNotification(notification));
      yield put(usersActions.deleteSuccess());
      yield put(usersActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.deleteFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveUser() {
  while (true) {
    const { payload } = yield take(usersActions.save);

    try {
      yield put(appActions.startLoading());
      const { id } = payload;
      yield usersDao.update(id, payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(usersActions.refreshUserClaims({ uid: id }));
      const { type } = yield take([
        usersActions.refreshUserClaimsSuccess,
        usersActions.refreshUserClaimsFail,
      ]);
      if (type === usersActions.refreshUserClaimsFail().type)
        throw new Error('Erro ao atualizar token de segurança');

      yield put(usersActions.saveSuccess());
      yield put(usersActions.load());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchSaveUser');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.saveFail());
      yield put(
        notifierActions.enqueue({
          message: `Erro ao salvar - ${e.message}`,
          options: {
            variant: 'error',
          },
        })
      );
      yield put(appActions.finishLoading());
    }
  }
}

function* watchRefreshUserClaims() {
  // const { projectId } = firebase.app().options;

  while (true) {
    const {
      payload: { uid },
    } = yield take(usersActions.refreshUserClaims);

    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: '/auth/refresh-user-claims',
        local: localApi,
      });

      yield ky.post(url, {
        json: { uid },
        headers,
        ...opts,
      });
      yield put(
        notifierActions.enqueue({
          message: 'Atualizado com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(usersActions.refreshUserClaimsSuccess());
    } catch (e) {
      // console.log('Erro ao executar watchSaveUser');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.refreshUserClaimsFail());
      yield put(
        notifierActions.enqueue({
          message: `Erro ao atualizar - ${e.message}`,
          options: {
            variant: 'error',
          },
        })
      );
    }
  }
}

function* watchRefreshUserClaimsClick() {
  // const { projectId } = firebase.app().options;

  while (true) {
    const {
      payload: { uid },
    } = yield take(usersActions.refreshUserClaimsClick);

    try {
      yield put(appActions.startLoading());
      yield put(usersActions.refreshUserClaims({ uid }));
      const { type } = yield take([
        usersActions.refreshUserClaimsSuccess,
        usersActions.refreshUserClaimsFail,
      ]);
      if (type === usersActions.refreshUserClaimsFail().type)
        throw new Error('Erro ao atualizar token de segurança');

      yield put(usersActions.refreshUserClaimsClickSuccess());
      yield put(usersActions.load());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchSaveUser');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.refreshUserClaimsClickFail());
      yield put(
        notifierActions.enqueue({
          message: `Erro ao atualizar - ${e.message}`,
          options: {
            variant: 'error',
          },
        })
      );
      yield put(appActions.finishLoading());
    }
  }
}
function* watchGetAuthUser() {
  // const { projectId } = firebase.app().options;

  while (true) {
    const { payload: uid } = yield take(usersActions.getAuthUser);

    try {
      yield put(appActions.startLoading());

      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/admin/user/${uid}`,
        local: localApi,
      });

      const response = yield ky.get(url, { headers, ...opts });
      const userData = yield response.json();

      yield put(usersActions.getAuthUserSuccess(userData));
      yield put(usersActions.load());
    } catch (e) {
      // console.log('Erro ao executar watchSaveUser');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.getAuthUserFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchNewUser() {
  const db = firebase.firestore();
  while (true) {
    const {
      payload: { email, role },
    } = yield take(usersActions.newUser);
    const {
      settings: {
        enterprise: { id: enterpriseId, name },
      },
    } = yield select((state) => state);

    try {
      yield put(appActions.startLoading());
      const invitationDoc = yield db
        .collection('invitations')
        .where('email', '==', email)
        .get();
      if (!invitationDoc.empty)
        throw new Error('O convite já existe, não é possível criar novamente');
      const usersDoc = yield db
        .collection('users')
        .where('email', '==', email)
        .get();
      if (!usersDoc.empty)
        throw new Error('Já existe um usuário com este e-mail cadastrado');

      const newInvitationData = {
        createdAt: Date.now(),
        email,
        enterpriseId,
        role,
        enterprises: [{ id: enterpriseId, name }],
      };
      const newInvitationDoc = yield db
        .collection('invitations')
        .add(newInvitationData);

      yield put(usersActions.newUserSuccess(newInvitationDoc.id));
      yield put(
        notifierActions.enqueue({
          message: 'Convite criado com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(usersActions.load());
      yield put(
        usersActions.selectInvitation({
          id: newInvitationDoc.id,
          ...newInvitationData,
        })
      );
    } catch (e) {
      // console.log('Erro ao executar watchnewUserUser');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(usersActions.newUserFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchGetEnterprises,
  watchLoad,
  watchLoadInvitations,
  watchSaveUser,
  watchDeleteUser,
  watchRefreshUserClaims,
  watchRefreshUserClaimsClick,
  watchGetAuthUser,
  watchNewUser,
];
