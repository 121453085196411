import firebase from './firebase';
import { v4 as uuidv4 } from 'uuid';
const uploadBucket = 'journey-uploads';

export const server = {
  process: (
    _fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    _transfer,
    _options
  ) => {
    const id = uuidv4();
    var storageRef = firebase.storage().ref();

    // console.log(metadata);
    // console.log(file);
    const { name, size, type } = file;
    var uploadTask = storageRef
      .child(`${uploadBucket}/${id}`)
      .put(file, { customMetadata: { name, size, type } });
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // var prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + prog + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log('Upload is running');
            break;
        }
        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (err) => {
        // console.log(err.code);
        error(err.code);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (err.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((_downloadURL) => {
          // console.log('File available at', downloadURL);
          load(id);
        });
      }
    );

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // console.log('uploadTask.cancel();');
        uploadTask.cancel();
        abort();
      },
    };
  },
  revert: (uniqueFileId, load, error) => {
    // console.log(`Deleting ${uploadBucket}/${uniqueFileId}`);
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${uploadBucket}/${uniqueFileId}`);
    fileRef
      .delete()
      .then(() => {
        load();
      })
      .catch((err) => {
        error(err.code);
      });
  },
  // restore: (uniqueFileId, load, error, progress, abort, headers) => {
  // console.log(`Restoring ${uploadBucket}/${uniqueFileId}`);
  //   const storageRef = firebase.storage().ref();
  //   const fileRef = storageRef.child(`${uploadBucket}/${uniqueFileId}`);
  //   fileRef
  //     .getDownloadURL()
  //     .then((m) => {
  // console.log(m);
  //       load(m);
  //     })
  //     .catch((err) => {
  //       error(err.code);
  //     });

  //   return {
  //     abort: () => {
  //       // User tapped abort, cancel our ongoing actions here

  //       // Let FilePond know the request has been cancelled
  //       abort();
  //     },
  //   };
  // },

  // Should get the temporary file object from the server
  // ...

  // Can call the error method if something is wrong, should exit after
  // error('oh my goodness');

  // // Can call the header method to supply FilePond with early response header string
  // // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
  // headers(headersString);

  // // Should call the progress method to update the progress to 100% before calling load
  // // (computable, loadedSize, totalSize)
  // progress(true, 0, 1024);

  // // Should call the load method with a file object when done
  // load(serverFileObject);
};
