'use strict';
import { put, select, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { reportsActions } from './reports-slice';

import ky from 'ky';
import moment from 'moment';
import { getApi } from '../../helpers/api';
import { getPixStatus } from '../../helpers/pix-status';

function* watchLoad() {
  const db = firebase.firestore();
  while (true) {
    yield take(reportsActions.load);
    const {
      settings: {
        enterprise: { id: eid },
      },
      reports: { reportType, initialDate, finalDate, serviceDate },
    } = yield select((state) => state);
    try {
      yield put(appActions.startLoading());
      let snapshot;
      switch (reportType) {
        case 'payments':
          snapshot = yield db
            .collection('pix')
            .where('eid', '==', eid)
            .where(
              'resultAt',
              '>=',
              moment(initialDate).startOf('day').valueOf()
            )
            .where('resultAt', '<=', moment(finalDate).endOf('day').valueOf())
            .where('result.resultId', '==', 'PIX_PAYMENT')
            .get();
          break;
        case 'pixStatus': {
          const initialServiceDate = moment(serviceDate)
            .startOf('month')
            .valueOf();
          const finalServiceDate = moment(serviceDate).endOf('month').valueOf();

          snapshot = yield db
            .collection('pix')
            .where('eid', '==', eid)
            .where(
              'invoiceData.serviceDate',
              '>=',
              moment(initialServiceDate).startOf('day').format('YYYY-MM-DD')
            )
            .where(
              'invoiceData.serviceDate',
              '<=',
              moment(finalServiceDate).endOf('day').format('YYYY-MM-DD')
            )
            .get();
          break;
        }
        case 'waMessages':
          snapshot = yield db
            .collection('wa-messages')
            .where('eid', '==', eid)
            .where(
              'createdAt',
              '>=',
              moment(initialDate).startOf('day').valueOf()
            )
            .where('createdAt', '<=', moment(finalDate).endOf('day').valueOf())
            .get();
          break;
      }
      let data = [];

      if (!snapshot.empty) {
        data = snapshot.docs.map((doc) => {
          return {
            txid: doc.id,
            ...doc.data(),
            status: getPixStatus(doc.data()),
          };
        });
      }
      yield put(reportsActions.setData(data));
      yield put(appActions.finishLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Erro ao executar watchLoad');
      // eslint-disable-next-line no-console
      console.error(e);
      yield put(appActions.setError(e.message));
      yield put(reportsActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchExport() {
  const db = firebase.firestore();

  while (true) {
    const { payload: exportType } = yield take(reportsActions.export);
    const {
      settings: {
        enterprise: { id: eid },
        localApi,
      },
      reports: { reportType, initialDate, finalDate, serviceDate },
    } = yield select((state) => state);

    const bgTaskDocRef = yield db
      .collection(`enterprises/${eid}/bg-tasks`)
      .add({
        createdAt: Date.now(),
        title: 'Geração de arquivo',
        progress: 0,
        step: 'Aguardando...',
        content: 'Gerando arquivo',
      });

    const { url, headers, ...opts } = yield getApi({
      path: '/report',
      local: localApi,
    });
    yield ky.post(url, {
      json: {
        eid,
        reportType,
        initialDate,
        finalDate,
        serviceDate,
        exportType,
        bgTaskId: bgTaskDocRef.id,
      },
      headers,
      ...opts,
    });
  }
}

export default [watchLoad, watchExport];
