import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Box, Button, IconButton, Popover } from '@mui/material';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { smartTipActions } from './smart-tip-slice';

const useStyles = makeStyles((theme) => ({
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
  '@keyframes blinker': {
    ['0%, 90%']: { scale: 1 },
    ['45%']: { scale: 1.25 },
  },
  clsTipIcon: {
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    cursor: 'help',
  },
}));

function SmartTip({ id, children }) {
  const { dismissed } = useSelector((state) => state.smartTip);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { clsTipIcon } = useStyles();
  const dispatch = useDispatch();

  const dismissClick = () => {
    dispatch(smartTipActions.addDismissed(id));
  };

  if ((dismissed ?? []).includes(id)) return <></>;

  return (
    <>
      <Box marginLeft={2} marginRight={2}>
        <IconButton onClick={handleClick}>
          <TipsAndUpdatesIcon color="info" className={clsTipIcon} />
        </IconButton>
      </Box>
      <Popover
        id="smart-tip-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2} sx={{ maxWidth: 500 }}>
          {children}
          <Box marginTop={4}>
            <Button variant="outlined" color="secondary" onClick={dismissClick}>
              Não mostrar mais essa dica
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

SmartTip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default SmartTip;
