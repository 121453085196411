import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import ReportTable from '../../components/ReportTable';
import { formatValue } from '../../helpers/formatter';
import useConfirm from '../../hooks/useConfirm';
import Print from '../reports/Print';
import { walletActions } from './wallet-slice';

const useStyles = makeStyles((theme) => ({
  clsCard: {
    height: '100%',
  },
  clsNobr: {
    'white-space': 'nowrap',
  },
  clsIn: {
    color: theme.palette.finance.cashIn,
  },
  clsOut: {
    color: theme.palette.finance.cashOut,
  },
  clsCash: {
    color: theme.palette.finance.cash,
  },
  clsCenter: {
    justifyContent: 'center',
  },
  clsSuccess: {
    color: theme.palette.success.main,
  },
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));

export default function Wallet() {
  const [showBalance, toggleShowBalance] = useState(false);
  const [showIncome, toggleShowIncome] = useState(false);
  const {
    clsCard,
    clsNobr,
    clsIn,
    clsOut,
    clsCash,
    clsCenter,
    clsSuccess,
    clsWarning,
  } = useStyles();
  const {
    initialDate,
    finalDate,
    balance,
    isBalanceLoading,
    areTransactionsLoading,
    transactions,
    isTransferLoading,
    fees: {
      transfer: {
        pixTransfersCountOfCurrentMonth,
        monthlyTransfersWithoutFee,
      } = {},
      pix: { debit: { defaultValue } = {} } = {},
    } = {},
  } = useSelector((state) => state.wallet);
  const {
    enterprise: { name: enterpriseName },
  } = useSelector((state) => state.settings);

  const ref = useRef();

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @page {
    size: auto;
    margin: 10mm;
  }
`;

  const title = `Extrato ${enterpriseName} - ${moment(initialDate).format(
    'DD/MM/YYYY'
  )} a ${moment(finalDate).format('DD/MM/YYYY')}`;
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle,
    documentTitle: title,
  });

  // TODO: Corrigir qtd de transferencias atual
  const willChargeTransfer = true;
  //pixTransfersCountOfCurrentMonth >= monthlyTransfersWithoutFee;

  const ConfirmTransferMessage = (
    <>
      <Typography variant="body2">
        Atenção! Você possui uma quantidade limitada de transferências gratuitas
        por mês.
      </Typography>
      <Box
        marginTop={2}
        marginBottom={2}
        display="flex"
        flexDirection="row"
        width={1}
        alignItems="center"
        justifyContent="space-around"
      >
        {willChargeTransfer ? (
          <>
            <Box>
              <WarningIcon fontSize="large" className={clsWarning} />
            </Box>
            <Box>
              <Typography variant="h6">
                Transferências gratuitas: {pixTransfersCountOfCurrentMonth}/
                {monthlyTransfersWithoutFee}
                <br />
                Será cobrada uma tarifa de {formatValue(defaultValue, 'money')}!
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <CheckIcon fontSize="large" className={clsSuccess} />
            </Box>
            <Box>
              <Typography variant="h6">
                Transferências gratuitas: {pixTransfersCountOfCurrentMonth}/
                {monthlyTransfersWithoutFee}
              </Typography>
              <Typography variant="h6">
                Não será cobrada nenhuma tarifa!
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Typography variant="body2">
        Deseja continuar com a transferência?
      </Typography>
    </>
  );

  const [TransferConfirmationDialog, confirmTransfer] = useConfirm(
    'Transferência',
    ConfirmTransferMessage,
    'Transferir',
    SendIcon
  );

  const dispatch = useDispatch();

  const columns = [
    {
      id: 'transactionDate',
      numeric: false,
      label: 'Data',
      align: 'left',
      formatter: (v) => formatValue(v, 'date'),
    },
    {
      id: 'description',
      numeric: false,
      label: 'Descrição',
      align: 'left',
      className: clsNobr,
    },
    {
      id: 'value',
      numeric: true,
      label: 'Valor',
      align: 'right',
      formatter: (v) => formatValue(v, 'money'),
      className: (v) => (v > 0 ? clsIn : clsOut),
    },
    {
      id: 'balance',
      numeric: true,
      label: 'Saldo',
      align: 'right',
      formatter: (v) => formatValue(v, 'money'),
    },
  ];

  let income = 0;
  let enhancedData;
  if (transactions) {
    const reducedTransactions = transactions.reduce((acc, cur) => {
      if (
        cur.type === 'INTERNAL_TRANSFER_DEBIT' ||
        cur.type === 'PAYMENT_FEE'
      ) {
        const findIndex = acc.findIndex(
          ({ paymentId, type }) =>
            paymentId === cur.paymentId && type === 'ZETA_FEE'
        );
        if (findIndex === -1) {
          acc.push({ ...cur, type: 'ZETA_FEE' });
        } else {
          acc[findIndex].value += cur.value;
          if (cur.id.localeCompare(acc[findIndex].id) > 0)
            acc[findIndex].balance = cur.balance;
          if (cur.type === 'INTERNAL_TRANSFER_DEBIT')
            acc[findIndex].description = cur.description;
        }
      } else acc.push(cur);

      if (cur.value > 0) income += cur.value;
      return acc;
    }, []);
    const sortedTransactions = [...reducedTransactions].sort(
      ({ id: a }, { id: b }) => a.localeCompare(b)
    );

    const [{ balance: firstBalance = 0, value: firstValue = 0 } = {}] =
      sortedTransactions;
    const firstTransaction = {
      description: 'Saldo Anterior',
      balance: firstBalance - firstValue,
    };

    enhancedData = [
      firstTransaction,
      ...sortedTransactions.map((row) => ({
        transactionDate: moment(row.date).valueOf(),
        description: row.description,
        value: row.value,
        balance: row.balance,
      })),
    ];
  }

  useEffect(() => {
    dispatch(
      walletActions.setInitialDate(moment(finalDate).startOf('month').valueOf())
    );
    dispatch(walletActions.getBalance());
  }, [dispatch]);

  const handleTransferClick = async () => {
    const answer = await confirmTransfer(ConfirmTransferMessage);
    const value = willChargeTransfer ? balance - defaultValue : balance;
    if (answer) dispatch(walletActions.transfer(value));
  };

  const handleGetTransactionsClick = () => {
    dispatch(walletActions.getTransactions());
    dispatch(walletActions.getBalance());
  };

  return (
    <>
      <Box p={2}>
        <Box display="flex" flexDirection="row">
          <Box flexBasis={0.5} flexGrow={1} paddingRight={1}>
            <Card className={clsCard}>
              <CardHeader
                title="Saldo"
                action={
                  <IconButton
                    aria-label="ocultar saldo"
                    onClick={() => toggleShowBalance(!showBalance)}
                    size="large"
                  >
                    {showBalance ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                }
              />
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  minHeight="60px"
                  alignItems="center"
                >
                  {isBalanceLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography
                        variant="h3"
                        align="center"
                        component="p"
                        className={clsCash}
                      >
                        {showBalance ? formatValue(balance, 'money') : '***'}
                      </Typography>
                      {showBalance && (
                        <Box marginLeft={2}>
                          <IconButton
                            aria-label="Atualizar saldo"
                            onClick={() => dispatch(walletActions.getBalance())}
                            size="large"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </CardContent>
              {!isBalanceLoading && showBalance && (
                <CardActions disableSpacing className={clsCenter}>
                  <ButtonWithProgress
                    tooltipTitle="Solicitar transferência"
                    aria-label="Transferir"
                    disabled={(balance ?? 0) === 0}
                    isLoading={isTransferLoading}
                    endIcon={<SendIcon />}
                    color="primary"
                    onClick={handleTransferClick}
                  >
                    Transferir
                  </ButtonWithProgress>
                </CardActions>
              )}
            </Card>
          </Box>
          <Box flexBasis={0.5} flexGrow={1} paddingLeft={1}>
            <Card className={clsCard}>
              <CardHeader
                title="Recebimentos"
                action={
                  <IconButton
                    aria-label="ocultar recebimentos"
                    onClick={() => toggleShowIncome(!showIncome)}
                    size="large"
                  >
                    {showIncome ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                }
              />
              <CardContent>
                <Box display="flex" justifyContent="center" minHeight="60px">
                  <Typography
                    variant="h3"
                    align="center"
                    component="p"
                    className={clsIn}
                  >
                    {showIncome ? formatValue(income, 'money') : '***'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box
          paddingTop={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box>
            <DatePicker
              inputVariant="filled"
              autoOk
              label="Data inicial"
              format="DD/MM/YYYY"
              value={moment(initialDate)}
              maxDate={moment(finalDate)}
              onChange={(d) =>
                dispatch(walletActions.setInitialDate(d.valueOf()))
              }
            />
          </Box>
          <Box marginLeft={2}>
            <DatePicker
              inputVariant="filled"
              autoOk
              label="Data final"
              format="DD/MM/YYYY"
              minDate={moment(initialDate)}
              value={moment(finalDate)}
              onChange={(d) =>
                dispatch(walletActions.setFinalDate(d.valueOf()))
              }
            />
          </Box>
          <Box marginLeft={2}>
            <ButtonWithProgress
              tooltipTitle="Gerar Extrato"
              onClick={handleGetTransactionsClick}
              isLoading={areTransactionsLoading}
              startIcon={<PlayArrowIcon />}
              variant="contained"
              color="primary"
            >
              Gerar Extrato
            </ButtonWithProgress>
          </Box>
          {enhancedData && (
            <>
              <Box marginLeft={2}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handlePrint}
                  size="large"
                >
                  <PrintIcon fontSize="small" />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
        {enhancedData && (
          <>
            <Box width={1}>
              <ReportTable
                title="Pagamentos"
                data={enhancedData}
                columns={columns}
                tableProps={{
                  size: 'small',
                }}
              />
              <Box marginTop={2} />
            </Box>
            <Box style={{ display: 'none' }}>
              <Print ref={ref} title={title}>
                <ReportTable
                  title="Pagamentos"
                  data={enhancedData}
                  columns={columns}
                  tableProps={{
                    size: 'small',
                  }}
                />
              </Print>
            </Box>
          </>
        )}
      </Box>
      <TransferConfirmationDialog />
    </>
  );
}
