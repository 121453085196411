import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { mailingActions } from '../mailing/mailing-slice';
import { journeysActions } from './journeys-slice';

const useStyles = makeStyles((theme) => ({
  clsHidden: {
    display: 'none',
  },
  clsField: {
    height: '100%',
  },
  clsFieldContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  clsProgressPanel: {
    borderRadius: '0.5em',
    backgroundColor: theme.palette.background.field,
    color: theme.palette.text.secondary,
    height: '76px',
  },
  clsProgressBar: {
    width: '95%',
  },
}));

function SendJourneyStep4({ visible }) {
  const {
    clsHidden,
    clsField,
    clsFieldContent,
    clsProgressPanel,
    clsProgressBar,
  } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    journeys: { sendType, sendState },
    mailing: {
      charge: { fileProcessingResult: { recordCount } = {} } = {},
      selectedLines,
    },
  } = useSelector((state) => state);
  const sendTypeFormatted =
    sendType === 'csv' ? 'Envio de arquivo' : 'A partir do cadastro';

  return (
    <>
      <Box margin={1} paddingTop={1} className={clsx(!visible && clsHidden)}>
        {sendState === 'START' && (
          <>
            <Typography variant="body2" color="textSecondary" component="p">
              Resumo do envio:
            </Typography>

            <Box marginTop={2} marginBottom={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Card variant="outlined" className={clsField}>
                    <CardContent className={clsFieldContent}>
                      <Typography variant="caption" color="textSecondary">
                        Tipo de envio
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {sendTypeFormatted}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card variant="outlined" className={clsField}>
                    <CardContent className={clsFieldContent}>
                      <Typography variant="caption" color="textSecondary">
                        Quantidade de registros recebidos
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {recordCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card variant="outlined" className={clsField}>
                    <CardContent className={clsFieldContent}>
                      <Typography variant="caption" color="textSecondary">
                        Quantidade de registros selecionados
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {selectedLines?.length}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              {selectedLines?.length === 1 ? (
                <Typography variant="body1" color="textPrimary" component="p">
                  Será enviado apenas um registro !
                </Typography>
              ) : (
                <Typography variant="body1" color="textPrimary" component="p">
                  Serão enviados {selectedLines?.length} registros !
                </Typography>
              )}
            </Box>
            <Box marginTop={2}>
              <Typography variant="body1" color="textPrimary" component="p">
                ATENÇÃO! Ao clicar em ENVIAR não será mais possível cancelar a
                ação!
              </Typography>
            </Box>
          </>
        )}
        {sendState === 'SENDING' && (
          <Box margin={1}>
            <Box
              className={clsProgressPanel}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle1">Processando</Typography>
              <LinearProgress className={clsProgressBar} color="secondary" />
            </Box>
          </Box>
        )}
        {sendState === 'SUCCESS' && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={2}
          >
            <Typography variant="body1">
              Envio disparado com sucesso!
            </Typography>
            <Typography variant="body1">
              O envio será processado em segundo plano, você será notificado
              quando for concluído.
            </Typography>
            <Box marginTop={2}>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(
                    journeysActions.selectJourney({
                      selected: null,
                      selectedContext: null,
                    })
                  );
                  dispatch(mailingActions.reset());
                  dispatch(journeysActions.sendReset());
                  navigate('/monitor');
                }}
              >
                Monitorar
              </Button>
            </Box>
          </Box>
        )}
        {sendState === 'FAIL' && (
          <Typography>
            Falha
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(mailingActions.reset());
                dispatch(journeysActions.sendReset());
              }}
            >
              Reiniciar
            </Button>
          </Typography>
        )}
      </Box>
    </>
  );
}

SendJourneyStep4.propTypes = {
  visible: PropTypes.bool.isRequired,
};
export default SendJourneyStep4;
