import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((_theme) => ({
  print: {
    color: 'black',
    '& .MuiTypography-h4': {
      color: 'black',
      fontSize: '20px',
    },
    '& .MuiTypography-h5': {
      color: 'black',
      fontSize: '13px',
    },
    '& .MuiTypography-body1': {
      color: 'black',
      fontSize: '10px',
    },
    '& .MuiTableCell-root': {
      color: 'black',
      fontSize: '9px',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
    },
    '& .MuiDivider-root': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    '& table': { pageBreakInside: 'auto' },
    '& .page-break': {
      pageBreakBefore: 'always',
    },
    '& thead': {
      display: 'table-row-group',
      '& .MuiTableCell-root': {
        fontWeight: 'bold',
        borderTop: '1px solid rgba(0,0,0,0.2)',
        borderBottom: '0px',
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    '& .MuiTableCell-sizeSmall': {
      padding: '3px 12px 3px 8px',
    },
  },
}));

const Print = React.forwardRef(({ children, title }, ref) => {
  const classes = useStyles();
  return (
    <Box
      ref={ref}
      className={classes.print}
      p={0}
      display="flex"
      flexDirection="column"
    >
      <Box width={1}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Divider />
      <Box marginTop={2}></Box>
      {children}
    </Box>
  );
});
Print.displayName = 'ComponentToExport';
Print.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Print;
