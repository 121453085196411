'use strict';
import moment from 'moment';
import { put, select, take } from 'redux-saga/effects';
import { getApi } from '../../helpers/api';
import rpcCall from '../../sagas/rpc';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { walletActions } from './wallet-slice';

function* watchGetBalance() {
  while (true) {
    yield take(walletActions.getBalance);
    try {
      const {
        localApi,
        enterprise: { pixAccount },
      } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/wallet/${encodeURI(pixAccount)}/balance`,
        local: localApi,
      });
      const { error, balance, fees } = yield rpcCall({
        url,
        method: 'get',
        headers,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(walletActions.getBalanceFail());
      } else {
        yield put(walletActions.getBalanceSuccess({ balance, fees }));
      }
    } catch (e) {
      yield put(walletActions.getBalanceFail());
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchGetTransactions() {
  while (true) {
    yield take(walletActions.getTransactions);
    try {
      const {
        localApi,
        enterprise: { pixAccount },
      } = yield select((state) => state.settings);
      const { initialDate, finalDate } = yield select((state) => state.wallet);
      const { url, headers, ...opts } = yield getApi({
        path: `/wallet/${encodeURI(
          pixAccount
        )}/transactions?initialDate=${initialDate}&finalDate=${finalDate}`,
        local: localApi,
      });
      yield put(appActions.startLoading());
      const { error, data } = yield rpcCall({
        url,
        method: 'get',
        headers,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(walletActions.getTransactionsFail());
      } else {
        yield put(walletActions.getTransactionsSuccess(data));
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(walletActions.getTransactionsFail());
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchTransfer() {
  while (true) {
    const { payload: value } = yield take(walletActions.transfer);
    try {
      const {
        localApi,
        enterprise: { id: eid },
      } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: '/wallet/send-standalone-pix',
        local: localApi,
      });
      const dateTime = moment().format('YYYYMMDDhhmmss');
      const payload = {
        transactionData: { eid, dateTime, value, skipRetries: true },
      };

      const { error, status } = yield rpcCall({
        url,
        method: 'post',
        headers,
        payload,
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(walletActions.transferFail());
      } else {
        yield put(walletActions.transferSuccess());
        if (status === 'ok')
          yield put(
            notifierActions.enqueue({
              message:
                'Solicitação de transferência realizada com sucesso. Aguarde alguns minutos até o término da transação.',
              options: {
                variant: 'success',
              },
            })
          );
        else if (status === 'ignored')
          yield put(
            notifierActions.enqueue({
              message: 'Solicitação ignorada, saldo zerado !',
              options: {
                variant: 'success',
              },
            })
          );
      }
    } catch (e) {
      yield put(walletActions.transferFail());
      yield put(appActions.setError(e.message));
    }
  }
}

export default [watchGetBalance, watchGetTransactions, watchTransfer];
