import { Alert } from '@mui/material';
import PropTypes from 'prop-types';

export default function HelpAlert(props) {
  console.log(props);
  return <Alert severity="info">{props.children}</Alert>;
}
HelpAlert.propTypes = {
  node: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};
