import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Clock from '../../components/Clock';
import { versionsActions } from './versions-slice';

const shortEnv = (env) => env.slice(8);
const shortRegion = (region) =>
  region === 'southamerica-east1' ? '' : ` - ${region}`;

export default function Monitor() {
  const dispatch = useDispatch();
  const {
    versions: { data },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(versionsActions.load());
  }, [dispatch]);

  const apps = [...Object.keys(data)].sort();

  return (
    <Box p={2}>
      <Box marginBottom={2} display="flex" flexDirection="row">
        <Typography variant="h5">Versões</Typography>
        <Box flexGrow={1} />
        <Typography variant="caption">
          <Clock />
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {apps.map((app, index) => (
          <Grid key={index} item md={4} sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <Box height={1} display="flex" flexDirection="column">
                <Box flexGrow={1}>
                  <CardHeader title={app} />
                  <CardContent>
                    {data[app].map(
                      (
                        { projectId, region, appVersion, helpersVersion },
                        ix
                      ) => (
                        <Typography key={ix}>{`${shortEnv(
                          projectId
                        )}${shortRegion(
                          region
                        )} - ${appVersion} - h ${helpersVersion}`}</Typography>
                      )
                    )}
                  </CardContent>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
