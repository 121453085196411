import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const EditFormContent = ({ children, ...otherProps }) => {
  return (
    <Box flexGrow={1} {...otherProps}>
      {children}
    </Box>
  );
};

EditFormContent.displayName = 'EditFormContent';
EditFormContent.propTypes = {
  children: PropTypes.node,
};

export default EditFormContent;
