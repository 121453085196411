'use strict';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function Clock() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return <Box sx={{ width: '46px' }}>{moment(date).format('HH:mm:ss')}</Box>;
}
