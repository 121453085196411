'use strict';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';

import { makeStyles } from '@mui/styles';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import DisplayFormField from '../../components/EditForm/DisplayFormField';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import { formatValue } from '../../helpers/formatter';
import { validateField } from '../../helpers/validations';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));

export default function FormChangeContact({
  inline,
  onCancelClick,
  // eslint-disable-next-line no-unused-vars
  onConfirmClick,
}) {
  const { clsWarning } = useStyles();
  const {
    monitor: {
      isAwaitingCommand,
      selected,
      journey: { customerData: { phone = '', name = '' } = {} } = {},
      noContactPhones = [],
    },
  } = useSelector((state) => state);
  const [isChangeContactConfirmationOpen, setIsChangeContactConfirmationOpen] =
    useState(false);
  const [dataToSave, setDataToSave] = useState();

  const defaultValues = {
    phone,
    name,
    newPhone: phone,
    newName: name,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({ defaultValues });

  const onSubmit = ({ newPhone, newName }) => {
    const dataToSave = {};
    if (newPhone.replace(/[^\d+]+/g, '') !== phone.replace(/[^\d+]+/g, ''))
      dataToSave.newPhone = newPhone.replace(/[^\d+]+/g, '');
    if (newName !== name) dataToSave.newName = newName;
    setDataToSave(dataToSave);
    setIsChangeContactConfirmationOpen(true);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <EditForm>
        {!inline && (
          <EditFormHeader
            title="Alterar Dados de Contato"
            onCloseClick={onCancelClick}
          />
        )}
        <EditFormContent>
          <Stack direction="column" gap={2}>
            <Stack direction="row" alignItems="center">
              <Box flexGrow={1} flexBasis={0.4}>
                <DisplayFormField
                  field={{
                    name: 'name',
                    label: 'Nome Atual',
                    type: 'text',
                  }}
                  value={name}
                />
              </Box>
              <Box paddingLeft={1} paddingRight={1}>
                <ArrowForwardIcon />
              </Box>
              <Box flexGrow={1} flexBasis={0.4}>
                <EditFormField
                  field={{
                    name: 'newName',
                    label: 'Novo Nome',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um nome válido',
                    },
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box flexGrow={1} flexBasis={0.4}>
                <DisplayFormField
                  field={{
                    name: 'phone',
                    label: 'Telefone Atual',
                    type: 'text',
                  }}
                  defaultValue={formatValue(phone, 'phone')}
                />
              </Box>
              <Box paddingLeft={1} paddingRight={1}>
                <ArrowForwardIcon />
              </Box>
              <Box flexGrow={1} flexBasis={0.4}>
                <EditFormField
                  field={{
                    name: 'newPhone',
                    label: 'Novo Telefone',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    validate: (value) => {
                      const { valid, error } = validateField(value, {
                        type: 'phone',
                      });
                      return valid || error;
                    },
                    minLength: {
                      value: 4,
                      message: 'Digite um telefone válido',
                    },
                  }}
                />
              </Box>
            </Stack>
            {noContactPhones.length > 0 && (
              <Box marginTop={2} marginBottom={2}>
                <Typography variant="body2" color="textSecondary">
                  Os telefones abaixo não tiveram sucesso anteriormente:
                </Typography>
                {noContactPhones.map((noContactPhone, index) => (
                  <Typography key={index}>
                    {formatValue(noContactPhone, 'phone')}
                  </Typography>
                ))}
              </Box>
            )}
          </Stack>
        </EditFormContent>
        <EditFormFooter>
          <EditFormMessage>
            {Object.keys(errors).length > 0 && (
              <Typography color="error" variant="caption">
                Os erros devem ser corrigidos antes de salvar
              </Typography>
            )}
          </EditFormMessage>
          <EditFormAction>
            <ButtonWithProgress
              tooltipTitle="Confirmar"
              // onClick={() => setIsChangeContactConfirmationOpen(true)}
              // onClick={async () => {
              //   const result = await trigger('newPhone');
              //   if (result) setIsChangeContactConfirmationOpen(true);
              // }}
              variant="contained"
              color="primary"
              disabled={!isDirty || isAwaitingCommand[selected.id]}
              startIcon={<SaveIcon />}
              type="submit"
            >
              Confirmar
            </ButtonWithProgress>
          </EditFormAction>
        </EditFormFooter>
      </EditForm>
      <ConfirmationDialog
        open={isChangeContactConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsChangeContactConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <SaveIcon />,
              onClick: () => onConfirmClick(dataToSave),
            },
          },
        ]}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <WarningIcon className={clsWarning} />
          <Box marginLeft={1}>
            <Typography component="span">ATENÇÃO !</Typography>
          </Box>
        </Box>
        <br />
        Tem certeza que deseja alterar os dados?
      </ConfirmationDialog>
    </form>
  );
}

FormChangeContact.propTypes = {
  inline: PropTypes.bool,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};
