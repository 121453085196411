import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import DisplayFormField from '../../components/EditForm/DisplayFormField';
import { accountTypes, companyTypes } from './SubaccountForm';

const SubaccountDisplayForm = ({
  subaccountType,
  birthDate,
  companyType,
  cpf,
  cnpj,
  phone,
  address,
  addressNumber,
  complement,
  province,
  postalCode,
  incomeValue,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'subaccountType',
            label: 'Tipo de conta',
            type: 'text',
          }}
          value={
            accountTypes.find(({ value }) => value === subaccountType).label
          }
        />
      </Grid>
      {subaccountType === 'PF' && (
        <Grid item xs={6}>
          <DisplayFormField
            field={{
              name: 'birthDate',
              label: 'Data de Nascimento',
              type: 'text',
            }}
            value={moment(birthDate).format('DD/MM/YYYY')}
          />
        </Grid>
      )}
      {subaccountType === 'PJ' && (
        <Grid item xs={6}>
          <DisplayFormField
            field={{
              name: 'companyType',
              label: 'Tipo de Empresa',
              type: 'text',
            }}
            value={
              companyTypes.find(({ value }) => value === companyType).label
            }
          />
        </Grid>
      )}
      {subaccountType === 'PF' && (
        <Grid item xs={6}>
          <DisplayFormField
            field={{
              name: 'cpf',
              label: 'CPF',
              type: 'docNumber',
            }}
            value={cpf}
          />
        </Grid>
      )}
      {subaccountType === 'PJ' && (
        <Grid item xs={6}>
          <DisplayFormField
            field={{
              name: 'cnpj',
              label: 'CNPJ',
              type: 'docNumber',
            }}
            value={cnpj}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'phone',
            label: 'Telefone',
            type: 'phone',
          }}
          value={phone}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'address',
            label: 'Endereço',
            type: 'text',
          }}
          value={address}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'addressNumber',
            label: 'Número',
            type: 'text',
          }}
          value={addressNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'complement',
            label: 'Complemento',
            type: 'text',
          }}
          value={complement}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'province',
            label: 'Bairro',
            type: 'text',
          }}
          value={province}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'postalCode',
            label: 'CEP',
            type: 'text',
          }}
          value={postalCode}
        />
      </Grid>
      <Grid item xs={6}>
        <DisplayFormField
          field={{
            name: 'incomeValue',
            label: 'Renda/Faturamento',
            type: 'text',
          }}
          value={incomeValue}
        />
      </Grid>
    </Grid>
  );
};

SubaccountDisplayForm.propTypes = {
  subaccountType: PropTypes.string,
  birthDate: PropTypes.string,
  companyType: PropTypes.string,
  cpf: PropTypes.string,
  cnpj: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  addressNumber: PropTypes.number,
  complement: PropTypes.string,
  province: PropTypes.string,
  postalCode: PropTypes.string,
  incomeValue: PropTypes.number,
};

export default SubaccountDisplayForm;
