'use strict';
import moment from 'moment';
import dddJson from './ddd';

export const compareKeys = (a, b) => {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
};
export const testPhone = (strPhone) => {
  if (strPhone.substring(0, 1) === '+') return true;
  if (strPhone.length < 10 || strPhone.length > 11) return false;
  const [ddd, _phone] = [strPhone.substring(0, 2), strPhone.substring(2)];
  if (!Object.keys(dddJson.estadoPorDdd).find((x) => x === ddd)) return false;
  return true;
};
export const testCPF = (strCPF) => {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === undefined) return false;
  if (strCPF == '00000000000') return false;
  if (strCPF == '11111111111') return false;
  if (strCPF == '22222222222') return false;
  if (strCPF == '33333333333') return false;
  if (strCPF == '44444444444') return false;
  if (strCPF == '55555555555') return false;
  if (strCPF == '66666666666') return false;
  if (strCPF == '77777777777') return false;
  if (strCPF == '88888888888') return false;
  if (strCPF == '99999999999') return false;
  if (strCPF.length !== 11) return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
};
export const testCNPJ = (cnpj) => {
  if (cnpj === undefined) return false;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};
export const isBoolean = (str) => {
  return (
    str.trim().toLowerCase() === 'true' ||
    str.trim().toLowerCase() === 'false' ||
    str.trim().toLowerCase() === 'sim' ||
    str.trim().toLowerCase() === 'não' ||
    str.trim().toLowerCase() === 'nao' ||
    str.trim().toLowerCase() === 'verdadeiro' ||
    str.trim().toLowerCase() === 'falso' ||
    str.trim().toLowerCase() === 'v' ||
    str.trim().toLowerCase() === 'f'
  );
};
export const parseBoolean = (str) => {
  return (
    str.trim().toLowerCase() === 'true' ||
    str.trim().toLowerCase() === 'sim' ||
    str.trim().toLowerCase() === 'verdadeiro' ||
    str.trim().toLowerCase() === 'v'
  );
};
export const isInteger = (str) => {
  const intValue = parseInt(str);
  const floatValue = parseFloat(str);
  return !isNaN(intValue) && intValue === floatValue && intValue >= 0;
};
export const isDecimal = (str) => {
  const floatValue = parseFloat(str.replace(/,/g, '.'));
  return !isNaN(floatValue) && floatValue >= 0;
};
export const isDate = (str) => {
  const asDDMMYYYY = moment(str, 'DD/MM/YYYY', true);
  const asYYYYMMDD = moment(str, 'YYYY-MM-DD', true);
  return asDDMMYYYY.isValid() || asYYYYMMDD.isValid();
};

export const isFutureDate = (str) => {
  const asDDMMYYYY = moment(str, 'DD/MM/YYYY', true);
  const asYYYYMMDD = moment(str, 'YYYY-MM-DD', true);
  let valid = asDDMMYYYY.isValid() || asYYYYMMDD.isValid();
  if (valid) {
    const asDDMMYYYY = moment(str, 'DD/MM/YYYY', true);
    const asYYYYMMDD = moment(str, 'YYYY-MM-DD', true);
    const dateValue = asDDMMYYYY.isValid() ? asDDMMYYYY : asYYYYMMDD;
    valid = !dateValue.isBefore(moment().startOf('day'));
  }
  return valid;
};

export const parseDate = (str) => {
  const asDDMMYYYY = moment(str, 'DD/MM/YYYY', true);
  const asYYYYMMDD = moment(str, 'YYYY-MM-DD', true);
  return asDDMMYYYY.isValid()
    ? asDDMMYYYY.format('YYYY-MM-DD')
    : asYYYYMMDD.format('YYYY-MM-DD');
};

export const validateField = (v, field, validateRequiredOnly = false) => {
  if (
    !field.required &&
    (v === undefined || (typeof v === 'string' && v.trim() === ''))
  ) {
    return {
      valid: true,
      error: '',
      sanitizedValue: undefined,
    };
  }
  if (
    field.required &&
    (v === undefined || (typeof v === 'string' && v.trim() === ''))
  ) {
    return {
      valid: false,
      error: 'O campo é obrigatório',
      sanitizedValue: undefined,
    };
  }
  if (validateRequiredOnly) return { valid: true };
  const value = v.toString();
  switch (field.type) {
    case 'futureDate': {
      let valid = isDate(value);
      let error = '';
      if (!valid) {
        error = 'Deve ser uma data válida no formato DD/MM/AAAA ou AAAA-MM-DD';
      } else {
        valid = isFutureDate(value);
        if (!valid)
          error = 'Deve ser uma data igual ou superior ao dia de hoje';
      }
      const sanitizedValue = valid ? parseDate(value) : value;
      return {
        valid,
        error,
        sanitizedValue,
      };
    }
    case 'date': {
      const valid = isDate(value);
      const sanitizedValue = valid ? parseDate(value) : value;
      return {
        valid,
        error: valid
          ? ''
          : 'Deve ser uma data válida no formato DD/MM/AAAA ou AAAA-MM-DD',
        sanitizedValue,
      };
    }
    case 'boolean': {
      const valid = isBoolean(value);
      const sanitizedValue = valid ? parseBoolean(value) : value;
      return {
        valid,
        error: valid ? '' : 'Deve ser true ou false',
        sanitizedValue,
      };
    }
    case 'decimal': {
      if (!isDecimal(value))
        return {
          valid: false,
          error: 'Deve ser um número decimal maior do que zero',
        };
      const sanitizedValue = parseFloat(value.replace(/,/g, '.'));
      if (field.maxValue && sanitizedValue > field.maxValue)
        return {
          valid: false,
          error: `O valor não deve ser maior do que ${field.maxValue}`,
        };
      return {
        valid: true,
        error: '',
        sanitizedValue,
      };
    }
    case 'integer': {
      if (!isInteger(value))
        return {
          valid: false,
          error: 'Deve ser um número inteiro maior do que zero',
        };
      const sanitizedValue = parseInt(value.replace(/,/g, '.'));
      if (field.maxValue && sanitizedValue > field.maxValue)
        return {
          valid: false,
          error: `O valor não deve ser maior do que ${field.maxValue}`,
        };
      return {
        valid: true,
        error: '',
        sanitizedValue,
      };
    }
    case 'message':
    case 'text': {
      const sanitizedValue = value.trim();
      const valid = sanitizedValue !== '';
      return {
        valid,
        error: valid ? '' : 'Campo obrigatório',
        sanitizedValue,
      };
    }
    case 'docNumber': {
      const sanitizedValue = value.match(/\d+/g).join('');
      const validCPF = testCPF(sanitizedValue);
      const validCNPJ = testCNPJ(sanitizedValue);
      return {
        valid: validCPF || validCNPJ,
        error: validCPF || validCNPJ ? '' : 'Documento (CPF ou CNPJ) inválido',
        sanitizedValue,
      };
    }
    case 'cpf': {
      const sanitizedValue = value.match(/\d+/g).join('');
      const validCPF = testCPF(sanitizedValue);
      return {
        valid: validCPF,
        error: validCPF ? '' : 'CPF inválido',
        sanitizedValue,
      };
    }
    case 'cnpj': {
      const sanitizedValue = value.match(/\d+/g).join('');
      const validCNPJ = testCNPJ(sanitizedValue);
      return {
        valid: validCNPJ,
        error: validCNPJ ? '' : 'CNPJ inválido',
        sanitizedValue,
      };
    }
    case 'phone': {
      const sanitizedValue = value.match(/[\d+]+/g).join('');
      // console.log('sanitizedValue', sanitizedValue);
      const valid = testPhone(sanitizedValue);
      // console.log('valid', valid);
      return {
        valid,
        error: valid ? '' : 'Telefone inválido',
        sanitizedValue: valid ? sanitizedValue : value,
      };
    }
    case 'phoneList': {
      const sanitizedValue = value.match(/[\d+,]+/g);
      if (!sanitizedValue)
        return {
          valid: false,
          error: 'Um ou mais telefones são inválidos',
          sanitizedValue: undefined,
        };
      const arrPhones = sanitizedValue.join('').split(',');
      let valid = true;
      arrPhones.forEach((phone) => {
        if (!testPhone(phone)) valid = false;
      });
      return {
        valid,
        error: valid ? '' : 'Um ou mais telefones são inválidos',
        sanitizedValue: valid ? arrPhones : value,
      };
    }
    case 'select':
      switch (field.valueType) {
        case 'integer': {
          let valid = isInteger(value);
          if (valid)
            valid = !!field.options
              .map(({ value }) => value)
              .find((a) => a === parseInt(value));
          const sanitizedValue = valid ? parseInt(value) : value;
          const options = field.options
            .map(({ value, label }) => `${value} para ${label}`)
            .join(' ou ');
          return {
            valid,
            error: valid ? '' : `Deve ser uma das seguintes opções: ${options}`,
            sanitizedValue,
          };
        }
        case 'text':
      }
      break;
  }
  return { valid: true };
};
