import { v4 as uuidv4 } from 'uuid';
import firebase from '../../helpers/firebase';
const uploadBucket = 'temp-upload';

export const server = {
  process: (
    _fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    _transfer,
    _options
  ) => {
    const id = uuidv4();
    var storageRef = firebase.storage().ref();

    // console.log(metadata);
    var uploadTask = storageRef.child(`${uploadBucket}/${id}.csv`).put(file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // var prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + prog + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log('Upload is running');
            break;
        }
        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (err) => {
        // console.log(err.code);
        error(err.code);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (err.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((_downloadURL) => {
          // console.log('File available at', downloadURL);
          load(id);
        });
      }
    );

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // console.log('uploadTask.cancel();');
        uploadTask.cancel();
        abort();
      },
    };
  },
  revert: (uniqueFileId, load, error) => {
    // console.log(`Deleting ${uploadBucket}/${uniqueFileId}.csv`);
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${uploadBucket}/${uniqueFileId}.csv`);
    fileRef
      .delete()
      .then(() => {
        load();
      })
      .catch((err) => {
        error(err.code);
      });
  },
};
