import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import ConfirmationDialog from '../components/ConfirmationDialog';

const useConfirm = (
  title,
  message,
  confirmLabel = 'Salvar',
  ConfirmIcon = SaveIcon
) => {
  const [promise, setPromise] = useState(null);
  const [msg, setMsg] = useState(message);

  const confirm = (msg) => {
    if (msg) setMsg(msg);
    return new Promise((resolve) => {
      setPromise({ resolve });
    });
  };

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // You could replace the Dialog with your library's version
  const ConfirmationDialogComponent = () => (
    <ConfirmationDialog
      open={promise !== null}
      title={title}
      actions={[
        {
          name: 'Cancelar',
          buttonProps: {
            autoFocus: true,
            onClick: handleCancel,
          },
        },
        {
          name: confirmLabel,
          showLoading: true,
          buttonProps: {
            variant: 'contained',
            color: 'primary',
            startIcon: <ConfirmIcon />,
            onClick: handleConfirm,
          },
        },
      ]}
    >
      {msg}
    </ConfirmationDialog>
  );
  return [ConfirmationDialogComponent, confirm];
};

export default useConfirm;
