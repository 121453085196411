'use strict';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import firebase from '../../helpers/firebase';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { newUserActions } from './new-user-slice';

const useStyles = makeStyles((_theme) => ({
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
}));

function NewUserWizard(props) {
  const classes = useStyles();
  const { uid } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm();

  const fieldsPerStep = [
    ['enterpriseName', 'enterpriseGender'],
    ['agentName', 'agentGender'],
    ['waNotificationPhone'],
  ];

  const [step, setStep] = useState(0);
  let isNextDisabled = false;

  const onSubmit = ({
    enterpriseName,
    enterpriseGender,
    agentName,
    agentGender,
    waNotificationPhone,
  }) => {
    dispatch(
      newUserActions.newUserWizardSuccess({
        name: enterpriseName,
        gender: enterpriseGender,
        agent: {
          name: agentName,
          gender: agentGender,
        },
        ownerUid: uid,
        waNotificationPhone,
      })
    );
  };

  const handleNextClick = async () => {
    await trigger(fieldsPerStep[step]);
    const currentStepErrors = _.pick(errors, fieldsPerStep[step]);
    if (Object.keys(currentStepErrors).length === 0) setStep(step + 1);
  };
  const handlePrevClick = () => {
    setStep(step - 1);
  };
  const logoutButton = (
    <IconButton
      onClick={() => {
        dispatch(newUserActions.newUserWizardFail());
        firebase.auth().signOut();
      }}
      size="large"
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  );

  return (
    <form
      {...props}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        width="100%"
        minHeight="100vh"
        padding={4}
        overflow="auto"
        display="flex"
        flexDirection="column"
      >
        <Paper className={classes.paper}>
          <Step1
            className={clsx(step !== 0 && classes.hidden)}
            control={control}
            logoutButton={logoutButton}
          />
          <Step2
            className={clsx(step !== 1 && classes.hidden)}
            control={control}
            logoutButton={logoutButton}
          />
          <Step3
            className={clsx(step !== 2 && classes.hidden)}
            control={control}
            logoutButton={logoutButton}
          />
          <Step4
            className={clsx(step !== 3 && classes.hidden)}
            control={control}
            logoutButton={logoutButton}
          />
          <Box flexGrow={1} />
          <Box>
            <Stepper activeStep={step}>
              <Step>
                <StepLabel>Nome da empresa</StepLabel>
              </Step>
              <Step>
                <StepLabel>Agente Digital</StepLabel>
              </Step>
              <Step>
                <StepLabel>WhatsApp</StepLabel>
              </Step>
              <Step>
                <StepLabel>Confirmação</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Box p={2}>
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1}>
                {Object.keys(errors).length > 0 ? (
                  <Typography color="error" variant="caption">
                    Os erros devem ser corrigidos antes de salvar
                  </Typography>
                ) : (
                  <Typography variant="caption" color="textSecondary">
                    Estas configurações podem ser alteradas depois.
                  </Typography>
                )}
              </Box>
              <Box flexGrow={1} />
              {step >= 1 && (
                <Box marginLeft={1}>
                  <Button
                    color="primary"
                    disabled={isLoading}
                    aria-label="voltar"
                    startIcon={<NavigateBeforeIcon />}
                    onClick={handlePrevClick}
                  >
                    Voltar
                  </Button>
                </Box>
              )}
              {step < 3 && (
                <Box marginLeft={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isNextDisabled || isLoading}
                    aria-label="próximo"
                    endIcon={<NavigateNextIcon />}
                    onClick={handleNextClick}
                  >
                    Próximo
                  </Button>
                </Box>
              )}
              {step === 3 && (
                <Box marginLeft={1}>
                  <ButtonWithProgress
                    tooltipTitle="Começar"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isNextDisabled || isLoading}
                    aria-label="salvar"
                    startIcon={<SaveIcon />}
                  >
                    Começar
                  </ButtonWithProgress>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </form>
  );
}

export default NewUserWizard;
