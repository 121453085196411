import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import ReportTable from '../../components/ReportTable';
import { formatValue } from '../../helpers/formatter';

const useStyles = makeStyles(() => ({
  print: {
    color: 'black',
  },
  nobr: {
    'white-space': 'nowrap',
  },
}));

const Payments = ({ data, currentOrderBy, onOrderByChange }) => {
  const classes = useStyles();

  const enhancedData = data.map((row) => ({
    paymentDate: moment(
      row.result.pixData?.paymentData?.horario
        ? row.result.pixData?.paymentData?.horario
        : row.sendPixResult?.sendPixResult?.horario
    ).valueOf(),
    docNumber: row.cob.devedor.cnpj ?? row.cob.devedor.cpf,
    customerName: row.cob.devedor.nome.toString(),
    invoiceId: row.invoiceData.invoiceId,
    e2eid: row.result?.pixData?.paymentData?.endToEndId,
    invoiceDate: moment(row.invoiceData.invoiceDate).valueOf(),
    serviceDate: moment(row.invoiceData.serviceDate).valueOf(),
    invoiceValue: row.invoiceData.invoiceValue,
    paidValue: parseFloat(row.result.pixData?.paymentData?.valor),
    sentValue: parseFloat(row.sendPixResult?.request?.valor ?? 0),
  }));

  const columns = [
    {
      id: 'paymentDate',
      numeric: false,
      disablePadding: true,
      label: 'Data',
      formatter: (v) => formatValue(v, 'dateTime'),
      align: 'center',
    },
    {
      id: 'docNumber',
      numeric: false,
      disablePadding: false,
      label: 'Documento',
      formatter: (v) => formatValue(v, 'docNumber'),
      align: 'center',
      className: classes.nobr,
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: 'Nome',
      align: 'center',
    },
    {
      id: 'invoiceId',
      numeric: false,
      disablePadding: false,
      label: 'Id Fatura',
      align: 'center',
    },
    {
      id: 'invoiceDate',
      numeric: false,
      disablePadding: false,
      label: 'Vencimento',
      formatter: (v) => formatValue(v, 'date'),
      align: 'center',
    },
    {
      id: 'serviceDate',
      numeric: false,
      disablePadding: false,
      label: 'Referencia',
      formatter: (v) => formatValue(v, 'yearMonth'),
      align: 'center',
    },
    {
      id: 'invoiceValue',
      numeric: false,
      disablePadding: false,
      label: 'Valor Fatura (R$)',
      formatter: (v) => formatValue(v, 'decimal'),
      align: 'center',
    },
    {
      id: 'paidValue',
      numeric: false,
      disablePadding: false,
      label: 'Valor Pago (R$)',
      formatter: (v) => formatValue(v, 'decimal'),
      align: 'center',
    },
    {
      id: 'sentValue',
      numeric: false,
      disablePadding: false,
      label: 'Valor Repasse (R$)',
      formatter: (v) => formatValue(v, 'decimal'),
      align: 'center',
    },
  ];

  return (
    <Box width={1}>
      <ReportTable
        title="Pagamentos"
        data={enhancedData}
        columns={columns}
        orderBy={currentOrderBy ?? { column: 'paymentDate' }}
        onOrderByChange={onOrderByChange}
        tableProps={{
          className: classes.table,
          size: 'small',
        }}
      />
      <Box marginTop={2} />
    </Box>
  );
};

Payments.propTypes = {
  data: PropTypes.array,
  currentOrderBy: PropTypes.object,
  onOrderByChange: PropTypes.func,
};

export default Payments;

/*
Visão 1 (Resumida)

(OK) Rafael Lucrédio - documento 218.470.658-21
  Unidade 10 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Pago (R$ 95,00 no dia 05/06/2021)
  Unidade 11 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Pago (R$ 95,00 no dia 05/06/2021)

(Agendados) Magda Maria Garcia Massafeli - documento xxx.xxx.xxx-xx
  Unidade 12 - Vencimento 10/06/2021 - R$ 100,00 - Situação: No prazo

(Inadimplente) Magda Maria Garcia Massafeli - documento xxx.xxx.xxx-xx
  Unidade 12 - Vencimento 10/06/2021 - R$ 100,00 - Situação: No prazo

(Contestação) Magda Maria Garcia Massafeli - documento xxx.xxx.xxx-xx
  Unidade 12 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Cliente não aceitou
  Texto da contestação (Enviado no dia 05/06/2021):
      “O valor da unidade 11 está errado, na verdade é R$ 800,00”

Visão 2 (Detalhada)

Rafael Lucrédio - documento 218.470.658-21 (Finalizado)
  Unidade 10 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Pago (R$ 95,00 no dia 05/06/2021)
  Unidade 11 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Em atraso

  Régua de Contatos:
  01/06/2021 - CPC
  02/06/2021 - Primeiro Lembrete
  05/06/2021 - Lembrete 3 dias antes do vencimento
  05/06/2021 - Cliente pagou a unidade 10 - R$ 95,00
  10/06/2021 - Lembrete no dia do vencimento
  15/05/2021 - Quebra de acordo 5 dias após vencimento
  30/05/2021 - Quebra de acordo 15 dias após vencimento

  Pix gerados:
  1212341123abc - R$ 100,00 - Criado em 01/06/2021 - expiração 01/06/2021 23:59:59
  1212341123abc - R$ 110,00 - Criado em 01/06/2021 - expiração 01/07/2021 23:59:59

(*) Magda Maria Garcia Massafeli - documento xxx.xxx.xxx-xx (Em andamento)
  Unidade 12 - Vencimento 10/06/2021 - R$ 100,00 - Situação: Dentro do prazo

  Régua de Contatos:
  01/06/2021 - CPC
  02/06/2021 - Primeiro Lembrete
  05/06/2021 - Lembrete 3 dias antes do vencimento
  (*) 10/06/2021 - Lembrete no dia do vencimento (Agendado)

  Pix gerados:
  1212341123abc - R$ 100,00 - Criado em 01/06/2021 - expiração 01/06/2021 23:59:59
  1212341123abc - R$ 110,00 - Criado em 01/06/2021 - expiração 01/07/2021 23:59:59

*/
