import firebase from '../helpers/firebase';

const waSessionsDao = {
  get: async () => {
    const db = firebase.firestore();

    let waSessionsSnapshot = await db.collection('wa-sessions').get();
    let waSessions = waSessionsSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return waSessions;
  },
};
export default waSessionsDao;
