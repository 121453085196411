import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import app from '../features/app/app-slice';
import auth from '../features/auth/auth-slice';
import bgTasks from '../features/bg-tasks/bg-tasks-slice';
import customers from '../features/customers/customers-slice';
import enterpriseSelect from '../features/enterprise-select/enterprise-select-slice';
import enterprises from '../features/enterprises/enterprises-slice';
import issues from '../features/issues/issues-slice';
import journeys from '../features/journeys/journeys-slice';
import mailing from '../features/mailing/mailing-slice';
import monitor from '../features/monitor/monitor-slice';
import newUser from '../features/new-user/new-user-slice';
import notifier from '../features/notifier/notifier-slice';
import reports from '../features/reports/reports-slice';
import settings from '../features/settings/settings-slice';
import smartTip from '../features/smart-tip/smart-tip-slice';
import users from '../features/users/users-slice';
import versions from '../features/versions/versions-slice';
import waStatus from '../features/wa-status/wa-status-slice';
import wallet from '../features/wallet/wallet-slice';
import rootSaga from '../sagas';

// const logger = createLogger();
const saga = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth,
    app,
    settings,
    newUser,
    journeys,
    customers,
    notifier,
    bgTasks,
    mailing,
    reports,
    wallet,
    monitor,
    issues,
    waStatus,
    versions,
    users,
    enterprises,
    enterpriseSelect,
    smartTip,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'notifier.notifications',
          'notifier.visibleNotifications',
        ],
        ignoredActions: ['notifier/enqueue'],
      },
    })
      //.concat(logger)
      .concat(saga),
});

saga.run(rootSaga);
export default store;
