'use strict';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, colors, Grid, IconButton, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const colorPalette = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
];

const useStyles = makeStyles((theme) => {
  return {
    clsGrid: {
      width: theme.spacing(24),
      height: theme.spacing(24),
    },
    clsSelected: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      border: '1px solid black',
      boxSizing: 'border-box',
    },
    clsButton: {
      height: '100%',
    },
  };
});

function ColorPicker({ value, onChange }) {
  const { clsGrid, clsSelected, clsButton } = useStyles();
  const [anchorEl, setAnchorEl] = useState();

  return (
    // <Box width="192px" height="192px" display="flex">
    //   {colorPalette.map((colorName, index) => )}
    //   <IconButton style={{ backgroundColor: colors[value][500] }}></IconButton>
    // </Box>
    <>
      <Box padding={1}>
        <Button
          style={{ backgroundColor: colors[value ?? colorPalette[0]][600] }}
          className={clsButton}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <EditIcon />
        </Button>
      </Box>
      <Popover
        id="tag-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Grid container className={clsGrid}>
          {colorPalette.map((colorName, index) => (
            <Grid
              item
              key={index}
              xs={3}
              style={{ backgroundColor: colors[colorName ?? 'orange'][600] }}
              className={clsx(colorName === value && clsSelected)}
            >
              <IconButton
                onClick={() => {
                  setAnchorEl();
                  onChange(colorName);
                }}
                size="large"
              >
                <CheckIcon
                  style={{
                    color:
                      colorName === value
                        ? 'white'
                        : colors[colorName ?? 'orange'][600],
                  }}
                />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
