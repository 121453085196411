import PropTypes from 'prop-types';

function FirebaseIcon({ disabled }) {
  return (
    <img
      style={{
        width: '1em',
        height: '1em',
        ...(disabled && {
          filter: 'saturate(0)',
        }),
      }}
      src="/firebase_28dp.png"
    />
  );
}

FirebaseIcon.propTypes = {
  disabled: PropTypes.bool,
};

export default FirebaseIcon;
