'use strict';
import { put, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { versionsActions } from './versions-slice';
// import ky from 'ky';

function* watchLoad() {
  while (true) {
    yield take(versionsActions.load);
    yield put(appActions.startLoading());
    try {
      const fnVersions = firebase
        .app()
        .functions()
        .httpsCallable('fn-adm-versions');

      const { data } = yield fnVersions();
      // console.log(data);

      // [
      //   {
      //     "url": "https://bot-zu4s5vffdq-rj.a.run.app",
      //     "status": "ok",
      //     "appVersion": "0.0.24",
      //     "appName": "bot",
      //     "metadata": {
      //       "projectId": "condomi-prod",
      //       "region": "southamerica-east1"
      //     },
      //     "helpersVersion": "0.1.83"
      //   },
      //   ...
      // ]

      const appsMap = data.reduce(
        (
          acc,
          {
            appName,
            appVersion,
            helpersVersion,
            metadata: { projectId, region },
          }
        ) => {
          if (!acc[appName]) acc[appName] = [];

          acc[appName].push({ appVersion, helpersVersion, projectId, region });
          return acc;
        },
        {}
      );
      // console.log(appsMap);
      yield put(versionsActions.loadSuccess(appsMap));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(versionsActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [watchLoad];
