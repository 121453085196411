'use strict';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Checkbox,
  colors,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions } from '../../features/settings/settings-slice';
import ButtonWithProgress from '../ButtonWithProgress';

function TagList({ onClose, onChange, selected, onApply, onDiscard }) {
  const {
    settings: { enterprise: { tags = [] } = {} },
    auth: { permissions },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigateToSettings = () => {
    dispatch(settingsActions.openSettings('cfg-tag'));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography color="textSecondary" variant="body2">
          Marcadores
        </Typography>
        <Box flexGrow={1} />
        {onClose && (
          <IconButton edge="end" size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      {[...tags]
        .sort(({ name: a }, { name: b }) => a.localeCompare(b))
        .map(({ name, color }, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={!!selected.find((field) => field.name === name)}
                  onChange={(e) => onChange(e.target.checked, name)}
                  style={{ color: colors[color ?? 'orange'][600] }}
                />
              }
              label={name}
            />
          );
        })}
      {permissions['cfg-tag'] && (
        <Box marginTop={2}>
          <Button variant="outlined" onClick={navigateToSettings}>
            Gerenciar marcadores
          </Button>
        </Box>
      )}
      {onApply && (
        <Box marginTop={2} display="flex" flexDirection="row">
          <ButtonWithProgress
            tooltipTitle="Aplicar"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => {
              onApply();
              onClose();
            }}
          >
            Aplicar
          </ButtonWithProgress>
          {onDiscard && (
            <Box marginLeft={1}>
              <ButtonWithProgress
                tooltipTitle="Descartar"
                variant="outlined"
                color="primary"
                startIcon={<ClearIcon />}
                onClick={() => {
                  onDiscard();
                  onClose();
                }}
              >
                Descartar
              </ButtonWithProgress>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

TagList.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  onApply: PropTypes.func,
  onDiscard: PropTypes.func,
};

export default TagList;
