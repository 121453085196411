'use strict';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Card, colors, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import FormSolveIssue from '../issues/FormSolveIssue';
import { issuesActions } from '../issues/issues-slice';
import { monitorActions } from './monitor-slice';

const useStyles = makeStyles(() => ({
  clsWarningCard: {
    borderColor: colors.orange[600],
  },
  clsWarningIcon: {
    color: colors.orange[600],
  },
}));

function JourneyIssues() {
  const { clsWarningCard, clsWarningIcon } = useStyles();
  const dispatch = useDispatch();

  const {
    issues: {
      issueSolve: { solveType },
    },
    monitor: { journey: { customerData: { id, cid } = {}, issue } = {} },
  } = useSelector((state) => state);

  const handleSolveIssueClick = () => {
    dispatch(issuesActions.setSolveType(issue.type));
    dispatch(monitorActions.loadNoContactPhones(id ?? cid));
  };

  const handleSolveIssueClose = () => {
    dispatch(issuesActions.resetSolveIssue());
  };

  return (
    <>
      <Card variant="outlined" className={clsWarningCard}>
        <Box display="flex" flexDirection="row" p={2} alignItems="center">
          <WarningIcon fontSize="large" className={clsWarningIcon} />
          <Box marginLeft={2}>
            <Typography variant="body1">
              Esta jornada possui uma pendência a ser resolvida
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box marginLeft={2}>
            <Button
              onClick={handleSolveIssueClick}
              variant="contained"
              color="secondary"
            >
              VERIFICAR E RESOLVER
            </Button>
          </Box>
        </Box>
      </Card>
      <ResponsiveDialog
        open={!!solveType}
        maxWidth="md"
        onClose={handleSolveIssueClose}
        fullHeight
        fullWidth
      >
        <FormSolveIssue onCancelClick={handleSolveIssueClose} />
      </ResponsiveDialog>
    </>
  );
}

JourneyIssues.propTypes = {};
export default JourneyIssues;
