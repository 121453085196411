'use strict';
import PropTypes from 'prop-types';
import React from 'react';

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

export default function Heading(props) {
  var children = React.Children.toArray(props.children);
  var text = children.reduce(flatten, '');
  var slug = text.toLowerCase().replace(/\W/g, '-');
  return React.createElement('h' + props.level, { id: slug }, props.children);
}

Heading.propTypes = {
  level: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};
