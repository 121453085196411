'use strict';
import { all, put, select, take } from 'redux-saga/effects';
import enterprisesDao from '../../dao/enterprises';
import usersDao from '../../dao/users';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { authActions } from '../auth/auth-slice';
import { enterpriseSelectActions } from './enterprise-select-slice';

function* watchLoadEnterprises() {
  const db = firebase.firestore();

  while (true) {
    try {
      yield take(enterpriseSelectActions.loadEnterprises);
      yield put(appActions.startLoading());
      const {
        auth: {
          userData: { id: uid, role },
        },
      } = yield select((state) => state);

      let enterprises;
      // TODO - Permissão de empresas
      if (role === 'superadm' || role === 'dev')
        enterprises = yield enterprisesDao.get();
      else {
        let user;
        var docRef = yield db.collection('users').doc(uid).get();
        if (docRef.exists) {
          user = docRef.data();
        } else {
          throw new Error(`User ${uid} not found`);
        }

        enterprises = yield all(
          user.enterprises.map(({ id }) => enterprisesDao.get(id))
        );
      }
      yield put(enterpriseSelectActions.loadEnterprisesSuccess(enterprises));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(enterpriseSelectActions.loadEnterprisesFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSelectEnterprise() {
  while (true) {
    const { payload: enterpriseId } = yield take(
      enterpriseSelectActions.selectEnterprise
    );
    try {
      yield put(appActions.startLoading());
      const {
        auth: {
          authData,
          userData: { id: uid },
        },
      } = yield select((state) => state);
      yield usersDao.update(uid, { enterpriseId });
      yield put(appActions.finishLoading());
      yield put(
        authActions.login({
          authData,
        })
      );
      window.location.reload();
    } catch (e) {
      // console.log('Erro ao executar watchGetEnterprises');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(enterpriseSelectActions.getEnterprisesFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [watchLoadEnterprises, watchSelectEnterprise];
