'use strict';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disableSelection,
    disabledCount = 0,
    //disabledLines = [],
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const indeterminate =
    numSelected > 0 && numSelected < rowCount - disabledCount; //disabledLines.length;
  const checked = rowCount > 0 && numSelected === rowCount - disabledCount; //disabledLines.length;

  return (
    <TableHead>
      <TableRow>
        {!disableSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={indeterminate}
              checked={checked}
              onChange={(e) => onSelectAllClick(e, indeterminate)}
              inputProps={{ 'aria-label': 'Selecionar todos' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric
                ? 'right'
                : headCell.align
                ? headCell.align
                : 'left'
            }
            padding={
              disableSelection && index === 0
                ? 'checkbox'
                : headCell.disablePadding
                ? 'none'
                : 'normal'
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  disableSelection: PropTypes.bool,
  disabledLines: PropTypes.arrayOf(PropTypes.number),
  disabledCount: PropTypes.number,
};

export default EnhancedTableHead;
