/* eslint-disable no-unused-vars */
'use strict';
import ky from 'ky';
import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import customersDao from '../../dao/customers';
import { getApi } from '../../helpers/api';
import firebase from '../../helpers/firebase';
import rpcCall from '../../sagas/rpc';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { monitorActions } from './monitor-slice';

import moment from 'moment';
import { getPixStatus } from '../../helpers/pix-status';
import removeSpecialChars from '../../helpers/remove-special-chars';

// const createPixsChannel = (eid) => {
//   const db = firebase.firestore();
//   return eventChannel((emitter) => {
//     const doc = db.collection('pix').where('eid', '==', eid);
//     const unsubscribe = doc.onSnapshot(emitter);
//     return unsubscribe;
//   });
// };

// function* watchPixsUpdates() {
//   const {
//     settings: {
//       enterprise: { id: eid },
//     },
//   } = yield select((state) => state);
//   yield put(appActions.startLoading());
//   let firstTime = true;
//   const chan = yield call(createPixsChannel, eid);
//   try {
//     yield put(appActions.finishLoading());
//     while (true) {
//       const snapshot = yield take(chan);
//       const changes = snapshot.docChanges();
//       const itemsToAdd = changes
//         .filter(({ type }) => type === 'added')
//         .map(({ doc }) => ({
//           id: doc.id,
//           ...doc.data(),
//           status: getPixStatus(doc.data()),
//         }));

//       if (itemsToAdd.length > 0) {
//         yield put(monitorActions.addPixs(itemsToAdd));
//         if (!firstTime) {
//           yield put(
//             monitorActions.addHighlightedIds(itemsToAdd.map(({ id }) => id))
//           );
//           firstTime = false;
//         }
//       }

//       const itemsToModify = changes
//         .filter(({ type }) => type === 'modified')
//         .map(({ doc }) => ({
//           id: doc.id,
//           ...doc.data(),
//           status: getPixStatus(doc.data()),
//         }));

//       if (itemsToModify.length > 0) {
//         yield put(monitorActions.modifyPixs(itemsToModify));
//         yield put(
//           monitorActions.addHighlightedIds(itemsToModify.map(({ id }) => id))
//         );
//       }

//       const itemsToRemove = changes
//         .filter(({ type }) => type === 'removed')
//         .map(({ doc: { id } }) => id);
//       if (itemsToRemove.length > 0) {
//         yield put(monitorActions.removePixs(itemsToRemove));
//         const {
//           monitor: { selected },
//         } = yield select((state) => state);
//         if (selected && itemsToRemove.find((id) => selected.id === id))
//           yield put(monitorActions.closeDetail());
//         yield put(monitorActions.setJourneys());
//         yield put(monitorActions.setBots({}));
//       }
//     }
//   } catch (e) {
//     yield put(appActions.setError(e.message));
//   } finally {
//     if (yield cancelled()) {
//       chan.close();
//     }
//   }
// }

function* watchLoad() {
  const db = firebase.firestore();
  while (true) {
    const { payload } = yield take(monitorActions.load);
    if (!payload) continue;
    const { keywords, serviceDate, docNumber } = payload;
    const {
      settings: {
        enterprise: { id: eid },
      },
      monitor: { recordsLimit },
    } = yield select((state) => state);
    yield put(appActions.startLoading());
    try {
      let pixRef = db.collection('pix').where('eid', '==', eid);

      if (keywords ?? '' !== '')
        pixRef = pixRef.where(
          'keywords',
          'array-contains-any',
          removeSpecialChars(keywords)
            .toLocaleLowerCase()
            .split(' ')
            .filter((s) => s !== '')
        );
      if (serviceDate ?? '' !== '')
        pixRef = pixRef
          .where('invoiceData.serviceDate', '>=', `${serviceDate}-01`)
          .where(
            'invoiceData.serviceDate',
            '<=',
            moment(`${serviceDate}-01`).endOf('month').format('YYYY-MM-DD')
          );
      if (docNumber ?? '' !== '')
        pixRef = pixRef.where(
          'invoiceData.docNumber',
          '==',
          docNumber.replace(/[^\d]+/g, '')
        );

      const pixs = yield pixRef.limit(recordsLimit + 1).get();
      if (pixs.empty) {
        yield put(
          monitorActions.setSearchState({
            inputs: payload,
            count: 0,
            firstSearch: true,
          })
        );
        yield put(monitorActions.setPixs([]));
      } else {
        yield put(
          monitorActions.setPixs(
            pixs.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              status: getPixStatus(doc.data()),
            }))
          )
        );
        yield put(
          monitorActions.setSearchState({
            inputs: payload,
            count: pixs.docs.length,
            firstSearch: true,
          })
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      yield put(appActions.setError(e.message));
    }
    yield put(appActions.finishLoading());

    // const pixsUpdatesTask = yield fork(watchPixsUpdates);
    // yield take(monitorActions.exit);
    // yield cancel(pixsUpdatesTask);
  }
}

// const createJourneyChannel = (jid) => {
//   const db = firebase.firestore();
//   return eventChannel((emitter) => {
//     const doc = db.collection('journey-sessions').doc(jid);
//     const unsubscribe = doc.onSnapshot(emitter);
//     return unsubscribe;
//   });
// };

// function* watchJourneyUpdates(jid) {
//   yield put(appActions.startLoading());
//   const chan = yield call(createJourneyChannel, jid);
//   try {
//     yield put(appActions.finishLoading());
//     while (true) {
//       const snapshot = yield take(chan);
//       yield put(monitorActions.setJourney(snapshot.data()));
//     }
//   } catch (e) {
//     yield put(appActions.setError(e.message));
//     yield put(monitorActions.loadFail());
//   } finally {
//     if (yield cancelled()) {
//       chan.close();
//       // console.log('Closed updates channel');
//     }
//   }
// }

function createJourneysChannel(customerDocNumber) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const journeySessionsDoc = db
      .collection('journey-sessions')
      .where('customerData.customerDocNumber', '==', customerDocNumber);
    const journeySessionsUnsubscribe = journeySessionsDoc.onSnapshot(
      (snapshot) => emitter(snapshot)
    );
    return () => {
      journeySessionsUnsubscribe();
    };
  });
}

function* watchJourneysUpdates(customerDocNumber) {
  const chan = yield call(createJourneysChannel, customerDocNumber);
  try {
    while (true) {
      const snapshot = yield take(chan);
      const changes = snapshot.docChanges();
      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToAdd.length > 0)
        yield put(monitorActions.addJourneys(itemsToAdd));

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));

      if (itemsToModify.length > 0)
        yield put(monitorActions.modifyJourneys(itemsToModify));
      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(monitorActions.removeJourneys(itemsToRemove));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
      // console.log('Closed updates channel');
    }
  }
}

function createBotsChannel(customerDocNumber) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const dwBotSessionsDoc = db
      .collection('dw-bot-sessions')
      .where('customerData.customerDocNumber', '==', customerDocNumber);
    const dwBotSessionsUnsubscribe = dwBotSessionsDoc.onSnapshot((snapshot) =>
      emitter({ collection: 'dw-bot-sessions', snapshot })
    );
    const botSessionsDoc = db
      .collection('bot-sessions')
      .where('customerData.customerDocNumber', '==', customerDocNumber);
    const botSessionsUnsubscribe = botSessionsDoc.onSnapshot((snapshot) =>
      emitter({ collection: 'bot-sessions', snapshot })
    );
    return () => {
      dwBotSessionsUnsubscribe();
      botSessionsUnsubscribe();
    };
  });
}

function* watchBotsUpdates(customerDocNumber) {
  const chan = yield call(createBotsChannel, customerDocNumber);
  try {
    while (true) {
      const { collection, snapshot } = yield take(chan);
      const changes = snapshot.docChanges();
      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
      if (itemsToAdd.length > 0)
        yield put(monitorActions.addBots({ collection, itemsToAdd }));

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({ id: doc.id, ...doc.data() }));

      if (itemsToModify.length > 0)
        yield put(monitorActions.modifyBots({ collection, itemsToModify }));
      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(monitorActions.removeBots({ collection, itemsToRemove }));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
      // console.log('Closed updates channel');
    }
  }
}

function createPixsChannel(jid) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const pixDoc = db.collection('pix').where('jid', '==', jid);
    const pixUnsubscribe = pixDoc.onSnapshot((snapshot) => emitter(snapshot));
    return () => {
      pixUnsubscribe();
    };
  });
}

function* watchPixsUpdates(jid) {
  const chan = yield call(createPixsChannel, jid);
  try {
    while (true) {
      const snapshot = yield take(chan);
      const changes = snapshot.docChanges();
      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({
          id: doc.id,
          ...doc.data(),
          status: getPixStatus(doc.data()),
        }));
      if (itemsToAdd.length > 0)
        yield put(monitorActions.addSelectedPixs(itemsToAdd));

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({
          id: doc.id,
          ...doc.data(),
          status: getPixStatus(doc.data()),
        }));

      if (itemsToModify.length > 0)
        yield put(monitorActions.modifySelectedPixs(itemsToModify));
      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(monitorActions.removeSelectedPixs(itemsToRemove));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
      // console.log('Closed updates channel');
    }
  }
}

function createWaMessagesChannel(sid) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const waMessagesDoc = db.collection('wa-messages').where('sid', '==', sid);
    const waMessageUnsubscribe = waMessagesDoc.onSnapshot((snapshot) =>
      emitter(snapshot)
    );
    return () => {
      waMessageUnsubscribe();
    };
  });
}

function* watchWaMessagesUpdates(sid) {
  const chan = yield call(createWaMessagesChannel, sid);
  try {
    while (true) {
      const snapshot = yield take(chan);
      const changes = snapshot.docChanges();
      const itemsToAdd = changes
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => ({
          id: doc.id,
          ...doc.data(),
        }));
      if (itemsToAdd.length > 0)
        yield put(monitorActions.addSelectedWaMessages(itemsToAdd));

      const itemsToModify = changes
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => ({
          id: doc.id,
          ...doc.data(),
        }));

      if (itemsToModify.length > 0)
        yield put(monitorActions.modifySelectedWaMessages(itemsToModify));
      const itemsToRemove = changes
        .filter(({ type }) => type === 'removed')
        .map(({ doc: { id } }) => id);
      if (itemsToRemove.length > 0)
        yield put(monitorActions.removeSelectedWaMessages(itemsToRemove));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
      // console.log('Closed updates channel');
    }
  }
}

function* watchLoadJourney() {
  while (true) {
    const {
      payload: { customerDocNumber, jid, sid },
    } = yield take(monitorActions.loadJourneys);

    const journeysUpdateTask = yield fork(
      watchJourneysUpdates,
      customerDocNumber
    );
    const botsUpdateTask = yield fork(watchBotsUpdates, customerDocNumber);
    const pixsUpdateTask = yield fork(watchPixsUpdates, jid);
    const waMessagesUpdateTask = yield fork(watchWaMessagesUpdates, sid);
    const { payload: reload = true } = yield take(monitorActions.closeDetail);

    yield put(monitorActions.setJourneys());
    yield put(monitorActions.setBots({}));
    yield put(monitorActions.setSelectedPixs());

    yield cancel(journeysUpdateTask);
    yield cancel(botsUpdateTask);
    yield cancel(pixsUpdateTask);
    yield cancel(waMessagesUpdateTask);
    if (reload) {
      const {
        monitor: { searchState: { inputs } = {} },
      } = yield select((state) => state);
      yield put(monitorActions.load(inputs));
    }
  }
}

function* simulateOperation({ id, ...payload }) {
  try {
    const { localApi } = yield select((state) => state.settings);
    const { url, headers, ...opts } = yield getApi({
      path: `/pix/${id}/simulate`,
      local: localApi,
    });
    const { error, data } = yield rpcCall({
      url,
      method: 'post',
      headers,
      payload,
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(error));
      yield put(monitorActions.simulateOperationFail({ id, error }));
    } else {
      yield put(monitorActions.simulateOperationSuccess({ id, data }));
      const {
        monitor: { selected },
      } = yield select((state) => state);
      yield put(
        monitorActions.setSelected({ pix: selected.pix, isDetailOpen: false })
      );
    }
  } catch (e) {
    yield put(monitorActions.simulateOperationFail({ id, error: e.message }));
    yield put(appActions.setError(e.message));
  }
}

function* watchSimulateOperation() {
  while (true) {
    const { payload } = yield take(monitorActions.simulateOperation);
    yield fork(simulateOperation, payload);
  }
}

function* cancelPix({ id, ...payload }) {
  try {
    const { localApi } = yield select((state) => state.settings);
    yield put(appActions.startLoading());

    const { url, headers, ...opts } = yield getApi({
      path: `/pix/${id}/cancel`,
      local: localApi,
    });
    const { error, data } = yield rpcCall({
      url,
      method: 'post',
      headers,
      payload,
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(error));
      yield put(monitorActions.cancelPixFail({ id, error }));
    } else {
      yield put(monitorActions.cancelPixSuccess({ id, data }));
      const {
        monitor: { selected },
      } = yield select((state) => state);
      yield put(
        monitorActions.setSelected({ pix: selected.pix, isDetailOpen: false })
      );
      yield put(appActions.finishLoading());
      yield put(
        notifierActions.enqueue({
          message: 'Cobrança cancelada com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
    }
  } catch (e) {
    yield put(monitorActions.cancelPixFail({ id, error: e.message }));
    yield put(appActions.finishLoading());

    yield put(appActions.setError(e.message));
  }
}

function* watchCancelPix() {
  while (true) {
    const { payload } = yield take(monitorActions.cancelPix);
    yield fork(cancelPix, payload);
  }
}

function* updatePix({ txid, ...payload }) {
  try {
    const { localApi } = yield select((state) => state.settings);
    yield put(appActions.startLoading());
    const { url, headers, ...opts } = yield getApi({
      path: `/pix/${txid}`,
      local: localApi,
    });
    const { error, data } = yield rpcCall({
      url,
      method: 'patch',
      headers,
      payload,
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(error));
      yield put(monitorActions.updatePixFail({ txid, error }));
      yield put(appActions.finishLoading());
    } else {
      yield put(monitorActions.updatePixSuccess({ txid, data }));
      const {
        monitor: { selected },
      } = yield select((state) => state);
      yield put(
        monitorActions.setSelected({ pix: selected.pix, isDetailOpen: false })
      );
      yield put(appActions.finishLoading());
      yield put(
        notifierActions.enqueue({
          message: 'Cobrança alterada com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
    }
  } catch (e) {
    yield put(monitorActions.updatePixFail({ txid, error: e.message }));
    yield put(appActions.setError(e.message));
    yield put(appActions.finishLoading());
  }
}

function* watchUpdatePix() {
  while (true) {
    const {
      payload: { updateCustomer, ...data },
    } = yield take(monitorActions.updatePix);
    yield fork(updatePix, data);
    try {
      if (updateCustomer) {
        const {
          settings: {
            enterprise: { id: eid },
          },
        } = yield select((state) => state);
        const { cid, ...invoiceData } = data;
        const invoiceDay = parseInt(
          moment(invoiceData.invoiceDate).format('DD')
        );

        yield customersDao.updateInvoice(eid, cid, {
          ...invoiceData,
          invoiceDay,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      yield put(
        appActions.setError('Erro ao atualizar o cadastro: ' + e.message)
      );
    }
  }
}

function* changeContact({ jid, ...payload }) {
  try {
    const { localApi } = yield select((state) => state.settings);
    yield put(appActions.startLoading());
    const { url, headers, ...opts } = yield getApi({
      path: `/journey/${jid}/change-contact`,
      local: localApi,
    });
    const { error, data } = yield rpcCall({
      url,
      method: 'post',
      headers,
      payload,
      ...opts,
    });
    if (error) {
      yield put(appActions.setError(error));
      yield put(monitorActions.changeContactFail({ jid, error }));
      yield put(appActions.finishLoading());
    } else {
      yield put(monitorActions.changeContactSuccess({ jid, data }));
      yield put(appActions.finishLoading());
      yield put(
        notifierActions.enqueue({
          message: 'Dados alterados com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
    }
  } catch (e) {
    yield put(monitorActions.changeContactFail({ jid, error: e.message }));
    yield put(appActions.setError(e.message));
    yield put(appActions.finishLoading());
  }
}

function* watchChangeContact() {
  while (true) {
    const { payload } = yield take(monitorActions.changeContact);
    yield fork(changeContact, payload);
  }
}

function* deleteJourney(id) {
  try {
    const { localApi } = yield select((state) => state.settings);
    const { url, headers, ...opts } = yield getApi({
      path: `/journey/${id}`,
      local: localApi,
    });
    yield ky(url, { method: 'delete', headers, ...opts });
    yield put(monitorActions.setJourneys());
    yield put(monitorActions.setSelected());
    yield put(monitorActions.setBots({}));
    yield put(monitorActions.deleteJourneySuccess(id));
    yield put(monitorActions.closeDetail(true));
    yield put(appActions.finishLoading());
  } catch (e) {
    yield put(monitorActions.deleteJourneyFail({ id, error: e.message }));
    yield put(appActions.setError(e.message));
    yield put(appActions.finishLoading());
  }
}

function* watchDeleteJourney() {
  while (true) {
    const { payload } = yield take(monitorActions.deleteJourney);
    yield put(appActions.startLoading());
    yield fork(deleteJourney, payload);
  }
}

function* deletePix(txid) {
  try {
    const { localApi } = yield select((state) => state.settings);
    yield put(appActions.startLoading());
    const { url, headers } = yield getApi({
      path: `/pix/${txid}`,
      local: localApi,
    });
    const { error, data } = yield rpcCall({
      url,
      method: 'delete',
      headers,
    });
    if (error) {
      yield put(appActions.setError(error));
      yield put(monitorActions.deletePixFail({ txid, error }));
      yield put(appActions.finishLoading());
    } else {
      yield put(monitorActions.deletePixSuccess({ txid }));
      const {
        monitor: { selected },
      } = yield select((state) => state);
      yield put(monitorActions.closeDetail(true));
      yield put(appActions.finishLoading());
      yield put(
        notifierActions.enqueue({
          message: 'Cobrança excluída com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
    }
  } catch (e) {
    yield put(monitorActions.deletePixFail({ txid, error: e.message }));
    yield put(appActions.setError(e.message));
    yield put(appActions.finishLoading());
  }
}

function* watchDeletePix() {
  while (true) {
    const { payload } = yield take(monitorActions.deletePix);
    yield fork(deletePix, payload);
  }
}

function* watchRestartJourney() {
  while (true) {
    const {
      payload: { jid, confirmCpc = false },
    } = yield take(monitorActions.restartJourney);
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/journey/${jid}/restart`,
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        payload: {
          stepId: 'START',
          reset: true,
          ignoreBusinessHours: true,
          confirmCpc,
        },
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(monitorActions.restartJourneyFail({ error: error.message }));
      } else {
        yield put(monitorActions.restartJourneySuccess());
        yield put(
          notifierActions.enqueue({
            message: 'Jornada reiniciada com sucesso !',
            options: {
              variant: 'success',
            },
          })
        );
      }
    } catch (e) {
      yield put(monitorActions.restartJourneyFail({ error: e.message }));
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchInterruptJourney() {
  while (true) {
    const { payload: jid } = yield take(monitorActions.interruptJourney);
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/journey/${jid}/interrupt`,
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        payload: {
          result: {
            resultId: 'OTHER',
            userComment: 'Usuário interrompeu a jornada',
            resultDescription: 'Outro motivo',
          },
        },
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(
          monitorActions.interruptJourneyFail({ error: error.message })
        );
      } else {
        yield put(monitorActions.interruptJourneySuccess());
        yield put(
          notifierActions.enqueue({
            message: 'Jornada interrompida com sucesso !',
            options: {
              variant: 'success',
            },
          })
        );
      }
    } catch (e) {
      yield put(monitorActions.interruptJourneyFail({ error: e.message }));
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchSendMsg() {
  while (true) {
    const {
      payload: { jid, msgs },
    } = yield take(monitorActions.sendMsg);
    try {
      const { localApi } = yield select((state) => state.settings);
      const { url, headers, ...opts } = yield getApi({
        path: `/journey/${jid}/send-msg`,
        local: localApi,
      });

      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers,
        payload: { msgs },
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
        yield put(monitorActions.sendMsgFail({ error: error.message }));
      } else {
        yield put(monitorActions.sendMsgSuccess());
      }
    } catch (e) {
      yield put(monitorActions.sendMsgFail({ error: e.message }));
      yield put(appActions.setError(e.message));
    }
  }
}

function* delayHightlight(ids) {
  yield delay(8000);
  yield put(monitorActions.removeHighlightedIds(ids));
}

function* watchAddHighlightedIds() {
  while (true) {
    const { payload } = yield take(monitorActions.addHighlightedIds);
    yield fork(delayHightlight, payload);
  }
}

function* watchUpdateTags() {
  const db = firebase.firestore();
  while (true) {
    const {
      payload: { cid, tags },
    } = yield take(monitorActions.updateTags);
    try {
      yield put(appActions.startLoading());
      const snapshot = yield db
        .collection('pix')
        .where('invoiceData.customerData.cid', '==', cid)
        .get();
      for (const doc of snapshot.docs) {
        const { invoiceData } = doc.data();
        const { customerData } = invoiceData;

        const { localApi } = yield select((state) => state.settings);
        const { url, headers, ...opts } = yield getApi({
          path: `/pix/${doc.id}/update-customer-data`,
          local: localApi,
        });
        yield ky.post(url, {
          json: { customerData: { ...customerData, tags } },
          headers,
          ...opts,
        });
      }
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(monitorActions.updateTagsSuccess());
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(monitorActions.updateTagsFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadNoContactPhones() {
  const db = firebase.firestore();
  while (true) {
    const { payload: cid } = yield take(monitorActions.loadNoContactPhones);
    const {
      settings: {
        enterprise: { id: eid },
      },
    } = yield select((state) => state);
    try {
      // console.log(`Searching collection enterprises/${eid}/customers/${cid}`);
      const doc = yield db
        .collection(`enterprises/${eid}/customers`)
        .doc(cid)
        .get();
      if (!doc.exists) {
        throw new Error('Cliente não encontrado');
      }
      yield put(
        monitorActions.loadNoContactPhonesSuccess(
          doc.data().noContactPhones ?? []
        )
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      // Rafael (21/08/2022) Não gera mais erro porque nem sempre vai existir o cliente no cadastro
      // yield put(appActions.setError(e.message));
      // yield put(monitorActions.loadNoContactPhonesFail());
      yield put(monitorActions.loadNoContactPhonesSuccess([]));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadWhatsappTemplates() {
  const db = firebase.firestore();
  while (true) {
    yield take(monitorActions.loadWhatsappTemplates);
    const {
      settings: {
        enterprise: { waSessionId },
      },
    } = yield select((state) => state);
    try {
      // console.log(`Searching collection enterprises/${eid}/customers/${cid}`);
      const snapshot = yield db
        .collection(`wa-sessions/${waSessionId}/templates`)
        .get();
      if (!snapshot.empty) {
        yield put(
          monitorActions.setWhatsappTemplates(
            snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          )
        );
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(monitorActions.setWhatsappTemplates(undefined));

      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchLoad,
  watchLoadJourney,
  watchSimulateOperation,
  watchCancelPix,
  watchChangeContact,
  watchUpdatePix,
  watchDeletePix,
  watchDeleteJourney,
  watchAddHighlightedIds,
  watchSendMsg,
  watchRestartJourney,
  watchInterruptJourney,
  watchUpdateTags,
  watchLoadNoContactPhones,
  watchLoadWhatsappTemplates,
];
