'use strict';

/**
 * Fluxo
 * =====
 *
 * Cliente não confirmou:
 *
 * 1. Telefone pertence a cliente?
 * 1.1 (SIM) Reenviar lembretes
 * 1.1.1 FIM
 * 1.2 (NAO) Trocar de telefone
 * 1.2.1 FIM
 *
 * Cliente contestou:
 *
 * 1. Precisa trocar os dados do pix?
 * 1.1 (SIM) Trocar pix
 * 1.1.1 FIM
 * 1.2 (NAO) Enviar mensagem ao cliente?
 * 1.2.1 (SIM) Enviar mensagem
 * 1.2.1.1 FIM
 * 1.2.2 (NAO) FIM
 *
 */

export const issueTypeMap = {
  ['REJECTED']: {
    label: 'Contestação',
    description: 'O cliente contestou a cobrança',
    paths: {
      ['1']: 'Verificar cobranças',
      ['1.1']: 'Não precisa de ajuste',
      ['1.2']: 'Precisa de ajuste',
    },
  },
  ['CPC_INCOMPLETE']: {
    label: 'CPC Não Confirmado',
    description: 'O cliente não confirmou a identidade.',
    paths: {
      ['1']: 'Verificar o cadastro',
      ['1.1']: 'Telefone pertence ao cliente',
      ['1.2']: 'Telefone não pertence ao cliente',
    },
  },
  ['NO_CPC']: {
    label: 'Não é o cliente',
    description: 'A pessoa respondeu que não é o cliente',
    paths: {
      ['1']: 'Verificar o cadastro',
      ['1.1']: 'Telefone pertence ao cliente',
      ['1.2']: 'Telefone não pertence ao cliente',
    },
  },
  ['AUDIO_IMG']: {
    label: 'Audio / Imagem',
  },
  ['MAX_ERROR']: {
    label: 'Erro máximo',
  },
  ['RENEWAL_ALERT']: {
    label: 'Renovação',
    description: 'Alerta de renovação',
    paths: {
      ['1']: 'Verificar alerta',
    },
  },
  ['EXTERNAL_PAYMENT']: {
    label: 'Pagamento externo',
    description: 'Pagamento externo detectado no extrato',
    paths: {
      ['1']: 'Verificar alerta',
    },
  },
};
