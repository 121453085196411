import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';

import CheckIcon from '@mui/icons-material/Check';
import { formatValue } from '../../helpers/formatter';
import { journeysActions } from '../journeys/journeys-slice';
import MailingPreview from './MailingPreview';
import { mailingActions } from './mailing-slice';
const useStyles = makeStyles((theme) => ({
  clsProgressPanel: {
    borderRadius: '0.5em',
    backgroundColor: theme.palette.background.field,
    color: theme.palette.text.secondary,
    height: '76px',
  },
  clsProgressBar: {
    width: '95%',
  },
  clsHidden: {
    display: 'none',
  },
}));

function MailingDb() {
  const { clsProgressPanel, clsProgressBar, clsHidden } = useStyles();
  const { selected } = useSelector((state) => state.journeys);
  const {
    isProcessingPreview,
    charge: { chid } = {},
    createChargeError,
  } = useSelector((state) => state.mailing);
  const {
    enterprise: { inTrial },
    // trialDaysLeft,
  } = useSelector((state) => state.settings);

  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mailingActions.reset());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [monthCount, serviceDate, invoiceMonth] = watch([
    'monthCount',
    'serviceDate',
    'invoiceMonth',
  ]);

  const isProcessing = isProcessingPreview;
  const isRecurring = tabValue === 1 || tabValue === 2;
  const divideValue = tabValue === 2;
  const isValidRecurring = monthCount && monthCount <= 12;

  const exampleValue = 500;
  const installmentValue = isValidRecurring
    ? divideValue
      ? exampleValue / monthCount
      : exampleValue
    : undefined;

  const exampleInvoices = isValidRecurring
    ? Array.from(Array(monthCount)).map((_v, i) => ({
        serviceDate: moment(serviceDate).add(i, 'month').format('MM/YYYY'),
        invoiceMonth: moment(invoiceMonth).add(i, 'month').format('MM/YYYY'),
        installmentValue,
        i: i + 1,
      }))
    : [];

  const onSubmit = (formData) => {
    const { id: jid } = selected;
    const dataToSave = {
      jid,
      ...formData,
      serviceDate: moment(formData.serviceDate).format('YYYYMM'),
      invoiceMonth: moment(formData.invoiceMonth).format('YYYYMM'),
      type: 'db',
      monthCount: isRecurring ? monthCount : 1,
      divideValue,
    };
    // console.log(dataToSave);

    dispatch(mailingActions.createCharge(dataToSave));
  };

  return (
    <>
      <Box className={clsx((isProcessing || chid) && clsHidden)}>
        <EditForm p={0}>
          <EditFormContent overflow="auto">
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                p={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
              >
                <Typography>Quantas faturas deseja gerar?</Typography>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  aria-label="Tipo de envio"
                  value={tabValue}
                  onChange={(_ev, newValue) => {
                    if (newValue !== undefined) {
                      reset();
                      setTabValue(newValue);
                    }
                  }}
                >
                  <ToggleButton value={0}>Uma por cliente</ToggleButton>
                  <ToggleButton disabled={inTrial} value={1}>
                    {inTrial && <LockIcon />}
                    Mais de uma (Recorrente)
                  </ToggleButton>
                  <ToggleButton disabled={inTrial} value={2}>
                    {inTrial && <LockIcon />}
                    Mais de uma (Parcelamento)
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box p={1}>
                <Grid container item spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <EditFormField
                      field={{
                        name: 'serviceDate',
                        label: isRecurring
                          ? 'Data de Referência Inicial'
                          : 'Data de Referência',
                        type: 'date',
                      }}
                      defaultValue={moment().format('YYYYMM')}
                      control={control}
                      rules={{
                        required: 'Obrigatório',
                        validate: inTrial
                          ? (v) => {
                              return moment(v).diff(moment(), 'month') < 1
                                ? undefined
                                : 'No modo avaliação escolha uma data no mês atual ou no próximo';
                            }
                          : undefined,
                      }}
                      openTo="month"
                      views={['year', 'month']}
                      format="MM/YYYY"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <EditFormField
                      field={{
                        name: 'invoiceMonth',
                        label: isRecurring
                          ? 'Mês de Vencimento Inicial'
                          : 'Mês de Vencimento',
                        type: 'futureDate',
                      }}
                      defaultValue={moment().endOf('month')}
                      control={control}
                      rules={{
                        required: 'Obrigatório',
                        validate: inTrial
                          ? (v) => {
                              return moment(v).diff(moment(), 'month') < 2
                                ? undefined
                                : 'No modo avaliação escolha uma data de vencimento no mês atual ou no próximo';
                            }
                          : undefined,
                      }}
                      openTo="month"
                      views={['year', 'month']}
                      format="MM/YYYY"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <EditFormField
                      field={{
                        name: 'monthCount',
                        label: `Quantidade de ${
                          divideValue ? 'parcelas' : 'faturas'
                        } - (Máximo 12)`,
                        type: 'integer',
                      }}
                      defaultValue={2}
                      control={control}
                      rules={{
                        required: 'Obrigatório',
                        min: {
                          value: 2,
                          message: 'O valor mínimo é 2',
                        },
                        max: {
                          value: 12,
                          message: 'O valor máximo é 12',
                        },
                      }}
                      hidden={!isRecurring}
                    />
                  </Grid>

                  {isRecurring && isValidRecurring && (
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography variant="body1">
                        {divideValue
                          ? `O valor cadastrado será DIVIDIDO por ${monthCount}`
                          : `O valor cadastrado será REPETIDO ${monthCount} vezes`}
                        , por exemplo:
                      </Typography>
                      <Typography variant="body1" component="p">
                        Valor cadastrado: {formatValue(exampleValue, 'money')} -
                        Valor da {divideValue ? 'Parcela' : 'Fatura'}:{' '}
                        {formatValue(installmentValue, 'money')}
                      </Typography>
                      {exampleInvoices
                        .slice(0, 3)
                        .map(({ invoiceMonth, installmentValue, i }) => (
                          <Typography variant="caption" component="p" key={i}>
                            {divideValue ? 'Parcela' : 'Fatura'} {i} - Venc:{' '}
                            {invoiceMonth} -{' '}
                            {formatValue(installmentValue, 'money')}
                          </Typography>
                        ))}
                      {exampleInvoices.length > 3 && (
                        <>
                          {exampleInvoices.length > 4 && (
                            <Typography variant="caption" component="p">
                              ...
                            </Typography>
                          )}
                          {exampleInvoices
                            .slice(-1)
                            .map(({ invoiceMonth, installmentValue, i }) => (
                              <Typography
                                variant="caption"
                                component="p"
                                key={i}
                              >
                                {divideValue ? 'Parcela' : 'Fatura'} {i} - Venc:{' '}
                                {invoiceMonth} -{' '}
                                {formatValue(installmentValue, 'money')}
                              </Typography>
                            ))}
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </form>
          </EditFormContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={2}
          >
            <ButtonWithProgress
              // type="submit"
              tooltipTitle="Gerar lista de faturas"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={Object.keys(errors).length > 0}
              aria-label="salvar"
              startIcon={<CheckIcon />}
            >
              Gerar lista de faturas
            </ButtonWithProgress>
          </Box>
          {inTrial && (
            <Box
              display="flex"
              alignItems="center"
              padding={2}
              justifyContent="center"
              gap={1}
            >
              <LockIcon />
              <Typography>
                Funções desabilitadas durante o modo avaliação
              </Typography>
            </Box>
          )}
        </EditForm>
      </Box>
      {isProcessing && !createChargeError && (
        <Box margin={1}>
          <Box
            className={clsProgressPanel}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle1">Processando</Typography>
            <LinearProgress className={clsProgressBar} color="secondary" />
          </Box>
        </Box>
      )}
      {isProcessing && createChargeError && (
        <Box margin={1}>
          <Typography variant="subtitle1" color="error">
            {createChargeError?.message ?? 'Ocorreu um erro ao criar a carga'}
          </Typography>

          <Box marginTop={2}>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  journeysActions.selectJourney({
                    selected: null,
                    selectedContext: null,
                  })
                );
                dispatch(mailingActions.reset());
                dispatch(journeysActions.sendReset());
              }}
            >
              Reiniciar
            </Button>
          </Box>
        </Box>
      )}
      {!!chid && !isProcessing && (
        <Box margin={1}>
          <MailingPreview />
        </Box>
      )}
    </>
  );
}

MailingDb.propTypes = {};
export default MailingDb;
