import { Box } from '@mui/material';
import moment from 'moment';
import CustomRichTextEditor from './custom-richtext-editor';
import { parseWhatsappMarkdown } from './markdown';
import { testCNPJ } from './validations';

export const formatValue = (value, type) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (value === undefined) return '';
  switch (type) {
    case 'message': {
      return (
        <Box component="span">
          {parseWhatsappMarkdown(value).map(
            ({ text, bold, italics }, index) => (
              <Box
                key={index}
                component="span"
                style={{
                  fontWeight: bold ? 'bold' : undefined,
                  fontStyle: italics ? 'italic' : undefined,
                }}
              >
                {text}
              </Box>
            )
          )}
        </Box>
      );
    }
    case 'text':
      return value;
    case 'decimal':
      return `${formatter.format(value)}`;
    case 'money':
      return `R$ ${formatter.format(value)}`;
    case 'integer':
      return `${value}`;
    case 'boolean':
      return value ? 'Sim' : 'Não';
    case 'docNumber':
      if (!value) return '';
      if (testCNPJ(value))
        return `${value.substring(0, 2)}.${value.substring(
          2,
          5
        )}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(
          12
        )}`;
      else
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9)}`;
    case 'phone': {
      if (value[0] === '+') return value;
      let nationalPhone = value;
      //if (value.slice(0, 2) === '55') nationalPhone = value.slice(2);
      //else nationalPhone = value;
      const ix = nationalPhone.length - 4;
      return `(${nationalPhone.substring(0, 2)}) ${nationalPhone.substring(
        2,
        ix
      )}-${nationalPhone.substring(ix)}`;
    }
    case 'futureDate':
    case 'date':
      if (!value) return '';
      return moment(value).format('DD/MM/YYYY');
    case 'yearMonth':
      if (!value) return '';
      return moment(value).format('MM/YYYY');
    case 'month':
      if (!value) return '';
      return moment(value).format('MMM');
    case 'time':
      if (!value) return '';
      return moment(value).format('HH:mm:ss');
    case 'dateTime':
      if (!value) return '';
      return `${moment(value).format('DD/MM/YYYY')}\u00A0às\u00A0${moment(
        value
      ).format('HH:mm:ss')}`;
  }
  return '';
  //console.log(selected[fieldName]);
  //return selected[fieldName];
};

export const formatCustomFieldValue = (value, prop) => {
  if (value === undefined) return '-';
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const startAdornment =
    prop?.inputAdornment?.position === 'start'
      ? prop?.inputAdornment?.text + ' '
      : '';
  const endAdornment =
    prop?.inputAdornment?.position === 'end'
      ? ' ' + prop?.inputAdornment?.text
      : '';
  switch (prop.type) {
    case 'message': {
      return (
        <Box component="span">
          {parseWhatsappMarkdown(CustomRichTextEditor.serialize({ value })).map(
            ({ text, bold, italics }, index) => (
              <Box
                key={index}
                component="span"
                style={{
                  fontWeight: bold ? 'bold' : undefined,
                  fontStyle: italics ? 'italic' : undefined,
                }}
              >
                {text}
              </Box>
            )
          )}
        </Box>
      );
    }
    case 'text':
      return value;
    case 'decimal':
      return `${startAdornment}${formatter.format(value)}${endAdornment}`;
    case 'integer':
      return `${startAdornment}${value}${endAdornment}`;
    case 'boolean':
      return value ? 'Sim' : 'Não';
    case 'docNumber':
      if (!value) return '';
      if (testCNPJ(value))
        return `${value.substring(0, 2)}.${value.substring(
          2,
          5
        )}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(
          12
        )}`;
      else
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9)}`;
    case 'phone': {
      const ix = value.length - 4;
      return `(${value.substring(0, 2)}) ${value.substring(
        2,
        ix
      )}-${value.substring(ix)}`;
    }
    case 'futureDate':
    case 'date':
      return moment(value).format('DD/MM/YYYY');
    case 'month':
      return moment(value).format('MM/YYYY');
    case 'select':
      return prop.options.find((v) => v.value === value)?.label;
  }
  return '';
  //console.log(selected[fieldName]);
  //return selected[fieldName];
};
