'use strict';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GitHubIcon from '@mui/icons-material/GitHub';
import HelpIcon from '@mui/icons-material/Help';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WarningIcon from '@mui/icons-material/Warning';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../helpers/firebase';
import { authActions } from '../auth/auth-slice';

import {
  Badge,
  Box,
  Fab,
  LinearProgress,
  ListItemButton,
  Stack,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { NavLink, Route, Routes, useSearchParams } from 'react-router-dom';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { getBaseUrl } from '../../helpers/api';
import getDomain from '../../helpers/domains';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SignInScreen from '../auth/SignInScreen';
import Customers from '../customers/Customers';
import EnterpriseSelect from '../enterprise-select/EnterpriseSelect';
import Enterprises from '../enterprises/Enterprises';
import Help from '../help/Help';
import InitScreen from '../init/InitScreen';
import Issues from '../issues/Issues';
import { issuesActions } from '../issues/issues-slice';
import Journeys from '../journeys/Journeys';
import SendJourney from '../journeys/SendJourney';
import { journeysActions } from '../journeys/journeys-slice';
import { mailingActions } from '../mailing/mailing-slice';
import Monitor from '../monitor/Monitor';
import NewUserScreen from '../new-user/NewUserScreen';
import WelcomeScreen from '../new-user/WelcomeScreen';
import Profile from '../profile/Profile';
import Reports from '../reports/Reports';
import Settings from '../settings/Settings';
import { settingsActions } from '../settings/settings-slice';
import Users from '../users/Users';
import Versions from '../versions/Versions';
import WAStatus from '../wa-status/WAStatus';
import Wallet from '../wallet/Wallet';
import MainAppBar from './MainAppBar';
import Protected from './Protected';
import { appActions } from './app-slice';

const drawerWidth = 240;
const useRouterLinkStyles = makeStyles((theme) => ({
  activeRoute: {
    backgroundColor: theme.palette.action.selected,
    '& *': { color: theme.palette.primary.main },
  },
}));

const RouterLink = React.forwardRef(
  ({ children, className, ...other }, ref) => {
    const classes = useRouterLinkStyles();
    return (
      <NavLink
        ref={ref}
        {...other}
        className={({ isActive }) =>
          clsx(className, isActive && classes.activeRoute)
        }
      >
        {children}
      </NavLink>
    );
  }
);
RouterLink.displayName = 'RouterLink';
RouterLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  clsRoot: {
    display: 'flex',
    height: '100dvh',
  },

  clsDrawerDividerHidden: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  clsToolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  clsAppBarSpacer: theme.mixins.toolbar,
  clsDrawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  clsDrawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7.5),
    },
  },

  clsContent: {
    flexGrow: 1,
    // minHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  clsContentPad: {
    margin: theme.spacing(0),
    overflow: 'auto',
  },

  clsFlexGrow: {
    flexGrow: 1,
  },
  clsHidden: {
    display: 'none',
  },
  clsLinearProgress: {
    zIndex: 1300,
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
  },

  clsSecretActive: {
    backgroundColor: 'black',
    '& *': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: 'rgba(100,100,100)',
    },
  },
  clsBadge: {
    color: '#FFFFFF',
  },
}));
export default function App() {
  const {
    clsRoot,

    clsDrawerDividerHidden,
    clsToolbarIcon,
    clsAppBarSpacer,
    clsDrawerPaper,
    clsDrawerPaperClose,

    clsContent,
    clsContentPad,

    clsFlexGrow,
    clsHidden,
    clsLinearProgress,

    clsSecretActive,
    clsBadge,
  } = useStyles();
  const dispatch = useDispatch();
  useDocumentTitle(getDomain(window.location.hostname).name);
  const [searchParams] = useSearchParams();

  const { currentView, isLoading, isSecretActive } = useSelector(
    (state) => state.app
  );

  const {
    //authData,
    claims,
    userData: { emailVerified, createdAt, role } = {},
    permissions,
    simulateUserView,
  } = useSelector((state) => state.auth);

  const {
    enterprise: { ignoreCustomer, journeyId, defaultSendType, inTrial },
    pixAccount,
    isOpen: isSettingsOpen,
    configRequired,
    adminRequired,
    localApi,
    limitedAccess,
    trialDaysLeft,
  } = useSelector((state) => state.settings);

  const { issues } = useSelector((state) => state.issues);
  const {
    data: journeys,
    selected: selectedJourney,
    selectedContext: selectedJourneyContext,
  } = useSelector((state) => state.journeys);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isEnterpriseSelectOpen, setIsEnterpriseSelectOpen] = useState(false);

  const showWallet = !!pixAccount.parentAccountId;

  const handleSettingsClick = () => {
    dispatch(settingsActions.openSettings());
  };
  const handleProfileClick = () => {
    setIsProfileOpen(true);
  };
  const handleEnterpriseSelectClick = () => {
    setIsEnterpriseSelectOpen(true);
  };
  const handleSendClick = async () => {
    const mainJourney = journeys.find(({ id }) => id === journeyId);
    await dispatch(
      journeysActions.selectJourney({
        selected: mainJourney,
        selectedContext: 'send',
      })
    );
    await dispatch(journeysActions.setSendType(defaultSendType));
    await dispatch(journeysActions.setSendActiveStep(1));
  };

  const handleCloseSendJourney = (reload) => {
    if (reload) dispatch(journeysActions.load());
    dispatch(
      journeysActions.selectJourney({ selected: null, selectedContext: null })
    );
    dispatch(mailingActions.reset());
    dispatch(journeysActions.sendReset());
  };

  useEffect(() => {
    dispatch(settingsActions.init());
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          const {
            displayName,
            email,
            emailVerified,
            uid,
            phoneNumber,
            photoUrl,
          } = user;
          dispatch(
            authActions.login({
              authData: {
                displayName,
                email,
                emailVerified,
                uid,
                phoneNumber,
                photoUrl,
              },
              invitation: searchParams.get('inv'),
            })
          );
        } else dispatch(authActions.logout());
      });
    return () => {
      dispatch(issuesActions.stop());
      unregisterAuthObserver();
    };
  }, [dispatch]);

  switch (currentView) {
    case 'init':
      return <InitScreen />;
    case 'signIn':
      return <SignInScreen />;
    case 'welcome':
      return <WelcomeScreen />;
    case 'newUser':
    case 'newInvitation':
      return <NewUserScreen />;
    default:
      break;
  }

  return (
    <div className={clsRoot}>
      <MainAppBar
        onProfileClick={handleProfileClick}
        onEnterpriseSelectClick={handleEnterpriseSelectClick}
        onDrawerClick={() => setIsDrawerOpen(true)}
        isDrawerOpen={isDrawerOpen}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(clsDrawerPaper, !isDrawerOpen && clsDrawerPaperClose),
        }}
        open={isDrawerOpen}
      >
        <div className={clsToolbarIcon}>
          <IconButton onClick={() => setIsDrawerOpen(false)} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider className={clsx(!isDrawerOpen && clsDrawerDividerHidden)} />
        <Box>
          <List>
            {permissions['cus-add'] && !ignoreCustomer && (
              <ListItemButton component={RouterLink} to="/customers">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
              </ListItemButton>
            )}
            {(permissions['jrn-add'] ||
              (permissions['jrn-snd'] &&
                moment(createdAt).isBefore(moment('2023-06-01'))) ||
              permissions['jrn-del']) && (
              <ListItemButton component={RouterLink} to="/journeys">
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Jornadas" />
              </ListItemButton>
            )}
            <ListItemButton component={RouterLink} to="/monitor">
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Cobranças" />
            </ListItemButton>
            {permissions['iss-slv'] && (
              <ListItemButton component={RouterLink} to="/issues">
                <ListItemIcon>
                  <Badge
                    badgeContent={issues.length}
                    color="error"
                    classes={{ badge: clsBadge }}
                  >
                    <FormatListBulletedIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary="Pendências" />
              </ListItemButton>
            )}
            {(permissions['rpt-pay'] || permissions['rpt-sta']) && (
              <ListItemButton component={RouterLink} to="/reports">
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Relatório" />
              </ListItemButton>
            )}
            {permissions['wal'] && showWallet && (
              <ListItemButton component={RouterLink} to="/wallet">
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary="Carteira" />
              </ListItemButton>
            )}
            {permissions['msg-qrc'] && (
              <ListItemButton component={RouterLink} to="/wa-status">
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText primary="WhatsApp" />
              </ListItemButton>
            )}
            {(permissions['usr-add'] || permissions['usr-prm']) && (
              <ListItemButton component={RouterLink} to="/users">
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>
                <ListItemText primary="Usuários" />
              </ListItemButton>
            )}
            {permissions['ent-add'] && (
              <ListItemButton component={RouterLink} to="/enterprises">
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Empresas" />
              </ListItemButton>
            )}
          </List>
        </Box>
        <Box flexGrow={1}></Box>
        <Box>
          <List>
            {permissions['hlp'] && (
              <ListItemButton component={RouterLink} to="/help">
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="Ajuda" />
              </ListItemButton>
            )}
            {permissions['ins'] && (
              <Tooltip
                title={
                  localApi
                    ? `API LOCAL (${getBaseUrl(localApi)})`
                    : `API CLOUD (${getBaseUrl(localApi)})`
                }
              >
                <ListItemButton
                  onClick={() =>
                    dispatch(
                      settingsActions.changeSettings({
                        localApi: !localApi,
                      })
                    )
                  }
                  className={clsx(localApi && clsSecretActive)}
                >
                  <ListItemIcon>
                    {localApi ? <CloudOffIcon /> : <CloudDoneIcon />}
                  </ListItemIcon>
                  <ListItemText primary="API" />
                </ListItemButton>
              </Tooltip>
            )}
            {permissions['dbg'] && (
              <ListItemButton
                onClick={() =>
                  dispatch(appActions.setIsSecretActive(!isSecretActive))
                }
                className={clsx(isSecretActive && clsSecretActive)}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Inspetor" />
              </ListItemButton>
            )}

            {permissions['vrs'] && (
              <ListItemButton component={RouterLink} to="/versions">
                <ListItemIcon>
                  <GitHubIcon />
                </ListItemIcon>
                <ListItemText primary="Versões" />
              </ListItemButton>
            )}
            {role === 'dev' && (
              <ListItemButton
                onClick={() => {
                  dispatch(authActions.setSimulateUserView(!simulateUserView));
                  dispatch(
                    authActions.setRole(simulateUserView ? 'dev' : 'own')
                  );
                }}
                className={clsx(simulateUserView && clsSecretActive)}
              >
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Visão usuário" />
              </ListItemButton>
            )}
          </List>
        </Box>
        <Typography
          className={clsx(!isDrawerOpen && clsHidden)}
          color="textSecondary"
          align="center"
          variant="caption"
          display="block"
          gutterBottom
        >
          {process.env.REACT_APP_NAME} - v{process.env.REACT_APP_VERSION} -{' '}
          {process.env.REACT_APP_ENV}
        </Typography>
      </Drawer>
      <main className={clsContent}>
        <div className={clsAppBarSpacer} />
        {isLoading && (
          <LinearProgress color="secondary" className={clsLinearProgress} />
        )}
        {emailVerified &&
          !configRequired &&
          (adminRequired || claims?.userData === undefined) && (
            <Box
              marginTop={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box marginRight={1}>
                <WarningIcon color="error" />
              </Box>
              <Typography
                variant="body2"
                color="error"
                align="center"
                display="block"
                gutterBottom
              >
                Quase lá! O cadastro está OK, agora basta aguardar a ativação da
                sua conta!
              </Typography>
              <Box marginLeft={1}>
                <WarningIcon color="error" />
              </Box>
            </Box>
          )}
        <div
          className={clsx(clsContentPad, clsFlexGrow)}
          id="main-div-container"
        >
          <Routes>
            <Route
              path="/customers"
              element={
                <Protected allow={permissions['cus-add'] && !ignoreCustomer}>
                  <Customers />
                </Protected>
              }
            />
            <Route
              path="/journeys"
              element={
                <Protected
                  allow={
                    permissions['jrn-add'] ||
                    (permissions['jrn-snd'] &&
                      moment(createdAt).isBefore(moment('2023-06-01'))) ||
                    permissions['jrn-del']
                  }
                >
                  <Journeys />
                </Protected>
              }
            />
            <Route
              path="/reports"
              element={
                <Protected
                  allow={permissions['rpt-pay'] || permissions['rpt-sta']}
                >
                  <Reports />
                </Protected>
              }
            />
            <Route
              path="/wallet"
              element={
                <Protected allow={permissions['wal'] && showWallet}>
                  <Wallet />
                </Protected>
              }
            />
            <Route
              path="/wa-status"
              element={
                <Protected allow={permissions['msg-qrc']}>
                  <WAStatus />
                </Protected>
              }
            />
            <Route
              path="/monitor"
              element={
                <Protected allow={true}>
                  <Monitor />
                </Protected>
              }
            />
            <Route
              path="/issues"
              element={
                <Protected allow={permissions['iss-slv']}>
                  <Issues />
                </Protected>
              }
            />
            <Route
              path="/versions"
              element={
                <Protected allow={permissions['vrs']}>
                  <Versions />
                </Protected>
              }
            />
            <Route
              path="/users"
              element={
                <Protected
                  allow={permissions['usr-add'] || permissions['usr-prm']}
                >
                  <Users />
                </Protected>
              }
            />
            <Route
              path="/enterprises"
              element={
                <Protected allow={permissions['ent-add']}>
                  <Enterprises />
                </Protected>
              }
            />
            <Route path="/help">
              <Route
                index
                element={
                  <Protected allow={permissions['hlp']}>
                    <Help />
                  </Protected>
                }
              />
              <Route
                path=":section"
                element={
                  <Protected allow={permissions['hlp']}>
                    <Help />
                  </Protected>
                }
              />
            </Route>
            <Route path="/" element={<Monitor />} />
          </Routes>
        </div>
        {!emailVerified && (
          <Typography
            variant="caption"
            color="error"
            align="center"
            display="block"
            gutterBottom
          >
            ATENÇÃO! Algumas funcionalidades não estarão habilitadas até você
            verificar seu e-mail.{' '}
            <Link onClick={() => handleProfileClick()} href="#">
              Clique aqui para verificar
            </Link>
          </Typography>
        )}
        {configRequired && (
          <Typography
            variant="caption"
            color="error"
            align="center"
            display="block"
            gutterBottom
          >
            ATENÇÃO! Existem algumas configurações faltando, algumas
            funcionalidades não estarão habilitadas até você corrigir.{' '}
            <Link onClick={() => handleSettingsClick()} href="#">
              Clique aqui para verificar
            </Link>
          </Typography>
        )}
        {emailVerified &&
          !configRequired &&
          (adminRequired || claims?.userData === undefined) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box marginRight={1}>
                <WarningIcon color="error" />
              </Box>
              <Typography
                variant="body2"
                color="error"
                align="center"
                display="block"
                gutterBottom
              >
                Quase lá! O cadastro está OK, agora basta aguardar a ativação da
                sua conta!
              </Typography>
              <Box marginLeft={1}>
                <WarningIcon color="error" />
              </Box>
            </Box>
          )}
        {inTrial && trialDaysLeft < 0 && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box marginRight={1}>
              <WarningIcon color="error" />
            </Box>
            <Typography
              variant="body2"
              color="error"
              align="center"
              display="block"
              gutterBottom
            >
              O período de avaliação terminou. Entre em contato com a nossa
              equipe para ativar a versão completa e continuar utilizando o
              sistema!
            </Typography>
            <Box marginLeft={1}>
              <WarningIcon color="error" />
            </Box>
          </Box>
        )}
        <Stack direction="row" justifyContent="center">
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            display="block"
            gutterBottom
          >
            Copyright
          </Typography>
          <Box paddingLeft={0.5} paddingRight={0.5} paddingTop="2px">
            <img src="/favicon-16x16.png" />
          </Box>
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            display="block"
            gutterBottom
          >
            <Link color="inherit" href="https://www.zetaone.com.br/">
              {getDomain(window.location.hostname).name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Stack>
        <Fab
          sx={{ position: 'absolute', bottom: 48, right: 48 }}
          color="secondary"
          onClick={handleSendClick}
          disabled={!emailVerified || limitedAccess || !permissions['jrn-snd']}
        >
          <SendIcon />
        </Fab>
      </main>
      <ResponsiveDialog open={isSettingsOpen} maxWidth="lg" fullWidth>
        <Settings onClose={() => dispatch(settingsActions.closeSettings())} />
      </ResponsiveDialog>
      <ResponsiveDialog open={isProfileOpen} maxWidth="sm" fullWidth>
        <Profile onClose={() => setIsProfileOpen(false)} />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isEnterpriseSelectOpen}
        maxWidth="md"
        onClose={() => setIsEnterpriseSelectOpen(false)}
        fullWidth
      >
        <EnterpriseSelect onClose={() => setIsEnterpriseSelectOpen(false)} />
      </ResponsiveDialog>
      {selectedJourney && selectedJourneyContext === 'send' && (
        <ResponsiveDialog
          open={!!selectedJourney}
          maxWidth="lg"
          fullWidth
          disableEscapeKeyDown
          fullHeight
        >
          <SendJourney onClose={handleCloseSendJourney} />
        </ResponsiveDialog>
      )}
    </div>
  );
}
