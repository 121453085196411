'use strict';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { formatValue } from '../../helpers/formatter';

const useStyles = makeStyles((theme) => ({
  clsTooltip: {
    maxWidth: 500,
  },
  clsFieldExample: {
    fontWeight: 'bold',
    borderWidth: '1px',
  },
  clsCaption: {
    color: theme.palette.text.disabled,
  },
  clsField: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.text.disabled,
  },
}));

function DisplayFormField({ value, defaultValue, field, children, ...props }) {
  const { clsCaption, clsTooltip, clsField } = useStyles();

  const { label, description, inputAdornment, tooltipHelpTitle, type } = field;

  let component;

  component = (
    <Box className={clsField} paddingBottom={0.5} {...props} height={1}>
      <Typography variant="caption" className={clsCaption}>
        {label}
      </Typography>
      {children}
      {!children && (
        <Typography variant="body1">
          {inputAdornment &&
            (inputAdornment.position ?? 'start') === 'start' &&
            `${inputAdornment.text} `}
          {formatValue(value ?? defaultValue, type)}
          {inputAdornment &&
            (inputAdornment.position ?? 'start') === 'end' &&
            ` ${inputAdornment.text}`}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box display="flex" width={1} height={1} flexDirection="row">
      <Box flexGrow={1}>{component}</Box>
      {(description || tooltipHelpTitle) && (
        <Box paddingLeft={1}>
          <Tooltip
            className={clsTooltip}
            title={tooltipHelpTitle ?? description}
          >
            <HelpIcon color="action" />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

DisplayFormField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string.isRequired,
    inputAdornment: PropTypes.shape({
      position: PropTypes.string,
      text: PropTypes.string,
    }),
    options: PropTypes.array,
    tooltipHelpTitle: PropTypes.node,
    RichTextToolbarComponent: PropTypes.elementType,
    RichTextToolbarComponentProps: PropTypes.object,
  }),
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  variant: PropTypes.string,
  children: PropTypes.node,
};
export default DisplayFormField;
