'use strict';
import { Box, Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  clsIcon: {
    fontSize: '5em',
    width: '100%',
    //color: 'rgba(120,120,120,0.2)',
  },
}));

function EmptyContent({ title, detail, faIcon }) {
  const { clsIcon } = useStyles();

  return (
    <>
      <Box marginTop={4} marginBottom={4}>
        <Typography variant="subtitle1" align="center" color="textSecondary">
          <Icon className={clsx('fa-solid', faIcon ?? 'fa-ghost', clsIcon)} />
        </Typography>
      </Box>
      <Typography variant="h4" align="center" color="textSecondary">
        {title}
      </Typography>
      <Box marginTop={4} marginBottom={4}>
        <Typography variant="body1" align="center" color="textSecondary">
          {detail}
        </Typography>
      </Box>
    </>
  );
}

EmptyContent.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  faIcon: PropTypes.string,
};

export default EmptyContent;
