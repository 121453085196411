'use strict';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Tooltip, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { makeStyles } from '@mui/styles';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import CPCWizard from './CPCWizard';
import RejectedWizard from './RejectedWizard';
import RenewalAlertWizard from './RenewalAlertWizard';
import { issuesActions } from './issues-slice';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));
export default function FormSolveIssue({ onCancelClick }) {
  const { clsWarning } = useStyles();
  const dispatch = useDispatch();
  const [isSolveWithoutActionConfirmOpen, setIsSolveWithoutActionConfirmOpen] =
    useState();
  const {
    issues: {
      isAwaitingCommand,
      issueSolve: { solveType, solvePath },
    },
    monitor: {
      journey: { id: jid },
    },
  } = useSelector((state) => state);

  return (
    <>
      <EditForm>
        <EditFormHeader
          title="Resolver pendência"
          onCloseClick={onCancelClick}
        />
        {(solveType === 'CPC_INCOMPLETE' || solveType === 'NO_CPC') && (
          <CPCWizard />
        )}
        {solveType === 'REJECTED' && <RejectedWizard />}
        {solveType === 'RENEWAL_ALERT' && <RenewalAlertWizard />}
        <EditFormFooter>
          <EditFormMessage />
          <EditFormAction display="flex" flexDirection="row">
            <Tooltip title="Voltar">
              <Box marginRight={1}>
                <Button
                  disabled={solvePath.length === 1 || isAwaitingCommand}
                  onClick={() => {
                    dispatch(issuesActions.rewindSolvePath());
                  }}
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </Box>
            </Tooltip>
            <ButtonWithProgress
              tooltipTitle="Marcar como Resolvida"
              onClick={() => setIsSolveWithoutActionConfirmOpen(true)}
              variant="contained"
              color="primary"
              disabled={isAwaitingCommand}
              isLoading={isAwaitingCommand}
              startIcon={<CheckIcon />}
            >
              Marcar como Resolvida
            </ButtonWithProgress>
          </EditFormAction>
        </EditFormFooter>
      </EditForm>
      <ConfirmationDialog
        open={!!isSolveWithoutActionConfirmOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsSolveWithoutActionConfirmOpen(false),
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(issuesActions.clearIssue(jid));
                setIsSolveWithoutActionConfirmOpen(false);
              },
              startIcon: <CheckIcon />,
            },
          },
        ]}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <WarningIcon className={clsWarning} />
          <Box marginLeft={1}>
            <Typography component="span">ATENÇÃO !</Typography>
          </Box>
        </Box>
        <br />
        Tem certeza que deseja marcar essa pendência como resolvida?
      </ConfirmationDialog>
    </>
  );
}

FormSolveIssue.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
};
