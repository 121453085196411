'use strict';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import DisplayFormField from '../../components/EditForm/DisplayFormField';

export default function ExternalPaymentWizard() {
  const {
    issues: { selectedExternalIssue },
  } = useSelector((state) => state);

  return (
    <>
      <Box>
        <Typography variant="body2">
          Um pagamento foi recebido na sua conta porém não foi possível
          identificar a cobrança automaticamente.
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="body2">
          Isso aconteceu pois o pagamento foi feito de forma direta (isto é, sem
          utilizar o QRCode enviado). Por isso é necessário identificar e fazer
          a baixa manualmente.
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="body2">
          Para resolver esta pendência, verifique o comprovante clicando no link
          abaixo. Após identificar qual é a cobrança, faça o cancelamento da
          mesma no menu Cobranças;
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Typography variant="body2">
          Quando fizer o cancelamento, basta marcar a pendência como resolvida.
        </Typography>
      </Box>
      <Box paddingTop={2} paddingBottom={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Card variant="outlined">
            <Box p={2} display="flex" flexDirection="column">
              <Box>
                <Typography variant="subtitle2">Dados do pagamento:</Typography>
              </Box>
              <Box>
                <DisplayFormField
                  field={{
                    name: 'issueDate',
                    label: 'Data',
                    type: 'dateTime',
                  }}
                  defaultValue={selectedExternalIssue?.issueDate}
                />
              </Box>
              <Box>
                <DisplayFormField
                  field={{
                    name: 'currentValue',
                    label: 'Valor',
                    type: 'money',
                  }}
                  defaultValue={selectedExternalIssue?.currentValue}
                />
              </Box>
            </Box>
            <CardActionArea>
              <Box p={2}>
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<InsertDriveFileIcon />}
                  component={Link}
                  href={selectedExternalIssue?.transactionReceiptUrl}
                  target="_blank"
                  rel="noopener"
                >
                  Comprovante
                </Button>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      <Box flexGrow={1} />
    </>
  );
}

ExternalPaymentWizard.propTypes = {};
