'use strict';
import emoji from 'node-emoji';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Link, Tooltip } from '@mui/material';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import { useForm, useWatch } from 'react-hook-form';
import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import ConfirmationDialog from '../../components/ConfirmationDialog.jsx';
import DisplayFormField from '../../components/EditForm/DisplayFormField';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import FirebaseConsoleLink from '../../components/FirebaseConsoleLink.jsx';
import ResponsiveDialog from '../../components/ResponsiveDialog.jsx';
import { formatValue } from '../../helpers/formatter';
import { getPixStatus, pixStatusMap } from '../../helpers/pix-status.js';
import { appActions } from '../app/app-slice.js';
import { getMessenger } from '../wa-status/get-messenger.js';
import FormCancelPix from './FormCancelPix.jsx';
import JourneyDetail from './JourneyDetail';
import RemindersPlan from './RemindersPlan.jsx';
import { monitorActions } from './monitor-slice.js';

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
});

const DetailField = ({ label, value }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Box flexBasis="40%">
      <Typography variant="caption" color="textSecondary" component="span">
        {label} :{' '}
      </Typography>
    </Box>
    <Box flexBasis="60%">
      <Typography variant="body1" color="textPrimary" component="span">
        {value ?? ''}
      </Typography>
    </Box>
  </Box>
);
DetailField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  clsCard: { height: '100%', display: 'flex', flexDirection: 'column' },
  clsAwaiting: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.disabledBackground,
    WebkitTapHighlightColor: 'transparent',
  },
  clsProgress: {
    color: 'secondary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-3rem',
    marginLeft: '-3rem',
  },
  clsWrapper: {
    position: 'relative',
    height: '100%',
  },
  clsWarning: {
    color: theme.palette.warning.main,
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
}));

function PixDetail({ onClose }) {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.auth);
  const {
    isAwaitingCommand,
    selected,
    journey = {},
    selectedPixs,
  } = useSelector((state) => state.monitor);
  const {
    enterprise: { name, gender, waSessionId },
  } = useSelector((state) => state.settings);

  const { isSecretActive } = useSelector((state) => state.app);

  const {
    customerData: { cpcStatus },
  } = journey;
  const messenger = getMessenger(waSessionId);

  const getMsg = (pix) => {
    if (messenger === 'api') {
      const pixStatus = getPixStatus(pix);
      const {
        qrcode,
        invoiceData: {
          invoiceDate,
          invoiceValue,
          invoiceId,
          invoiceOverdueValue,
          invoiceDiscount,
        },
      } = pix;

      const when = moment(invoiceDate).isSame(moment(), 'day')
        ? '*HOJE*'
        : 'até';
      const formattedDate = moment(invoiceDate).format('DD/MM/YYYY');
      const formattedValue = currencyFormatter.format(
        pixStatus === pixStatusMap.NO_PRAZO
          ? invoiceValue - invoiceDiscount
          : invoiceValue + invoiceOverdueValue
      );
      const msgs = [];

      msgs.push({
        type: 'text',
        content: `Olá!\n\nEstou com o QRCode para pagamento d${gender} *${name}*:`,
      });

      let invoiceText = '';

      if (pixStatus === pixStatusMap.VENCIDA) {
        invoiceText = `${emoji.get(
          ':warning:'
        )} VENCEU dia *${formattedDate}* no valor de *${formattedValue}* - ref. ${invoiceId}`;
      } else {
        invoiceText = `Pagamento ${when} dia *${formattedDate}* no valor de *${formattedValue} - ref. ${invoiceId}`;
      }

      msgs.push({
        type: 'image',
        filename: 'qrcode.png',
        text: `${invoiceText}\n\nLink para pagamento:\n\n${qrcode.url}`,
        base64content: qrcode.imagemQrcode,
        url: qrcode.imgUrl,
      });

      return msgs;
    } else {
      throw new Error('Não é possível enviar');
    }
  };

  const { clsWarning, clsSecretActive, clsSecret } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isFormCancelPixOpen, setIsFormCancelPixOpen] = useState(false);
  const [isFormDeletePixOpen, setIsFormDeletePixOpen] = useState(false);
  const [isFormSendPixOpen, setIsFormSendPixOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [isJourneyDetailOpen, setIsJourneyDetailOpen] = useState(false);
  const [isRemindersPlanOpen, setIsRemindersPlanOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
  } = useForm();

  const handleCloseClick = () => {
    if (!isDirty) onClose();
    else setIsCloseConfirmationOpen(true);
  };

  const onSubmit = (data) => {
    const { invoiceValue, invoiceDiscount, invoiceOverdue } = data;
    let valid = true;
    if (invoiceValue !== undefined && invoiceDiscount !== undefined) {
      if (invoiceValue - invoiceDiscount <= 0) {
        valid = false;
        setError('invoiceValue', {
          type: 'manual',
          message:
            'O valor da fatura não pode ser igual ou menor o valor do desconto',
        });
        setError('invoiceDiscount', {
          type: 'manual',
          message:
            'O valor da fatura não pode ser igual ou menor o valor do desconto',
        });
      }
    }
    if (invoiceDiscount !== undefined) {
      if (invoiceDiscount < 0) {
        valid = false;
        setError('invoiceDiscount', {
          type: 'manual',
          message: 'O valor do desconto precisa ser maior do que 0',
        });
      }
    }

    if (invoiceOverdue !== undefined) {
      if (invoiceOverdue < 0 || invoiceOverdue > 20) {
        valid = false;
        setError('invoiceOverdue', {
          type: 'manual',
          message: 'O valor da multa precisa ser entre 0 e 20',
        });
      }
    }
    if (valid) {
      dispatch(
        monitorActions.updatePix({
          cid: selected.pix.invoiceData.customerData.id,
          seq: selected.pix.seq,
          invoiceId: selected?.pix?.invoiceData?.invoiceId,
          docNumber: selected?.pix?.invoiceData?.docNumber,
          txid: selected.pix.id,
          ...data,
        })
      );
    }
  };

  const [invoiceDate, invoiceValue, invoiceOverdue, invoiceDiscount] = useWatch(
    {
      control,
      name: [
        'invoiceDate',
        'invoiceValue',
        'invoiceOverdue',
        'invoiceDiscount',
      ],
    }
  );
  // console.log([invoiceDate, invoiceValue, invoiceOverdue, invoiceDiscount]);
  const newStatus = moment(
    invoiceDate ?? selected.pix.invoiceData.invoiceDate
  ).isBefore(moment().startOf('day'))
    ? pixStatusMap.VENCIDA
    : pixStatusMap.NO_PRAZO;
  const newInvoiceCurrentValue =
    newStatus === pixStatusMap.VENCIDA
      ? (invoiceValue ?? selected.pix.invoiceData.invoiceValue) *
        (1 + (invoiceOverdue ?? selected.pix.invoiceData.invoiceOverdue) / 100)
      : (invoiceValue ?? selected.pix.invoiceData.invoiceValue) -
        (invoiceDiscount ?? selected.pix.invoiceData.invoiceDiscount);

  const [editMode, setEditMode] = useState({ isEditMode: false });
  const FieldComponent = editMode.isEditMode ? EditFormField : DisplayFormField;

  useEffect(() => {
    if (editMode.isEditMode && editMode?.field) {
      const fieldElement = document.getElementById(editMode?.field);
      if (fieldElement) {
        fieldElement.focus();
      }
    }
  }, [editMode]);

  const canEdit =
    permissions['pix-upd'] &&
    (selected.pix.status === pixStatusMap.NO_PRAZO ||
      selected.pix.status === pixStatusMap.VENCIDA);
  const canReactivate =
    permissions['pix-upd'] &&
    journey.currentPhase !== 'END_JOURNEY' &&
    (selected.pix.status === pixStatusMap.CANCELADA ||
      selected.pix.status === pixStatusMap.EXPIRADA);

  // let { status } = selected.pix;
  // let invoiceCurrentValue = parseFloat(selected.pix.cob?.valor?.original ?? 0);
  let invoiceCurrentValue = parseFloat(
    selected.pix.invoiceData?.invoiceValue ?? 0
  );
  if (editMode.isEditMode) {
    // status = newStatus;
    invoiceCurrentValue = newInvoiceCurrentValue;
  }
  // let statusText = status;
  // let invoiceCurrentValueText = formatValue(invoiceCurrentValue, 'decimal');

  // if (status === pixStatusMap.PAGA)
  //   statusText += ` (${formatValue(selected.pix.resultAt, 'dateTime')})`;
  // if (status === pixStatusMap.CANCELADA)
  //   statusText += ` (${formatValue(selected.pix.cancelledAt, 'dateTime')})`;
  // if (status === pixStatusMap.EXPIRADA)
  //   statusText += ` (${formatValue(selected.pix.expiredAt, 'dateTime')})`;
  // if (
  //   status === pixStatusMap.NO_PRAZO &&
  //   (invoiceDiscount ?? selected.pix.invoiceData.invoiceDiscount) > 0
  // )
  //   invoiceCurrentValueText += ` (desc. ${formatValue(
  //     invoiceDiscount ?? selected.pix.invoiceData.invoiceDiscount,
  //     'money'
  //   )})`;
  // if (
  //   status === pixStatusMap.VENCIDA &&
  //   (invoiceOverdue ?? selected.pix.invoiceData.invoiceOverdue) > 0
  // )
  //   invoiceCurrentValueText += ` (multa ${formatValue(
  //     ((invoiceOverdue ?? selected.pix.invoiceData.invoiceOverdue) *
  //       (invoiceValue ?? selected.pix.invoiceData.invoiceValue)) /
  //       100,
  //     'money'
  //   )})`;

  return (
    <>
      <form noValidate autoComplete="off">
        <EditForm>
          <EditFormHeader
            title="Detalhes do PIX"
            onCloseClick={handleCloseClick}
            actions={
              permissions['dbg'] && (
                <IconButton
                  onClick={() =>
                    dispatch(appActions.setIsSecretActive(!isSecretActive))
                  }
                  className={clsx(isSecretActive && clsSecretActive)}
                  size="large"
                >
                  <BuildIcon />
                </IconButton>
              )
            }
          />
          <EditFormContent>
            <Box marginTop={2}>
              <Grid container spacing={2} alignItems="stretch">
                {isSecretActive && (
                  <Grid item xs={12}>
                    <Box>
                      <FirebaseConsoleLink doc={`/pix/${selected.pix.id}`} />
                    </Box>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={clsSecret}
                    >
                      {selected.pix.invoiceData.enterpriseData.pixAccount}
                    </Typography>
                  </Grid>
                )}
                {canEdit && !editMode.isEditMode && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Para alterar algum valor, clique em um dos campos abaixo:
                    </Alert>
                  </Grid>
                )}
                {canReactivate && !editMode.isEditMode && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <InfoIcon color="secondary" />
                      <Box marginLeft={1}>
                        <Typography component="span">
                          Este PIX está encerrado, para retomar clique em
                          REATIVAR
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {selected.pix.status === pixStatusMap.PAGA && (
                  <Grid item xs={12}>
                    <Alert severity="success">
                      PIX PAGO em{' '}
                      {formatValue(selected.pix.resultAt, 'dateTime')}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={selected.pix.invoiceData.externalId ? 6 : 4}>
                  <DisplayFormField
                    field={{
                      name: 'invoiceId',
                      label: 'Identificador',
                      type: 'text',
                    }}
                    value={selected.pix.invoiceData?.invoiceId}
                    variant="standard"
                    disabled={true}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({ isEditMode: true, field: 'invoiceDate' });
                    }}
                  />
                </Grid>
                <Grid item xs={selected.pix.invoiceData.externalId ? 6 : 4}>
                  <DisplayFormField
                    field={{
                      name: 'docNumber',
                      label: 'Documento',
                      type: 'text',
                    }}
                    value={formatValue(
                      selected.pix.invoiceData?.docNumber,
                      'docNumber'
                    )}
                    variant="standard"
                    disabled={true}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({ isEditMode: true, field: 'invoiceDate' });
                    }}
                  />
                </Grid>
                <Grid item xs={selected.pix.invoiceData.externalId ? 6 : 4}>
                  <DisplayFormField
                    field={{
                      name: 'serviceDate',
                      label: 'Mês Referência',
                      type: 'text',
                    }}
                    value={formatValue(
                      selected?.pix?.invoiceData?.serviceDate,
                      'yearMonth'
                    )}
                    variant="standard"
                    disabled={true}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({ isEditMode: true, field: 'invoiceDate' });
                    }}
                  />
                </Grid>
                {selected.pix.invoiceData.externalId && (
                  <Grid item xs={6}>
                    <DisplayFormField
                      field={{
                        name: 'externalId',
                        label: 'Id externo',
                        type: 'text',
                      }}
                      defaultValue={selected.pix.invoiceData.externalId}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FieldComponent
                    id="invoiceDate"
                    field={{
                      name: 'invoiceDate',
                      label: 'Vencimento',
                      type: 'date',
                    }}
                    defaultValue={selected?.pix?.invoiceData?.invoiceDate}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({ isEditMode: true, field: 'invoiceDate' });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldComponent
                    id="invoiceValue"
                    field={{
                      name: 'invoiceValue',
                      label: 'Valor Original',
                      type: 'decimal',
                      inputAdornment: { position: 'start', text: 'R$' },
                    }}
                    defaultValue={selected?.pix?.invoiceData?.invoiceValue}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({
                          isEditMode: true,
                          field: 'invoiceValue',
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldComponent
                    id="invoiceDiscount"
                    field={{
                      name: 'invoiceDiscount',
                      label: 'Desconto',
                      type: 'decimal',
                      inputAdornment: { position: 'start', text: 'R$' },
                    }}
                    defaultValue={selected?.pix?.invoiceData?.invoiceDiscount}
                    control={control}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({
                          isEditMode: true,
                          field: 'invoiceDiscount',
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldComponent
                    id="invoiceOverdue"
                    field={{
                      name: 'invoiceOverdue',
                      label: 'Multa',
                      type: 'decimal',
                      inputAdornment: { position: 'end', text: '%' },
                    }}
                    defaultValue={selected?.pix?.invoiceData?.invoiceOverdue}
                    control={control}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({
                          isEditMode: true,
                          field: 'invoiceOverdue',
                        });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FieldComponent
                    id="invoiceInterest"
                    field={{
                      name: 'invoiceInterest',
                      label: 'Juros',
                      type: 'decimal',
                      inputAdornment: { position: 'end', text: '%' },
                    }}
                    defaultValue={selected?.pix?.invoiceData?.invoiceInterest}
                    control={control}
                    onClick={() => {
                      if (canEdit)
                        setEditMode({
                          isEditMode: true,
                          field: 'invoiceInterest',
                        });
                    }}
                  />
                </Grid>
                {editMode.isEditMode && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <InfoIcon color="secondary" />
                      <Box marginLeft={1}>
                        <Typography component="span">
                          Confira os valores antes de salvar!
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {editMode.isEditMode && (
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <InfoIcon color="secondary" />
                      <Box marginLeft={1}>
                        <Typography component="span">
                          Atenção! Somente este PIX será alterado. Para alterar
                          o valor para as próximas cobranças, você deve alterar
                          diretamente no cadastro de clientes!
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                {/* <Grid item xs={6}>
                <DisplayFormField
                  field={{
                    name: 'status',
                    label: editMode.isEditMode ? 'Novo Status' : 'Status',
                    type: 'text',
                  }}
                  variant="standard"
                  disabled={true}
                  onClick={() => {
                    if (canEdit)
                      setEditMode({ isEditMode: true, field: 'invoiceDate' });
                  }}
                >
                  <Box display="flex" flexDirection="row">
                    <Typography variant="body1">{statusText}</Typography>
                    <Box flexGrow={1} />
                    <StatusIcon
                      status={
                        editMode.isEditMode ? newStatus : selected.pix.status
                      }
                    />
                  </Box>
                </DisplayFormField>
              </Grid>
              <Grid item xs={6}>
                <DisplayFormField
                  field={{
                    name: 'invoiceCurrentValue',
                    label: editMode.isEditMode ? 'Novo Valor' : 'Valor Atual',
                    type: 'text',
                    inputAdornment: { position: 'start', text: 'R$' },
                  }}
                  value={invoiceCurrentValueText}
                  variant="standard"
                  disabled={true}
                  onClick={() => {
                    if (canEdit)
                      setEditMode({ isEditMode: true, field: 'invoiceDate' });
                  }}
                />
              </Grid> */}
                {!editMode.isEditMode &&
                  selected.pix.status === pixStatusMap.CANCELADA && (
                    <>
                      <Grid item xs={6}>
                        <DisplayFormField
                          field={{
                            name: 'reason',
                            label: 'Motivo do cancelamento',
                            type: 'text',
                          }}
                          defaultValue={
                            selected.pix.result?.reason?.description
                          }
                        />
                      </Grid>
                      {selected.pix.result?.reason?.userComment && (
                        <Grid item xs={6}>
                          <DisplayFormField
                            field={{
                              name: 'userComment',
                              label: 'Comentário do usuário',
                              type: 'text',
                            }}
                            defaultValue={
                              selected.pix.result?.reason?.userComment
                            }
                          />
                        </Grid>
                      )}
                    </>
                  )}
                {/* {selected.pix.status === pixStatusMap.PAGA && (
                  <Grid item xs={12}>
                    <DisplayFormField
                      field={{
                        name: 'e2eid',
                        label:
                          'Id da transação PIX (Conferir com comprovante do cliente)',
                        type: 'text',
                      }}
                      defaultValue={
                        selected.pix.result?.pixData?.paymentData?.endToEndId
                      }
                    />
                  </Grid>
                )} */}

                {/* {editMode.isEditMode && (
                  <Grid item xs={12}>
                    <EditFormField
                      field={{
                        name: 'updateCustomer',
                        label: 'Atualizar o cadastro de clientes',
                        description:
                          'Se esta opção estiver MARCADA, os dados do cadastro serão atualizados. Caso contrário, as alterações são aplicadas somente a esta cobrança',
                        type: 'boolean',
                      }}
                      defaultValue={false}
                      control={control}
                    />
                  </Grid>
                )} */}
              </Grid>
            </Box>
          </EditFormContent>
          <EditFormFooter paddingTop={2}>
            <EditFormMessage>
              {!editMode.isEditMode && (
                <>
                  {/* <Button
                    color="primary"
                    startIcon={<CropFreeIcon />}
                    component={Link}
                    href={selected?.pix?.qrcode?.url}
                    target="_blank"
                    rel="noopener"
                  >
                    QR Code
                  </Button> */}
                  <Box>
                    <Button
                      color="primary"
                      startIcon={<EventIcon />}
                      onClick={() => setIsRemindersPlanOpen(true)}
                    >
                      Régua
                    </Button>
                  </Box>
                  {selected.pix.status === pixStatusMap.PAGA &&
                    selected.pix.result?.pixData?.paymentData
                      ?.transactionReceiptUrl && (
                      <Box paddingLeft={1}>
                        <Button
                          color="primary"
                          startIcon={<ReceiptIcon />}
                          component={Link}
                          href={
                            selected.pix.result.pixData.paymentData
                              .transactionReceiptUrl
                          }
                          target="_blank"
                          rel="noopener"
                        >
                          Comprovante
                        </Button>
                      </Box>
                    )}
                </>
              )}
              {Object.keys(errors).length > 0 && (
                <Typography color="error" variant="caption">
                  Os erros devem ser corrigidos antes de salvar
                </Typography>
              )}
            </EditFormMessage>
            {selected.pix.status !== pixStatusMap.PAGA && (
              <EditFormAction
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                {!editMode.isEditMode && permissions['pix-can'] && (
                  <>
                    {(selected.pix.status === pixStatusMap.NO_PRAZO ||
                      selected.pix.status === pixStatusMap.VENCIDA) && (
                      <ButtonWithProgress
                        tooltipTitle="Cancelar"
                        onClick={() => setIsFormCancelPixOpen(true)}
                        color="primary"
                        disabled={isAwaitingCommand[selected.pix.id]}
                        isLoading={isAwaitingCommand[selected.pix.id]}
                        startIcon={<DoneIcon />}
                      >
                        Cancelar
                      </ButtonWithProgress>
                    )}

                    {canReactivate && !editMode.isEditMode && (
                      <ButtonWithProgress
                        tooltipTitle="Reativar"
                        onClick={() => setEditMode({ isEditMode: true })}
                        color="primary"
                        disabled={isAwaitingCommand[selected.pix.id]}
                        isLoading={isAwaitingCommand[selected.pix.id]}
                        startIcon={<ReplayIcon />}
                      >
                        Reativar
                      </ButtonWithProgress>
                    )}
                  </>
                )}
                {messenger === 'api' &&
                  !editMode.isEditMode &&
                  permissions['pix-rst'] &&
                  (selected.pix.status === pixStatusMap.NO_PRAZO ||
                    selected.pix.status === pixStatusMap.VENCIDA) && (
                    <Box
                      marginLeft={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <ButtonWithProgress
                        tooltipTitle="Enviar"
                        variant="contained"
                        onClick={() => setIsFormSendPixOpen(true)}
                        color="primary"
                        disabled={
                          isAwaitingCommand[selected.pix.id] ||
                          cpcStatus !== 'CPC_SUCCESS'
                        }
                        isLoading={isAwaitingCommand[selected.pix.id]}
                        startIcon={<SendIcon />}
                      >
                        Enviar
                      </ButtonWithProgress>
                      {cpcStatus !== 'CPC_SUCCESS' && (
                        <Box marginLeft={1}>
                          <Tooltip title="Você não pode enviar o QRCode enquanto o cliente não confirmar a identidade">
                            <WarningIcon className={clsWarning} />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  )}
                {editMode.isEditMode && (
                  <>
                    <ButtonWithProgress
                      tooltipTitle="Descartar"
                      onClick={() => {
                        setEditMode({ isEditMode: false });
                        reset();
                      }}
                      color="primary"
                      startIcon={<CancelIcon />}
                    >
                      Descartar
                    </ButtonWithProgress>

                    <Box marginLeft={1}>
                      <ButtonWithProgress
                        tooltipTitle="Salvar"
                        onClick={() => setIsSaveConfirmationOpen(true)}
                        color="primary"
                        disabled={
                          !isDirty || isAwaitingCommand[selected.pix.id]
                        }
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        Salvar
                      </ButtonWithProgress>
                    </Box>
                  </>
                )}
                {((permissions['pix-sim'] &&
                  process.env.REACT_APP_ENV !== 'condomi-prod') ||
                  permissions['pix-del']) && (
                  <Box marginLeft={1}>
                    <IconButton
                      onClick={handleMenuClick}
                      disabled={isAwaitingCommand[selected.pix.id]}
                      size="large"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                )}
              </EditFormAction>
            )}
          </EditFormFooter>
        </EditForm>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {process.env.REACT_APP_ENV !== 'condomi-prod' && (
            <MenuItem
              disabled={isAwaitingCommand[selected.pix.id]}
              onClick={() => {
                handleMenuClose();
                dispatch(
                  monitorActions.simulateOperation({
                    id: selected.pix.id,
                    seq: selected.pix.seq,
                    operation: 'payment',
                    valor: invoiceCurrentValue,
                  })
                );
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Simular pagamento</ListItemText>
            </MenuItem>
          )}
          {/* {process.env.REACT_APP_ENV !== 'condomi-prod' && (
            <MenuItem
              disabled={isAwaitingCommand[selected.pix.id]}
              onClick={() => {
                handleMenuClose();
                dispatch(
                  monitorActions.simulateOperation({
                    id: selected.pix.id,
                    seq: selected.pix.seq,
                    operation: 'overdue',
                  })
                );
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Simular atraso</ListItemText>
            </MenuItem>
          )}
          {process.env.REACT_APP_ENV !== 'condomi-prod' && (
            <MenuItem
              disabled={isAwaitingCommand[selected.pix.id]}
              onClick={() => {
                handleMenuClose();
                dispatch(
                  monitorActions.simulateOperation({
                    id: selected.pix.id,
                    seq: selected.pix.seq,
                    operation: 'expire',
                  })
                );
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Simular expiração</ListItemText>
            </MenuItem>
          )} */}
          {permissions['pix-del'] && (
            <MenuItem
              disabled={
                editMode.isEditMode ||
                (selected.pix.status !== pixStatusMap.NO_PRAZO &&
                  selected.pix.status !== pixStatusMap.VENCIDA &&
                  selected.pix.status !== pixStatusMap.EXPIRADA) ||
                isAwaitingCommand[selected.pix.id] ||
                selectedPixs.length === 1
              }
              onClick={() => {
                handleMenuClose();
                setIsFormDeletePixOpen(true);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Excluir</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <ResponsiveDialog
          open={isFormCancelPixOpen}
          maxWidth="xs"
          disableEscapeKeyDown
          fullWidth
        >
          <FormCancelPix
            onCancelClick={() => setIsFormCancelPixOpen(false)}
            onConfirmClick={(result) => {
              setIsFormCancelPixOpen(false);
              dispatch(
                monitorActions.cancelPix({
                  id: selected.pix.id,
                  seq: selected.pix.seq,
                  ...result,
                })
              );
            }}
          />
        </ResponsiveDialog>
        <ConfirmationDialog
          open={isFormSendPixOpen}
          title="Confirmação"
          actions={[
            {
              name: 'Cancelar',
              buttonProps: {
                autoFocus: true,
                onClick: () => {
                  setIsFormSendPixOpen(false);
                },
              },
            },
            {
              name: 'Enviar',
              showLoading: true,
              buttonProps: {
                variant: 'contained',
                color: 'primary',
                startIcon: <SendIcon />,
                onClick: () => {
                  setIsFormSendPixOpen(false);
                  dispatch(
                    monitorActions.setSelected({
                      pix: selected.pix,
                      isDetailOpen: false,
                    })
                  );
                  dispatch(
                    monitorActions.sendMsg({
                      jid: selected.pix.jid,
                      msgs: getMsg(selected.pix),
                    })
                  );
                },
              },
            },
          ]}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningIcon className={clsWarning} />
            <Box marginLeft={1}>
              <Typography component="span">ATENÇÃO !</Typography>
            </Box>
          </Box>
          <br />
          Tem certeza que deseja enviar esse QR Code para o cliente?
        </ConfirmationDialog>
        <ConfirmationDialog
          open={isFormDeletePixOpen}
          title="Confirmação"
          actions={[
            {
              name: 'Cancelar',
              buttonProps: {
                onClick: () => setIsFormDeletePixOpen(false),
              },
            },
            {
              name: 'Excluir',
              showLoading: true,
              buttonProps: {
                variant: 'contained',
                color: 'secondary',
                onClick: () => {
                  dispatch(monitorActions.deletePix(selected.pix.id));
                },
                startIcon: <DeleteIcon />,
              },
            },
          ]}
        >
          Tem certeza que deseja excluir? Essa ação não poderá ser desfeita
        </ConfirmationDialog>
        <ConfirmationDialog
          open={isCloseConfirmationOpen}
          title="Existem dados não salvos"
          actions={[
            {
              name: 'Cancelar',
              buttonProps: {
                autoFocus: true,
                onClick: () => {
                  setIsCloseConfirmationOpen(false);
                },
              },
            },
            {
              name: 'Sair sem Salvar',
              buttonProps: {
                onClick: onClose,
              },
            },
            {
              name: 'Salvar',
              showLoading: true,
              buttonProps: {
                variant: 'contained',
                color: 'primary',
                startIcon: <SaveIcon />,
                onClick: () => {
                  setIsCloseConfirmationOpen(false);
                  handleSubmit(onSubmit)();
                },
              },
            },
          ]}
        >
          Alguns dados foram modificados e ainda não foram salvos.
          <br />
          <br />
          Deseja salvá-los antes de fechar a janela?
        </ConfirmationDialog>
        <ConfirmationDialog
          open={isSaveConfirmationOpen}
          title="Confirmação"
          actions={[
            {
              name: 'Cancelar',
              buttonProps: {
                autoFocus: true,
                onClick: () => {
                  setIsSaveConfirmationOpen(false);
                },
              },
            },
            {
              name: 'Salvar',
              showLoading: true,
              buttonProps: {
                variant: 'contained',
                color: 'primary',
                startIcon: <SaveIcon />,
                onClick: () => {
                  setIsSaveConfirmationOpen(false);
                  handleSubmit(onSubmit)();
                },
              },
            },
          ]}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningIcon className={clsWarning} />
            <Box marginLeft={1}>
              <Typography component="span">ATENÇÃO !</Typography>
            </Box>
          </Box>
          <br />
          Tem certeza que deseja alterar os dados da cobrança?
        </ConfirmationDialog>
      </form>
      {isJourneyDetailOpen && (
        <ResponsiveDialog
          open={!!isJourneyDetailOpen}
          maxWidth="xl"
          fullWidth
          fullHeight
          onClose={() => {
            setIsJourneyDetailOpen(false);
          }}
        >
          <JourneyDetail
            onClose={() => {
              setIsJourneyDetailOpen(false);
            }}
          />
        </ResponsiveDialog>
      )}
      {isRemindersPlanOpen && (
        <ResponsiveDialog
          open={!!isRemindersPlanOpen}
          onClose={() => {
            setIsRemindersPlanOpen(false);
          }}
        >
          <RemindersPlan
            onClose={() => {
              setIsRemindersPlanOpen(false);
            }}
          />
        </ResponsiveDialog>
      )}
    </>
  );
}

PixDetail.propTypes = {
  pix: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default PixDetail;
