import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';

const Step4 = ({ control, logoutButton, ...otherProps }) => {
  const [
    enterpriseName,
    enterpriseGender,
    agentName,
    agentGender,
    waNotificationPhone,
  ] = useWatch({
    control,
    name: [
      'enterpriseName',
      'enterpriseGender',
      'agentName',
      'agentGender',
      'waNotificationPhone',
    ],
  });
  const {
    newUser: {
      authData: { email },
    },
  } = useSelector((state) => state);

  return (
    <EditForm {...otherProps}>
      <EditFormHeader title="Confirmação" actions={logoutButton} />
      <EditFormContent>
        <Typography variant="body1" color="textPrimary" component="p">
          Quase tudo pronto! Para começar a utilizar o sistema, o último passo é
          confirmar seu e-mail.
        </Typography>
        <Typography variant="body1" color="textPrimary" component="p">
          Um e-mail de confirmação será enviado para:
        </Typography>
        <Box p={2}>
          <Typography variant="body1" color="primary" component="p">
            {email}
          </Typography>
        </Box>
        <Typography variant="body1" color="textPrimary" component="p">
          Assim que possível acesse sua caixa de entrada e siga as instruções do
          e-mail.
        </Typography>
        <Typography variant="body1" color="textPrimary" component="p">
          Você já pode acessar o sistema, mas só poderá criar as jornadas após
          confirmar o e-mail.
        </Typography>
        <Box marginTop={2}></Box>
        <Typography variant="body1" color="textPrimary" component="p">
          Confirme os dados, se estiverem corretos clique em COMEÇAR.
        </Typography>
        <Box marginTop={2}>
          <Typography variant="body2" color="textSecondary" component="p">
            Empresa:
          </Typography>
          <Typography variant="body1" color="primary" component="p">
            {' '}
            {enterpriseName}
            {` (tratamento ${
              enterpriseGender === 'a' ? 'Feminino' : 'Masculino'
            })`}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Typography variant="body2" color="textSecondary" component="p">
            Agente Digital:
          </Typography>
          <Typography variant="body1" color="primary" component="p">
            {' '}
            {agentName}
            {` (tratamento ${agentGender === 'a' ? 'Feminino' : 'Masculino'})`}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Typography variant="body2" color="textSecondary" component="p">
            Nnúmero para notificação:
          </Typography>
          <Typography variant="body1" color="primary" component="p">
            {waNotificationPhone}
          </Typography>
        </Box>
      </EditFormContent>
    </EditForm>
  );
};
Step4.propTypes = {
  control: PropTypes.object,
  logoutButton: PropTypes.node,
};
export default Step4;
