export default (str) => {
  const src =
    'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
  const dst =
    'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
  let newStr = '';
  for (let i = 0; i < str.length; i++) {
    let change = false;
    for (let a = 0; a < src.length; a++) {
      if (str.substr(i, 1) == src.substr(a, 1)) {
        newStr += dst.substr(a, 1);
        change = true;
        break;
      }
    }
    if (change == false) {
      newStr += str.substr(i, 1);
    }
  }
  return newStr;
};
