'use strict';
import { select, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';
import { smartTipActions } from './smart-tip-slice';

function* watchAddDismissed() {
  const db = firebase.firestore();
  while (true) {
    const { payload } = yield take(smartTipActions.addDismissed);

    const {
      auth: {
        authData: { uid },
      },
    } = yield select();

    const userDoc = yield db.collection('users').doc(uid).get();
    const { dismissedTips = [] } = userDoc.data();
    if (dismissedTips.includes(payload)) continue;

    yield userDoc.ref.update({ dismissedTips: [...dismissedTips, payload] });
  }
}

function* watchReset() {
  const db = firebase.firestore();
  while (true) {
    yield take(smartTipActions.reset);

    const {
      auth: {
        authData: { uid },
      },
    } = yield select();

    yield db.collection('users').doc(uid).update({ dismissedTips: [] });
  }
}

export default [watchAddDismissed, watchReset];
