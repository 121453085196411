/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Divider, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Typography from '@mui/material/Typography';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormContent from '../../components/EditForm/EditFormContent.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';

import DisplayFormField from '../../components/EditForm/DisplayFormField.jsx';
import { levels } from '../auth/permissions';

function InvitationDetail({ onClose }) {
  const {
    users: { selectedInvitation },
  } = useSelector((state) => state);
  const [copied, setCopied] = useState(false);
  const firstAccessLink = `${window.location.origin}?inv=${selectedInvitation.id}`;

  return (
    <EditForm>
      <EditFormHeader title="Detalhes" onCloseClick={() => onClose(false)} />
      <EditFormContent overflow="auto">
        <Box p={1}>
          <Grid container>
            <Grid container item spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <DisplayFormField
                  field={{
                    name: 'email',
                    label: 'e-mail',
                    type: 'text',
                  }}
                  value={selectedInvitation.email}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <DisplayFormField
                  field={{
                    name: 'role',
                    label: 'Perfil de Acesso',
                    type: 'text',
                  }}
                  value={
                    levels.find(
                      ({ role: roleToFind }) =>
                        selectedInvitation.role === roleToFind
                    ).label
                  }
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display="flex" flexDirection="row">
                  <DisplayFormField
                    field={{
                      name: 'link',
                      label: 'Link para primeiro acesso',
                      type: 'text',
                    }}
                    value={firstAccessLink}
                  />
                  <CopyToClipboard
                    text={firstAccessLink}
                    onCopy={() => setCopied(true)}
                  >
                    <IconButton size="large">
                      <FileCopyIcon color={copied ? 'primary' : undefined} />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {copied && (
                  <Typography
                    variant="caption"
                    color="primary"
                    align="center"
                    component="div"
                  >
                    Copiado para a área de transferência
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormAction></EditFormAction>
      </EditFormFooter>
    </EditForm>
  );
}

InvitationDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default InvitationDetail;
