import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchState: {
    inputs: undefined,
    result: undefined,
    firstSearch: false, // Não fez nenhuma busca ainda
  },
  data: [],
  metadata: {},
  recordsLimit: 200,
};
export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    load: () => {},
    exit: () => initialState,
    loadSuccess: (state, action) => {
      state.data = action.payload;
    },
    loadFail: (state, _action) => {
      state.data = [];
    },
    selectCustomer: (state, { payload: { selected, selectedContext } }) => {
      state.selected = selected;
      state.selectedContext = selectedContext;
      if (selected === undefined) state.selectedCurrentJourney = undefined;
    },
    setSelectedCurrentJourney: (state, { payload }) => {
      state.selectedCurrentJourney = payload;
    },
    loadMetadata: () => {},
    loadMetadataSuccess: (state, action) => {
      state.metadata = action.payload;
    },
    loadMetadataFail: (state, _action) => {
      state.metadata = {};
    },
    delete: () => {},
    deleteSuccess: () => {},
    deleteFail: () => {},
    save: () => {},
    saveSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    saveFail: () => {},
    add: () => {},
    addSuccess: (state) => {
      state.selected = undefined;
      state.selectedContext = undefined;
    },
    addFail: () => {},
    batchUpdate: () => {},
    batchUpdateSuccess: () => {},
    batchUpdateFail: () => {},
    setSearchState: (state, { payload }) => {
      state.searchState = payload;
    },
  },
});

export const customersActions = customersSlice.actions;
export default customersSlice.reducer;
