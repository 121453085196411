import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getDomain from '../../helpers/domains';
import InvitationWizard from './InvitationWizard';
import NewUserWizard from './NewUserWizard';

const useStyles = makeStyles((_theme) => ({
  root: {
    height: '100vh',
  },
}));

function NewUserScreen() {
  const {
    app: { currentView },
  } = useSelector((state) => state);

  const classes = useStyles();
  const [animationStep, setAnimationStep] = useState(1);

  useEffect(() => {
    setTimeout(() => setAnimationStep(2), 2000);
    setTimeout(() => setAnimationStep(3), 3000);
    // setTimeout(() => setAnimationStep(4), 5000);
    // setTimeout(() => setAnimationStep(5), 6000);
  }, []);

  if (animationStep < 3)
    return (
      <Container>
        <Grid
          className={classes.root}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
        >
          <Grid item>
            {animationStep >= 1 && animationStep <= 2 && (
              <Typography variant="h3">
                Bem vindo{' '}
                {getDomain(window.location.hostname).gender === 'o'
                  ? 'ao'
                  : 'à'}{' '}
                {getDomain(window.location.hostname).name} !
              </Typography>
            )}
            {/* {animationStep >= 3 && animationStep <= 4 && (
              <Fade timeout={2000} in={animationStep === 3}>
                <Typography variant="h4">
                  Precisamos de algumas informações para começar...
                </Typography>
              </Fade>
            )} */}
          </Grid>
        </Grid>
      </Container>
    );
  else if (currentView === 'newUser') return <NewUserWizard />;
  else if (currentView === 'newInvitation') return <InvitationWizard />;
}

export default NewUserScreen;
