import { map } from 'ramda';
import { all, fork } from 'redux-saga/effects';
import appSagas from '../features/app/app-sagas';
import authSagas from '../features/auth/auth-sagas';
import bgTasksSagas from '../features/bg-tasks/bg-tasks-sagas';
import customersSagas from '../features/customers/customers-sagas';
import enterpriseSelectSagas from '../features/enterprise-select/enterprise-select-sagas';
import enterprisesSagas from '../features/enterprises/enterprises-sagas';
import issuesSagas from '../features/issues/issues-sagas';
import journeysSagas from '../features/journeys/journeys-sagas';
import mailingSagas from '../features/mailing/mailing-sagas';
import monitorSagas from '../features/monitor/monitor-sagas';
import newUserSagas from '../features/new-user/new-user-sagas';
import notifierSagas from '../features/notifier/notifier-sagas';
import reportsSagas from '../features/reports/reports-sagas';
import settingsSagas from '../features/settings/settings-sagas';
import smartTipSagas from '../features/smart-tip/smart-tip-sagas';
import usersSagas from '../features/users/users-sagas';
import versionsSagas from '../features/versions/versions-sagas';
import waStatusSagas from '../features/wa-status/wa-status-sagas';
import walletSagas from '../features/wallet/wallet-sagas';

export default function* rootSaga() {
  yield all(
    map(fork, [
      ...appSagas,
      ...authSagas,
      ...notifierSagas,
      ...bgTasksSagas,
      ...settingsSagas,
      ...newUserSagas,
      ...journeysSagas,
      ...customersSagas,
      ...mailingSagas,
      ...reportsSagas,
      ...monitorSagas,
      ...issuesSagas,
      ...waStatusSagas,
      ...versionsSagas,
      ...usersSagas,
      ...enterprisesSagas,
      ...enterpriseSelectSagas,
      ...walletSagas,
      ...smartTipSagas,
    ])
  );
}
