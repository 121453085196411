import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import WhatsappDialog from '../../components/WhatsappDialog';

const Step3 = ({ control, logoutButton, ...otherProps }) => {
  const [enterpriseName, agentName] = useWatch({
    control,
    name: ['enterpriseName', 'agentName'],
  });

  const content = {
    contactName: agentName ?? 'Agente Digital',
    items: [
      {
        direction: 'in',
        type: 'text',
        text: `Aviso!\nJornada de João (${enterpriseName}):\nTelefone 11999991234 não concluiu confirmação de identidade`,
        timestamp: Date.now(),
        accent: true,
      },
    ],
  };

  return (
    <EditForm {...otherProps}>
      <EditFormHeader title="WhatsApp" actions={logoutButton} />
      <EditFormContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Informe um número que tem conta no WhatsApp para você receber as
          notificações sobre os clientes. Este número não é a conta utilizada
          para enviar os lembretes e cobranças, é apenas para NOTIFICAÇÂO. Por
          exemplo quando um cliente contestar a fatura ou não confirmar a
          identidade, você será notificado através de uma mensagem WhatsApp.
        </Typography>
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'waNotificationPhone',
                  label: 'Número',
                  type: 'text',
                }}
                defaultValue={''}
                control={control}
                rules={{
                  required: 'Obrigatório',
                  minLength: {
                    value: 2,
                    message: 'Digite um número de telefone válido',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                Exemplo:
              </Typography>
              <WhatsappDialog content={content} />
            </Grid>
          </Grid>
        </Box>
      </EditFormContent>
    </EditForm>
  );
};
Step3.propTypes = {
  control: PropTypes.object,
  logoutButton: PropTypes.node,
};
export default Step3;
