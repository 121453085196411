import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import ConfirmationDialog from '../components/ConfirmationDialog';

export const CONFIRM_RESULT = {
  CANCEL: 'CANCEL',
  EXIT: 'EXIT',
  CONFIRM: 'CONFIRM',
};

const useCloseConfirm = () => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(CONFIRM_RESULT.CONFIRM);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(CONFIRM_RESULT.CANCEL);
    handleClose();
  };

  const handleExit = () => {
    promise?.resolve(CONFIRM_RESULT.EXIT);
    handleClose();
  };

  // You could replace the Dialog with your library's version
  const ConfirmationDialogComponent = () => (
    <ConfirmationDialog
      open={promise !== null}
      title="Existem dados não salvos"
      actions={[
        {
          name: 'Cancelar',
          buttonProps: {
            autoFocus: true,
            onClick: handleCancel,
          },
        },
        {
          name: 'Sair sem Salvar',
          buttonProps: {
            onClick: handleExit,
          },
        },
        {
          name: 'Salvar',
          showLoading: true,
          buttonProps: {
            variant: 'contained',
            color: 'primary',
            startIcon: <SaveIcon />,
            onClick: handleConfirm,
          },
        },
      ]}
    >
      Alguns dados foram modificados e ainda não foram salvos.
      <br />
      <br />
      Deseja salvá-los antes de fechar a janela?
    </ConfirmationDialog>
  );
  return [ConfirmationDialogComponent, confirm];
};

export default useCloseConfirm;
