'use strict';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FileField from '../../components/FileField';
import RHFRichTextField from '../../components/RHFRichTextField/RHFRichTextField';
import WhatsappDialog from '../../components/WhatsappDialog';
import CustomRichTextEditor from '../../helpers/custom-richtext-editor';
import FieldToolbar from './FieldToolbar';

const useStyles = makeStyles((_theme) => ({
  tooltip: {
    maxWidth: 500,
    // backgroundColor: theme.palette.background.default,
  },
  fieldExample: {
    fontWeight: 'bold',
    borderWidth: '1px',
  },
}));

const parseValue = (value, type) => {
  switch (type) {
    case 'file':
    case 'phoneList':
    case 'text':
      return value;
    case 'decimal': {
      let parsed = parseFloat(value);
      return !isNaN(parsed) ? parsed : '';
    }
    case 'integer': {
      let parsed = parseInt(value);
      return !isNaN(parsed) ? parsed : '';
    }
  }
};

export default function CustomField({
  rules,
  field,
  defaultValue,
  control,
  onGlobalFieldClick,
  isGlobalField,
}) {
  const classes = useStyles();
  const { enterprise } = useSelector((state) => state.settings);

  const {
    name,
    type,
    inputAdornment,
    options,
    description,
    dialogExample,
    customFields,
    allowInCsv,
    required,
  } = field;

  const label = `${field.label}${required ? '' : ' (opcional)'}`;

  let component;
  let example;

  const replaceVars = (text) => {
    return text
      .replaceAll('#{ag.gender}', enterprise.agent.gender)
      .replaceAll('#{ag.name}', enterprise.agent.name)
      .replaceAll('#{ent.gender}', enterprise.gender)
      .replaceAll('#{ent.name}', enterprise.name);
  };

  const getWhatsappContent = (example) => {
    const content = {
      contactName: enterprise.agent.name,
      items: example.map((item) => ({
        ...item,
        text: replaceVars(item.text),
        timestamp: Date.now(),
      })),
    };
    return content;
  };

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: rules ?? {
      validate:
        type !== 'boolean' && required
          ? (v) => {
              if (isGlobalField) return v !== undefined || 'Campo obrigatório';
            }
          : undefined,
      valueAsNumber: type === 'decimal' || type === 'integer',
      valueAsBoolean: type === 'boolean',
      pattern:
        type === 'integer'
          ? {
              value: /^[0-9]*$/,
              message: 'Digite apenas números',
            }
          : undefined,
    },
    defaultValue,
  });
  const inputType =
    type === 'decimal' || type === 'integer' ? 'number' : undefined;
  // console.log(value);
  switch (type) {
    case 'message':
      component = (
        <RHFRichTextField
          name={name}
          label={label}
          error={!!error}
          helperText={error ? error.message : ''}
          value={value}
          onChange={onChange}
          fullWidth
          variant="filled"
          ToolbarComponent={FieldToolbar}
          ToolbarProps={{ items: customFields }}
        />
      );
      if (dialogExample) {
        const regExp = new RegExp(`(#{${name}})`, 'g');
        const exampleValue =
          CustomRichTextEditor.serialize({ value, useExample: true }) ?? '???';
        example = dialogExample.map((item) => ({
          ...item,
          text: item.text.replaceAll(regExp, `${exampleValue}`),
          accent: regExp.exec(item.text),
        }));
      }
      break;
    case 'file': {
      const { acceptedFileTypes, maxFileSize } = field;
      component = (
        <FileField
          name={name}
          label={label}
          value={value ?? ''}
          onChange={onChange}
          error={!!error}
          acceptedFileTypes={acceptedFileTypes}
          maxFileSize={maxFileSize}
          helperText={error ? error.message : ''}
          type={inputType}
          InputProps={
            inputAdornment && {
              [`${inputAdornment?.position ?? 'start'}Adornment`]: (
                <InputAdornment position={inputAdornment?.position ?? 'start'}>
                  {inputAdornment.text}
                </InputAdornment>
              ),
            }
          }
          fullWidth
          variant="filled"
        />
      );
      break;
    }
    case 'phoneList':
    case 'text':
    case 'decimal':
    case 'integer':
      component = (
        <TextField
          name={name}
          label={label}
          value={value ?? ''}
          onChange={(e) => {
            onChange(parseValue(e.target.value, type));
          }}
          error={!!error}
          helperText={error ? error.message : ''}
          type={inputType}
          InputProps={
            inputAdornment && {
              [`${inputAdornment?.position ?? 'start'}Adornment`]: (
                <InputAdornment position={inputAdornment?.position ?? 'start'}>
                  {inputAdornment.text}
                </InputAdornment>
              ),
            }
          }
          fullWidth
          variant="filled"
        />
      );
      if (dialogExample) {
        const regExp = new RegExp(`(#{${name}})`, 'g');
        const exampleValue = value ?? defaultValue ?? 2;
        example = dialogExample.map((item) => ({
          ...item,
          text: item.text.replaceAll(regExp, `*${exampleValue}*`),
          accent: regExp.exec(item.text),
        }));
      }
      break;
    case 'boolean':
      component = (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => onChange(e.target.checked)}
                checked={value !== undefined ? value : false}
              />
            }
            label={label}
          />
        </FormControl>
      );
      break;
    case 'futureDate':
    case 'date':
      component = (
        <DatePicker
          clearable
          label={label}
          format="DD/MM/YYYY"
          value={value ?? null}
          disablePast={type === 'futureDate'}
          onChange={(v) => {
            onChange(v ? v.format('YYYY-MM-DD') : null);
          }}
          error={!!error}
          helperText={error ? error.message : ''}
          inputVariant="filled"
          fullWidth
        />
      );
      break;
    case 'select':
      component = (
        <FormControl error={!!error} variant="filled" fullWidth>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            label={label}
            onChange={onChange}
            value={value ?? ''}
          >
            {options.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      );
      if (dialogExample) {
        const regExp = new RegExp(`(#{${name}})`, 'g');
        const exampleValue =
          options.find((op) => op.value === value)?.dialogFragment ??
          options[0].dialogFragment;
        example = dialogExample.map((item) => ({
          ...item,
          text: item.text.replaceAll(regExp, `*${exampleValue}*`),
          accent: regExp.exec(item.text),
        }));
      }

      break;
    default:
  }

  // if (options) {
  //   console.log(options);
  //   console.log(value);
  //   console.log(options.find((op) => op.value === value));
  // }
  return (
    <Box display="flex" width={1} flexDirection="column">
      <Box display="flex" width={1} flexDirection="row" alignItems="center">
        {allowInCsv && (
          <Box paddingRight={1}>
            <Tooltip
              //classes={{ tooltip: classes.tooltip }}
              title={
                <Box padding={2}>
                  <Box paddingBottom={2} display="flex" flexDirection="row">
                    <Box paddingRight={1}>
                      <Checkbox checked={false} />
                    </Box>
                    <Box>
                      <Typography>
                        Valor individualizado, deve ser informado em arquivo
                        para cada cliente.
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box paddingTop={2} display="flex" flexDirection="row">
                    <Box paddingRight={1}>
                      <Checkbox checked={true} />
                    </Box>
                    <Box>
                      <Typography>
                        Valor global. Será aplicado a todos os clientes.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Checkbox
                onChange={(e) => {
                  onGlobalFieldClick(e.target.checked);
                  if (type === 'boolean')
                    onChange(e.target.checked ? value ?? false : undefined);
                  else onChange(e.target.checked ? value : undefined);
                }}
                checked={isGlobalField}
              />
            </Tooltip>
          </Box>
        )}
        <Box flexGrow={1}>
          {isGlobalField || !allowInCsv ? (
            component
          ) : (
            <Typography variant="subtitle1">{label}</Typography>
          )}
        </Box>
        {description && (
          <Box paddingLeft={1}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                <Box padding={2}>
                  <Box marginBottom={example ? 2 : 0}>
                    <Typography>{description}</Typography>
                  </Box>
                  {example && (
                    <>
                      <Box>
                        <Typography>Exemplo:</Typography>
                      </Box>
                      <WhatsappDialog content={getWhatsappContent(example)} />
                    </>
                  )}
                </Box>
              }
            >
              <HelpIcon color="action" />
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
}

CustomField.propTypes = {
  rules: PropTypes.object,
  field: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  control: PropTypes.object,
  error: PropTypes.object,
  onGlobalFieldClick: PropTypes.func,
  isGlobalField: PropTypes.bool,
};
