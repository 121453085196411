import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const config = {
  'condomi-prod': {
    apiKey: 'AIzaSyD6Qa0gRoBHUJtfV7t19rhjzGCloY6Gt50',
    authDomain: 'condomi-prod.firebaseapp.com',
    projectId: 'condomi-prod',
    storageBucket: 'condomi-prod.appspot.com',
    messagingSenderId: '866455857563',
    appId: '1:866455857563:web:84863b37fdbd586668d93e',
    measurementId: 'G-P22Q63RDMC',
  },
  'condomi-dev': {
    apiKey: 'AIzaSyDZ388wOJJGuQ1o30UvcpITryjW29uDCxk',
    authDomain: 'condomi-dev.firebaseapp.com',
    projectId: 'condomi-dev',
    storageBucket: 'condomi-dev.appspot.com',
    messagingSenderId: '782340485308',
    appId: '1:782340485308:web:0c828bc2ea00477129e0b9',
    measurementId: 'G-86941FH24V',
  },
  'condomi-dev2': {
    apiKey: 'AIzaSyB62P8JUBfhDdSTtzMrbR4EdKe-YNfQ5SI',
    authDomain: 'condomi-dev2.firebaseapp.com',
    projectId: 'condomi-dev2',
    storageBucket: 'condomi-dev2.appspot.com',
    messagingSenderId: '977017560447',
    appId: '1:977017560447:web:b2aeba29abbaf1aa748e4a',
  },
  'condomi-stg': {
    apiKey: 'AIzaSyBvDoDfLboUNupHhPIq1tkgqvooMWltsmM',
    authDomain: 'condomi-stg.firebaseapp.com',
    projectId: 'condomi-stg',
    storageBucket: 'condomi-stg.appspot.com',
    messagingSenderId: '1038698077057',
    appId: '1:1038698077057:web:771e9809be44c1a265a680',
  },
};

export const getFirestoreConsoleLink = (doc) =>
  `https://console.firebase.google.com/project/${
    process.env.REACT_APP_ENV
  }/firestore/data/${encodeURIComponent(doc)}`;

// console.log(`Ambiente ${process.env.REACT_APP_ENV}`);
// console.log(config[process.env.REACT_APP_ENV]);

firebase.initializeApp(config[process.env.REACT_APP_ENV]);

(window.firebase ?? firebase).firestore().settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

export default window.firebase ?? firebase;
