'use strict';
import { Chip, colors } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { useController } from 'react-hook-form';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(() => ({
  clsDeleteIcon: {
    color: 'rgba(255,255,255,0.7)',
  },
}));

function TagChip({
  /*control, name, defaultValue, */ value,
  onDelete,
  ...props
}) {
  const {
    settings: { enterprise: { tags } = {} },
  } = useSelector((state) => state);
  const theme = useTheme();
  const { clsDeleteIcon } = useStyles();

  // let value;
  // if (control) {
  //   ({
  //     field: { value },
  //   } = useController({
  //     name,
  //     control,
  //     defaultValue,
  //   }));
  // } else {
  //   value = defaultValue;
  // }

  const tag = (tags ?? []).find((t) => t.name === value);
  const backgroundColor = tag ? colors[tag.color][600] : undefined;
  const color = tag
    ? theme.palette.getContrastText(backgroundColor)
    : undefined;

  return tag ? (
    <Chip
      style={{
        backgroundColor,
        color,
      }}
      classes={{
        deleteIcon: clsDeleteIcon,
      }}
      label={value}
      onDelete={onDelete}
      {...props}
    />
  ) : (
    <></>
  );
}

TagChip.propTypes = {
  // control: PropTypes.object,
  // name: PropTypes.string,
  // defaultValue: PropTypes.string,
  value: PropTypes.string,
  onDelete: PropTypes.func,
};

export default TagChip;
