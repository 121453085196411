'use strict';
import ky from 'ky';
import { eventChannel } from 'redux-saga';
import { call, select, take } from 'redux-saga/effects';
import { getApi } from '../helpers/api';
import firebase from '../helpers/firebase';

function createRpcChannel(id, largeOpts) {
  const db = firebase.firestore();
  return eventChannel((emitter) => {
    const doc = db.collection('rpc').doc(id);
    const unsubscribe = doc.onSnapshot(async (doc) => {
      if (!doc.exists) return;
      const { result } = doc.data();
      if (!result) return;
      const { isLargeResult } = result;
      if (!isLargeResult) emitter(result);
      else {
        const { url, headers, ...opts } = largeOpts;
        const response = await ky(url, { method: 'get', headers, ...opts });
        const data = await response.json();
        emitter({ data });
      }
    });
    return unsubscribe;
  });
}

function* rpcCall({ url, headers, payload, method = 'get', ...opts }) {
  try {
    const response = yield ky(url, { method, json: payload, headers, ...opts });
    const { rpcid } = yield response.json();
    const { localApi } = yield select((state) => state.settings);
    const largeOpts = yield getApi({
      path: `/rpc/${rpcid}/large`,
      local: localApi,
    });

    const chan = yield call(createRpcChannel, rpcid, largeOpts);
    const result = yield take(chan);
    chan.close();
    return result;
  } catch (e) {
    return { error: e.message };
  }
}

export default rpcCall;
