import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  searchState: {
    inputs: undefined,
    result: undefined,
    firstSearch: false, // Não fez nenhuma busca ainda
  },
  pixs: [],
  selected: undefined,
  selectedPixs: undefined,
  selectedWaMessages: undefined,
  journey: undefined,
  remindersPlan: undefined,
  journeys: undefined,
  bots: {},
  isAwaitingCommand: {},
  highlightedIds: [],
  isLoadingJourneys: false,
  isAwaitingSendMsg: false,
  isAwaitingJourneyCommand: false,
  recordsLimit: 200,
  whatsappTemplates: [],
};
export const monitorSlice = createSlice({
  name: 'monitor',
  initialState,
  reducers: {
    load: () => {},
    exit: () => initialState,
    setPixs: (state, { payload }) => {
      state.pixs = payload;
    },
    addPixs: (state, { payload }) => {
      state.pixs.push(...payload);
    },
    removePixs: (state, { payload }) => {
      state.pixs = state.pixs.filter(
        (j) => !payload.find((idToRemove) => idToRemove === j.id)
      );
      if (state.selected) {
        const newSelected = payload.find(
          (pix) => pix.id === state.selected.pix.id
        );
        if (newSelected) state.selected.pix = undefined;
      }
    },
    modifyPixs: (state, { payload }) => {
      const idsToFind = payload.map(({ id }) => id);
      state.pixs = state.pixs.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.pixs.push(...payload);
      if (state.selected) {
        const newSelected = payload.find(
          (pix) => pix.id === state.selected.pix.id
        );
        if (newSelected) state.selected.pix = newSelected;
      }
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    setRemindersPlan: (state, { payload }) => {
      state.remindersPlan = payload;
    },
    // loadJourney: () => {},
    closeDetail: () => {},
    // setJourney: (state, { payload }) => {
    //   state.journey = payload;
    // },
    loadJourneys: (state) => {
      state.isLoadingJourneys = true;
    },
    setJourneys: (state, { payload }) => {
      state.journeys = payload;
    },
    addJourneys: (state, { payload: itemsToAdd }) => {
      if (!state.journeys) state.journeys = [];
      state.journeys.push(...itemsToAdd);
      state.journey = state.journeys.find(
        ({ jid }) => jid === state.selected.pix.jid
      );
      state.isLoadingJourneys = false;
      // state.journeys.sort((a, b) => a.createdAt - b.createdAt);
    },
    removeJourneys: (state, { payload: itemsToRemove }) => {
      if (!state.journeys) state.journeys = [];
      state.journeys = state.journeys.filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
      state.journey = state.journeys.find(
        ({ jid }) => jid === state.selected.pix.jid
      );
      // state.journeys.sort((a, b) => a.createdAt - b.createdAt);
    },
    modifyJourneys: (state, { payload: itemsToModify }) => {
      if (!state.journeys) state.journeys = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.journeys = state.journeys.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.journeys.push(...itemsToModify);
      state.journey = state.journeys.find(
        ({ jid }) => jid === state.selected.pix.jid
      );
      // state.journeys[collection].sort((a, b) => a.createdAt - b.createdAt);
    },

    loadBots: () => {},
    setBots: (state, { payload }) => {
      state.bots = payload;
    },
    addBots: (state, { payload: { collection, itemsToAdd } }) => {
      if (!state.bots[collection]) state.bots[collection] = [];
      state.bots[collection].push(...itemsToAdd);
      // state.bots[collection].sort((a, b) => a.createdAt - b.createdAt);
    },
    removeBots: (state, { payload: { collection, itemsToRemove } }) => {
      if (!state.bots[collection]) state.bots[collection] = [];
      state.bots[collection] = state.bots[collection].filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
      // state.bots[collection].sort((a, b) => a.createdAt - b.createdAt);
    },
    modifyBots: (state, { payload: { collection, itemsToModify } }) => {
      if (!state.bots[collection]) state.bots[collection] = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.bots[collection] = state.bots[collection].filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.bots[collection].push(...itemsToModify);
      // state.bots[collection].sort((a, b) => a.createdAt - b.createdAt);
    },
    setSelectedPixs: (state, { payload }) => {
      state.selectedPixs = payload;
    },
    addSelectedPixs: (state, { payload: itemsToAdd }) => {
      if (!state.selectedPixs) state.selectedPixs = [];
      state.selectedPixs.push(...itemsToAdd);
    },
    removeSelectedPixs: (state, { payload: itemsToRemove }) => {
      if (!state.selectedPixs) state.selectedPixs = [];
      state.selectedPixs = state.selectedPixs.filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
    },
    modifySelectedPixs: (state, { payload: itemsToModify }) => {
      if (!state.selectedPixs) state.selectedPixs = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.selectedPixs = state.selectedPixs.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.selectedPixs.push(...itemsToModify);
    },

    setSelectedWaMessages: (state, { payload }) => {
      state.selectedWaMessages = payload;
    },
    addSelectedWaMessages: (state, { payload: itemsToAdd }) => {
      if (!state.selectedWaMessages) state.selectedWaMessages = [];
      state.selectedWaMessages.push(...itemsToAdd);
    },
    removeSelectedWaMessages: (state, { payload: itemsToRemove }) => {
      if (!state.selectedWaMessages) state.selectedWaMessages = [];
      state.selectedWaMessages = state.selectedWaMessages.filter(
        (j) => !itemsToRemove.find((idToRemove) => idToRemove === j.id)
      );
    },
    modifySelectedWaMessages: (state, { payload: itemsToModify }) => {
      if (!state.selectedWaMessages) state.selectedWaMessages = [];
      const idsToFind = itemsToModify.map(({ id }) => id);
      state.selectedWaMessages = state.selectedWaMessages.filter(
        (j) => !idsToFind.find((idToRemove) => idToRemove === j.id)
      );
      state.selectedWaMessages.push(...itemsToModify);
    },

    simulateOperation: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = true;
    },
    simulateOperationSuccess: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    simulateOperationFail: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    cancelPix: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = true;
    },
    cancelPixSuccess: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    cancelPixFail: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    updatePix: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = true;
    },
    updatePixSuccess: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    updatePixFail: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    deletePix: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = true;
    },
    deletePixSuccess: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    deletePixFail: (state, { payload }) => {
      state.isAwaitingCommand[payload.id] = false;
    },
    deleteJourney: (state) => {
      state.isAwaitingJourneyCommand = true;
    },
    deleteJourneySuccess: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    deleteJourneyFail: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    restartJourney: (state) => {
      state.isAwaitingJourneyCommand = true;
    },
    restartJourneySuccess: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    restartJourneyFail: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    addHighlightedIds: (state, { payload }) => {
      state.highlightedIds.push(...payload);
    },
    removeHighlightedIds: (state, { payload }) => {
      _.pull(state.highlightedIds, ...payload);
    },
    sendMsg: (state) => {
      state.isAwaitingSendMsg = true;
    },
    sendMsgSuccess: (state) => {
      state.isAwaitingSendMsg = false;
    },
    sendMsgFail: (state) => {
      state.isAwaitingSendMsg = false;
    },
    interruptJourney: (state) => {
      state.isAwaitingJourneyCommand = true;
    },
    interruptJourneySuccess: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    interruptJourneyFail: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    changeContact: (state) => {
      state.isAwaitingJourneyCommand = true;
    },
    changeContactSuccess: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    changeContactFail: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    updateTags: (state) => {
      state.isAwaitingJourneyCommand = true;
    },
    updateTagsSuccess: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    updateTagsFail: (state) => {
      state.isAwaitingJourneyCommand = false;
    },
    setSearchState: (state, { payload }) => {
      state.searchState = payload;
    },
    loadNoContactPhones: (state) => {
      state.isLoadingNoContactPhones = true;
    },
    loadNoContactPhonesSuccess: (state, { payload }) => {
      state.isLoadingNoContactPhones = false;
      state.noContactPhones = payload;
    },
    loadNoContactPhonesFail: (state) => {
      state.isLoadingNoContactPhones = false;
      state.noContactPhones = [];
    },
    loadWhatsappTemplates: () => {},
    setWhatsappTemplates: (state, action) => {
      state.whatsappTemplates = action.payload;
    },
  },
});

export const monitorActions = monitorSlice.actions;

export default monitorSlice.reducer;
