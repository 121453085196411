'use strict';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import vCard from 'vcard-parser';
import firebase from '../helpers/firebase';
import { formatValue } from '../helpers/formatter';
import getWapiUrl from '../helpers/get-wapi-url';
import { parseWhatsappMarkdown } from '../helpers/markdown';
import FirebaseConsoleIconLink from './FirebaseConsoleIconLink';

const fontFamily = [
  'SF Pro Text',
  '"SF Pro Icons"',
  'system',
  '-apple-system',
  'system-ui',
  '"Helvetica Neue"',
  'Helvetica',
  'Arial',
  '"Lucida Grande"',
  '"Kohinoor Devanagari"',
  'sans-serif',
].join(',');

// const lightPalette = {
//   shadow: '#303030',
//   background: '#DFD5CC',
//   out: '#D4F9BA',
//   in: '#FFFFFF',
//   contactName: '#31C383',
//   contactNameAdm: '#C331C3',
//   check: '#34B7F1',
//   text: '#000000',
//   sysText: 'rgba(100, 150, 200)',
//   timestampText: 'rgba(0, 0, 0, 0.45)',
//   readIcon: '#4fc3f7',
// };

// const darkPalette = {
//   shadow: 'rgba(241, 241, 242, 0.95)',
//   background: '#0c1418',
//   out: '#056162',
//   in: '#262d31',
//   sys: '#262d31',
//   contactName: '#31C383',
//   contactNameAdm: '#C331C3',
//   check: '#34B7F1',
//   text: '#FFFFFF',
//   sysText: 'rgba(100, 150, 200)',
//   timestampText: 'rgba(241, 241, 242, 0.63)',
//   readIcon: '#34b7f1',
// };

const getDownloadLink = (id) => {
  const { projectId } = firebase.app().options;
  let baseUrl = '';
  if (projectId === 'condomi-prod') baseUrl = 'https://condomi.zetaone.com.br';
  else baseUrl = `https://${projectId}.zetaone.com.br`;

  return `${baseUrl}/fn-adm-download/${id}`;
};

const useStyles = makeStyles((theme) => {
  //const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;
  const palette = theme.palette.whatsapp;
  return {
    background: {
      backgroundColor: palette.background,
    },
    bubbleContainer: {
      position: 'relative',
      maxWidth: '85%',
    },
    bubble: {
      borderBottomLeftRadius: '7.5px',
      borderBottomRightRadius: '7.5px',
      borderTopLeftRadius: '7.5px',
      borderTopRightRadius: '7.5px',
      fontFamily,
      fontSize: '14.2px',
      fontWeight: '400',
      lineHeight: '19px',
      fontFeatureSettings: '"kern"',
      textRendering: 'optimizelegibility',
      boxShadow: '0 1px .5px rgba(48,48,48,.13)',
      paddingBottom: '8px',
      paddingLeft: '9px',
      paddingRight: '7px',
      paddingTop: '6px',
      marginBottom: '2px',
    },
    bubbleBreak: {
      marginBottom: '12px',
    },
    bubbleTailOut: {
      borderTopRightRadius: '0px',
    },
    bubbleTailIn: {
      borderTopLeftRadius: '0px',
    },
    out: {
      backgroundColor: palette.out,
      color: palette.text,
    },
    in: {
      backgroundColor: palette.in,
      color: palette.text,
    },
    sys: {
      backgroundColor: palette.in,
      color: palette.sysText,
    },
    accent: {
      border: '1px solid',
      borderColor: 'rgba(255, 0, 0, 0.8)',
    },
    contactName: {
      color: palette.contactName,
      display: 'inline-flex',
      maxWidth: '100%',
      fontSize: '12.8px',
      fontWeight: '500',
      lineHeight: '22px',
      verticalAlign: 'baseline',
    },
    contactNameAdm: {
      color: palette.contactNameAdm,
    },
    bubbleSpaceIn: {
      width: '56px',
      display: 'inline-block',
    },
    bubbleSpaceOut: {
      width: '74px',
      display: 'inline-block',
    },
    bubbleSpaceSys: {
      width: '74px',
      display: 'inline-block',
    },
    tailOut: {
      color: palette.out,
      position: 'absolute',
      top: 0,
      display: 'block',
      width: '8px',
      height: '13px',
      right: '-8px',
      fontSize: '0px',
    },
    tailIn: {
      color: palette.in,
      position: 'absolute',
      top: 0,
      display: 'block',
      width: '8px',
      height: '13px',
      left: '-8px',
      fontSize: '0px',
    },
    timestamp: {
      position: 'relative',
      float: 'right',
      margin: '-10px 0 -5px 4px',
      color: palette.timestampText,
      fontFamily,
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '19px',
      fontFeatureSettings: '"kern"',
      textRendering: 'optimizelegibility',
      height: '15px',
      verticalAlign: 'baseline',
    },
    timestampText: {
      lineHeight: '15px',
      height: '15px',
      verticalAlign: 'top',
    },
    readIcon: {
      marginLeft: '3px',
      display: 'inline-block',
      color: palette.readIcon,
      height: '15px',
      lineHeight: '15px',
    },
    deliveredIcon: {
      marginLeft: '3px',
      display: 'inline-block',
      color: palette.deliveredIcon,
      height: '15px',
      lineHeight: '15px',
    },
    sentIcon: {
      marginLeft: '3px',
      display: 'inline-block',
      color: palette.sentIcon,
      height: '15px',
      lineHeight: '15px',
    },
    waitingIcon: {
      marginLeft: '3px',
      display: 'inline-block',
      color: palette.waitingIcon,
      height: '15px',
      lineHeight: '15px',
    },
    fieldExample: {
      fontWeight: 'bold',
      borderWidth: '1px',
    },
  };
});

const svgTailIn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 13"
    width="8"
    height="13"
  >
    <path
      opacity=".13"
      fill="#0000000"
      d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
    ></path>
    <path
      fill="currentColor"
      d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
    ></path>
  </svg>
);
const svgTailOut = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 13"
    width="8"
    height="13"
  >
    <path
      opacity=".13"
      d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
    ></path>
    <path
      fill="currentColor"
      d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
    ></path>
  </svg>
);
const svgReadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    width="16"
    height="15"
  >
    <path
      fill="currentColor"
      d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
    ></path>
  </svg>
);
const svgDeliveredIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    width="16"
    height="15"
  >
    <path
      fill="currentColor"
      d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
    ></path>
  </svg>
);
const svgSentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -2 16 15"
    width="16"
    height="15"
  >
    <path
      d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z"
      fill="currentcolor"
    ></path>
  </svg>
);
const svgWaitingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    width="16"
    height="15"
  >
    <path
      fill="currentColor"
      d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z"
    ></path>
  </svg>
);

// const content = {
//   contactName: 'julia',
//   items: [
//     {
//       direction: 'in',
//       text: 'top',
//       timestamp: 1619435296555,
//     },
//     {
//       direction: 'in',
//       text: 'Oi tudo bem?',
//       timestamp: 1619435304935,
//     },
//     {
//       direction: 'out',
//       text: 'Sim, e você?',
//       timestamp: 1619435312751,
//     },
//   ],
// };
function WhatsappDialog({ content }) {
  const classes = useStyles();
  const { isSecretActive } = useSelector((state) => state.app);

  const contentProcessed = content.items.map((item, index, array) => {
    const prev = array[index - 1];
    const next = array[index + 1];
    let more = {};

    if (
      !prev ||
      (prev &&
        (item.direction !== prev.direction ||
          item.contactName !== prev.contactName))
    )
      more = { tail: true, showContactName: true };
    if (next && item.direction !== next.direction)
      more = { ...more, bubbleBreak: true };
    return { ...item, ...more };
  });
  const { contactName } = content;

  return (
    <Box padding={2} className={classes.background}>
      {contentProcessed.map((content, index) => {
        const {
          accent,
          direction,
          type,
          text,
          // image,
          caption,
          file,
          url,
          timestamp,
          bubbleBreak,
          tail,
          showContactName,
          contactVcard,
          customContent,
          contactName: itemContactName,
          messenger,
          // wid,
          buttons,
          waMessage,
        } = content;

        if (type === 'custom')
          return <Fragment key={index}>{customContent}</Fragment>;
        const card = type === 'vcard' ? vCard.parse(contactVcard) : {};
        return (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent={direction === 'sys' ? 'center' : undefined}
            className={clsx(direction === 'sys' && classes.sysBubble)}
          >
            {direction === 'out' && <Box flexGrow={1} />}
            {type === 'sticker' ? (
              <Box
                style={{ maxWidth: '30%', height: 'auto' }}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Box>
                  {file ? (
                    <img
                      style={{ maxWidth: '100%', height: 'auto' }}
                      src={`${getWapiUrl()}/files/${file}`}
                    />
                  ) : (
                    <Typography variant="caption">
                      {'<Não é possível exibir figurinhas>'}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.bubbleContainer}>
                  <Box
                    className={clsx(
                      classes.bubble,
                      direction === 'in' && classes.in,
                      direction === 'out' && classes.out,
                      direction === 'sys' && classes.sys,
                      bubbleBreak && classes.bubbleBreak
                    )}
                  >
                    <Box>&nbsp;</Box>
                    <Box className={classes.timestamp}>
                      <Box component="span" className={classes.timestampText}>
                        {moment(timestamp).format('HH:mm')}
                      </Box>
                      {direction === 'out' && (
                        <Box className={classes.readIcon}>{svgReadIcon}</Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className={classes.bubbleContainer}>
                {tail && direction !== 'sys' && (
                  <Box
                    component="span"
                    className={clsx(
                      direction === 'in' && classes.tailIn,
                      direction === 'out' && classes.tailOut
                    )}
                  >
                    {direction === 'in' && svgTailIn}
                    {direction === 'out' && svgTailOut}
                  </Box>
                )}
                <Box
                  className={clsx(
                    classes.bubble,
                    direction === 'in' && classes.in,
                    direction === 'out' && classes.out,
                    direction === 'sys' && classes.sys,
                    bubbleBreak && classes.bubbleBreak,
                    tail && direction === 'in' && classes.bubbleTailIn,
                    tail && direction === 'out' && classes.bubbleTailOut,
                    accent && classes.accent
                  )}
                >
                  {showContactName && direction === 'in' && (
                    <Box
                      className={clsx(
                        classes.contactName,
                        contactName === 'Administrador' &&
                          classes.contactNameAdm
                      )}
                    >
                      <Box
                        component="span"
                        flexBasis="auto"
                        flexGrow={0}
                        flexShrink={1}
                      >
                        {itemContactName ?? contactName}
                      </Box>
                    </Box>
                  )}
                  <Box>
                    {type === 'text' && (
                      <>
                        {text.split('\n').map((par, index) => (
                          <Box key={index} component="span">
                            {index > 0 && <br />}
                            {parseWhatsappMarkdown(par).map(
                              ({ text, bold, italics }, indexPar) => (
                                <Box
                                  key={indexPar}
                                  component="span"
                                  style={{
                                    fontWeight: bold ? 'bold' : undefined,
                                    fontStyle: italics ? 'italic' : undefined,
                                  }}
                                >
                                  {text}
                                </Box>
                              )
                            )}
                          </Box>
                        ))}
                        {buttons && buttons.length > 0 && (
                          <Stack
                            marginTop={1}
                            direction="column"
                            gap={1}
                            paddingRight="2px"
                          >
                            {buttons.map((button, index) => (
                              <Button variant="outlined" disabled key={index}>
                                {button}
                              </Button>
                            ))}
                          </Stack>
                        )}
                      </>
                    )}
                    {type === 'image' && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        {url || file ? (
                          <img
                            style={{ maxWidth: '100%', height: 'auto' }}
                            src={url ?? `${getWapiUrl()}/files/${file}`}
                          />
                        ) : (
                          <Typography variant="caption">
                            {'<Não é possível exibir imagens>'}
                          </Typography>
                        )}
                        {text ? (
                          <Box marginTop={1}>
                            {text.split('\n').map((par, index) => (
                              <Box key={index} component="span">
                                {index > 0 && <br />}
                                {parseWhatsappMarkdown(par).map(
                                  ({ text, bold, italics }, indexPar) => (
                                    <Box
                                      key={indexPar}
                                      component="span"
                                      style={{
                                        fontWeight: bold ? 'bold' : undefined,
                                        fontStyle: italics
                                          ? 'italic'
                                          : undefined,
                                      }}
                                    >
                                      {text}
                                    </Box>
                                  )
                                )}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                    {type === 'vcard' && (
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="textSecondary">
                          Contato
                        </Typography>
                        <Divider />
                        <Box
                          marginTop={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          {card.photo &&
                            card.photo[0] &&
                            card.photo[0].value && (
                              <Box marginRight={1}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={`data:image/jpeg;base64, ${card.photo[0].value}`}
                                />
                              </Box>
                            )}
                          <Box>
                            {`${card.fn[0].value} - ${card.tel
                              .map(({ value }) =>
                                formatValue(
                                  value
                                    .replace(/[^\d+]+/g, '')
                                    .replace('+55', ''),
                                  'phone'
                                )
                              )
                              .join(', ')}`}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(type === 'document' || type === 'file') && (
                      <Box display="flex" flexDirection="column">
                        <Box marginBottom={1}>Documento: {caption}</Box>
                        {file ? (
                          <Button
                            color="primary"
                            component={Link}
                            href={
                              file.serverId
                                ? getDownloadLink(file.serverId)
                                : `${getWapiUrl()}/files/${file}`
                            }
                            target="_blank"
                            rel="noopener"
                            endIcon={<GetAppIcon />}
                          >
                            Baixar
                          </Button>
                        ) : (
                          <Box>
                            <Typography variant="caption">
                              {'<Não foi possível exibir o conteúdo>'}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {(type === 'ptt' || type === 'audio') && (
                      <Box>
                        <audio controls>
                          <source
                            src={`${getWapiUrl()}/files/${file}`}
                            type="audio/ogg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                        {/* <Button
                          color="primary"
                          component={Link}
                          href={`${getWapiUrl()}/files/${file}`}
                          target="_blank"
                          rel="noopener"
                          endIcon={<GetAppIcon />}
                        >
                          Baixar
                        </Button> */}
                      </Box>
                    )}
                    <Box
                      component="span"
                      className={clsx(
                        direction === 'in' && classes.bubbleSpaceIn,
                        direction === 'out' && classes.bubbleSpaceOut,
                        direction === 'sys' && classes.bubbleSpaceSys
                      )}
                    />
                  </Box>
                  {direction === 'in' ? (
                    <Tooltip
                      placement="top"
                      sx={{ cursor: 'pointer' }}
                      title={
                        direction === 'in' &&
                        messenger === 'whatsapp' &&
                        waMessage ? (
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            gap={2}
                            p={1}
                          >
                            <Stack direction="column">
                              <Typography variant="caption">
                                Criada:{' '}
                                {formatValue(waMessage.createdAt, 'dateTime')}
                              </Typography>
                              <Typography variant="caption">
                                Enviada:{' '}
                                {waMessage.sent
                                  ? formatValue(waMessage.sentAt, 'dateTime')
                                  : '-'}
                              </Typography>
                              <Typography variant="caption">
                                Entregue:{' '}
                                {waMessage.delivered
                                  ? formatValue(
                                      waMessage.deliveredAt,
                                      'dateTime'
                                    )
                                  : '-'}
                              </Typography>
                              <Typography variant="caption">
                                Lida:{' '}
                                {waMessage.read
                                  ? formatValue(waMessage.readAt, 'dateTime')
                                  : '-'}
                              </Typography>
                              {isSecretActive && (
                                <Typography variant="caption">
                                  Conversa:{' '}
                                  {waMessage?.conversation?.id
                                    ? `${waMessage?.conversation?.id} (${waMessage?.conversation?.origin?.type})`
                                    : '-'}
                                </Typography>
                              )}
                            </Stack>
                            {isSecretActive && (
                              <Box sx={{ fontSize: '2em' }}>
                                <FirebaseConsoleIconLink
                                  doc={`wa-messages/${waMessage.id}`}
                                />
                              </Box>
                            )}
                          </Stack>
                        ) : (
                          <Typography variant="caption">
                            Sem informações de envio
                          </Typography>
                        )
                      }
                    >
                      <Box className={classes.timestamp}>
                        <Box component="span" className={classes.timestampText}>
                          {moment(timestamp).format('HH:mm')}
                        </Box>
                        {waMessage ? (
                          <>
                            {waMessage.read ? (
                              <Box className={classes.readIcon}>
                                {svgReadIcon}
                              </Box>
                            ) : waMessage.delivered ? (
                              <Box className={classes.deliveredIcon}>
                                {svgDeliveredIcon}
                              </Box>
                            ) : waMessage.sent ? (
                              <Box className={classes.sentIcon}>
                                {svgSentIcon}
                              </Box>
                            ) : (
                              <Box className={classes.waitingIcon}>
                                {svgWaitingIcon}
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box
                            component="span"
                            className={classes.timestampText}
                          >
                            {' '}
                            ...
                          </Box>
                        )}
                      </Box>
                    </Tooltip>
                  ) : (
                    <>
                      <Box className={classes.timestamp}>
                        <Box component="span" className={classes.timestampText}>
                          {moment(timestamp).format('HH:mm')}
                        </Box>
                        {direction === 'out' && (
                          <Box className={classes.readIcon}>{svgReadIcon}</Box>
                        )}
                      </Box>
                    </>
                  )}
                </Box>

                {direction === 'in' && <Box flexGrow={1} />}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
WhatsappDialog.propTypes = {
  content: PropTypes.object.isRequired,
};

export default WhatsappDialog;
