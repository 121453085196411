'use strict';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { getFirestoreConsoleLink } from '../helpers/firebase';

const useStyles = makeStyles(() => ({
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
}));

export default function FirebaseConsoleLink({ doc }) {
  const { clsSecret } = useStyles();

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      variant="caption"
      color="textSecondary"
      className={clsSecret}
      href={getFirestoreConsoleLink(doc)}
    >
      {doc}
    </Link>
  );
}
FirebaseConsoleLink.propTypes = {
  doc: PropTypes.string.isRequired,
};
