'use strict';
import { put, take } from 'redux-saga/effects';
import { appActions } from '../app/app-slice';
import { journeysActions } from '../journeys/journeys-slice';
import { notifierActions } from '../notifier/notifier-slice';

function* watchAppErrors() {
  while (true) {
    const { payload: message } = yield take(appActions.setError);
    yield put(
      notifierActions.enqueue({
        message,
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* watchLoadMainJourney() {
  while (true) {
    yield take(appActions.loadMainJourney);
    yield put(journeysActions.load());
    yield take([journeysActions.loadSuccess, journeysActions.loadFail]);
    yield put(journeysActions.loadMetadata());
    yield take([
      journeysActions.loadMetadataSuccess,
      journeysActions.loadMetadataFail,
    ]);
  }
}

export default [watchAppErrors, watchLoadMainJourney];
