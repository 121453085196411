'use strict';
import { Box, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useFocused, useSelected } from 'slate-react';

const useStyles = makeStyles((theme) => ({
  preview: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  field: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: alpha(theme.palette.grey[500], 0.5),
    border: '1px solid',
    borderColor: alpha(theme.palette.grey[500], 0.9),
  },
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const FieldElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const classes = useStyles();
  const { isPreview } = element;
  return (
    <Box
      {...attributes}
      className={clsx(
        !isPreview && classes.field,
        isPreview && classes.preview,
        selected && focused && classes.selected
      )}
      display="inline-block"
      style={{
        fontWeight: element.bold ? 'bold' : 'normal',
        fontStyle: element.italic ? 'italic' : 'normal',
        textDecoration: element.strike ? 'line-through' : undefined,
      }}
    >
      {element.isPreview ? element.example ?? '?????' : element.label}
      {children}
    </Box>
  );
};
FieldElement.propTypes = {
  attributes: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
};

export default FieldElement;
