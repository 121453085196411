import { Box } from '@mui/material';
import PropTypes from 'prop-types';

function EditFormMessage({ children }) {
  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      {children}
    </Box>
  );
}

EditFormMessage.propTypes = {
  children: PropTypes.node,
};

export default EditFormMessage;
