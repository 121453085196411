import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { reportsActions } from './reports-slice';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PrintIcon from '@mui/icons-material/Print';

import { DatePicker } from '@mui/x-date-pickers';
import Messages from './Messages';
import Payments from './Payments';
import PixStatus from './PixStatus';
import Print from './Print';

export default function Reports() {
  const ref = useRef();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOrderBy, setCurrentOrderBy] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (exportType) => {
    dispatch(reportsActions.export(exportType));
  };

  const {
    auth: { permissions },
    settings: {
      enterprise: { name: enterpriseName, cnabData },
    },
    reports: { initialDate, finalDate, serviceDate, data, reportType },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      reportsActions.setInitialDate(
        moment(finalDate).startOf('month').valueOf()
      )
    );
    const defaultReportType = permissions['rpt-pay'] ? 'payments' : 'pixStatus';
    // console.log(defaultReportType);
    dispatch(reportsActions.setReportType(defaultReportType));
  }, [dispatch]);

  let ReportComponent;
  let title;
  switch (reportType) {
    case 'pixStatus':
      ReportComponent = PixStatus;
      title = 'Situação das Jornadas';
      break;
    case 'payments':
      ReportComponent = Payments;
      title = `Pagamentos ${enterpriseName} - de ${moment(initialDate).format(
        'DD/MM/YYYY'
      )} a ${moment(finalDate).format('DD/MM/YYYY')}`;
      break;
    case 'waMessages':
      ReportComponent = Messages;
      title = `Mensagens ${enterpriseName} - de ${moment(initialDate).format(
        'DD/MM/YYYY'
      )} a ${moment(finalDate).format('DD/MM/YYYY')}`;
      break;
  }

  const component =
    data.length > 0 ? (
      <ReportComponent
        data={data}
        currentOrderBy={currentOrderBy}
        onOrderByChange={(orderBy) => setCurrentOrderBy(orderBy)}
      />
    ) : (
      <></>
    );

  const printComponent = (
    <Print ref={ref} title={title}>
      {component}
    </Print>
  );
  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @page {
    size: auto;
    margin: 10mm;
  }
`;

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle,
    documentTitle: title,
  });
  const load = () => {
    dispatch(reportsActions.load());
  };

  return (
    <>
      <Box display="flex" flexDirection="row" p={2} alignItems="center">
        <Box>
          <FormControl variant="filled">
            <InputLabel>Tipo</InputLabel>
            <Select
              onChange={(e) => {
                if (e.target.value !== reportType) {
                  dispatch(reportsActions.setReportType(e.target.value));
                }
              }}
              value={reportType ?? ''}
            >
              {permissions['rpt-pay'] && (
                <MenuItem value="payments">Pagamentos</MenuItem>
              )}
              {permissions['rpt-sta'] && (
                <MenuItem value="pixStatus">Situação das cobranças</MenuItem>
              )}
              {permissions['rpt-msg'] && (
                <MenuItem value="waMessages">Mensagens</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        {reportType === 'payments' && (
          <>
            <Box marginLeft={2}>
              <DatePicker
                inputVariant="filled"
                autoOk
                label="Data de pagamento inicial"
                format="DD/MM/YYYY"
                value={moment(initialDate)}
                maxDate={moment(finalDate)}
                onChange={(d) =>
                  dispatch(reportsActions.setInitialDate(d.valueOf()))
                }
              />
            </Box>
            <Box marginLeft={2}>
              <DatePicker
                inputVariant="filled"
                autoOk
                label="Data de pagamento final"
                format="DD/MM/YYYY"
                minDate={moment(initialDate)}
                value={moment(finalDate)}
                onChange={(d) =>
                  dispatch(reportsActions.setFinalDate(d.valueOf()))
                }
              />
            </Box>
          </>
        )}
        {reportType === 'pixStatus' && (
          <Box marginLeft={2}>
            <DatePicker
              clearable
              label="Mês de referência"
              format="MM/YYYY"
              value={moment(serviceDate)}
              onChange={(d) =>
                dispatch(reportsActions.setServiceDate(d.valueOf()))
              }
              inputVariant="filled"
              openTo="month"
              views={['year', 'month']}
              fullWidth
              showTodayButton
              autoOk
            />
          </Box>
        )}
        {reportType === 'waMessages' && (
          <>
            <Box marginLeft={2}>
              <DatePicker
                inputVariant="filled"
                autoOk
                label="Data inicial"
                format="DD/MM/YYYY"
                value={moment(initialDate)}
                maxDate={moment(finalDate)}
                onChange={(d) =>
                  dispatch(reportsActions.setInitialDate(d.valueOf()))
                }
              />
            </Box>
            <Box marginLeft={2}>
              <DatePicker
                inputVariant="filled"
                autoOk
                label="Data final"
                format="DD/MM/YYYY"
                minDate={moment(initialDate)}
                value={moment(finalDate)}
                onChange={(d) =>
                  dispatch(reportsActions.setFinalDate(d.valueOf()))
                }
              />
            </Box>
          </>
        )}
        <Box marginLeft={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={load}
            startIcon={<PlayArrowIcon />}
          >
            Gerar
          </Button>
        </Box>
        {data.length > 0 && (
          <>
            <Box marginLeft={2}>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
              >
                <InsertDriveFileIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handlePrint}>
                  <ListItemIcon>
                    <PrintIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Imprimir" />
                </MenuItem>
                <MenuItem onClick={() => handleExport('csv-pt-br')}>
                  <ListItemIcon>
                    <InsertDriveFileIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="CSV (BR)" />
                </MenuItem>
                <MenuItem onClick={() => handleExport('csv-us-en')}>
                  <ListItemIcon>
                    <InsertDriveFileIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="CSV (US)" />
                </MenuItem>
                {permissions['rpt-cnb'] &&
                  cnabData &&
                  reportType === 'payments' && (
                    <MenuItem onClick={() => handleExport('cnab')}>
                      <ListItemIcon>
                        <AttachMoneyIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="CNAB" />
                    </MenuItem>
                  )}
              </Menu>
            </Box>
          </>
        )}
      </Box>
      {data.length > 0 && (
        // <Box>
        //   <Box>Versão para impressão</Box>
        //   <Box>{printComponent}</Box>
        // </Box>
        <Box style={{ display: 'none' }}>{printComponent}</Box>
      )}
      <Box p={2}>{component}</Box>
    </>
  );
}
