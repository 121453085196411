'use strict';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import FirebaseConsoleLink from '../../components/FirebaseConsoleLink';
import { formatValue } from '../../helpers/formatter';
import getCustomerAppURL from '../../helpers/get-customer-app-url.js';
import { pixStatusMap } from '../../helpers/pix-status';
import IssueType from '../issues/IssueType';
import { issueTypeMap } from '../issues/issue-type';
import StatusIcon from './StatusIcon.jsx';
import { monitorActions } from './monitor-slice.js';

const DetailField = ({ label, value, Icon }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Box flexBasis="40%">
      <Typography variant="caption" color="textSecondary" component="span">
        {label} :{' '}
      </Typography>
    </Box>
    <Box flexBasis="60%">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" color="textPrimary" component="span">
          {value ?? ''}
        </Typography>
        {Icon}
      </Box>
    </Box>
  </Box>
);
DetailField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  Icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  clsTabs: {
    color: theme.palette.text.main,
  },
  clsSelected: {
    borderColor: theme.palette.secondary.main,
  },
  clsCard: {
    height: '100%',
  },
  clsMsgField: {
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(255,255,255,0.8)' : undefined,
  },
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
}));

function JourneyPix() {
  const {
    app: { isSecretActive },
    monitor: { selectedPixs = [], selected, journey },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showCompleted, setShowCompleted] = useState(false);

  const hasHiddenPixs = selectedPixs.find(
    ({ status }) =>
      status !== pixStatusMap.NO_PRAZO && status !== pixStatusMap.VENCIDA
  );
  let journeyPixs = [...selectedPixs]
    .filter(
      ({ status, issue }) =>
        showCompleted ||
        status === pixStatusMap.NO_PRAZO ||
        status === pixStatusMap.VENCIDA ||
        !!issue
    )
    .sort(
      (
        { invoiceData: { invoiceDate: a = '1900-01-01' }, createdAt: a2 },
        { invoiceData: { invoiceDate: b = '1900-01-01' }, createdAt: b2 }
      ) => {
        if (moment(a).valueOf() - moment(b).valueOf() === 0)
          return moment(a2).valueOf() - moment(b2).valueOf();
        return moment(a).valueOf() - moment(b).valueOf();
      }
    );
  const { clsSecret, clsSelected, clsCard } = useStyles();

  return (
    <Accordion variant="outlined" defaultExpanded={true} square={false}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Typography variant="body1">Cobranças em andamento</Typography>
          <Button
            color="primary"
            startIcon={<OpenInNewIcon />}
            component={Link}
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`${getCustomerAppURL()}/${journey?.id}`}
            target="_blank"
            rel="noopener"
          >
            QR Code
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {journeyPixs.map((pix, index) => (
            <Grid key={index} item xs={6} sm={4} md={3} lg={2} xl={2}>
              <Card
                variant="outlined"
                className={clsx(
                  pix.id === selected.pix.id && clsSelected,
                  clsCard
                )}
              >
                <CardActionArea
                  className={clsCard}
                  onClick={() => {
                    dispatch(
                      monitorActions.setSelected({
                        pix: pix,
                        isDetailOpen: true,
                      })
                    );
                  }}
                >
                  <Box p={1}>
                    {isSecretActive && (
                      <>
                        <Box>
                          <FirebaseConsoleLink doc={`/pix/${pix.id}`} />
                        </Box>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          className={clsSecret}
                        >
                          {pix.invoiceData.enterpriseData.pixAccount}
                        </Typography>
                      </>
                    )}
                    <DetailField label="Id" value={pix.invoiceData.invoiceId} />
                    <DetailField
                      label="Venc"
                      value={formatValue(pix.invoiceData.invoiceDate, 'date')}
                    />
                    <DetailField
                      label="Valor"
                      value={`R$ ${formatValue(
                        parseFloat(pix.invoiceData.invoiceValue ?? 0),
                        'decimal'
                      )}`}
                    />
                    <DetailField
                      label="Status"
                      value={pix.status}
                      Icon={<StatusIcon status={pix.status} fontSize="small" />}
                    />
                    {pix.issue && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="span"
                        >
                          <IssueType
                            type={issueTypeMap[pix.issue.type].label}
                          />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          {hasHiddenPixs && (
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2} container>
              <Box display="flex" alignItems="flex-end">
                {showCompleted && (
                  <Button
                    color="primary"
                    startIcon={<VisibilityOffIcon />}
                    onClick={() => setShowCompleted(!showCompleted)}
                  >
                    Ocultar as finalizadas
                  </Button>
                )}
                {!showCompleted && (
                  <IconButton
                    onClick={() => setShowCompleted(!showCompleted)}
                    size="large"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

JourneyPix.propTypes = {};
export default JourneyPix;
