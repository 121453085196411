'use strict';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EmptyContent from '../../components/EmptyContent';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { formatValue } from '../../helpers/formatter';
import removeSpecialChars from '../../helpers/remove-special-chars';
import getSid from '../../helpers/sid';
import JourneyDetail from '../monitor/JourneyDetail';
import { monitorActions } from '../monitor/monitor-slice';
import FormSolveExternalIssue from './FormSolveExternalIssue';
import IssueType from './IssueType';
import { issueTypeMap } from './issue-type';
import { issuesActions } from './issues-slice';

export default function Issues() {
  const {
    app: { isSecretActive },
    issues: { issues, externalIssues, selectedExternalIssue },
    monitor: { journeys, isLoadingJourneys, recordsLimit },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const secretColumns = isSecretActive
    ? [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'txid',
        },
      ]
    : [];

  const enhancedData = [
    ...externalIssues.map((row) => ({
      ...row,
      id: row.id,
      invoiceDate: undefined,
      issueDate: row.date,
      issueType: row.type,
      userinputs: [{ content: 'Pagamento externo recebido' }],
      name: {
        value: '',
        props: {
          sortValue: '',
          filterValue: '',
        },
      },
      phone: '',
      currentValue: parseFloat(row.value ?? 0),
      keywords: removeSpecialChars(
        [
          row.status,
          moment(row.issueDate).format('MM/YYYY'),
          moment(row.issueDate).format('DD/MM/YYYY'),
        ].join(' ')
      ),
    })),
    ...issues.map((row) => ({
      id: row.id,
      issueDate: row.issue.date,
      issueType: row.issue.type,
      userinputs: (row.issue?.userinputs ?? [])
        .filter(({ type }) => type === 'text')
        .map(({ content }) => ({ content })),
      name: {
        value: row.invoiceData?.customerData?.name ?? '',
        props: {
          sortValue: row.invoiceData?.customerData?.name ?? '',
          filterValue: removeSpecialChars(
            row.invoiceData?.customerData?.name ?? ''
          ),
        },
      },
      phone: row.invoiceData?.customerData?.phone ?? '',
      invoiceDate: row.invoiceData.invoiceDate,
      currentValue: parseFloat(row.invoiceData?.invoiceValue ?? 0),
      keywords: removeSpecialChars(
        [
          row.invoiceData?.customerData?.name,
          row.invoiceData?.customerData?.phone,
          row.invoiceData?.invoiceId,
          row.invoiceData?.docNumber,
          row.status,
          moment(row.invoiceData?.invoiceDate).format('DD/MM/YYYY'),
          moment(row.invoiceData?.serviceDate).format('MM/YYYY'),
          ...(row.invoiceData?.customerData?.tags ?? []),
        ].join(' ')
      ),

      /*
      id: row.id,
      ...row.invoiceData,
      name: {
        value: row.invoiceData?.customerData?.name ?? '',
        props: {
          sortValue: row.invoiceData?.customerData?.name ?? '',
          filterValue: removeSpecialChars(
            row.invoiceData?.customerData?.name ?? ''
          ),
        },
      },
      invoiceId: {
        value: row.invoiceData?.invoiceId ?? '',
        props: {
          sortValue: row.invoiceData?.invoiceId ?? '',
          filterValue: removeSpecialChars(
            row.invoiceData?.invoiceId ?? '' ?? ''
          ),
        },
      },
      phone: row.invoiceData?.customerData?.phone ?? '',
      // currentValue: parseFloat(row.cob?.valor?.original ?? 0),
      currentValue: parseFloat(row.invoiceData?.invoiceValue ?? 0),
      issueType: row.issue.type,
      issueDate: row.issue.date,
      result: row.result?.resultId,
      resultAt: row.resultAt,
      overdueAt: row.overdueAt,
      expiredAt: row.expiredAt,
      status: { status: row.status, issue: row.issue },
      tags: row.invoiceData?.customerData?.tags ?? [],
      userinputs: (row.issue?.userinputs ?? []).filter(
        ({ type }) => type === 'text'
      ),
      keywords: removeSpecialChars(
        [
          row.invoiceData?.customerData?.name,
          row.invoiceData?.customerData?.phone,
          row.invoiceData?.invoiceId,
          row.invoiceData?.docNumber,
          row.status,
          moment(row.invoiceData?.invoiceDate).format('DD/MM/YYYY'),
          moment(row.invoiceData?.serviceDate).format('MM/YYYY'),
          ...(row.invoiceData?.customerData?.tags ?? []),
        ].join(' ')
      ),
      */
    })),
  ];

  const columns = [
    ...secretColumns,
    {
      id: 'issueDate',
      numeric: false,
      disablePadding: true,
      label: 'Data',
      formatter: (v) => formatValue(v, 'dateTime'),
    },
    {
      id: 'issueType',
      numeric: false,
      disablePadding: true,
      label: 'Tipo',
      formatter: (type) => IssueType({ type: issueTypeMap[type].label }),
    },
    {
      id: 'userinputs',
      numeric: false,
      disablePadding: true,
      label: 'Conteúdo',
      formatter: ({ content }) => {
        return content;
      },
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Responsável',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Telefone',
      formatter: (v) => (v ? formatValue(v, 'phone') : ''),
    },
    {
      id: 'invoiceDate',
      numeric: false,
      label: 'Vencimento',
      formatter: (v) => formatValue(v, 'date'),
    },
    {
      id: 'currentValue',
      numeric: false,
      label: 'Valor',
      formatter: (v) => formatValue(v, 'decimal'),
    },

    // {
    //   id: 'issueStatus',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Status',
    //   formatter: (status) => StatusComponent({ status }),
    // },
  ];

  const filters = {
    keywords: {
      type: 'keywords',
      label: 'Palavras-chave',
      currentValue: '',
    },
    // issueStatus: {
    //   type: 'list',
    //   options: [
    //     issueStatusMap.NOVA,
    //     issueStatusMap.LIDA,
    //     issueStatusMap.RESOLVIDA,
    //   ],
    //   label: 'Status',
    //   currentValue: [issueStatusMap.NOVA, issueStatusMap.LIDA],
    // },
  };
  const subtitle =
    enhancedData.length === recordsLimit + 1
      ? `Atenção! Exibindo somente os primeiros ${recordsLimit} registros. Caso necessário faça uma busca mais detalhada`
      : undefined;

  const handleSolveExternalIssueClose = () => {
    dispatch(issuesActions.setSelectedExternalIssue());
  };

  return (
    <>
      {enhancedData.length > 0 && (
        <EnhancedTable
          title="Pendências"
          subtitle={subtitle}
          onRowClick={(row) => {
            const { id: idToFind, issueType } = row;
            const pix = issues.find(({ id }) => id === idToFind);
            if (pix) {
              dispatch(
                monitorActions.setSelected({ pix, isDetailOpen: false })
              );
              dispatch(
                monitorActions.loadJourneys({
                  customerDocNumber:
                    pix.invoiceData.customerData.customerDocNumber,
                  jid: pix.jid,
                  sid: getSid(pix.invoiceData.customerData.phone),
                })
              );
            } else {
              const externalIssue = externalIssues.find(
                ({ id }) => id === idToFind
              );
              if (externalIssue) {
                dispatch(issuesActions.setSolveType(issueType));
                dispatch(issuesActions.setSelectedExternalIssue(row));
              }
            }
          }}
          data={enhancedData.slice(0, recordsLimit)}
          columns={columns}
          filters={filters}
          orderBy="name"
          tableProps={{ size: 'small' }}
          rowsPerPage={100}
          disableSelection={true}
          // searchField={<SearchForm onSearch={() => {}} />}
        />
      )}
      {enhancedData.length === 0 && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <EmptyContent
            title="Nada!"
            detail="Você não tem nenhuma pendência pra resolver"
            faIcon="fa-face-smile"
          />
        </Box>
      )}
      <Backdrop open={isLoadingJourneys}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {journeys && (
        <ResponsiveDialog
          open={!!journeys}
          maxWidth="xl"
          fullWidth
          fullHeight
          onClose={() => {
            dispatch(monitorActions.closeDetail(false));
          }}
        >
          <JourneyDetail
            onClose={(dataUpdated) => {
              dispatch(monitorActions.closeDetail(dataUpdated));
            }}
          />
        </ResponsiveDialog>
      )}
      <ResponsiveDialog
        open={!!selectedExternalIssue}
        maxWidth="md"
        onClose={handleSolveExternalIssueClose}
        fullHeight
        fullWidth
      >
        <FormSolveExternalIssue onCancelClick={handleSolveExternalIssueClose} />
      </ResponsiveDialog>
    </>
  );
}
