import { useDispatch, useSelector } from 'react-redux';

import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import ptBr from 'filepond/locale/pt-br.js';
import { FilePond, registerPlugin } from 'react-filepond';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

import clsx from 'clsx';

import { mailingActions } from './mailing-slice';
import { server } from './upload-mailing-server';

import CsvFields from '../mailing/CsvFields';
import MailingPreview from '../mailing/MailingPreview';

const useStyles = makeStyles((theme) => ({
  clsFilepond: {
    '& .filepond--panel-root': {
      backgroundColor: theme.palette.background.field,
    },
    '& .filepond--drop-label': {
      color: theme.palette.text.secondary,
    },
    '& .filepond--item-panel': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .filepond--root': {
      marginBottom: 0,
    },
    '& [data-filepond-item-state="processing-complete"] .filepond--item-panel':
      {
        backgroundColor: theme.palette.primary.main,
      },
    '& [data-filepond-item-state*="error"] .filepond--item-panel,[data-filepond-item-state*="invalid"] .filepond--item-panel':
      {
        backgroundColor: theme.palette.error.main,
      },
  },
  clsProgressPanel: {
    borderRadius: '0.5em',
    backgroundColor: theme.palette.background.field,
    color: theme.palette.text.secondary,
    height: '76px',
  },
  clsProgressBar: {
    width: '95%',
  },
  clsHidden: {
    display: 'none',
  },
}));

function MailingUpload() {
  const { clsFilepond, clsProgressPanel, clsProgressBar, clsHidden } =
    useStyles();
  const {
    journeys: { selected },
    mailing: { uploadedFiles, encoding, delimiter, isProcessingPreview },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleProcessFile = (_err, file) => {
    const { serverId, fileExtension, fileSize, filename } = file;

    dispatch(mailingActions.startProcessingPreview());
    dispatch(
      mailingActions.setUploadedFiles([
        {
          serverId,
          fileExtension,
          fileSize,
          filename,
        },
      ])
    );
    dispatch(mailingActions.createChargeSuccess(serverId));
    dispatch(
      mailingActions.processCharge({
        chid: serverId,
        encoding,
        delimiter,
        jid: selected.id,
      })
    );
  };

  const handleRevertFile = () => {
    dispatch(mailingActions.reset());
  };
  const handleAbortFile = () => {
    dispatch(mailingActions.reset());
  };

  const isProcessing = uploadedFiles.length > 0 && isProcessingPreview;

  return (
    <Box margin={1}>
      <Box className={clsx(isProcessing && clsHidden)}>
        <FilePond
          {...ptBr}
          onprocessfilerevert={handleRevertFile}
          onprocessfileabort={handleAbortFile}
          className={clsFilepond}
          disabled={isProcessingPreview}
          onprocessfile={handleProcessFile}
          allowFileTypeValidation
          acceptedFileTypes={['text/csv', 'text/plain']}
          allowFileSizeValidation
          maxFileSize="300KB"
          server={server}
          chunkSize={8388608}
          chunkUploads
          credits={false}
          labelIdle={
            'Arraste e solte o arquivo com a base de clientes ou <span class="filepond--label-action"> Clique aqui </span>'
          }
        />
      </Box>
      {isProcessing && (
        <Box
          className={clsProgressPanel}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="subtitle1">Processando</Typography>
          <LinearProgress className={clsProgressBar} color="secondary" />
        </Box>
      )}
      {uploadedFiles.length === 0 && <CsvFields />}
      {uploadedFiles.length > 0 && !isProcessingPreview && <MailingPreview />}
    </Box>
  );
}

MailingUpload.propTypes = {};
export default MailingUpload;
