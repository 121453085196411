import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MailingCustomIntegration from '../mailing/MailingCustomIntegration';
import MailingDb from '../mailing/MailingDb';
import MailingManual from '../mailing/MailingManual';
import MailingUpload from '../mailing/MailingUpload';

const useStyles = makeStyles((_theme) => ({
  clsHidden: {
    display: 'none',
  },
}));

function SendJourneyStep2({ visible }) {
  const { clsHidden } = useStyles();

  const { sendType } = useSelector((state) => state.journeys);

  return (
    <Box className={clsx(!visible && clsHidden)} paddingTop={1}>
      {sendType === 'db' && <MailingDb />}
      {sendType === 'manual' && <MailingManual />}
      {sendType === 'csv' && <MailingUpload />}
      {sendType === 'custom-integration' && <MailingCustomIntegration />}
    </Box>
  );
}

SendJourneyStep2.propTypes = {
  visible: PropTypes.bool.isRequired,
};
export default SendJourneyStep2;
