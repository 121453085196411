'use strict';
import { all, put, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { settingsActions } from '../settings/settings-slice';
import { authActions } from './auth-slice';
// import enterprisesDao from '../../dao/enterprises';
import enterprisesDao from '../../dao/enterprises';
import { issuesActions } from '../issues/issues-slice';
import { newUserActions } from '../new-user/new-user-slice';
import { smartTipActions } from '../smart-tip/smart-tip-slice';

/*
  app/init
    auth/login
      Se existe no BD:
        Se a validação do e-mail estava pendente e foi validado
          Atualiza informação de e-mail validado
        auth/loginSuccess
      Se não existe no BD:
    - Se não é convite (usuário avulso)
      - Mostra formulário
      - Cria empresa
      - Cria usuario no BD
      auth/loginSuccess
    - Se é convite
      - Cria usuário no BD
      auth/loginSuccess
  app/initSuccess
*/

function* watchUserLogin() {
  const db = firebase.firestore();

  while (true) {
    const {
      payload: { authData, invitation },
    } = yield take(authActions.login);
    try {
      yield put(appActions.setCurrentView('init'));
      // console.log('1. Verifica se o usuário existe no BD - ', authData.uid);
      var userDoc = yield db.collection('users').doc(authData.uid).get();
      let userData;
      if (userDoc.exists) {
        // console.log('2. Usuario existe no BD');
        userData = userDoc.data();
        // console.log('2. Usuario existe no BD', userData);
        const { emailVerified } = userData;
        if (!emailVerified && authData.emailVerified) {
          yield userDoc.ref.update({ emailVerified: true });
          userData.emailVerified = true;
        }
        // TODO - Ver como permissionar empresas
        if (userData.role === 'zeta' || userData.role === 'dev') {
          userData.enterprises = yield enterprisesDao.get();
        } else {
          userData.enterprises = yield all(
            userData.enterprises.map(({ id }) => enterprisesDao.get(id))
          );
        }
      } else {
        // console.log('2. Usuario não existe no BD');

        // Empresa nova
        if (!invitation) {
          yield put(appActions.setCurrentView('newUser'));
          yield put(newUserActions.newUserWizard(authData));
          const newUserWizardResult = yield take([
            newUserActions.newUserWizardSuccess,
            newUserActions.newUserWizardFail,
          ]);
          if (
            newUserWizardResult.type === newUserActions.newUserWizardFail().type
          )
            throw new Error('Erro no formulário de novo usuário');

          yield put(
            newUserActions.createEnterprise(newUserWizardResult.payload)
          );
          const createEnterpriseResult = yield take([
            newUserActions.createEnterpriseSuccess,
            newUserActions.createEnterpriseFail,
          ]);
          if (
            createEnterpriseResult.type ===
            newUserActions.createEnterpriseFail().type
          )
            throw new Error('Erro ao criar empresa');
          const { eid, name } = createEnterpriseResult.payload;

          const userToCreate = {
            ...authData,
            id: authData.uid,
            createdAt: Date.now(),
            enterpriseId: eid,
            role: 'own',
            enterprises: [{ id: eid, name }],
          };

          yield put(newUserActions.createUser(userToCreate));
          const { type, payload } = yield take([
            newUserActions.createUserSuccess,
            newUserActions.createUserFail,
          ]);
          if (type === newUserActions.createUserFail().type)
            throw new Error('Erro ao criar usuário');
          userData = payload;

          // Convite
        } else {
          const invitationDoc = yield db
            .collection('invitations')
            .doc(invitation)
            .get();
          if (!invitationDoc.exists)
            throw new Error('Convite inválido, verifique o link recebido');
          const invitationData = invitationDoc.data();

          if (invitationData.email !== authData.email)
            throw new Error(
              'Convite inválido para este e-mail, verifique o link recebido'
            );

          yield put(newUserActions.invitationWizard(authData));
          yield put(appActions.setCurrentView('newInvitation'));
          const invitationWizardResult = yield take([
            newUserActions.invitationWizardSuccess,
            newUserActions.invitationWizardFail,
          ]);
          if (
            invitationWizardResult.type ===
            newUserActions.invitationWizardFail().type
          )
            throw new Error('Erro ao apresentar convite');
          const userToCreate = {
            ...authData,
            id: authData.uid,
            invitationAcceptedAt: Date.now(),
            invitationId: invitationDoc.id,
            ...invitationData,
          };

          yield put(newUserActions.createUser(userToCreate));
          const { type, payload } = yield take([
            newUserActions.createUserSuccess,
            newUserActions.createUserFail,
          ]);
          if (type === newUserActions.createUserFail().type)
            throw new Error('Erro ao criar usuário');
          userData = payload;

          yield invitationDoc.ref.delete();
        }
        yield firebase.auth().currentUser.sendEmailVerification();
      }
      // console.log('userData: ', userData);
      yield put(settingsActions.loadEnterprise(userData.enterpriseId));
      const { type } = yield take([
        settingsActions.loadEnterpriseSuccess,
        settingsActions.loadEnterpriseFail,
      ]);
      if (type === settingsActions.loadEnterpriseFail().type)
        throw new Error('Erro ao carregar dados da empresa');

      const { claims } = yield firebase
        .auth()
        .currentUser.getIdTokenResult(true);

      yield put(authActions.loginSuccess({ authData, userData, claims }));
      yield put(smartTipActions.setDismissed(userData.dismissedTips));
      yield put(appActions.loadMainJourney());
      yield put(issuesActions.start());
      yield put(appActions.setCurrentView('main'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      yield put(appActions.setError(e.message));
      yield put(authActions.loginFail());
      firebase.auth().signOut();
      yield put(appActions.setCurrentView('signIn'));
    }

    //     yield db.collection('users').doc(actionUser.uid).update(actionUser);
    //   } else {
    //     // console.log(
    //     //   `Usuario ${actionUser.displayName} - ${actionUser.uid} não existe, criando`
    //     // );
    //     yield db
    //       .collection('users')
    //       .doc(actionUser.uid)
    //       .set({ ...actionUser, enterprises: [] });
    //     user = actionUser;
    //   }
    //   // console.log('Usuario carregado', user);
    //   // 1.1. Carrega as empresas sob gestão do usuário
    //   let enterprises;
    //   if (user.enterprises) {
    //     if (user.role === 'admin') {
    //       enterprises = yield enterprisesDao.get();
    //     } else {
    //       enterprises = yield all(
    //         user.enterprises.map(({ id }) => enterprisesDao.get(id))
    //       );
    //     }
    //     yield put(authActions.setUser({ ...user, enterprises }));
    //     // 2. Verifica se a empresa existe. Caso não exista, vai pra tela de novo usuário
    //     if (user.enterpriseId) {
    //       // console.log('enterpriseId existe no usuário');
    //       let enterpriseDoc = yield db
    //         .collection('enterprises')
    //         .doc(user.enterpriseId)
    //         .get();
    //       if (enterpriseDoc.exists) {
    //         // console.log('Enterprise existe no BD');
    //         let enterprise = enterpriseDoc.data();
    //         yield put(
    //           settingsActions.setEnterprise({
    //             id: user.enterpriseId,
    //             ...enterprise,
    //           })
    //         );
    //         yield put(appActions.initSuccess());
    //       } else {
    //         // console.log('Enterprise não existe no BD');
    //         yield put(authActions.newUser());
    //       }
    //     } else {
    //       // console.log('Usuário não possui enterpriseId');
    //       yield put(authActions.newUser());
    //     }
    //   } else {
    //     // console.log('Usuário não possui enterprises');
    //     yield put(authActions.newUser());
    //   }
    // } catch (e) {
    //   // console.log('Erro ao executar saga de login');
    //   // console.log(e);
    //   yield put(appActions.initFail(e.message));
    // }
  }
}

// function* watchNewUser() {
//   while (true) {
//     yield take(authActions.newUser);
//     yield firebase.auth().currentUser.sendEmailVerification();
//   }
// }

function* watchUserLogout() {
  while (true) {
    yield take(authActions.logout);
    // console.log('Saga de logout');
    yield put(appActions.reset());
    yield put(settingsActions.reset());
    yield put(appActions.setCurrentView('signIn'));
  }
}

export default [watchUserLogin, watchUserLogout /*, watchNewUser*/];
