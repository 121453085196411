'use strict';

import { capitalize } from '../../helpers/capitalize';

export const getFields = (user, enterprise) => {
  const [firstName] = capitalize(user.displayName).split(' ');
  const fullName = capitalize(user.displayName);

  return {
    customerData: {
      label: 'Dados do cliente',
      items: {
        firstName: {
          label: 'Primeiro nome',
          example: firstName,
        },
        name: {
          label: 'Nome completo',
          example: fullName,
        },
      },
    },
    enterpriseData: {
      label: 'Dados da empresa',
      items: {
        name: {
          label: 'Nome da empresa',
          example: enterprise.name,
        },
        gender: {
          label: 'Gênero da empresa',
          example: enterprise.gender,
        },
        agent: {
          label: 'Agente virtual',
          items: {
            name: {
              label: 'Nome do agente',
              example: enterprise.agent.name,
            },
            gender: {
              label: 'Gênero do agente',
              example: enterprise.agent.gender,
            },
          },
        },
      },
    },
  };
};
