'use strict';

export const pixStatusMap = {
  PAGA: 'PAGO',
  CANCELADA: 'CANCELADO',
  EXPIRADA: 'EXPIRADO',
  VENCIDA: 'VENCIDO',
  SEM_CPC: 'SEM CPC',
  NO_PRAZO: 'NO PRAZO',
  PENDENCIA: 'PENDÊNCIA',
};

export const getPixStatus = ({
  result,
  cancelledAt,
  expiredAt,
  overdueAt,
  noCpcAt,
}) =>
  result && result.resultId === 'PIX_PAYMENT'
    ? pixStatusMap.PAGA
    : noCpcAt
    ? pixStatusMap.SEM_CPC
    : cancelledAt
    ? pixStatusMap.CANCELADA
    : expiredAt
    ? pixStatusMap.EXPIRADA
    : overdueAt
    ? pixStatusMap.VENCIDA
    : pixStatusMap.NO_PRAZO;

// export default { pixStatusMap, getPixStatus };
