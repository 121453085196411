export const parseWhatsappTemplate = (text, variables) => {
  return text.replace(/\{\{(.*?)\}\}/g, (_match, group) => {
    try {
      //   console.log('text: ', text);
      //   console.log('group: ', group);
      //   console.log('variables: ', variables[parseInt(group) - 1]?.text);
      return variables[parseInt(group) - 1]?.text;
    } catch (e) {
      return '????????';
    }
  });
};
