'use strict';
import LabelIcon from '@mui/icons-material/Label';
import { Box, Button, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useController, useFieldArray } from 'react-hook-form';
import TagChip from '../Tags/TagChip';
import TagList from '../Tags/TagList';

const TagChipField = ({ control, name, defaultValue, onDelete }) => {
  const {
    field: { value },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return <TagChip value={value} onDelete={onDelete} />;
};

TagChipField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onDelete: PropTypes.func,
};

function TagField({ control, onApply, onDiscard }) {
  const [anchorEl, setAnchorEl] = useState();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });

  const handleChange = (checked, name) => {
    if (checked) {
      append({ name });
    } else {
      const indexFind = fields.map(({ name }) => name).indexOf(name);
      remove(indexFind);
    }
  };

  const sortedFields = [
    ...fields.map((field, index) => ({ ...field, index })),
  ].sort(({ name: a }, { name: b }) => (a ? a.localeCompare(b) : 1));

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          startIcon={<LabelIcon />}
        >
          marcadores
        </Button>
        {sortedFields.map((tag) => (
          <Box component="span" key={tag.id} marginLeft={1}>
            <TagChipField
              control={control}
              name={`tags.${tag.index}.name`}
              defaultValue={tag.name}
              onDelete={() => remove(tag.index)}
            />
          </Box>
        ))}
      </Box>
      <Popover
        id="tag-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={2}>
          <TagList
            selected={fields}
            onClose={() => setAnchorEl(null)}
            onChange={handleChange}
            onApply={onApply}
            onDiscard={onDiscard}
          />
        </Box>
      </Popover>
    </>
  );
}

TagField.propTypes = {
  control: PropTypes.object,
  onApply: PropTypes.func,
  onDiscard: PropTypes.func,
};

export default TagField;
