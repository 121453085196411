/* eslint-disable no-unused-vars */
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import { validateField } from '../../helpers/validations';
import useCloseConfirm, { CONFIRM_RESULT } from '../../hooks/useCloseConfirm';
import useConfirm from '../../hooks/useConfirm';
import SubaccountFormConfirmation from './SubaccountFormConfirmation';
import { settingsActions } from './settings-slice';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));

export const accountTypes = [
  {
    value: 'PF',
    label: 'Pessoa Física',
  },
  {
    value: 'PJ',
    label: 'Pessoa Jurídica',
  },
];

export const companyTypes = [
  {
    value: 'MEI',
    label: 'Micro Empreendedor Individual',
  },
  {
    value: 'LIMITED',
    label: 'Empresa Limitada',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Empresa Individual',
  },
  {
    value: 'ASSOCIATION',
    label: 'Associação',
  },
];

const SubaccountForm = ({ onCloseClick }) => {
  const dispatch = useDispatch();
  const { isLoading, isSecretActive } = useSelector((state) => state.app);
  const {
    enterprise: { pixAccountData },
  } = useSelector((state) => state.settings);
  const { clsWarning } = useStyles();

  useEffect(() => {
    dispatch(settingsActions.getPixAccounts());
  }, [dispatch]);
  const [CloseConfirmDialog, confirmClose] = useCloseConfirm();

  const [ConfirmDialog, confirm] = useConfirm(
    'Confirmar ativação',
    '',
    'Confirmar'
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      subaccountType: pixAccountData?.accountType ?? 'PJ',
      birthDate: pixAccountData?.birthDate ?? '',
      companyType: pixAccountData?.companyType ?? 'LIMITED',
      cnpj: pixAccountData?.cnpj ?? '',
      cpf: pixAccountData?.cpf ?? '',
      phone: pixAccountData?.mobilePhone ?? '',
      address: pixAccountData?.address ?? '',
      addressNumber: pixAccountData?.addressNumber ?? '',
      complement: pixAccountData?.complement ?? '',
      province: pixAccountData?.province ?? '',
      postalCode: pixAccountData?.postalCode ?? '',
      incomeValue: pixAccountData?.incomeValue ?? '',
    },
  });

  const watchCompanyType = watch('subaccountType');

  const onSubmit = async (formData) => {
    const {
      subaccountType,
      birthDate,
      companyType,
      cpf,
      cnpj,
      phone,
      address,
      addressNumber,
      complement,
      province,
      postalCode,
      incomeValue,
    } = formData;

    const transformedFormData = {
      subaccountType,
      birthDate: subaccountType === 'PF' ? birthDate : undefined,
      companyType: subaccountType === 'PJ' ? companyType : undefined,
      cpf: cpf ? cpf.replace(/[^\d]+/g, '') : undefined,
      cnpj: cnpj ? cnpj.replace(/[^\d]+/g, '') : undefined,
      phone: phone.replace(/[^\d]+/g, ''),
      address,
      addressNumber,
      complement,
      province,
      postalCode: postalCode.replace(/[^\d]+/g, ''),
      incomeValue: parseFloat(incomeValue),
    };
    const confirmMsg = (
      <SubaccountFormConfirmation formData={transformedFormData} />
    );
    const result = await confirm(confirmMsg);
    if (result) {
      dispatch(
        settingsActions.createSubaccount({
          cpfCnpj:
            subaccountType === 'PF'
              ? cpf.replace(/[^\d]+/g, '')
              : cnpj.replace(/[^\d]+/g, ''),
          birthDate: subaccountType === 'PF' ? birthDate : undefined,
          companyType: subaccountType === 'PJ' ? companyType : undefined,
          phone: phone.replace(/[^\d]+/g, ''),
          mobilePhone: phone.replace(/[^\d]+/g, ''),
          address,
          addressNumber,
          complement,
          province,
          postalCode: postalCode.replace(/[^\d]+/g, ''),
          incomeValue: parseFloat(incomeValue),
        })
      );
    }
  };

  const handleCloseClick = async () => {
    if (!isDirty) onCloseClick();
    else {
      const result = await confirmClose();
      switch (result) {
        case CONFIRM_RESULT.EXIT:
          onCloseClick();
          break;
        case CONFIRM_RESULT.CONFIRM:
          handleSaveClick();
          break;
        case CONFIRM_RESULT.CANCEL:
        default:
      }
    }
  };

  const handleSaveClick = async () => {
    handleSubmit(onSubmit)();
  };

  return (
    <EditForm>
      <EditFormHeader
        title="Ativação de subconta"
        onCloseClick={handleCloseClick}
      />
      <EditFormContent overflow="auto">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'subaccountType',
                    label: 'Tipo de Conta',
                    type: 'select',
                    options: accountTypes,
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                  }}
                />
              </Grid>
              {watchCompanyType === 'PF' && (
                <Grid item xs={6}>
                  <EditFormField
                    field={{
                      name: 'birthDate',
                      label: 'Data de Nascimento',
                      type: 'date',
                    }}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                    }}
                  />
                </Grid>
              )}
              {watchCompanyType === 'PJ' && (
                <Grid item xs={6}>
                  <EditFormField
                    field={{
                      name: 'companyType',
                      label: 'Tipo de Empresa',
                      type: 'select',
                      options: companyTypes,
                    }}
                    defaultValue="LIMITED"
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                    }}
                  />
                </Grid>
              )}
              {watchCompanyType === 'PJ' && (
                <Grid item xs={6}>
                  <EditFormField
                    field={{
                      name: 'cnpj',
                      label: 'CNPJ',
                      type: 'text',
                    }}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      validate: (value) => {
                        const { valid, error } = validateField(value, {
                          type: 'cnpj',
                        });
                        return valid || error;
                      },
                      minLength: {
                        value: 4,
                        message: 'Digite um documento válido',
                      },
                    }}
                  />
                </Grid>
              )}
              {watchCompanyType === 'PF' && (
                <Grid item xs={6}>
                  <EditFormField
                    field={{
                      name: 'cpf',
                      label: 'CPF',
                      type: 'text',
                    }}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      validate: (value) => {
                        const { valid, error } = validateField(value, {
                          type: 'cpf',
                        });
                        return valid || error;
                      },
                      minLength: {
                        value: 4,
                        message: 'Digite um documento válido',
                      },
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'phone',
                    label: 'Telefone',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    validate: (value) => {
                      const { valid, error } = validateField(value, {
                        type: 'phone',
                      });
                      return valid || error;
                    },
                    minLength: {
                      value: 4,
                      message: 'Digite um telefone válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'address',
                    label: 'Endereço',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um endereço válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'addressNumber',
                    label: 'Número',
                    type: 'integer',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: { value: 1, message: 'Digite um número válido' },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'complement',
                    label: 'Complemento',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: false,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'province',
                    label: 'Bairro',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: { value: 4, message: 'Digite um bairro válido' },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'postalCode',
                    label: 'CEP',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: { value: 4, message: 'Digite um CEP válido' },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'incomeValue',
                    label: 'Renda/Faturamento',
                    type: 'text',
                  }}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormMessage>
          {Object.keys(errors).length > 0 && (
            <Typography color="error" variant="caption">
              Os erros devem ser corrigidos antes de salvar
            </Typography>
          )}
        </EditFormMessage>
        <EditFormAction>
          <ButtonWithProgress
            tooltipTitle="Ativar"
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
            disabled={!isDirty || isLoading}
            aria-label="salvar"
            startIcon={<SaveIcon />}
          >
            Ativar
          </ButtonWithProgress>
        </EditFormAction>
      </EditFormFooter>
      <CloseConfirmDialog />
      <ConfirmDialog />
    </EditForm>
  );
};
SubaccountForm.propTypes = {
  onCloseClick: PropTypes.func,
};
export default SubaccountForm;
