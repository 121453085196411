'use strict';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardActionArea,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import JourneyPix from '../monitor/JourneyPix';
import Story from '../monitor/Story';
import { issueTypeMap } from './issue-type';
import { issuesActions } from './issues-slice';

const useStyles = makeStyles((theme) => ({
  clsSuccessIcon: {
    color: theme.palette.success.main,
  },
}));

export default function RejectedWizard() {
  const { clsSuccessIcon } = useStyles();
  const dispatch = useDispatch();
  const {
    monitor: { journey: { issue: { userinputs = [] } = {} } = {}, bots },
    issues: {
      issueSolve: { solveType, solvePath },
    },
  } = useSelector((state) => state);

  const currentSolveType = issueTypeMap[solveType];
  const currentSolvePathStep = solvePath.at(-1) ?? '1';

  return (
    <>
      {/* <Box display="flex" flexDirection="column"> */}
      <Stepper alternativeLabel activeStep={solvePath.length - 1}>
        {solvePath.map((step, index) => (
          <Step key={index}>
            <StepLabel>{currentSolveType.paths[step]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {currentSolvePathStep === '1' && (
        <>
          {userinputs.length > 0 ? (
            <>
              <Typography variant="body2">
                O cliente contestou a cobrança com a seguinte mensagem:{' '}
              </Typography>
              <Typography variant="h5">
                &quot;{userinputs.map(({ content }) => content).join(', ')}
                &quot;
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Typography variant="body2">
            <br />
            <br />O primeiro passo é confirmar se os dados da cobrança estão
            corretos. (Valor, data de vencimento, etc)
          </Typography>
          <JourneyPix />
          <Box
            display="flex"
            flexDirection="row"
            marginTop={2}
            marginBottom={2}
          >
            <Box flexBasis={0.5} flexGrow={1} marginRight={1}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    dispatch(issuesActions.addSolvePath('1.1'));
                  }}
                >
                  <Box
                    p={2}
                    minHeight="200px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <CheckIcon className={clsSuccessIcon} fontSize="large" />
                    <Typography variant="h5">
                      As cobranças estão corretas
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
            <Box flexBasis={0.5} flexGrow={1} marginLeft={1}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => {
                    dispatch(issuesActions.addSolvePath('1.2'));
                  }}
                >
                  <Box
                    p={2}
                    minHeight="200px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <CloseIcon color="error" fontSize="large" />
                    <Typography variant="h5">
                      Uma ou mais cobranças precisam de correção
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          </Box>
        </>
      )}
      {currentSolvePathStep === '1.1' && (
        <>
          <Typography variant="body2">
            Nesse caso você pode enviar uma mensagem ao cliente para esclarecer,
            ou encerrar a pendência sem fazer nada.
          </Typography>
          {Object.keys(bots).length > 0 ? <Story /> : <Box flexGrow={1} />}
        </>
      )}
      {currentSolvePathStep === '1.2' && (
        <>
          <Typography variant="body2">
            Nesse caso faça as correções, e se quiser pode enviar uma mensagem
            adicional ao cliente antes de encerrar.
          </Typography>
          <JourneyPix />
          {Object.keys(bots).length > 0 ? <Story /> : <Box flexGrow={1} />}
        </>
      )}
      <Box flexGrow={1} />
    </>
  );
}

RejectedWizard.propTypes = {};
