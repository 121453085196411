'use strict';
import ky from 'ky';
import _ from 'lodash';
import { fork, put, select, take } from 'redux-saga/effects';
import customersDao from '../../dao/customers';
import { getApi } from '../../helpers/api';
import firebase from '../../helpers/firebase';
import removeSpecialChars from '../../helpers/remove-special-chars';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { customersActions } from './customers-slice';

function* watchLoadCustomers() {
  const db = firebase.firestore();
  while (true) {
    const { payload } = yield take(customersActions.load);
    const { keywords } = payload ?? {};
    try {
      yield put(appActions.startLoading());
      const {
        settings: {
          enterprise: { id: eid },
        },
        customers: { recordsLimit },
      } = yield select();

      let customersRef = db.collection(`enterprises/${eid}/customers`);

      if (keywords ?? '' !== '')
        customersRef = customersRef.where(
          'keywords',
          'array-contains-any',
          removeSpecialChars(keywords)
            .toLocaleLowerCase()
            .split(' ')
            .filter((s) => s !== '')
        );

      const data = yield customersRef.limit(recordsLimit + 1).get();
      const customers = data.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      yield put(
        customersActions.setSearchState({
          inputs: payload,
          count: data.docs.length,
          firstSearch: true,
        })
      );

      yield put(customersActions.loadSuccess(customers));
      yield put(appActions.finishLoading());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      yield put(appActions.setError(e.message));
      yield put(customersActions.loadFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* deleteCustomer(payload) {
  try {
    yield put(appActions.startLoading());

    const {
      settings: {
        localApi,
        enterprise: { id: eid },
      },
      customers: { searchState },
    } = yield select();

    const { url, headers, ...opts } = yield getApi({
      path: '/customer/multi-delete',
      local: localApi,
    });

    const response = yield ky(url, {
      method: 'post',
      json: payload,
      headers: { ...headers, 'X-Zeta-Eid': eid },
      ...opts,
    });
    yield response.json();

    yield put(
      notifierActions.enqueue({
        message: 'Os registros foram excluídos com sucesso !',
        options: {
          variant: 'success',
        },
      })
    );
    yield put(customersActions.deleteSuccess());
    yield put(customersActions.load(searchState.inputs));
  } catch (e) {
    let message;
    try {
      const { error } = yield e.response.json();
      message = error;
    } catch {
      ({ message } = e);
    }
    yield put(appActions.setError(message));
    yield put(customersActions.deleteFail());
    yield put(appActions.finishLoading());
  }
}

function* watchDeleteCustomers() {
  while (true) {
    const { payload } = yield take(customersActions.delete);
    yield fork(deleteCustomer, payload);
  }
}

// const checkPhoneExists = async (eid, phone, id) => {
//   const checkExistsDoc = await customersDao.getByPhone(eid, phone);
//   if (!checkExistsDoc.empty) {
//     const [existingCustomer] = checkExistsDoc.docs;
//     if (!id || existingCustomer.id !== id)
//       throw new Error(
//         `ERRO! Já existe um cliente na base com este número de telefone. Nome: ${
//           existingCustomer.data().name
//         }`
//       );
//   }
// };
const checkCustomerDocNumberExists = async (eid, customerDocNumber, id) => {
  const checkExistsDoc = await customersDao.getByCustomerDocNumber(
    eid,
    customerDocNumber
  );
  if (!checkExistsDoc.empty) {
    const [existingCustomer] = checkExistsDoc.docs;
    if (!id || existingCustomer.id !== id)
      throw new Error(
        `ERRO! Já existe um cliente na base com este documento. Nome: ${
          existingCustomer.data().name
        } - ${existingCustomer.id}`
      );
  }
};

function* watchSaveCustomer() {
  while (true) {
    const { payload } = yield take(customersActions.save);

    try {
      yield put(appActions.startLoading());
      const {
        settings: {
          enterprise: { id: eid },
        },
        customers: { searchState },
      } = yield select();
      const { id, /*phone, */ customerDocNumber } = payload;
      // yield checkPhoneExists(eid, phone, id);
      yield checkCustomerDocNumberExists(eid, customerDocNumber, id);

      yield customersDao.update(eid, id, payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(customersActions.saveSuccess());
      yield put(customersActions.load(searchState.inputs));
    } catch (e) {
      // console.log('Erro ao executar watchSaveCustomer');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(customersActions.saveFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchBatchUpdate() {
  const db = firebase.firestore();

  while (true) {
    const {
      payload: { ids, data, field },
    } = yield take(customersActions.batchUpdate);

    try {
      yield put(appActions.startLoading());
      const {
        settings: {
          enterprise: { id: eid },
        },
        customers: { searchState },
      } = yield select();
      // console.log('IDs: ', ids);
      for (const key in ids) {
        // console.log('Update customer: ', eid, ids[key], data, field);
        if (field === 'tags') {
          const customerDoc = yield db
            .collection(`enterprises/${eid}/customers`)
            .doc(ids[key])
            .get();
          if (customerDoc.exists) {
            const prevData = customerDoc.data();
            yield customersDao.update(eid, ids[key], {
              tags: _.uniq([...(prevData.tags ?? []), ...data.tags]),
            });
          }
        } else {
          yield customersDao.update(eid, ids[key], data);
        }
      }
      yield put(
        notifierActions.enqueue({
          message: 'Atualizado com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );

      yield put(customersActions.batchUpdateSuccess());
      yield put(customersActions.load(searchState.inputs));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      yield put(appActions.setError(e.message));
      yield put(customersActions.batchUpdateFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchAddCustomer() {
  while (true) {
    const { payload } = yield take(customersActions.add);

    try {
      yield put(appActions.startLoading());
      const {
        settings: {
          enterprise: { id: eid },
        },
        customers: { searchState },
      } = yield select();
      // yield checkPhoneExists(eid, payload.phone);
      yield checkCustomerDocNumberExists(eid, payload.customerDocNumber);
      yield customersDao.add(eid, payload);
      yield put(
        notifierActions.enqueue({
          message: 'Salvo com sucesso !',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(customersActions.saveSuccess());
      if (!searchState.inputs) {
        yield put(
          customersActions.setSearchState({
            inputs: { keywords: payload.name },
          })
        );
      }
      yield put(
        customersActions.load(searchState.inputs ?? { keywords: payload.name })
      );

      // const { docNumber, phone } = payload;
      // const checkDocNumber = yield customersDao.getByDocNumber(eid,docNumber);
      // if(checkDocNumber.empty) {
      //   const checkPhone = yield customersDao.getByDocNumber(eid,phone);
      //   if(checkPhone.empty) {
    } catch (e) {
      // console.log('Erro ao executar watchAddCustomer');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(customersActions.saveFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadCustomersMetadata() {
  const db = firebase.firestore();

  while (true) {
    yield take(customersActions.loadMetadata);

    try {
      let snapshot = yield db.collection('metadata-customers').get();
      let data = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = {
          id: doc.id,
          ...doc.data(),
        };
        return acc;
      }, {});

      yield put(customersActions.loadMetadataSuccess(data));
    } catch (e) {
      // console.log('Erro ao executar watchCreateNotification');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(customersActions.loadMetadataFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSelectCustomer() {
  const db = firebase.firestore();

  while (true) {
    const { payload } = yield take(customersActions.selectCustomer);
    try {
      const { selected, selectedContext } = payload;
      const {
        settings: {
          enterprise: { id: eid },
        },
      } = yield select();
      if (selectedContext === 'edit') {
        const journeyDocs = yield db
          .collection('journey-sessions')
          .where(
            'customerData.customerDocNumber',
            '==',
            selected.customerDocNumber
          )
          .where('enterpriseData.eid', '==', eid)
          .get();
        if (journeyDocs.docs.length > 0) {
          const selectedJourney = journeyDocs.docs[0].data();
          const [firstInvoice] = [...selectedJourney.chargeData.invoices].sort(
            (a, b) => b.invoiceDate.localeCompare(a.invoiceDate)
          );

          const pixDoc = yield db
            .collection('pix')
            .doc(firstInvoice.txid)
            .get();
          yield put(
            customersActions.setSelectedCurrentJourney({
              journey: selectedJourney,
              pix: pixDoc?.data(),
            })
          );
        }
      }
    } catch (e) {
      yield put(appActions.setError(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchLoadCustomers,
  watchDeleteCustomers,
  watchSaveCustomer,
  watchAddCustomer,
  watchLoadCustomersMetadata,
  watchBatchUpdate,
  watchSelectCustomer,
];
