import firebase from '../helpers/firebase';

const journeysDao = {
  get: async (enterprise, key) => {
    // console.log(`enterprises/${enterprise}/journeys`);
    const db = firebase.firestore();

    let journeysSnapshot = await db
      .collection(`enterprises/${enterprise}/journeys`)
      .get(key);
    let journeys = journeysSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    // console.log('journeys');
    // console.log(journeys);
    // console.log('terminando forEach journeys');
    return journeys;
  },

  set: async (enterprise, key, data) => {
    // console.log('Saving');
    // console.log(key + ' => ');
    // console.log(data);

    const db = firebase.firestore();
    await db
      .collection(`enterprises/${enterprise}/journeys`)
      .doc(key)
      .set(data);
  },

  delete: async (enterprise, key) => {
    // console.log(`Deleting ${key}`);
    const db = firebase.firestore();

    await db.collection(`enterprises/${enterprise}/journeys`).doc(key).delete();
  },
};
export default journeysDao;
