import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function EditFormHeader({ title, actions, onCloseClick }) {
  return (
    <Box width={1} display="flex" flexDirection="row" alignItems="center">
      <Box flexGrow={1}>
        {typeof title === 'string' ? (
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      {actions && <Box>{actions}</Box>}
      {onCloseClick && (
        <Box marginLeft={2}>
          <IconButton edge="end" size="small" onClick={onCloseClick}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

EditFormHeader.propTypes = {
  title: PropTypes.any.isRequired,
  actions: PropTypes.node,
  onCloseClick: PropTypes.func,
};

export default EditFormHeader;
