/* eslint-disable react/prop-types */
'use strict';
import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import _ from 'lodash/lang';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import DefaultToolbar from './DefaultToolbar';
import SlateInput from './SlateInput';

const withFields = (editor) => {
  const { isVoid, isInline } = editor;

  editor.isVoid = (element) => {
    return element.type === 'field' ? true : isVoid(element);
  };
  editor.isInline = (element) => {
    return element.type === 'field' ? true : isInline(element);
  };
  return editor;
};

function RHFRichTextField({
  name,
  label,
  helperText,
  value: valueProp,
  onChange,
  ToolbarComponent = DefaultToolbar,
  ToolbarProps,
  ...props
}) {
  const editor = useMemo(() => withReact(withFields(createEditor())), []);
  const slateInputRef = useRef(null);
  const initialValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];
  const [value, setValue] = useState(valueProp ?? initialValue);
  const handleChange = (v) => {
    const hasChanges = !_.isEqualWith(
      valueProp,
      v,
      (_objValue, _othValue, key) => {
        if (key === 'isPreview') return true;
      }
    );
    if (hasChanges) {
      onChange(v);
      slateInputRef.current.onChange(v);
    }
    setValue(v);
  };

  const labelId = `${name}-label`;
  return (
    <>
      <Slate editor={editor} initialValue={value} onChange={handleChange}>
        <ToolbarComponent {...ToolbarProps} />
        <FormControl {...props}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <FilledInput
            value={value}
            inputComponent={SlateInput}
            inputRef={slateInputRef}
            inputProps={{ editor }}
            multiline
          />
          {helperText !== '' && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </Slate>
    </>
  );
}

RHFRichTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  ToolbarComponent: PropTypes.func,
  ToolbarProps: PropTypes.object,
};

export default RHFRichTextField;
