import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import ReportTable from '../../components/ReportTable';
import { formatValue } from '../../helpers/formatter';
// import { pixStatusMap } from '../../helpers/pix-status';

const useStyles = makeStyles(() => ({
  print: {
    color: 'black',
  },
  nobr: {
    'white-space': 'nowrap',
  },
}));

// const results = {
//   PIX_PAYMENT: pixStatusMap.PAGA,
//   PIX_EXPIRED: pixStatusMap.EXPIRADA,
//   NO_CPC: pixStatusMap.SEM_CPC,
// };

const PixStatus = ({ data, currentOrderBy, onOrderByChange }) => {
  const classes = useStyles();

  const enhancedData = data.map((row) => {
    // const isLate = moment(row.invoiceData.invoiceDate)
    //   .startOf('day')
    //   .isBefore(moment().startOf('day'));

    return {
      docNumber: row.invoiceData.docNumber,
      customerName: row.invoiceData?.customerData?.name,
      invoiceId: row.invoiceData.invoiceId,
      invoiceDate: moment(row.invoiceData.invoiceDate).valueOf(),
      serviceDate: moment(row.invoiceData.serviceDate).valueOf(),
      invoiceValue: row.invoiceData.invoiceValue,
      status: row.status,
      //results[row.result?.resultId] ??
      //(isLate ? pixStatusMap.VENCIDA : pixStatusMap.NO_PRAZO),
    };
  });

  const columns = [
    {
      id: 'docNumber',
      numeric: false,
      disablePadding: false,
      label: 'Documento',
      formatter: (v) => formatValue(v, 'docNumber'),
      align: 'center',
      className: classes.nobr,
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: 'Nome',
      align: 'center',
    },
    {
      id: 'invoiceId',
      numeric: false,
      disablePadding: false,
      label: 'Id Fatura',
      align: 'center',
    },
    {
      id: 'invoiceDate',
      numeric: false,
      disablePadding: false,
      label: 'Vencimento',
      formatter: (v) => formatValue(v, 'date'),
      align: 'center',
    },
    {
      id: 'serviceDate',
      numeric: false,
      disablePadding: false,
      label: 'Referencia',
      formatter: (v) => formatValue(v, 'yearMonth'),
      align: 'center',
    },
    {
      id: 'invoiceValue',
      numeric: false,
      disablePadding: false,
      label: 'Valor Fatura (R$)',
      formatter: (v) => formatValue(v, 'decimal'),
      align: 'center',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Situação',
      align: 'center',
    },
  ];

  return (
    <Box width={1}>
      <ReportTable
        title="Situação das cobranças"
        data={enhancedData}
        columns={columns}
        orderBy={currentOrderBy ?? { column: 'invoiceDate' }}
        onOrderByChange={onOrderByChange}
        tableProps={{
          className: classes.table,
          size: 'small',
        }}
      />
      <Box marginTop={2} />
    </Box>
  );
};

PixStatus.propTypes = {
  data: PropTypes.array,
  currentOrderBy: PropTypes.object,
  onOrderByChange: PropTypes.func,
};

export default PixStatus;
