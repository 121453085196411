'use strict';
import { put, select, take } from 'redux-saga/effects';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { getMessenger } from './get-messenger';
import { waStatusActions } from './wa-status-slice';

function* watchLoadSessions() {
  const db = firebase.firestore();

  while (true) {
    yield take(waStatusActions.loadSessions);

    try {
      yield put(appActions.startLoading());
      let snapshot = yield db.collection('wa-sessions').get();
      let data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log(data);
      yield put(waStatusActions.loadSessionsSuccess(data));
      yield put(waStatusActions.loadSessionDetails());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchLoadSessions');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(waStatusActions.loadSessionsFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoadSessionsDetails() {
  const fnWaStatus = firebase
    .app()
    .functions()
    .httpsCallable('fn-adm-wa-status');

  while (true) {
    yield take(waStatusActions.loadSessionDetails);
    const {
      waStatus: { sessions },
    } = yield select((state) => state);

    try {
      // console.log(sessions);

      const { data } = yield fnWaStatus({
        method: 'status',
        sessions: sessions.map(({ id }) => id),
      });
      // console.log(data);
      yield put(waStatusActions.loadSessionDetailsSuccess(data));
    } catch (e) {
      // console.log('Erro ao executar watchLoadSessions');
      // console.log(e);
      yield put(appActions.setError(e.message));
      yield put(waStatusActions.loadSessionDetailsFail());
    }
  }
}

function* watchConnect() {
  const fnWaStatus = firebase
    .app()
    .functions()
    .httpsCallable('fn-adm-wa-status');

  while (true) {
    const {
      payload: { wid, disableInbound },
    } = yield take(waStatusActions.connect);

    try {
      yield fnWaStatus({
        method: 'connect',
        wid,
        disableInbound,
      });
    } catch (e) {
      // console.log('Erro ao executar watchConnect');
      // console.log(e);
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchDisconnect() {
  const fnWaStatus = firebase
    .app()
    .functions()
    .httpsCallable('fn-adm-wa-status');

  while (true) {
    const { payload: wid } = yield take(waStatusActions.disconnect);

    try {
      yield fnWaStatus({
        method: 'disconnect',
        wid,
      });
    } catch (e) {
      // console.log('Erro ao executar watchDisconnect');
      // console.log(e);
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchLogout() {
  const fnWaStatus = firebase
    .app()
    .functions()
    .httpsCallable('fn-adm-wa-status');

  while (true) {
    const { payload: wid } = yield take(waStatusActions.logout);

    try {
      yield fnWaStatus({
        method: 'logout',
        wid,
      });
    } catch (e) {
      // console.log('Erro ao executar function* watchLogout()');
      // console.log(e);
      yield put(appActions.setError(e.message));
    }
  }
}

function* watchSendTest() {
  const fnAdmPublish = firebase
    .app()
    .functions()
    .httpsCallable('fn-adm-publish');

  while (true) {
    const {
      payload: { sessions, sid },
    } = yield take(waStatusActions.sendTest);
    try {
      yield Promise.all(
        sessions
          .filter(({ id }) => getMessenger(id) === 'api')
          .map(async ({ id, label }) => {
            await fnAdmPublish({
              topic: 'msg',
              event: 'send-msg',
              payload: {
                wid: id,
                sid,
                msgs: [
                  {
                    type: 'text',
                    content: `Mensagem de teste ${id} - ${label}`,
                  },
                ],
              },
            });
          })
      );
      yield put(
        notifierActions.enqueue({
          message: `Mensagens de teste enviadas para ${sid}`,
          options: {
            variant: 'success',
          },
        })
      );
    } catch (e) {
      // console.log('Erro ao executar watchSimulatePayment');
      // console.log(e);
      yield put(appActions.setError(e.message));
      // yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchLoadSessions,
  watchLoadSessionsDetails,
  watchConnect,
  watchDisconnect,
  watchLogout,
  watchSendTest,
];
