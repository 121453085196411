import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormMessage from '../../components/EditForm/EditFormMessage';

import CheckIcon from '@mui/icons-material/Check';
import { journeysActions } from '../journeys/journeys-slice';
import MailingPreview from './MailingPreview';
import { mailingActions } from './mailing-slice';
const useStyles = makeStyles((theme) => ({
  clsProgressPanel: {
    borderRadius: '0.5em',
    backgroundColor: theme.palette.background.field,
    color: theme.palette.text.secondary,
    height: '76px',
  },
  clsProgressBar: {
    width: '95%',
  },
  clsHidden: {
    display: 'none',
  },
}));

function MailingCustomIntegration() {
  const { clsProgressPanel, clsProgressBar, clsHidden } = useStyles();
  const {
    journeys: { selected },
    mailing: { isProcessingPreview, charge: { chid } = {}, createChargeError },
    settings: {
      enterprise: { integration },
    },
  } = useSelector((state) => state);
  // const { encoding, delimiter, isProcessingPreview } = useSelector(
  //   (state) => state.mailing
  // );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mailingActions.reset());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iniDate: moment().startOf('month').valueOf(),
      endDate: moment().endOf('month').valueOf(),
    },
  });

  const [endDate] = useWatch({
    control,
    name: ['endDate'],
  });

  const onSubmit = (formData) => {
    const { id: jid } = selected;
    const dataToSave = {
      jid,
      ...formData,
      iniDate: moment(formData.iniDate).format('YYYYMMDD'),
      endDate: moment(formData.endDate).format('YYYYMMDD'),
      type: 'custom-integration',
      integration,
    };
    console.log(dataToSave);

    dispatch(mailingActions.createCharge(dataToSave));
  };

  const isProcessing = isProcessingPreview;

  return (
    <>
      <Box className={clsx((isProcessing || chid) && clsHidden)}>
        <EditForm p={0}>
          <EditFormContent overflow="auto">
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                padding={1}
              >
                <Typography variant="body1">
                  Preciso saber de quais datas você quer obter as faturas:
                </Typography>
              </Box>
              <Box p={1}>
                {integration.name !== 'apex' &&
                  integration.name !== 'apex-pedidos' && (
                    <Grid container item spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <EditFormField
                          field={{
                            name: 'iniDate',
                            label: 'Data de Início',
                            type: 'date',
                          }}
                          //                      defaultValue={moment(initialDate)}
                          maxDate={moment(endDate)}
                          control={control}
                          rules={{
                            required: 'Obrigatório',
                          }}
                          format="DD/MM/YYYY"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <EditFormField
                          field={{
                            name: 'endDate',
                            label: 'Data de Fim',
                            type: 'date',
                          }}
                          //                      defaultValue={moment(finalDate)}
                          control={control}
                          rules={{
                            required: 'Obrigatório',
                          }}
                          format="DD/MM/YYYY"
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography variant="body1">
                          Se tiver algum CPF ou CNPJ específico para fazer a
                          busca, por favor informe no campo abaixo. Caso
                          contrário deixe em branco para buscar todos os títulos
                          dentro das datas
                        </Typography>
                        <Typography variant="body1">
                          IMPORTANTE! A busca em todos os CPFs pode demorar
                          bastante.
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <EditFormField
                          field={{
                            name: 'docNumber',
                            label: 'CPF ou CNPJ',
                            type: 'text',
                          }}
                          rules={{}}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  )}
                {(integration.name === 'apex' ||
                  integration.name === 'apex-pedidos') && (
                  <>
                    <EditFormField
                      field={{
                        name: 'yearMonth',
                        label: 'Mês/Ano',
                        type: 'yearMonth',
                      }}
                      control={control}
                      defaultValue={moment().format('YYYY-MM')}
                      rules={{
                        required: 'Obrigatório',
                      }}
                    />
                  </>
                )}
              </Box>
            </form>
          </EditFormContent>
          <EditFormFooter>
            <EditFormMessage>
              {Object.keys(errors).length > 0 && (
                <Typography color="error" variant="caption">
                  Os erros devem ser corrigidos antes de salvar
                </Typography>
              )}
            </EditFormMessage>
          </EditFormFooter>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={2}
          >
            <ButtonWithProgress
              // type="submit"
              tooltipTitle="Gerar lista de clientes"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={Object.keys(errors).length > 0}
              aria-label="salvar"
              startIcon={<CheckIcon />}
            >
              Gerar lista de clientes
            </ButtonWithProgress>
          </Box>
        </EditForm>
      </Box>
      {isProcessing && !createChargeError && (
        <Box margin={1}>
          <Box
            className={clsProgressPanel}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle1">Processando</Typography>
            <LinearProgress className={clsProgressBar} color="secondary" />
          </Box>
        </Box>
      )}
      {isProcessing && createChargeError && (
        <Box margin={1}>
          <Typography variant="subtitle1" color="error">
            {createChargeError?.message ?? 'Ocorreu um erro ao criar a carga'}
          </Typography>

          <Box marginTop={2}>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  journeysActions.selectJourney({
                    selected: null,
                    selectedContext: null,
                  })
                );
                dispatch(mailingActions.reset());
                dispatch(journeysActions.sendReset());
              }}
            >
              Reiniciar
            </Button>
          </Box>
        </Box>
      )}
      {!!chid && !isProcessing && (
        <Box margin={1}>
          <MailingPreview />
        </Box>
      )}
    </>
  );
}

MailingCustomIntegration.propTypes = {};
export default MailingCustomIntegration;
