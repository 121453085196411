import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './features/app/App';
import ScrollToTop from './features/app/ScrollToTop';
import Notifier from './features/notifier/Notifier';
import { darkTheme, lightTheme } from './helpers/themes';
import * as serviceWorker from './serviceWorker';
import store from './store';

const Main = () => {
  const {
    settings: { theme },
  } = useSelector((state) => state);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={theme && theme === 'dark' ? darkTheme : lightTheme}
        >
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter>
                <ScrollToTop />
                <Notifier />
                <CssBaseline enableColorScheme />
                <App />
              </BrowserRouter>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
