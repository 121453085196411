import { amber, green, lightBlue, pink, red, teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import _ from 'lodash/fp/object';

const primaryLight =
  process.env.REACT_APP_ENV === 'condomi-dev'
    ? teal
    : process.env.REACT_APP_ENV === 'condomi-dev2'
    ? { main: '#662d91' }
    : process.env.REACT_APP_ENV === 'condomi-stg'
    ? amber
    : { main: '#662d91' };

const secondaryLight =
  process.env.REACT_APP_ENV === 'condomi-dev'
    ? pink
    : process.env.REACT_APP_ENV === 'condomi-dev2'
    ? { main: '#fdb913' }
    : process.env.REACT_APP_ENV === 'condomi-stg'
    ? pink
    : { main: '#fdb913' };

const primaryDark =
  process.env.REACT_APP_ENV === 'condomi-dev'
    ? teal
    : process.env.REACT_APP_ENV === 'condomi-dev2'
    ? { main: '#806296' }
    : process.env.REACT_APP_ENV === 'condomi-stg'
    ? amber
    : { main: '#806296' };

const secondaryDark =
  process.env.REACT_APP_ENV === 'condomi-dev'
    ? pink
    : process.env.REACT_APP_ENV === 'condomi-dev2'
    ? { main: '#fdb913' }
    : process.env.REACT_APP_ENV === 'condomi-stg'
    ? pink
    : { main: '#fdb913' };

const baseTheme = {
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       html: {
  //         WebkitFontSmoothing: undefined,
  //         MozOsxFontSmoothing: undefined,
  //       },
  //     },
  //   },
  // },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
};

const lightThemeOptions = {
  palette: {
    mode: 'light',
    primary: primaryLight,
    secondary: secondaryLight,
    background: {
      field: '#ededed',
    },
    whatsapp: {
      shadow: '#303030',
      background: '#DFD5CC',
      out: '#D4F9BA',
      in: '#FFFFFF',
      contactName: '#31C383',
      contactNameAdm: '#C331C3',
      check: '#34B7F1',
      text: '#000000',
      sysText: 'rgba(100, 150, 200)',
      timestampText: 'rgba(0, 0, 0, 0.45)',
      readIcon: '#4fc3f7',
      deliveredIcon: 'rgba(0, 0, 0, 0.45)',
      sentIcon: 'rgba(0, 0, 0, 0.45)',
      waitingIcon: 'rgba(0, 0, 0, 0.45)',
    },
    finance: {
      cashIn: lightBlue[500],
      cashOut: red[500],
      cash: green[500],
    },
    // text: {
    //   primary: 'rgba(102, 45, 145, 0.87)',
    //   secondary: 'rgba(102, 45, 145, 0.6)',
    //   disabled: 'rgba(102, 45, 145, 0.38)',
    // },
    divider: 'rgba(102, 45, 145, 0.12)',
  },
};

const darkThemeOptions = {
  palette: {
    mode: 'dark',
    primary: primaryDark,
    secondary: secondaryDark,
    background: {
      field: '#575757',
    },
    whatsapp: {
      shadow: 'rgba(241, 241, 242, 0.95)',
      background: '#0c1418',
      out: '#056162',
      in: '#262d31',
      sys: '#262d31',
      contactName: '#31C383',
      contactNameAdm: '#C331C3',
      check: '#34B7F1',
      text: '#FFFFFF',
      sysText: 'rgba(100, 150, 200)',
      timestampText: 'rgba(241, 241, 242, 0.63)',
      readIcon: '#34b7f1',
      deliveredIcon: 'rgba(241, 241, 242, 0.63)',
      sentIcon: 'rgba(241, 241, 242, 0.63)',
      waitingIcon: 'rgba(241, 241, 242, 0.63)',
    },
    finance: {
      cashIn: lightBlue[200],
      cashOut: red[200],
      cash: green[200],
    },
  },
};

export const lightTheme = createTheme(_.merge(baseTheme, lightThemeOptions));
export const darkTheme = createTheme(_.merge(baseTheme, darkThemeOptions));
