import PropTypes from 'prop-types';
import { useState } from 'react';
import firebase from '../helpers/firebase';

import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import ptBr from 'filepond/locale/pt-br.js';
import { FilePond, registerPlugin } from 'react-filepond';
registerPlugin(FilePondPluginFileValidateSize);

import { server } from '../helpers/upload-server';

const useStyles = makeStyles((theme) => ({
  clsFilepond: {
    margin: 0,
    '& .filepond--panel-root': {
      backgroundColor: theme.palette.background.field,
    },
    '& .filepond--drop-label': {
      color: theme.palette.text.secondary,
    },
    '& .filepond--item-panel': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .filepond--root': {
      margin: 0,
    },
    '& [data-filepond-item-state="processing-complete"] .filepond--item-panel':
      {
        backgroundColor: theme.palette.primary.main,
      },
    '& [data-filepond-item-state*="error"] .filepond--item-panel,[data-filepond-item-state*="invalid"] .filepond--item-panel':
      {
        backgroundColor: theme.palette.error.main,
      },
  },
}));

function FileField({
  label,
  helperText,
  error,
  value,
  onChange,
  acceptedFileTypes,
  maxFileSize,
}) {
  const { clsFilepond } = useStyles();
  const { filename, fileSize, fileType, serverId } = value;
  const [files, setFiles] = useState(
    filename
      ? [
          {
            source: serverId,
            options: {
              type: 'local',
              file: {
                name: filename,
                size: fileSize,
                type: fileType,
              },
            },
          },
        ]
      : []
  );

  const onupdatefiles = (files) => {
    setFiles(files.map((fileItem) => fileItem.file));
    if (files.length === 0) {
      onChange();
    }
  };

  const onprocessfile = (_, updatedFile) => {
    const { filename, fileSize, fileType, serverId } = updatedFile;
    onChange({ filename, fileSize, fileType, serverId });
  };

  const getDownloadLink = (id) => {
    const { projectId } = firebase.app().options;
    let baseUrl = '';
    if (projectId === 'condomi-prod')
      baseUrl = 'https://condomi.zetaone.com.br';
    else baseUrl = `https://${projectId}.zetaone.com.br`;

    return `${baseUrl}/fn-adm-download/${id}`;
  };

  let labelIdle =
    'Arraste e solte o arquivo ou <span class="filepond--label-action"> Clique aqui </span>';

  return (
    <Box display="flex" flexDirection="row" width={1} alignItems="center">
      <Typography component="span">{label}</Typography>
      <Box flexGrow={1} marginLeft={2}>
        <FilePond
          {...ptBr}
          files={files}
          onupdatefiles={onupdatefiles}
          onprocessfile={onprocessfile}
          className={clsFilepond}
          allowFileTypeValidation
          acceptedFileTypes={acceptedFileTypes}
          allowFileSizeValidation
          maxFileSize={maxFileSize}
          server={server}
          credits={false}
          labelIdle={labelIdle}
        />
        {error && (
          <Typography
            component="p"
            variant="caption"
            color="error"
            align="center"
          >
            {helperText}
          </Typography>
        )}
      </Box>
      {serverId && (
        <Box marginLeft={2}>
          <IconButton
            component={Link}
            href={getDownloadLink(serverId)}
            target="_blank"
            rel="noopener"
            size="large"
          >
            <GetAppIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

FileField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  acceptedFileTypes: PropTypes.array,
  maxFileSize: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
};
export default FileField;
