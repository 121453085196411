import { Box, Card, CardActionArea, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import DescriptionIcon from '@mui/icons-material/Description';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import StorageIcon from '@mui/icons-material/Storage';
// import CreateIcon from '@mui/icons-material/Create';

import { makeStyles } from '@mui/styles';
import { journeysActions } from './journeys-slice';

const useStyles = makeStyles((theme) => ({
  clsHidden: {
    display: 'none',
  },
  clsContainer: {
    width: '100%',
  },
  clsChoice: {
    minHeight: '300px',
  },
  clsSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.field,
  },
}));

function SendJourneyStep1({ visible }) {
  const { clsHidden, clsContainer, clsChoice, clsSelected } = useStyles();

  const dispatch = useDispatch();

  const {
    app: { isSecretActive },
    journeys: { sendType },
    settings: {
      enterprise: { integration, ignoreCustomer },
    },
  } = useSelector((state) => state);
  const integrationName = integration?.name;

  const handleSelect = (type) => {
    dispatch(journeysActions.setSendType(type));
    dispatch(journeysActions.setSendActiveStep(1));
  };

  return (
    <Box className={clsx(!visible && clsHidden)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={2}
      >
        <Typography variant="body1">
          Escolha como quer fazer o envio:
        </Typography>
      </Box>
      <Box display="flex" className={clsContainer}>
        {((!ignoreCustomer && !integrationName) || isSecretActive) && (
          <Box flexGrow={1} paddingRight={1} flexBasis="100%">
            <Card
              variant="outlined"
              className={clsx(sendType === 'db' && clsSelected)}
            >
              <CardActionArea onClick={() => handleSelect('db')}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={2}
                  className={clsChoice}
                >
                  <Typography variant="subtitle1">
                    A partir do cadastro
                  </Typography>
                  <StorageIcon style={{ fontSize: '10em' }} />
                  <Typography variant="body2" align="center">
                    A carga é gerada a partir do cadastro de clientes.
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        )}
        {integrationName && (
          <Box flexGrow={1} paddingLeft={1} paddingRight={1} flexBasis="100%">
            <Card
              variant="outlined"
              className={clsx(sendType === 'custom-integration' && clsSelected)}
            >
              <CardActionArea
                onClick={() => handleSelect('custom-integration')}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={2}
                  className={clsChoice}
                >
                  <Typography variant="subtitle1">
                    A partir do sistema {integrationName.toUpperCase()}
                  </Typography>
                  <ImportExportIcon style={{ fontSize: '10em' }} />
                  <Typography variant="body2" align="center">
                    A carga é gerada a partir de integração automatizada.
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        )}
        {(!integrationName || isSecretActive) && (
          <Box flexGrow={1} paddingLeft={1} flexBasis="100%">
            <Card
              variant="outlined"
              className={clsx(sendType === 'csv' && clsSelected)}
            >
              <CardActionArea onClick={() => handleSelect('csv')}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={2}
                  className={clsChoice}
                >
                  <Typography variant="subtitle1">Com um arquivo</Typography>
                  <DescriptionIcon style={{ fontSize: '10em' }} />
                  <Typography variant="body2" align="center">
                    Você precisa preparar um arquivo no formato .txt com os
                    dados a serem carregados.
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
}

SendJourneyStep1.propTypes = {
  visible: PropTypes.bool.isRequired,
};
export default SendJourneyStep1;
