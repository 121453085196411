import { useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import ButtonWithProgress from './ButtonWithProgress';

export default function ConfirmationDialog({ open, title, children, actions }) {
  const { isLoading } = useSelector((state) => state.app);

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) =>
          action.showLoading ? (
            <ButtonWithProgress
              tooltipTitle={action.name}
              key={index}
              disabled={isLoading}
              {...action.buttonProps}
            >
              {action.name}
            </ButtonWithProgress>
          ) : (
            <Button disabled={isLoading} key={index} {...action.buttonProps}>
              {action.name}
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
