import firebase from '../helpers/firebase';

const pixAccountsDao = {
  get: async () => {
    const db = firebase.firestore();

    let pixAccountsSnapshot = await db.collection('pix-accounts').get();
    let pixAccounts = pixAccountsSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return pixAccounts;
  },
};
export default pixAccountsDao;
