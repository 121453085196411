'use strict';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import firebase from '../../helpers/firebase';
import { newUserActions } from './new-user-slice';

const useStyles = makeStyles((_theme) => ({
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
}));

function InvitationWizard() {
  const classes = useStyles();
  const {
    newUser: {
      authData: { email },
    },
  } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  let isNextDisabled = false;

  const logoutButton = (
    <IconButton
      onClick={() => {
        dispatch(newUserActions.invitationWizardFail());
        firebase.auth().signOut();
      }}
      size="large"
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  );

  return (
    <Box
      width="100%"
      minHeight="100vh"
      padding={4}
      overflow="auto"
      display="flex"
      flexDirection="column"
    >
      <Paper className={classes.paper}>
        <EditForm>
          <EditFormHeader title="Confirmação" actions={logoutButton} />
          <EditFormContent>
            <Typography variant="body1" color="textPrimary" component="p">
              Quase tudo pronto! Para começar a utilizar o sistema, o último
              passo é confirmar seu e-mail.
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p">
              Um e-mail de confirmação será enviado para:
            </Typography>
            <Box p={2}>
              <Typography variant="body1" color="primary" component="p">
                {email}
              </Typography>
            </Box>
            <Typography variant="body1" color="textPrimary" component="p">
              Assim que possível acesse sua caixa de entrada e siga as
              instruções do e-mail.
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p">
              Você já pode acessar o sistema, mas só poderá criar as jornadas
              após confirmar o e-mail.
            </Typography>
            <Box marginTop={2}></Box>
            <Typography variant="body1" color="textPrimary" component="p">
              Confirme os dados, se estiverem corretos clique em COMEÇAR.
            </Typography>
          </EditFormContent>
        </EditForm>
        <Box flexGrow={1} />
        <Box p={2}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} />

            <Box marginLeft={1}>
              <ButtonWithProgress
                tooltipTitle="Começar"
                variant="contained"
                color="primary"
                disabled={isNextDisabled || isLoading}
                aria-label="salvar"
                startIcon={<SaveIcon />}
                onClick={() => {
                  dispatch(newUserActions.invitationWizardSuccess());
                }}
              >
                Começar
              </ButtonWithProgress>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default InvitationWizard;
