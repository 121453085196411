import { createSlice } from '@reduxjs/toolkit';

export const mailingSlice = createSlice({
  name: 'mailing',
  initialState: {
    uploadedFiles: [],
    mailingPreview: [],
    isProcessingPreview: false,
    previewEncoding: 'utf-8',
    previewDelimiter: ',',
    charge: undefined,
  },
  reducers: {
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload;
    },
    startProcessingPreview: (state) => {
      state.isProcessingPreview = true;
    },
    finishProcessingPreview: (state) => {
      state.isProcessingPreview = false;
    },
    getMailingPreview: () => {},
    getMailingPreviewSuccess: (state, action) => {
      state.mailingPreview = action.payload;
    },
    getMailingPreviewFail: (state, action) => {
      state.mailingPreview = [];
      state.mailingPreviewError = action.payload;
    },
    reset: (state) => {
      state.mailingPreview = [];
      state.uploadedFiles = [];
      state.selectedLines = [];
      state.deletedLines = [];
      state.isProcessingPreview = false;
      state.charge = undefined;
      state.createChargeError = undefined;
    },
    createCharge: (state) => {
      state.charge = undefined;
    },
    createChargeSuccess: (state, action) => {
      state.charge = action.payload;
    },
    createChargeFail: (state, action) => {
      state.charge = undefined;
      state.createChargeError = action.payload;
    },
    createCustomIntegrationCharge: (state) => {
      state.charge = undefined;
    },
    createCustomIntegrationChargeSuccess: (state, action) => {
      state.charge = action.payload;
    },
    createCustomIntegrationChargeFail: (state, action) => {
      state.charge = undefined;
      state.createChargeError = action.payload;
    },
    processCharge: (state) => {
      state.charge = undefined;
    },
    processChargeSuccess: (state, action) => {
      state.charge = action.payload;
    },
    processChargeFail: (state, action) => {
      state.charge = undefined;
      state.createChargeError = action.payload;
    },
    setSelectedLines: (state, action) => {
      state.selectedLines = action.payload;
    },
  },
});

export const mailingActions = mailingSlice.actions;
export default mailingSlice.reducer;
