import ErrorIcon from '@mui/icons-material/Error';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import firebase from '../../helpers/firebase';
import { smartTipActions } from '../smart-tip/smart-tip-slice';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
}));

export default function Profile({ onClose }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    userData: { displayName, email, emailVerified },
  } = useSelector((state) => state.auth);

  const { dismissed } = useSelector((state) => state.smartTip);

  const [sendClicked, setSendClicked] = useState(false);
  const [clickedTime, setClickedTime] = useState();

  const handleSendClick = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        setClickedTime(Date.now());
        setSendClicked(true);
      });
    // .catch(console.error);
  };

  const resetSmartTipsClick = () => {
    dispatch(smartTipActions.reset());
  };

  return (
    <EditForm>
      <EditFormHeader title="Perfil" onCloseClick={onClose} />
      <EditFormContent>
        <Box>
          <Typography variant="body2" color="textSecondary" component="p">
            Nome:
          </Typography>
          <Typography variant="body1" color="primary" component="p">
            {' '}
            {displayName}
          </Typography>
        </Box>
        <Box
          marginTop={2}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
        >
          <Box display="flex" flexDirection="column" marginRight={1}>
            <Typography variant="body2" color="textSecondary" component="p">
              e-mail:
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography variant="body1" color="primary" component="p">
                {' '}
                {email}
              </Typography>
              {emailVerified ? (
                <VerifiedUserIcon
                  fontSize="small"
                  className={classes.success}
                />
              ) : (
                <ErrorIcon color="error" />
              )}
            </Stack>
          </Box>
          {!emailVerified && (
            <>
              <Box flexGrow={1} />
              <Box marginLeft={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={sendClicked}
                  onClick={handleSendClick}
                >
                  Re-enviar e-mail de confirmação
                </Button>
              </Box>
            </>
          )}
        </Box>
        {!emailVerified && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <>
              <Typography variant="caption" color="error" component="p">
                o e-mail não foi confirmado
              </Typography>
              {sendClicked && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  enviado {moment(clickedTime).fromNow()}
                </Typography>
              )}
            </>
          </Box>
        )}
        <Box marginTop={4}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={resetSmartTipsClick}
            disabled={(dismissed ?? []).length === 0}
          >
            Reiniciar dicas
          </Button>
        </Box>
      </EditFormContent>
    </EditForm>
  );
}

Profile.propTypes = {
  onClose: PropTypes.func,
};
