/* eslint-disable no-unused-vars */
'use strict';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BuildIcon from '@mui/icons-material/Build';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import Clock from '../../components/Clock';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';
import EditFormMessage from '../../components/EditForm/EditFormMessage.jsx';
import TagField from '../../components/EditForm/TagField.jsx';

import { useForm } from 'react-hook-form';
import { formatValue } from '../../helpers/formatter';
import JourneyPix from './JourneyPix';
import NextBotDetail from './NextBotDetail';
import PixDetail from './PixDetail';
import Story from './Story';
import { monitorActions } from './monitor-slice';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import FirebaseConsoleLink from '../../components/FirebaseConsoleLink.jsx';
import ResponsiveDialog from '../../components/ResponsiveDialog.jsx';
import { pixStatusMap } from '../../helpers/pix-status';
import { appActions } from '../app/app-slice';
import SmartTip from '../smart-tip/SmartTip.jsx';
import FormChangeContact from './FormChangeContact.jsx';
import JourneyIssues from './JourneyIssues.jsx';

const useStyles = makeStyles((theme) => ({
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
}));

function JourneyDetail({ onClose }) {
  const { clsSecret, clsSecretActive } = useStyles();
  const dispatch = useDispatch();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isRestartConfirmationOpen, setIsRestartConfirmationOpen] =
    useState(false);
  const [isInterruptConfirmationOpen, setIsInterruptConfirmationOpen] =
    useState(false);
  const [isChangeContactFormOpen, setIsChangeContactFormOpen] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { permissions } = useSelector((state) => state.auth);

  const { isSecretActive } = useSelector((state) => state.app);

  const {
    enterprise: { id: eid, ignoreCustomer },
  } = useSelector((state) => state.settings);

  const {
    selected,
    //journeys,
    selectedPixs,
    journey: {
      jid,
      customerData: { id, cid, name: customerName = '', phone = '' } = {},
      currentStepId,
      currentPhase,
      startAwait,
      contactData: { scheduleAfterSeconds, scheduleAt, botId: nextBotId } = {},
      hasErrors,
      issue,
      error,
      lastJourneyResumeAt,
    } = {},
    //pixs,
    isAwaitingJourneyCommand,
    bots,
  } = useSelector((state) => state.monitor);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      tags: selected.pix?.invoiceData?.customerData?.tags
        ? selected.pix?.invoiceData?.customerData?.tags.map((t) => ({
            name: t,
          }))
        : [],
    },
  });

  const onTagFormSubmit = (formData) => {
    const payload = {
      cid: selected.pix.invoiceData.customerData.cid,
      tags: formData.tags.map(({ name }) => name),
    };
    dispatch(monitorActions.updateTags(payload));
    reset(undefined, { keepValues: true });
    setDataUpdated(true);
  };

  const hasRecentRestart =
    lastJourneyResumeAt &&
    moment().isBefore(moment(lastJourneyResumeAt).add(24, 'hour'));
  const nextRestartAvailable = formatValue(
    moment(lastJourneyResumeAt).add(24, 'hour'),
    'dateTime'
  );

  const canRestart = selectedPixs
    ? !!selectedPixs.find(
        ({ jid: j, status }) =>
          j === jid &&
          (status === pixStatusMap.NO_PRAZO ||
            status === pixStatusMap.VENCIDA ||
            status === pixStatusMap.SEM_CPC)
      ) &&
      currentPhase !== 'AWAITING_CALLBACK' &&
      !hasRecentRestart
    : false;

  const canInterrupt = currentPhase !== 'END_JOURNEY';
  const canChangePhone = true;

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <EditForm>
        <EditFormHeader
          title={
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <Typography variant="h5">
                {customerName} - {formatValue(phone, 'phone')}
              </Typography>

              {permissions['pix-pho'] && (
                <Box
                  marginLeft={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Button
                    disabled={!canChangePhone}
                    onClick={() => {
                      dispatch(monitorActions.loadNoContactPhones(id ?? cid));
                      setIsChangeContactFormOpen(true);
                    }}
                    size="large"
                    startIcon={<EditIcon />}
                  >
                    Alterar dados de contato
                  </Button>

                  <SmartTip id="nono-digito">
                    <Typography>
                      DICA! Se o cliente não receber a mensagem, experimente
                      retirar o primeiro dígito.
                    </Typography>
                    <Box marginTop={2}>
                      <Typography>
                        Exemplo: Altere de (77) 9xxxx-xxxx para (77) xxxx-xxxx
                      </Typography>
                    </Box>
                  </SmartTip>
                </Box>
              )}

              <Button
                href={`https://api.whatsapp.com/send?phone=55${phone}`}
                target="_blank"
                rel="noopener"
                component={Link}
                startIcon={<WhatsAppIcon />}
              >
                Conversar com cliente
              </Button>
            </Box>
          }
          onCloseClick={() => onClose(dataUpdated)}
          actions={
            permissions['dbg'] && (
              <IconButton
                onClick={() =>
                  dispatch(appActions.setIsSecretActive(!isSecretActive))
                }
                className={clsx(isSecretActive && clsSecretActive)}
                size="large"
              >
                <BuildIcon />
              </IconButton>
            )
          }
        />
        {isSecretActive && (
          <FirebaseConsoleLink doc={`/journey-sessions/${jid}`} />
        )}
        {isSecretActive && !ignoreCustomer && (
          <FirebaseConsoleLink doc={`/enterprises/${eid}/customers/${cid}`} />
        )}
        {isSecretActive && hasErrors && (
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsSecret}
          >
            Jornada com erro{error?.message && ` - ${error?.message}`}
          </Typography>
        )}
        {currentPhase === 'END_JOURNEY' && (
          <Box
            marginTop={2}
            marginBottom={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            // justifyContent="center"
          >
            <InfoIcon color="secondary" />
            <Box marginLeft={1}>
              <Typography variant="h5">
                Essa jornada está FINALIZADA!
              </Typography>
            </Box>
          </Box>
        )}
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onTagFormSubmit)}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingTop={2}
            paddingBottom={2}
          >
            <TagField control={control} />
            {isDirty && (
              <Box marginLeft={2} display="flex" flexDirection="row">
                <ButtonWithProgress
                  tooltipTitle="Salvar"
                  type="submit"
                  onClick={handleSubmit(onTagFormSubmit)}
                  variant="outlined"
                  color="primary"
                  disabled={false}
                  aria-label="salvar"
                  startIcon={<SaveIcon />}
                >
                  Salvar
                </ButtonWithProgress>
                <Box marginLeft={1}>
                  <ButtonWithProgress
                    tooltipTitle="Descartar"
                    type="submit"
                    onClick={() =>
                      reset({
                        tags: selected.pix.invoiceData.customerData.tags.map(
                          (name) => ({ name })
                        ),
                      })
                    }
                    disabled={false}
                    aria-label="descartar"
                    startIcon={<ClearIcon />}
                  >
                    Descartar
                  </ButtonWithProgress>
                </Box>
              </Box>
            )}
          </Box>
        </form>
        {permissions['iss-slv'] && issue && (
          <Box marginBottom={2}>
            <JourneyIssues />
          </Box>
        )}
        <JourneyPix />
        {Object.keys(bots).length > 0 ? <Story /> : <Box flexGrow={1} />}

        <EditFormFooter>
          <EditFormMessage>
            {' '}
            <Typography variant="caption">
              <Clock />
            </Typography>
            {!(currentPhase === 'AWAITING_SCHEDULED_CONTACT' && nextBotId) && (
              <Box paddingLeft={1}>
                {currentPhase === 'END_JOURNEY' ? (
                  <Chip variant="outlined" label="Finalizada" />
                ) : (
                  <>
                    <Chip
                      label="Em andamento"
                      variant="outlined"
                      color="primary"
                    />
                  </>
                )}
              </Box>
            )}
            {isSecretActive && (
              <Box marginLeft={1}>
                <Chip
                  className={clsSecret}
                  label={`${currentStepId} - ${currentPhase}`}
                  variant="outlined"
                  color={currentPhase !== 'END_JOURNEY' ? 'primary' : undefined}
                />
              </Box>
            )}
            {currentPhase === 'AWAITING_SCHEDULED_CONTACT' && nextBotId && (
              <Box marginLeft={1}>
                <NextBotDetail
                  botId={nextBotId}
                  scheduledStartAt={
                    scheduleAt ?? startAwait + scheduleAfterSeconds * 1000
                  }
                />
              </Box>
            )}
          </EditFormMessage>
          <EditFormAction
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {permissions['pix-rst'] && (
              <Stack direction="row" alignItems="center" marginLeft={1} gap={1}>
                {hasRecentRestart && (
                  <Typography variant="caption">
                    Você pode enviar novamente em: {nextRestartAvailable}
                  </Typography>
                )}
                <ButtonWithProgress
                  tooltipTitle="Reenviar lembretes"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setIsRestartConfirmationOpen(true);
                    handleMenuClose();
                  }}
                  disabled={
                    (!canRestart || isAwaitingJourneyCommand) && !isSecretActive
                  }
                  isLoading={isAwaitingJourneyCommand}
                  startIcon={
                    hasRecentRestart ? <TimerIcon /> : <PlayArrowIcon />
                  }
                >
                  Reenviar lembretes
                </ButtonWithProgress>
              </Stack>
            )}
            {(permissions['jrn-del'] || permissions['jrn-int']) && (
              <Box marginLeft={1}>
                <IconButton
                  onClick={handleMenuClick}
                  disabled={isAwaitingJourneyCommand}
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )}
          </EditFormAction>
        </EditFormFooter>
      </EditForm>
      {(permissions['jrn-del'] || permissions['jrn-int']) && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {permissions['jrn-int'] && (
            <MenuItem
              disabled={!canInterrupt || isAwaitingJourneyCommand}
              onClick={() => {
                setIsInterruptConfirmationOpen(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <StopIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Interromper</ListItemText>
            </MenuItem>
          )}
          {permissions['jrn-del'] && (
            <MenuItem
              disabled={isAwaitingJourneyCommand}
              onClick={() => {
                setIsDeleteConfirmationOpen(true);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Excluir</ListItemText>
            </MenuItem>
          )}
        </Menu>
      )}
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsDeleteConfirmationOpen(false),
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(monitorActions.deleteJourney(jid));
              },
              startIcon: <DeleteIcon />,
            },
          },
        ]}
      >
        Tem certeza que deseja excluir? Essa ação não poderá ser desfeita
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isRestartConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsRestartConfirmationOpen(false),
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(monitorActions.restartJourney({ jid }));
                setIsRestartConfirmationOpen(false);
              },
              startIcon: <PlayArrowIcon />,
            },
          },
        ]}
      >
        Tem certeza que deseja reenviar os lembretes?
      </ConfirmationDialog>
      {selected?.isDetailOpen && (
        <ResponsiveDialog
          open={!!selected}
          maxWidth="sm"
          fullWidth
          onClose={() => {
            dispatch(
              monitorActions.setSelected({
                pix: selected.pix,
                isDetailOpen: false,
              })
            );
          }}
        >
          <PixDetail
            pix={selected}
            onClose={() => {
              dispatch(
                monitorActions.setSelected({
                  pix: selected.pix,
                  isDetailOpen: false,
                })
              );
            }}
          />
        </ResponsiveDialog>
      )}

      <ResponsiveDialog
        open={isChangeContactFormOpen}
        maxWidth="sm"
        onClose={() => setIsChangeContactFormOpen(false)}
        fullWidth
      >
        <FormChangeContact
          onCancelClick={() => setIsChangeContactFormOpen(false)}
          onConfirmClick={(newContactData) => {
            setIsChangeContactFormOpen(false);
            dispatch(
              monitorActions.changeContact({
                jid,
                newContactData,
                ignoreCustomer,
              })
            );
          }}
        />
      </ResponsiveDialog>
      <ConfirmationDialog
        open={isInterruptConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsInterruptConfirmationOpen(false),
            },
          },
          {
            name: 'Interromper',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: () => {
                dispatch(monitorActions.interruptJourney(jid));
                setIsInterruptConfirmationOpen(false);
              },
              startIcon: <PlayArrowIcon />,
            },
          },
        ]}
      >
        Tem certeza que deseja interromper a jornada?
      </ConfirmationDialog>
    </>
  );
}

JourneyDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default JourneyDetail;
