import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckIcon from '@mui/icons-material/Check';

import CloseIcon from '@mui/icons-material/Close';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { green } from '@mui/material/colors';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { enterpriseSelectActions } from './enterprise-select-slice';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  row: {
    cursor: 'pointer',
  },
}));

const Check = ({ checked }) => {
  if (checked) return <CheckIcon style={{ color: green[500] }} />;
  else return <CloseIcon color="error" />;
};
Check.propTypes = {
  checked: PropTypes.bool,
};

export default function EnterpriseSelect({ onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    enterpriseSelect: { data },
    settings: { enterprise },
  } = useSelector((state) => state);
  const { data: users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(enterpriseSelectActions.loadEnterprises());
  }, [dispatch]);
  const handleClick = (event, id) => {
    dispatch(enterpriseSelectActions.selectEnterprise(id));
  };

  return (
    <EditForm>
      <EditFormHeader title="Selecione a empresa" onCloseClick={onClose} />
      <EditFormContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell size="small"></TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Proprietário</TableCell>
                <TableCell>Ativa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...data]
                .sort(
                  (a, b) =>
                    (b.isActive ? 1 : 0) - (a.isActive ? 1 : 0) ||
                    a.name.localeCompare(b.name)
                )
                .map((row) => {
                  const isItemSelected = row.id === enterprise.id;
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      tabIndex={-1}
                      className={classes.row}
                    >
                      <TableCell size="small" padding="checkbox">
                        {isItemSelected && <CheckIcon />}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name} ({row.pixKeyDestinationType})
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {users
                          ? users
                              .map(({ id, displayName, email }) => ({
                                id,
                                formattedName: `${displayName} - ${email}`,
                              }))
                              .find(({ id }) => id === row.ownerUid)
                              ?.formattedName ?? ''
                          : ''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Check checked={row.isActive} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </EditFormContent>
    </EditForm>
  );
}

EnterpriseSelect.propTypes = {
  onClose: PropTypes.func,
};
