'use strict';
import PropTypes from 'prop-types';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pixStatusMap } from '../../helpers/pix-status';

const StatusIcon = ({ status, ...props }) => {
  const { clsSuccessIcon, clsWarningIcon } = makeStyles((theme) => ({
    clsSuccessIcon: {
      color: theme.palette.success.main,
    },
    clsWarningIcon: {
      color: theme.palette.warning.main,
    },
  }))();
  let Icon;
  switch (status) {
    case pixStatusMap.PAGA:
      Icon = <AttachMoneyIcon {...props} className={clsSuccessIcon} />;
      break;
    case pixStatusMap.NO_PRAZO:
      Icon = <CheckIcon {...props} />;
      break;
    case pixStatusMap.VENCIDA:
      Icon = <WarningIcon {...props} className={clsWarningIcon} />;
      break;
    case pixStatusMap.PENDENCIA:
      Icon = <WarningIcon {...props} className={clsWarningIcon} />;
      break;
    case pixStatusMap.SEM_CPC:
      Icon = <PhoneMissedIcon {...props} color="error" />;
      break;
    case pixStatusMap.CANCELADA:
      Icon = <BlockIcon {...props} color="error" />;
      break;
    case pixStatusMap.EXPIRADA:
      Icon = <BlockIcon {...props} color="error" />;
      break;
    default:
      Icon = <HelpIcon {...props} color="error" />;
      break;
  }
  return <Tooltip title={status}>{Icon}</Tooltip>;
};
StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusIcon;
