import { useSelector } from 'react-redux';
import firebase from '../../helpers/firebase';

import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const getFormat = (field) => {
  switch (field.type) {
    case 'message':
      return 'Texto';
    case 'docNumber':
      return 'CPF ou CNPJ válido';
    case 'phone':
      return 'Ex: 11999991234';
    case 'text':
      return 'Texto';
    case 'file':
      return 'Texto';
    case 'decimal':
      return 'Decimal. Ex: 100.00';
    case 'integer':
      return 'Número';
    case 'boolean':
      return 'true/false';
    case 'futureDate':
    case 'date':
      return 'DD/MM/AAAA';
    case 'select':
      return (
        'Número: ' +
        [...field.options]
          .sort((a, b) => a.value - b.value)
          .map(({ label, value }) => `${value}: ${label}`)
          .join(', ')
      );
  }
};

const useStyles = makeStyles((theme) => ({
  clsField: {
    height: '100%',
  },
  clsFieldContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

function CsvFields() {
  const { clsField, clsFieldContent } = useStyles();

  const { selected, csvFields } = useSelector((state) => state.journeys);
  const {
    enterprise: { id: eid },
  } = useSelector((state) => state.settings);
  const { id: jid, name } = selected;

  const getTemplateDownloadLink = () => {
    const { projectId } = firebase.app().options;
    let baseUrl = '';
    if (projectId === 'condomi-prod')
      baseUrl = 'https://condomi.zetaone.com.br';
    else baseUrl = `https://${projectId}.zetaone.com.br`;

    const templateName = `template${name.replace(/ /g, '')}`;

    const url = `${baseUrl}/fn-mailing-template/${eid}/${jid}/${templateName}`;
    return url;
  };

  return (
    <>
      <Box display="flex" alignItems="baseline">
        <Box>
          <Typography variant="body2" color="textSecondary" component="span">
            Campos a serem definidos no arquivo:
          </Typography>
        </Box>
        <Box margin={1}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<GetAppIcon />}
            component={Link}
            href={getTemplateDownloadLink()}
            target="_blank"
            rel="noopener"
          >
            Template
          </Button>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {Object.keys(csvFields)
          .sort((a, b) => csvFields[a].showOrder - csvFields[b].showOrder)
          .map((key) => (
            <Grid key={key} item xs={6} sm={3}>
              <Card variant="outlined" className={clsField}>
                <CardContent className={clsFieldContent}>
                  <Typography variant="body2">
                    {csvFields[key].label}
                  </Typography>
                  <Typography variant="caption" color="primary">
                    {getFormat(csvFields[key])}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Box marginTop={2} marginBottom={2}></Box>
    </>
  );
}
export default CsvFields;
