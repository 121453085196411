import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SessionDetail from './SessionDetail';

import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import getSid from '../../helpers/sid';
import { waStatusActions } from './wa-status-slice';

export default function WAStatus() {
  const dispatch = useDispatch();

  const {
    waStatus: {
      sessions,
      sessionsDetails,
      isConnecting,
      isLoggingOut,
      isLoading,
    },
    settings: {
      enterprise: { waNotificationPhone },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(waStatusActions.loadSessions());
    var timer = setInterval(
      () => dispatch(waStatusActions.loadSessionDetails()),
      5000
    );

    return function cleanup() {
      clearInterval(timer);
    };
  }, [dispatch]);

  const handleTestClick = () => {
    dispatch(
      waStatusActions.sendTest({ sessions, sid: getSid(waNotificationPhone) })
    );
  };

  return (
    <Box p={2}>
      {isLoading ? (
        <Container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <CircularProgress size="2em" />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Box
            marginBottom={2}
            display="flex"
            flexDirection="row"
            alignItems="baseline"
          >
            <Typography variant="h5">WhatsApp</Typography>
            <Box flexGrow={1} />
            <Button
              color="primary"
              onClick={() => handleTestClick()}
              endIcon={<SendIcon />}
            >
              Testar
            </Button>
          </Box>
          {sessions.map((session, key) => (
            <SessionDetail
              session={{ ...session, ...sessionsDetails[session.id] }}
              isConnecting={isConnecting[session.id]}
              isLoggingOut={isLoggingOut[session.id]}
              key={key}
            />
          ))}
        </>
      )}
    </Box>
  );
}
