import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dismissed: [],
};
export const smartTipSlice = createSlice({
  name: 'smart-tip',
  initialState,
  reducers: {
    reset: () => initialState,
    setDismissed: (state, action) => {
      state.dismissed = action.payload;
    },
    addDismissed: (state, action) => {
      if (!state.dismissed) state.dismissed = [];
      if (!state.dismissed.find((tip) => tip === action.payload))
        state.dismissed = [...state.dismissed, action.payload];
    },
  },
});

export const smartTipActions = smartTipSlice.actions;

export default smartTipSlice.reducer;
