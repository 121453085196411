'use strict';
import { Box, Button, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import CustomRichTextEditor from '../../helpers/custom-richtext-editor';

const useStyles = makeStyles((_theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  strike: {
    textDecoration: 'line-through',
  },
  buttonGroup: {
    minHeight: '30px',
  },
}));

function DefaultToolbar() {
  const editor = useSlate();
  const classes = useStyles();
  // const [isPreview, setIsPreview] = useState(false);

  // const togglePreview = () => {
  //   setIsPreview(!isPreview);
  //   CustomRichTextEditor.togglePreview(editor, !isPreview);
  // };

  useEffect(() => {
    CustomRichTextEditor.togglePreview(editor, false);
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box marginBottom={1}>
          <ButtonGroup size="small">
            <Button
              variant={
                CustomRichTextEditor.isBoldMarkActive(editor) && 'contained'
              }
              className={classes.bold}
              onClick={() => {
                CustomRichTextEditor.toggleBoldMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              B
            </Button>
            <Button
              variant={
                CustomRichTextEditor.isItalicMarkActive(editor) && 'contained'
              }
              className={classes.italic}
              onClick={() => {
                CustomRichTextEditor.toggleItalicMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              I
            </Button>
            <Button
              variant={
                CustomRichTextEditor.isStrikeMarkActive(editor) && 'contained'
              }
              className={classes.strike}
              onClick={() => {
                CustomRichTextEditor.toggleStrikeMark(editor);
                ReactEditor.focus(editor);
              }}
            >
              S
            </Button>
          </ButtonGroup>
        </Box>
        {/* <Box flexGrow={1} />
        <Box marginLeft={1} marginBottom={1}>
          <ButtonGroup size="small">
            <Button
              onClick={() => {
                console.log(
                  CustomRichTextEditor.serialize({ value: editor.children })
                );
                console.log(editor.children);
                ReactEditor.focus(editor);
              }}
            >
              Log
            </Button>
            <Button
              variant={isPreview ? 'contained' : undefined}
              color={isPreview ? 'primary' : undefined}
              onClick={togglePreview}
            >
              {!isPreview && <VisibilityIcon fontSize="small" />}
              {isPreview && <VisibilityOffIcon fontSize="small" />}
            </Button>
          </ButtonGroup>
        </Box> */}
      </Box>
    </>
  );
}
DefaultToolbar.propTypes = {};
export default DefaultToolbar;
