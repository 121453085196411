import moment from 'moment';
import 'moment/locale/pt-br';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      textTransform: 'capitalize',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

export default function NotificationDetail({ notification, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  if (notification) {
    const { title, content, createdAt, severity, readAt, action } =
      notification;

    const handleCustomAction = ({ type, payload }) => {
      dispatch({ type, payload });
    };
    return <>
      <DialogTitle className={classes.title}>
        <div>
          <Typography variant="h5" component="span">
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </div>

        <div className={classes.flexGrow} />
        {severity === 'info' && <InfoIcon className={classes.info} />}
        {severity === 'warning' && (
          <WarningIcon className={classes.warning} />
        )}
        {severity === 'error' && <ErrorIcon color="error" />}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
          {action && (
            <Button onClick={() => handleCustomAction(action)}>
              {action.description}
            </Button>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {readAt && (
          <Typography variant="caption" className={classes.marginLeft}>
            Lido em {moment(readAt).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        )}
        <div className={classes.flexGrow} />
        <Button
          onClick={() => {
            onClose(false);
          }}
          color="primary"
        >
          Marcar como Não Lida
        </Button>
        <Button
          onClick={() => {
            onClose(true);
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </>;
  } else return <div />;
}

NotificationDetail.propTypes = {
  notification: PropTypes.object,
  onClose: PropTypes.func,
};
