'use strict';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import { formatValue } from '../../helpers/formatter';
import removeSpecialChars from '../../helpers/remove-special-chars';
import { monitorActions } from './monitor-slice';

import moment from 'moment';
import EmptyContent from '../../components/EmptyContent';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import TagChip from '../../components/Tags/TagChip';
import { pixStatusMap } from '../../helpers/pix-status';
import getSid from '../../helpers/sid';
import JourneyDetail from './JourneyDetail';
import SearchForm from './SearchForm';
import StatusIcon from './StatusIcon';

const TagComponent = (props) => {
  return <TagChip {...props} size="small" />;
};

export default function Monitor() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(monitorActions.exit());
      dispatch(monitorActions.closeDetail());
    };
  }, [dispatch]);

  const {
    app: { isSecretActive },
    monitor: {
      pixs,
      journeys,
      highlightedIds,
      isLoadingJourneys,
      searchState,
      recordsLimit,
    },
  } = useSelector((state) => state);

  const handleSearch = (searchData) => {
    dispatch(monitorActions.load(searchData));
  };

  const enhancedData = pixs.map((row) => ({
    id: row.id,
    ...row.invoiceData,
    name: {
      value: row.invoiceData?.customerData?.name ?? '',
      props: {
        sortValue: row.invoiceData?.customerData?.name ?? '',
        filterValue: removeSpecialChars(
          row.invoiceData?.customerData?.name ?? ''
        ),
      },
    },
    invoiceId: {
      value: row.invoiceData?.invoiceId ?? '',
      props: {
        sortValue: row.invoiceData?.invoiceId ?? '',
        filterValue: removeSpecialChars(row.invoiceData?.invoiceId ?? '' ?? ''),
      },
    },
    phone: row.invoiceData?.customerData?.phone ?? '',
    // currentValue: parseFloat(row.cob?.valor?.original ?? 0),
    currentValue: parseFloat(row.invoiceData?.invoiceValue ?? 0),
    result: row.result?.resultId,
    resultAt: row.resultAt,
    overdueAt: row.overdueAt,
    expiredAt: row.expiredAt,
    status: {
      value: { status: row.status, issue: row.issue },
      props: {
        sortValue: row.issue ? 'PENDÊNCIAS' : row.status,
        filterValue: row.issue ? 'PENDÊNCIAS' : row.status,
      },
    },
    tags: row.invoiceData?.customerData?.tags ?? [],
    keywords: removeSpecialChars(
      [
        row.invoiceData?.customerData?.name,
        row.invoiceData?.customerData?.phone,
        row.invoiceData?.invoiceId,
        row.invoiceData?.docNumber,
        row.status,
        moment(row.invoiceData?.invoiceDate).format('DD/MM/YYYY'),
        moment(row.invoiceData?.serviceDate).format('MM/YYYY'),
        ...(row.invoiceData?.customerData?.tags ?? []),
      ].join(' ')
    ),
  }));
  const secretColumns = isSecretActive
    ? [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'txid',
        },
      ]
    : [];

  const columns = [
    ...secretColumns,
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Responsável',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Telefone',
      formatter: (v) => formatValue(v, 'phone'),
    },
    {
      id: 'invoiceId',
      numeric: false,
      label: 'Identificador',
    },
    {
      id: 'docNumber',
      numeric: false,
      label: 'Documento',
      formatter: (v) => formatValue(v, 'docNumber'),
    },
    {
      id: 'invoiceDate',
      numeric: false,
      label: 'Vencimento',
      formatter: (v) => formatValue(v, 'date'),
    },
    {
      id: 'serviceDate',
      numeric: false,
      label: 'Mês Referência',
      formatter: (v) => formatValue(v, 'yearMonth'),
    },
    {
      id: 'currentValue',
      numeric: false,
      label: 'Valor',
      formatter: (v) => formatValue(v, 'decimal'),
    },
    {
      id: 'tags',
      numeric: false,
      disablePadding: true,
      label: 'Marcadores',
      formatter: (value) => TagComponent({ value }),
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      // eslint-disable-next-line react/display-name
      formatter: (v) => (
        <Box display="flex" flexDirection="row">
          <StatusIcon
            fontSize="small"
            status={v.value.issue ? pixStatusMap.PENDENCIA : v.value.status}
          />
          <Box marginLeft={1}>
            <Typography>
              {v.value.issue ? 'PENDÊNCIAS' : v.value.status}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  const filters = {
    keywords: {
      type: 'keywords',
      label: 'Palavras-chave',
      currentValue: '',
    },
    serviceDate: {
      type: 'yearMonth',
      label: 'Data de referência',
      currentValue: null,
    },
    invoiceDate: {
      type: 'date',
      label: 'Data de vencimento',
      currentValue: null,
    },
    status: {
      type: 'list',
      options: [
        pixStatusMap.PAGA,
        pixStatusMap.NO_PRAZO,
        pixStatusMap.VENCIDA,
        pixStatusMap.CANCELADA,
        pixStatusMap.EXPIRADA,
        pixStatusMap.SEM_CPC,
        'PENDÊNCIAS',
      ],
      label: 'Status',
      currentValue: [],
    },
    tags: {
      type: 'tag-list',
      label: 'Marcadores',
      currentValue: [],
    },
  };
  const subtitle =
    enhancedData.length === recordsLimit + 1
      ? `Atenção! Exibindo somente os primeiros ${recordsLimit} registros. Caso necessário faça uma busca mais detalhada`
      : undefined;

  return (
    <>
      <EnhancedTable
        title="Cobranças"
        subtitle={subtitle}
        onRowClick={({ id: idToFind }) => {
          const pix = pixs.find(({ id }) => id === idToFind);
          dispatch(monitorActions.setSelected({ pix, isDetailOpen: false }));
          dispatch(
            monitorActions.loadJourneys({
              customerDocNumber: pix.invoiceData.customerData.customerDocNumber,
              jid: pix.jid,
              sid: getSid(pix.invoiceData.customerData.phone),
            })
          );
        }}
        data={enhancedData.slice(0, recordsLimit)}
        columns={columns}
        filters={filters}
        orderBy="invoiceDate"
        orderByDirection="desc"
        tableProps={{ size: 'small' }}
        rowsPerPage={100}
        disableSelection={true}
        highlightedIds={highlightedIds}
        searchField={<SearchForm onSearch={handleSearch} />}
      />
      {enhancedData.length === 0 && (
        <Box display="flex" flexDirection="column" alignItems="center">
          {searchState.firstSearch && (
            <EmptyContent
              title="Nada!"
              detail="Tente utilizar a palavra completa, ou o documento completo."
            />
          )}
        </Box>
      )}

      <Backdrop open={isLoadingJourneys}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {journeys && (
        <ResponsiveDialog
          open={!!journeys}
          maxWidth="xl"
          fullWidth
          fullHeight
          onClose={() => {
            dispatch(monitorActions.closeDetail(false));
          }}
        >
          <JourneyDetail
            onClose={(dataUpdated) => {
              dispatch(monitorActions.closeDetail(dataUpdated));
            }}
          />
        </ResponsiveDialog>
      )}
    </>
  );
}
