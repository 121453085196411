'use strict';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/pt-br';

import ResponsiveDialog from '../../components/ResponsiveDialog';
import NotificationDetail from './NotificationDetail';
import { notifierActions } from './notifier-slice';

const useStyles = makeStyles((theme) => ({
  popover: {
    //maxWidth: '50em',
  },
  notificationIcons: {
    '& .MuiListItemIcon-root': {
      minWidth: '0px',
      paddingRight: theme.spacing(2),
    },
  },
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  unread: {
    '& .MuiTypography-body2': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export default function NotificationsList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, selected } = useSelector((state) => state.notifier);

  const handleNotificationsClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationSelect = (not) => {
    dispatch(notifierActions.select(not));
  };
  const handleNotificationDetailClose = ({ id, isRead }) => {
    if (selected.isRead !== isRead)
      dispatch(notifierActions.setNotificationIsRead({ id, isRead }));
    dispatch(notifierActions.select());
  };

  return <>
    <IconButton color="inherit" onClick={handleNotificationsClick} size="large">
      <Badge
        badgeContent={notifications.filter((n) => !n.isRead).length}
        color="secondary"
      >
        <NotificationsIcon />
      </Badge>
    </IconButton>
    <Popover
      className={classes.popover}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleNotificationsClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List dense>
        {notifications.length === 0 && (
          <ListItem disabled>
            <ListItemText>Nenhuma notificação</ListItemText>
          </ListItem>
        )}
        {notifications
          .slice()
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((not, i) => (
            <ListItem
              button
              key={i}
              className={clsx(
                classes.notificationIcons,
                !not.isRead && classes.unread
              )}
              onClick={() => handleNotificationSelect(not)}
            >
              <ListItemIcon>
                {not.severity === 'info' && (
                  <InfoIcon className={classes.info} />
                )}
                {not.severity === 'warning' && (
                  <WarningIcon className={classes.warning} />
                )}
                {not.severity === 'error' && <ErrorIcon color="error" />}
                {not.severity === 'success' && (
                  <DoneIcon className={classes.success} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={not.title}
                secondary={moment(not.createdAt).fromNow()}
              />
            </ListItem>
          ))}
      </List>
    </Popover>
    <ResponsiveDialog open={!!selected} maxWidth="sm" fullWidth>
      <NotificationDetail
        notification={selected}
        onClose={(isRead) => {
          handleNotificationDetailClose({
            id: selected.id,
            isRead,
          });
        }}
      />
    </ResponsiveDialog>
  </>;
}
