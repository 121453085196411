import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import useIsSmallScreen from '../hooks/useIsSmallScreen';

const useStyles = makeStyles(() => ({
  clsPaper: {
    height: 'calc(100% - 64px)',
  },
}));

function ResponsiveDialog({ children, fullHeight, classes, ...otherProps }) {
  const fullScreen = useIsSmallScreen();
  const { clsPaper } = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      {...otherProps}
      classes={{
        ...classes,
        paper: fullHeight && !fullScreen ? clsPaper : undefined,
      }}
    >
      {children}
    </Dialog>
  );
}

ResponsiveDialog.propTypes = {
  fullHeight: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default ResponsiveDialog;
