'use strict';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import EditFormField from '../../components/EditForm/EditFormField';

const useStyles = makeStyles((theme) => ({
  clsTuneOpen: {
    color: theme.palette.primary.main,
  },
}));

function SearchForm({ onSearch }) {
  const { clsTuneOpen } = useStyles();
  const {
    monitor: { searchState },
  } = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      keywords: searchState.inputs?.keywords ?? '',
      serviceDate: searchState.inputs?.serviceDate ?? '',
      docNumber: searchState.inputs?.docNumber ?? '',
    },
  });
  const onSubmit = (data) => {
    if (
      (data.keywords ?? '') !== '' ||
      (data.serviceDate ?? '') !== '' ||
      (data.docNumber ?? '') !== ''
    ) {
      setAnchorEl(null);
      onSearch({
        ...data,
        keywords: data.keywords
          ? data.keywords.replace(/[^A-Za-z0-9]+/g, '')
          : '',
      });
    }
  };

  const { keywords, serviceDate, docNumber } = watch();
  const searchButtonEnabled =
    (keywords ?? '') !== '' ||
    (serviceDate ?? '') !== '' ||
    (docNumber ?? '') !== '';
  const isTuneOpen = Boolean(anchorEl);
  const hasTuneFields = (serviceDate ?? '') !== '' || (docNumber ?? '') !== '';
  const handleCurrentMonthSearch = (e) => {
    e.preventDefault();
    setValue('serviceDate', moment().format('YYYY-MM'), {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
    handleSubmit(onSubmit)();
  };

  return (
    // <Box p={2}>
    // </Box>
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <EditFormField
        id="keywords"
        field={{
          name: 'keywords',
          label: '',
          type: 'text',
        }}
        // defaultValue={}
        control={control}
        variant="standard"
        rules={{ required: false }}
        placeholder="Digite aqui para pesquisar"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              {(serviceDate !== '' || docNumber !== '') && (
                <Box p={1} display="flex" flexDirection="row">
                  {serviceDate && (
                    <Box marginRight={1}>
                      <Chip
                        size="small"
                        label={`ref : ${moment(`${serviceDate}-01`).format(
                          'MM/YYYY'
                        )}`}
                        onDelete={() => {
                          setValue('serviceDate', '', {
                            shouldDirty: true,
                            shouldValidate: true,
                            shouldTouch: true,
                          });
                        }}
                      />
                    </Box>
                  )}
                  {docNumber && (
                    <Box marginRight={1}>
                      <Chip
                        size="small"
                        label={`doc : ${docNumber}`}
                        onDelete={() => {
                          setValue('docNumber', '', {
                            shouldDirty: true,
                            shouldValidate: true,
                            shouldTouch: true,
                          });
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button color="primary" onClick={handleCurrentMonthSearch}>
                Mês Atual
              </Button>
              <IconButton
                className={clsx(hasTuneFields && clsTuneOpen)}
                onClick={(e) =>
                  setAnchorEl(isTuneOpen ? null : e.currentTarget)
                }
                size="large"
              >
                <TuneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isTuneOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Paper>
          <Box p={2}>
            <EditFormField
              id="serviceDate"
              field={{
                name: 'serviceDate',
                label: 'Mês de referência',
                type: 'yearMonth',
              }}
              // defaultValue={/*searchState.inputs?.serviceDate*/}
              control={control}
              variant="filled"
              rules={{ required: false }}
            />
            <Box marginTop={2}>
              <EditFormField
                id="docNumber"
                field={{
                  name: 'docNumber',
                  label: 'Documento',
                  type: 'text',
                }}
                // defaultValue={searchState.inputs?.docNumber}
                control={control}
                variant="filled"
                rules={{ required: false }}
              />
            </Box>
            <Box marginTop={2} display="flex" flexDirection="row">
              <Box flexGrow={1} />
              <Button
                disabled={!searchButtonEnabled}
                color="primary"
                onClick={() => reset()}
              >
                Limpar
              </Button>
              <Box marginLeft={2}>
                <Button
                  disabled={!searchButtonEnabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Pesquisar
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </form>
  );
}

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
export default SearchForm;
