import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';
import { settingsActions } from './settings-slice';

const WAForm = ({ control }) => {
  const dispatch = useDispatch();
  const {
    settings: { enterprise, waSessions },
    auth: { permissions },
  } = useSelector((state) => state);
  useEffect(() => {
    dispatch(settingsActions.getWASessions());
  }, [dispatch]);

  // console.log(waSessions);

  return (
    <>
      <Typography variant="body2" color="textSecondary" component="p">
        Configurações relacionadas ao WhatsApp
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {waSessions.length > 0 && permissions['cfg-msg'] && (
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'waSessionId',
                  label: 'Conta',
                  type: 'select',
                  options: waSessions.map(({ id: value, label }) => ({
                    value,
                    label,
                  })),
                }}
                defaultValue={enterprise.waSessionId}
                control={control}
                rules={{
                  required: 'Obrigatório',
                }}
              />
            </Grid>
          )}
          {permissions['cfg-msg'] && (
            <Grid item xs={6}>
              <EditFormField
                field={{
                  name: 'waNotificationPhone',
                  label: 'Numero para notificação',
                  type: 'text',
                }}
                defaultValue={enterprise.waNotificationPhone ?? ''}
                control={control}
                rules={{
                  required: 'Obrigatório',
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
WAForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default WAForm;
