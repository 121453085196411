'use strict';
import firebase from '../helpers/firebase';

const enterprisesDao = {
  get: async (id) => {
    const db = firebase.firestore();

    if (id) {
      const doc = await db.collection('enterprises').doc(id).get();
      return {
        id: doc.id,
        ...doc.data(),
      };
    } else {
      const enterprisesSnapshot = await db.collection('enterprises').get();
      const enterprises = enterprisesSnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      return enterprises;
    }
  },

  set: (key, data) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection('enterprises')
        .doc(key)
        .set(data)
        .then(resolve)
        .catch((e) => reject(e));
    });
  },

  add: (data) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection('enterprises')
        .add(data)
        .then(resolve)
        .catch((e) => reject(e));
    });
  },

  delete: (key) => {
    const db = firebase.firestore();

    const { projectId } = firebase.app().options;
    if (projectId === 'condomi-prod')
      return new Promise((resolve, reject) => {
        db.collection('enterprises')
          .doc(key)
          .update({ isActive: false })
          .then(resolve)
          .catch((e) => reject(e));
      });
    else
      return new Promise((resolve, reject) => {
        db.collection('enterprises')
          .doc(key)
          .delete()
          .then(resolve)
          .catch((e) => reject(e));
      });
  },

  update: async (key, data) => {
    const db = firebase.firestore();
    await db.collection('enterprises').doc(key).update(data);
  },
};
export default enterprisesDao;
