'use strict';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import {
  Box,
  FormControlLabel,
  Popover,
  TextField,
  colors,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ConfirmationDialog from '../ConfirmationDialog';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';

function descendingComparator(a, b, orderBy) {
  let sideA;
  let sideB;
  if (Array.isArray(a[orderBy])) {
    if (
      typeof a[orderBy][0] === 'object' &&
      !!a[orderBy][0]?.props?.sortValue
    ) {
      sideA = a[orderBy][0].props.sortValue;
      sideB = b[orderBy][0].props.sortValue;
    } else {
      [sideA] = a[orderBy];
      [sideB] = b[orderBy];
    }
  } else if (
    typeof a[orderBy] === 'object' &&
    a[orderBy]?.props?.sortValue !== undefined
  ) {
    sideA = a[orderBy]?.props?.sortValue;
    sideB = b[orderBy]?.props?.sortValue;
  } else {
    sideA = a[orderBy];
    sideB = b[orderBy];
  }

  let sideAwithType;
  let sideBwithType;

  if (typeof sideA === 'number') {
    sideAwithType = parseFloat(sideA);
    if (isNaN(sideAwithType));
    sideAwithType = sideA;
  } else {
    sideAwithType = sideA;
  }

  if (typeof sideB === 'number') {
    sideBwithType = parseFloat(sideB);
    if (isNaN(sideBwithType));
    sideBwithType = sideB;
  } else {
    sideBwithType = sideB;
  }

  if (sideAwithType === undefined) return 1;
  if (sideBwithType === undefined) return -1;
  if (typeof sideAwithType === 'string' && typeof sideBwithType === 'string') {
    if (sideAwithType !== sideBwithType)
      return sideBwithType
        .toLocaleUpperCase()
        .localeCompare(sideAwithType.toLocaleUpperCase());
    else return descendingComparator(a, b, 'id');
  }

  if (sideBwithType < sideAwithType) {
    return -1;
  }
  if (sideBwithType > sideAwithType) {
    return 1;
  }
  return descendingComparator(a, b, 'id');
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
  paper: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
  },
  pagination: {
    overflow: 'visible',
  },
  table: {
    minWidth: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    cursor: 'pointer',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  tableCell: {
    '& .MuiChip-root': {
      marginRight: theme.spacing(1),
    },
  },
  additionalLine: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  '@keyframes example': {
    from: { backgroundColor: theme.palette.secondary.main },
    to: { backgroundColor: undefined },
  },
  highlight: {
    animationName: '$example',
    animationDuration: '8s',
    animationTimingFunction: 'ease-out',
  },
}));

function EnhancedTable(props) {
  const { skipUndefinedData = false /*, disabledLines = []*/ } = props;
  const classes = useStyles();

  const [order, setOrder] = React.useState(props.orderByDirection ?? 'asc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ?? 10);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);
  const [submenuClickConfirm, setSubmenuClickConfirm] = React.useState({
    isOpen: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentFilters, setCurrentFilters] = React.useState(
    props.filters ?? {}
  );

  const {
    settings: { enterprise: { tags = [] } = {} },
  } = useSelector((state) => state);

  // Filters
  const isFilterOpen = Boolean(anchorEl);
  const popoverId = isFilterOpen ? 'filter-popover' : undefined;
  const isFilterActive = !_.isEqual(currentFilters, props.filters);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const onFilterChange = (id, value) => {
    let newFilters = _.cloneDeep(currentFilters);
    newFilters[id].currentValue = value;
    _.debounce(setCurrentFilters);
    setCurrentFilters(newFilters);
    setSelected([]);
  };
  const resetFilter = () => {
    setCurrentFilters(props.filters);
    setAnchorEl(null);
    setSelected([]);
  };

  // console.log(currentFilters);

  const filterRow = (row) => {
    if (currentFilters === {}) return true;

    return (
      Object.keys(currentFilters).filter((id) => {
        switch (currentFilters[id].type) {
          case 'keywords':
            return (
              row[id]?.props?.filterValue ??
              row[id]?.value ??
              row[id] ??
              ''
            )
              .toLowerCase()
              .includes(currentFilters[id].currentValue.toLowerCase());
          case 'list':
            return (
              currentFilters[id].currentValue.length === 0 ||
              !!currentFilters[id].currentValue.find(
                (v) =>
                  v.localeCompare(
                    row[id]?.props?.filterValue ?? row[id]?.value ?? row[id]
                  ) === 0
              )
            );
          case 'tag-list':
            return (
              currentFilters[id].currentValue.length === 0 ||
              _.intersection(
                currentFilters[id].currentValue,
                row[id]?.props?.filterValue ?? row[id]?.value ?? row[id] ?? []
              ).length > 0
            );
          case 'date':
          case 'yearMonth':
            return (
              row[id]?.props?.filterValue ??
              row[id]?.value ??
              row[id] ??
              ''
            ).includes(currentFilters[id].currentValue ?? '');
          case 'text':
            return (
              row[id]?.props?.filterValue ??
              row[id]?.value ??
              row[id] ??
              ''
            )
              .toLowerCase()
              .includes(currentFilters[id].currentValue.toLowerCase());
          case 'number':
            return (
              row[id]?.props?.filterValue ??
              row[id]?.value ??
              row[id] ??
              ''
            )
              .toLowerCase()
              .includes(currentFilters[id].currentValue.toLowerCase());
          case 'boolean':
            return (
              ((row[id]?.props?.filterValue ??
                row[id]?.value ??
                row[id] ??
                false) &&
                (currentFilters[id].currentValue.true ?? true)) ||
              (!(
                row[id]?.props?.filterValue ??
                row[id]?.value ??
                row[id] ??
                false
              ) &&
                (currentFilters[id].currentValue.false ?? true))
            );
          default:
            return true;
        }
      }).length === Object.keys(currentFilters).length
    );
  };

  const filteredData = props.data.filter((row) => filterRow(row));
  const selectedFiltered = selected.filter((idToFind) =>
    filteredData.find(({ id }) => idToFind === id)
  );
  // console.log(filteredData);
  // console.log(selected);
  // console.log(selectedFiltered);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, indeterminate) => {
    if (event.target.checked && !indeterminate) {
      const newSelecteds = props.data
        .filter(
          ({ disabled }) => !disabled
          //props.disabledLines ? props.disabledLines.indexOf(i + 1) < 0 : true
        )
        .map((n) => n.id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirm = () => {
    setIsDeleteConfirmationOpen(false);
    setSelected([]);
    props.onDeleteConfirm(selectedFiltered);
  };
  const handleSubmenuClick = (handler, confirm) => {
    if (confirm) setSubmenuClickConfirm({ isOpen: true, handler });
    else handler(selectedFiltered);
  };
  const handleSubmenuConfirm = () => {
    submenuClickConfirm.handler(selectedFiltered);
    setSubmenuClickConfirm({ isOpen: false });
  };

  useEffect(() => {
    if (props.onSelectionChange) props.onSelectionChange(selectedFiltered);
  }, [selected]);

  const isSelected = (name) => selectedFiltered.indexOf(name) !== -1;
  const hasFilter = !!props.filters && Object.keys(props.filters).length > 0;
  const disabledCount = props.data.filter(({ disabled }) => disabled).length;

  return (
    //   </Paper>
    // </div>
    <>
      <EnhancedTableToolbar
        numRecords={props.data.length}
        numSelected={selectedFiltered.length}
        numFiltered={filteredData.length}
        filters={props.filters}
        title={props.title}
        onDeleteClick={
          props.onDeleteConfirm !== undefined ? handleDeleteClick : undefined
        }
        onSubmenuClick={handleSubmenuClick}
        onNewClick={props.onNewClick}
        hasFilter={hasFilter}
        onFilterClick={handleFilterClick}
        isFilterActive={isFilterActive}
        onFilterResetClick={() => resetFilter()}
        keywordSearch={
          hasFilter ? currentFilters['keywords']?.currentValue ?? '' : ''
        }
        onKeywordSearchChange={(text) => onFilterChange('keywords', text)}
        extraMenu={props.extraMenu}
        searchField={props.searchField}
      />
      {props.subtitle && (
        <Typography
          variant="body2"
          id="tableTitle"
          component="div"
          align="center"
        >
          {props.subtitle}
        </Typography>
      )}
      {props.data.length > 0 && (
        <>
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              {...props.tableProps}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selectedFiltered.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredData.length}
                headCells={props.columns}
                disableSelection={props.disableSelection}
                disabledCount={disabledCount}
                // disabledLines={disabledLines}
              />
              <TableBody>
                {stableSort(filteredData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const isItemHighlighted = props.highlightedIds
                      ? props.highlightedIds.indexOf(row.id) >= 0
                      : false;
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Fragment key={row.id}>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          className={clsx(
                            classes.row,
                            isItemHighlighted && classes.highlight
                          )}
                        >
                          {!props.disableSelection && (
                            <TableCell
                              padding="checkbox"
                              className={clsx(
                                row.additionalLine && classes.noBorderBottom
                              )}
                            >
                              <Checkbox
                                onClick={(event) => handleClick(event, row.id)}
                                checked={isItemSelected}
                                // disabled={disabledLines.indexOf(index + 1) >= 0}
                                disabled={row.disabled}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                          )}
                          {props.columns.map((col, indexCol) => {
                            let cellData;
                            if (
                              (skipUndefinedData &&
                                row[col.id] === undefined) ||
                              row[col.id]?.props?.hidden
                            )
                              return <Fragment key={col.id}></Fragment>;
                            if (Array.isArray(row[col.id]))
                              cellData = row[col.id].map((v, i) => (
                                <Box key={i}>
                                  {col.formatter
                                    ? col.formatter(v)
                                    : v.value ?? v}
                                </Box>
                              ));
                            else
                              cellData = col.formatter
                                ? col.formatter(row[col.id])
                                : row[col.id].value ?? row[col.id];
                            return (
                              <TableCell
                                className={clsx(
                                  classes.tableCell,
                                  row.additionalLine && classes.noBorderBottom,
                                  row.disabled && classes.disabled
                                  // disabledLines.indexOf(index + 1) >= 0 &&
                                  //   classes.disabled
                                )}
                                key={col.id}
                                // component="th"
                                align={col.align}
                                colSpan={row[col.id]?.props?.colSpan ?? 1}
                                id={labelId}
                                scope="row"
                                padding={
                                  props.disableSelection && indexCol === 0
                                    ? 'checkbox'
                                    : col.disablePadding
                                    ? 'none'
                                    : 'normal'
                                }
                                onClick={
                                  row.disabled
                                    ? undefined
                                    : () => props.onRowClick(row)
                                }
                              >
                                {cellData}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {row.additionalLine && (
                          <TableRow>
                            <TableCell className={classes.additionalLine} />
                            <TableCell
                              colSpan={props.columns.length}
                              className={classes.additionalLine}
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {row.additionalLine}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[3, 10, 25, 50, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            // backIconButtonText="Página anterior"
            // nextIconButtonText="Próxima página"
          />
          <Box p={5} />
        </>
      )}
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setIsDeleteConfirmationOpen(false),
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: handleDeleteConfirm,
              startIcon: <DeleteIcon />,
            },
          },
        ]}
      >
        Você confirma a exclusão de {selected?.length ?? 0} registros?
        <br />
        <br />
        Tem certeza que deseja excluir? Essa ação não poderá ser desfeita
      </ConfirmationDialog>

      <ConfirmationDialog
        open={submenuClickConfirm.isOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              onClick: () => setSubmenuClickConfirm({ isOpen: false }),
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'secondary',
              onClick: handleSubmenuConfirm,
              startIcon: <SaveIcon />,
            },
          },
        ]}
      >
        Você confirma a alteração de {selected?.length ?? 0} registros?
        <br />
        <br />
        Essa ação não poderá ser desfeita
      </ConfirmationDialog>

      {hasFilter && (
        <Popover
          id={popoverId}
          open={isFilterOpen}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box padding={2} display="flex" flexDirection="column">
            {Object.keys(props.filters).map((id, ix) => {
              const { type, label, options, currentValue } = props.filters[id];
              switch (type) {
                case 'keywords':
                  return (
                    <TextField
                      key={ix}
                      label={label}
                      value={currentFilters[id].currentValue ?? currentValue}
                      onChange={(e) => {
                        onFilterChange(id, e.target.value);
                      }}
                      fullWidth
                      variant="filled"
                    />
                  );
                case 'text':
                  return (
                    <TextField
                      key={ix}
                      label={label}
                      value={currentFilters[id].currentValue ?? currentValue}
                      onChange={(e) => {
                        onFilterChange(id, e.target.value);
                      }}
                      fullWidth
                      variant="filled"
                    />
                  );
                case 'date':
                  return (
                    <DatePicker
                      key={ix}
                      clearable
                      label={label}
                      format="DD/MM/YYYY"
                      value={
                        currentFilters[id].currentValue ?? currentValue ?? null
                      }
                      onChange={(v) => {
                        onFilterChange(id, v ? v.format('YYYY-MM-DD') : null);
                      }}
                      inputVariant="filled"
                      fullWidth
                      showTodayButton
                      autoOk
                    />
                  );
                case 'yearMonth':
                  return (
                    <DatePicker
                      key={ix}
                      clearable
                      label={label}
                      format="MM/YYYY"
                      value={
                        currentFilters[id].currentValue ?? currentValue ?? null
                      }
                      onChange={(v) => {
                        onFilterChange(id, v ? v.format('YYYY-MM') : null);
                      }}
                      inputVariant="filled"
                      openTo="month"
                      views={['year', 'month']}
                      fullWidth
                      showTodayButton
                      autoOk
                    />
                  );
                case 'number':
                  return (
                    <TextField
                      key={ix}
                      label={label}
                      value={currentFilters[id].currentValue ?? currentValue}
                      onChange={(e) => {
                        onFilterChange(
                          id,
                          e.target.value.replace(/[^\d]+/g, '')
                        );
                      }}
                      fullWidth
                      variant="filled"
                    />
                  );
                case 'boolean':
                  return (
                    <Box key={ix} marginTop={ix > 0 ? 2 : 0}>
                      <Typography component="span">{label} :</Typography>
                      <Checkbox
                        checked={
                          currentFilters[id]?.currentValue.true ??
                          currentValue[true]
                        }
                        onChange={(e) => {
                          onFilterChange(id, {
                            true: e.target.checked,
                            false:
                              currentFilters[id]?.currentValue.false ??
                              currentValue[false],
                          });
                        }}
                      />
                      <Typography component="span">Sim</Typography>
                      <Checkbox
                        checked={
                          currentFilters[id]?.currentValue.false ??
                          currentValue[false]
                        }
                        onChange={(e) => {
                          onFilterChange(id, {
                            true:
                              currentFilters[id]?.currentValue.true ??
                              currentValue[true],
                            false: e.target.checked,
                          });
                        }}
                      />
                      <Typography component="span">Não</Typography>
                    </Box>
                  );
                case 'list':
                  return (
                    <Box key={ix} p={2}>
                      <Box display="flex" flexDirection="column">
                        <Typography color="textSecondary" variant="body2">
                          {label}
                        </Typography>

                        {[...options].map((name, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    currentFilters[id]
                                      ? !!currentFilters[id].currentValue.find(
                                          (nameToFind) => nameToFind === name
                                        )
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      onFilterChange(id, [
                                        ...(currentFilters[id]
                                          ? currentFilters[id].currentValue
                                          : []),
                                        name,
                                      ]);
                                    else
                                      onFilterChange(
                                        id,
                                        currentFilters[id]
                                          ? _.without(
                                              currentFilters[id].currentValue,
                                              name
                                            )
                                          : []
                                      );
                                  }}
                                />
                              }
                              label={name}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  );
                case 'tag-list':
                  return (
                    <Box key={ix} p={2}>
                      <Box display="flex" flexDirection="column">
                        <Typography color="textSecondary" variant="body2">
                          Marcadores
                        </Typography>

                        {[...tags]
                          .sort(({ name: a }, { name: b }) =>
                            a.localeCompare(b)
                          )
                          .map(({ name, color }, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={
                                      currentFilters[id]
                                        ? !!currentFilters[
                                            id
                                          ].currentValue.find(
                                            (nameToFind) => nameToFind === name
                                          )
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        onFilterChange(id, [
                                          ...(currentFilters[id]
                                            ? currentFilters[id].currentValue
                                            : []),
                                          name,
                                        ]);
                                      else
                                        onFilterChange(
                                          id,
                                          currentFilters[id]
                                            ? _.without(
                                                currentFilters[id].currentValue,
                                                name
                                              )
                                            : []
                                        );
                                      /*handleChange(e.target.checked, name)*/
                                    }}
                                    style={{
                                      color: colors[color ?? 'orange'][600],
                                    }}
                                  />
                                }
                                label={name}
                              />
                            );
                          })}
                      </Box>
                    </Box>
                  );
              }
            })}
            <Button color="primary" onClick={() => resetFilter()}>
              Limpar filtros
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
}

EnhancedTable.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableSelection: PropTypes.bool,
  // disabledLines: PropTypes.arrayOf(PropTypes.number),
  onRowClick: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func,
  extraMenu: PropTypes.object,
  onNewClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  tableProps: PropTypes.object,
  rowsPerPage: PropTypes.number,
  skipUndefinedData: PropTypes.bool,
  highlightedIds: PropTypes.arrayOf(PropTypes.string),
  searchField: PropTypes.node,
};
export default EnhancedTable;
