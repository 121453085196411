'use strict';
import { eventChannel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import store from '../../store';

import { Button } from '@mui/material';
import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { authActions } from '../auth/auth-slice';
import { notifierActions } from './notifier-slice';

function* watchSetNotificationIsRead() {
  const db = firebase.firestore();

  while (true) {
    const action = yield take(notifierActions.setNotificationIsRead);
    yield put(appActions.startLoading());
    try {
      const {
        userData: { id: userId },
      } = yield select((state) => state.auth);
      // console.log('uid');
      // console.log(uid);
      const { id, isRead } = action.payload;
      const notificationDocRef = db
        .collection(`users/${userId}/notifications`)
        .doc(id);

      // console.log(`updating notification ${id} with`, {
      //   isRead,
      //   readAt: isRead ? Date.now() : null,
      // });
      yield notificationDocRef.update({
        isRead,
        readAt: isRead ? Date.now() : null,
      });
      yield put(notifierActions.setNotificationIsReadSuccess());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchSetNotificationIsRead');
      // console.log(e);
      yield put(notifierActions.setNotificationIsReadFail(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCreatePersistentNotification() {
  const db = firebase.firestore();
  while (true) {
    const action = yield take(notifierActions.createPersistentNotification);
    yield put(appActions.startLoading());
    try {
      const {
        userData: { id: userId },
      } = yield select((state) => state.auth);
      const collectionRef = db.collection(`users/${userId}/notifications`);

      // console.log('creating notification', action.payload);
      yield collectionRef.add({ ...action.payload, createdAt: Date.now() });
      yield put(notifierActions.createPersistentNotificationSuccess());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchCreatePersistentNotification');
      // console.log(e);
      yield put(notifierActions.createPersistentNotificationFail(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function createNotificationChannel(userId, db) {
  return eventChannel((emitter) => {
    const doc = db
      .collection(`users/${userId}/notifications`)
      .orderBy('createdAt', 'desc')
      .limit(20);
    const unsubscribe = doc.onSnapshot(emitter);
    return unsubscribe;
  });
}

function* watchInitSuccessNotifier() {
  const db = firebase.firestore();
  while (true) {
    yield take(authActions.loginSuccess);
    const { initializedAt } = yield select((state) => state.app);
    const {
      userData: { id: userId },
    } = yield select((state) => state.auth);

    const chan = yield call(createNotificationChannel, userId, db);
    try {
      while (true) {
        const snapshot = yield take(chan);

        const newNotifications = snapshot
          .docChanges()
          .filter(({ type }) => type === 'added')
          .map(({ doc }) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.createdAt - b.createdAt);

        for (let not of newNotifications) {
          if (not.createdAt > initializedAt)
            yield put(
              notifierActions.enqueue({
                message: not.title,
                options: {
                  variant: not.severity,
                  // eslint-disable-next-line react/display-name
                  action: () => (
                    // eslint-disable-next-line react/jsx-filename-extension
                    <Button
                      onClick={() =>
                        store.dispatch(
                          notifierActions.select(not)
                          // not.action
                          //   ? {
                          //       type: not.action.type,
                          //       payload: not.action.payload,
                          //     }
                          //   : notifierActions.select(not),
                        )
                      }
                    >
                      Detalhes
                    </Button>
                  ),
                },
              })
            );
        }

        const updatedNotifications = snapshot
          .docChanges()
          .filter(({ type }) => type === 'modified')
          .map(({ doc }) => ({ id: doc.id, ...doc.data() }));
        yield put(notifierActions.newNotifications(newNotifications));
        yield put(notifierActions.modifyNotifications(updatedNotifications));
      }
    } finally {
      // console.log('saga terminated');
    }
  }
}

export default [
  watchSetNotificationIsRead,
  watchCreatePersistentNotification,
  watchInitSuccessNotifier,
];
