'use strict';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Badge,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import ResponsiveDialog from '../../components/ResponsiveDialog';
import BackgroundTaskDetail from './BackgroundTaskDetail';
import { bgTasksActions } from './bg-tasks-slice';

const useStyles = makeStyles((theme) => ({
  backgroundTasksList: {
    width: '300px',
  },
  backgroundTaskText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spinning: {
    animation: '$circular-rotate 4s linear infinite',
  },
  backgroundTaskProgress: {
    width: '100%',
  },
  '@keyframes circular-rotate': {
    '0%': {
      // Fix IE 11 wobbly
      transformOrigin: '50% 50%',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  success: {
    color: theme.palette.success.main,
  },
}));

export default function BackgroundTasksList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tasks, selected } = useSelector((state) => state.bgTasks);

  const handleBackgroundTasksClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleBackgroundTasksClose = () => {
    setAnchorEl(null);
  };
  const setSelectedBackgroundTask = (task) => {
    dispatch(bgTasksActions.select(task.id));
  };
  const handleBackgroundTaskDetailClose = () => {
    dispatch(bgTasksActions.select());
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleBackgroundTasksClick}
        size="large"
      >
        <Badge
          badgeContent={tasks.filter((task) => task.progress !== 1).length}
          color="secondary"
        >
          <AutorenewIcon
            className={clsx(
              tasks.filter((task) => task.progress !== 1).length > 0 &&
                classes.spinning
            )}
          />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleBackgroundTasksClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List dense>
          {tasks.length === 0 && (
            <ListItem disabled>
              <ListItemText>Nenhuma tarefa em segundo plano</ListItemText>
            </ListItem>
          )}
          {tasks
            .slice()
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((task, i) => (
              <ListItem
                button
                key={i}
                className={classes.backgroundTasksList}
                onClick={() => setSelectedBackgroundTask(task)}
              >
                <div className={classes.backgroundTaskProgress}>
                  <div className={classes.backgroundTaskText}>
                    <ListItemText
                      primary={task.title}
                      secondary={task.step}
                      // secondary={moment(task.createdAt).fromNow()}
                    />
                    {parseFloat(task.progress) === 1 &&
                      task.severity !== 'error' && (
                        <DoneIcon
                          fontSize="large"
                          className={classes.success}
                        />
                      )}
                    {parseFloat(task.progress) === 1 &&
                      task.severity === 'error' && (
                        <ErrorIcon fontSize="large" color="error" />
                      )}
                    {parseFloat(task.progress) < 1 && (
                      <Typography variant="h4">
                        {parseInt(parseFloat(task.progress) * 100)}%
                      </Typography>
                    )}
                  </div>
                  {parseFloat(task.progress) < 1 && <LinearProgress />}
                </div>
              </ListItem>
            ))}
        </List>
      </Popover>
      <ResponsiveDialog open={!!selected} maxWidth="sm" fullWidth>
        <BackgroundTaskDetail
          task={selected}
          onClose={() => {
            handleBackgroundTaskDetailClose();
          }}
        />
      </ResponsiveDialog>
    </>
  );
}
