import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function FileUploadWithControl({ name, label, control, rules, ...props }) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  return (
    <TextField
      {...props}
      disabled
      label={label}
      value={value?.name ?? ''}
      InputProps={{
        endAdornment: (
          <IconButton component="label">
            <CloudUploadIcon />
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                onChange(e.target.files ? e.target.files[0] : '');
              }}
            />
          </IconButton>
        ),
      }}
      error={!!error}
      helperText={error?.message}
    />
  );
}

FileUploadWithControl.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  rules: PropTypes.any,
};

export default FileUploadWithControl;
