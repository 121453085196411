'use strict';
import { eventChannel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';

import firebase from '../../helpers/firebase';
import { appActions } from '../app/app-slice';
import { authActions } from '../auth/auth-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { bgTasksActions } from './bg-tasks-slice';

function* watchCreateBgTask() {
  const db = firebase.firestore();

  while (true) {
    const action = yield take(bgTasksActions.createBgTask);
    yield put(appActions.startLoading());
    try {
      const {
        enterprise: { id },
      } = yield select((state) => state.settings);
      const collectionRef = db.collection(`enterprises/${id}/bg-tasks`);

      // console.log('creating bg-task', action.payload);
      yield collectionRef.add({ ...action.payload, createdAt: Date.now() });
      yield put(bgTasksActions.createBgTaskSuccess());
      yield put(appActions.finishLoading());
    } catch (e) {
      // console.log('Erro ao executar watchCreateBgTask');
      // console.log(e);
      yield put(bgTasksActions.createBgTaskFail(e.message));
      yield put(appActions.finishLoading());
    }
  }
}

function createBgTasksChannel(eid, db) {
  return eventChannel((emitter) => {
    const doc = db
      .collection(`enterprises/${eid}/bg-tasks`)
      .orderBy('createdAt', 'desc')
      .limit(20);
    const unsubscribe = doc.onSnapshot(emitter);
    return unsubscribe;
  });
}

function* watchInitSuccessBgTasks() {
  const db = firebase.firestore();

  while (true) {
    yield take(authActions.loginSuccess);
    const {
      enterprise: { id },
    } = yield select((state) => state.settings);

    const { initializedAt } = yield select((state) => state.app);

    const chan = yield call(createBgTasksChannel, id, db);
    try {
      while (true) {
        const snapshot = yield take(chan);

        const newBgTasks = snapshot
          .docChanges()
          .filter(({ type }) => type === 'added')
          .map(({ doc }) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.createdAt - b.createdAt);

        for (let task of newBgTasks) {
          if (task.createdAt > initializedAt)
            yield put(
              notifierActions.createPersistentNotification({
                title: `Iniciada: ${task.title}`,
                content: `Iniciada: ${task.title}`,
                severity: 'info',
                action: {
                  type: bgTasksActions.select().type,
                  payload: task.id,
                  description: 'Ver Detalhes',
                },
              })
            );
        }

        const modifiedBgTasks = snapshot
          .docChanges()
          .filter(({ type }) => type === 'modified')
          .map(({ doc }) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.createdAt - b.createdAt);

        for (let task of modifiedBgTasks) {
          if (task.progress === 1) {
            yield put(
              notifierActions.createPersistentNotification({
                title: `${task.severity === 'error' ? 'Erro' : 'Concluída'}: ${
                  task.title
                }`,
                content: `${
                  task.severity === 'error' ? 'Erro' : 'Concluída'
                }: ${task.title}`,
                severity: task.severity,
                action: {
                  type: bgTasksActions.select().type,
                  payload: task.id,
                  description: 'Ver Detalhes',
                },
              })
            );
          }
        }

        yield put(bgTasksActions.newBgTasks(newBgTasks));
        yield put(bgTasksActions.modifyBgTasks(modifiedBgTasks));
      }
    } finally {
      // console.log('saga terminated');
    }
  }
}

export default [
  watchCreateBgTask,
  //watchCreatePersistentNotification,
  watchInitSuccessBgTasks,
];
