'use strict';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, IconButton, Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import ButtonWithProgress from './ButtonWithProgress';
import FileUploadWithControl from './FileUpload/FileUploadWithControl';

function DocumentDetail({
  label,
  mode,
  name,
  missing,
  isDownloading,
  onEditClick,
  onClearClick,
  onDownloadClick,
  canEdit,
  canDelete,
  control,
  customValidate,
  children,
}) {
  return (
    <>
      <Box
        display={mode === 'display' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <CheckIcon color="success" />
        <ButtonWithProgress
          tooltipTitle="Baixar Documento"
          isLoading={isDownloading}
          startIcon={<DownloadIcon />}
          onClick={onDownloadClick}
        >
          {label}
        </ButtonWithProgress>
        <Box flexGrow={1}></Box>
        {children}
        {canEdit && (
          <Tooltip title="Substituir documento">
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        display={mode === 'edit' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        {missing ? <ErrorIcon color="error" /> : <CheckIcon color="success" />}
        <FileUploadWithControl
          fullWidth
          disabled={!canEdit}
          label={label}
          name={name}
          control={control}
          rules={{
            validate: customValidate,
          }}
        />
        {canDelete && (
          <IconButton onClick={onClearClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}

DocumentDetail.propTypes = {
  label: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  missing: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func,
  onClearClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  control: PropTypes.any,
  customValidate: PropTypes.any,
  children: PropTypes.node,
  title: PropTypes.string,
  detail: PropTypes.string,
  faIcon: PropTypes.string,
};

export default DocumentDetail;
