'use strict';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

function descendingComparator(a, b, orderBy) {
  let sideA;
  let sideB;

  if (typeof a[orderBy] === 'object' && !!a[orderBy].props) {
    sideA = a[orderBy].props.sortValue;
    sideB = b[orderBy].props.sortValue;
  } else {
    try {
      sideA = parseFloat(a[orderBy]);
    } catch (e) {
      sideA = a[orderBy];
    }
    try {
      sideB = parseFloat(b[orderBy]);
    } catch (e) {
      sideB = b[orderBy];
    }
    if (isNaN(sideA)) sideA = a[orderBy];
    if (isNaN(sideB)) sideB = b[orderBy];
  }
  if (sideA === undefined) return 1;
  if (sideB === undefined) return -1;
  if (typeof sideA === 'string' && typeof sideB === 'string') {
    return sideB.toLocaleUpperCase().localeCompare(sideA.toLocaleUpperCase());
  }

  if (sideB < sideA) {
    return -1;
  }
  if (sideB > sideA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ReportTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric
                ? 'right'
                : headCell.align
                ? headCell.align
                : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {orderBy ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ReportTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ReportTable(props) {
  const classes = useStyles();

  const { column: orderBy, order = 'asc' } = props.orderBy ?? {};

  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState(props.orderBy);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    props.onOrderByChange({ column: property, order: isAsc ? 'desc' : 'asc' });
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };

  const sortedData = orderBy
    ? stableSort(props.data, getComparator(order, orderBy))
    : props.data;
  return (
    <TableContainer>
      <Table
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
        {...props.tableProps}
      >
        <ReportTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={props.data.length}
          headCells={props.columns}
        />
        <TableBody>
          {sortedData.map((row, index) => {
            return (
              <TableRow key={index} hover tabIndex={-1}>
                {props.columns.map((col) => {
                  let cellData;
                  let cellClass;
                  if (Array.isArray(row[col.id])) {
                    cellData = row[col.id].map((v, i) => (
                      <Box key={i}>
                        {col.formatter ? col.formatter(v) : v?.value ?? v ?? ''}
                      </Box>
                    ));
                    if (typeof col.className === 'function')
                      [cellClass] = row[col.id].map((v) =>
                        col.className(v?.value ?? v ?? '')
                      );
                    else cellClass = col.className;
                  } else {
                    cellData = col.formatter
                      ? col.formatter(row[col.id])
                      : row[col.id]?.value ?? row[col.id] ?? '';
                    if (typeof col.className === 'function')
                      cellClass = col.className(
                        row[col.id]?.value ?? row[col.id] ?? ''
                      );
                    else cellClass = col.className;
                  }
                  return (
                    <TableCell
                      className={cellClass}
                      key={col.id}
                      component="th"
                      align={col.align}
                      scope="row"
                      padding={props.disablePadding ? 'none' : 'default'}
                    >
                      {cellData}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ReportTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderBy: PropTypes.shape({
    column: PropTypes.string,
    order: PropTypes.string,
  }),
  onOrderByChange: PropTypes.func,
  tableProps: PropTypes.object,
  disablePadding: PropTypes.bool,
};
