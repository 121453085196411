'use strict';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Tab, Tabs } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import WhatsappDialog from '../../components/WhatsappDialog';
import { parseWhatsappTemplate } from '../../helpers/whatsapp-templates.js';
import { getMessenger } from '../wa-status/get-messenger.js';
import BotDetail from './BotDetail';
import FormSendMsg from './FormSendMsg.jsx';
import { monitorActions } from './monitor-slice.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  clsCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.whatsapp.background,
  },
}));

function Story() {
  const { journeys, bots, whatsappTemplates, selectedWaMessages } = useSelector(
    (state) => state.monitor
  );

  const { permissions } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.settings);
  const { isSecretActive } = useSelector((state) => state.app);

  const messenger = getMessenger(enterprise.waSessionId);

  const { clsCard } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(monitorActions.loadWhatsappTemplates());
  }, []);

  //const [currentBot] = bots['bot-sessions'] ?? [];
  //const previousBots = [...(bots['dw-bot-sessions'] ?? [])];

  const botsTimeline = _.flatten(
    [...(bots['dw-bot-sessions'] ?? []), ...(bots['bot-sessions'] ?? [])].map(
      ({ dialog }) =>
        dialog.map((dialogItem) => ({
          type: 'bot',
          timestamp: dialogItem.createdAt,
          ...dialogItem,
        }))
    )
  );
  const botsDetails = [
    ...(bots['dw-bot-sessions'] ?? []),
    ...(bots['bot-sessions'] ?? []),
  ].map((bot) => ({
    type: 'bot',
    timestamp: bot.startedAt,
    src: 'sys',
    msg: { type: 'custom', customContent: <BotDetail bot={bot} /> },
  }));
  const journeysTimeline = _.flatten(
    journeys.map(({ story }) =>
      story
        .filter((s) => s.src !== 'sys-loop')
        .map((s) => ({ type: 'journey', timestamp: s.createdAt, ...s }))
    )
  );

  let timeline = [...botsTimeline, ...journeysTimeline, ...botsDetails].filter(
    ({ src }) => (src === 'sys' && isSecretActive) || src !== 'sys'
  );
  timeline.sort((a, b) => a.timestamp - b.timestamp);
  const tabs = timeline.reduce((acc, cur) => {
    const formattedDate = moment(cur.timestamp)
      .startOf('day')
      .format('YYYY-MM-DD');
    if (!acc[formattedDate]) acc[formattedDate] = [];
    acc[formattedDate].push(cur);
    return acc;
  }, {});

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs).length - 1);

  const currentTabAdjusted =
    currentTab >= Object.keys(tabs).length
      ? Object.keys(tabs).length - 1
      : currentTab;

  const formattedContent = tabs[Object.keys(tabs)[currentTabAdjusted]]
    ? {
        items: tabs[Object.keys(tabs)[currentTabAdjusted]]
          // .filter((c) => !!c.msg)
          .map((c) => {
            const { src, msg, createdAt } = c;

            const {
              type,
              text: imgText,
              url,
              caption,
              resultDescription,
              userComment,
              content,
              file,
              contactVcard,
              customContent,
              messenger,
              wid,
              whatsappVariant,
              mid,
            } = msg ? msg : {};

            const waMessage = (selectedWaMessages ?? []).find(
              (m) => m.mid === mid
            );

            let text;
            let image;
            let dialogType = type;
            let buttons;
            if (messenger === 'api')
              switch (type) {
                case 'event':
                  dialogType = 'text';
                  text = `_${content}_`;
                  break;
                case 'text':
                  dialogType = 'text';
                  text = content;
                  break;
                case 'result':
                  dialogType = 'text';
                  text =
                    `_Resultado da Jornada:_ *${resultDescription}*` +
                    (userComment ? `\n_Comentário:_ *${userComment}*` : '');
                  break;
                case 'image':
                  image = content;
                  text = imgText;
                  break;
                case 'document':
                  break;
                case 'sticker':
                  break;
                case 'audio':
                  break;
                case 'ptt':
                  break;
                case 'vcard':
                  break;
                case 'custom':
                  break;
                default:
                  dialogType = 'text';
                  text = '<não é possível exibir o conteúdo>';
                  break;
              }
            else {
              const whatsappType = whatsappVariant
                ? whatsappVariant.type
                : type;
              switch (whatsappType) {
                case 'event':
                  dialogType = 'text';
                  text = `_${content}_`;
                  break;
                case 'text':
                  dialogType = 'text';
                  text = content;
                  break;
                case 'result':
                  dialogType = 'text';
                  text =
                    `_Resultado da Jornada:_ *${resultDescription}*` +
                    (userComment ? `\n_Comentário:_ *${userComment}*` : '');
                  break;
                case 'image':
                  image = content;
                  text = imgText;
                  break;
                case 'document':
                  break;
                case 'sticker':
                  break;
                case 'audio':
                  break;
                case 'ptt':
                  break;
                case 'vcard':
                  break;
                case 'custom':
                  break;
                case 'template': {
                  dialogType = 'text';
                  const bodyVariables = whatsappVariant.content.components.find(
                    ({ type }) => type === 'body'
                  );
                  const parameters = bodyVariables?.parameters ?? [];

                  const template = whatsappTemplates.find(
                    ({ id }) => id === whatsappVariant.content.name
                  );
                  const body = template
                    ? template.components.find(({ type }) => type === 'BODY')
                    : '';
                  const rawText =
                    body?.text ?? '<não é possível exibir o conteúdo>';

                  const buttonsComponents = template
                    ? template.components.find(({ type }) => type === 'BUTTONS')
                    : undefined;

                  buttons = buttonsComponents?.buttons
                    ? buttonsComponents.buttons.map(({ text }) => text)
                    : undefined;

                  text = parseWhatsappTemplate(rawText, parameters);
                  break;
                }
                default:
                  dialogType = 'text';
                  text = '<não é possível exibir o conteúdo>';
                  break;
              }
            }

            return {
              src,
              direction:
                src === 'bot' || src === 'adm'
                  ? 'in'
                  : src === 'sys' || src === 'evt'
                  ? 'sys'
                  : 'out',
              type: dialogType,
              url,
              text,
              image,
              file,
              caption,
              contactVcard,
              timestamp: createdAt,
              customContent,
              contactName:
                src === 'bot' ? enterprise.agent.name : 'Administrador',
              messenger,
              wid,
              buttons,
              waMessage,
            };
          }),
      }
    : {};

  return (
    <>
      <Tabs
        value={currentTabAdjusted}
        onChange={(_, newValue) => setCurrentTab(newValue)}
        variant="scrollable"
        indicatorColor="secondary"
        textColor="secondary"
        scrollButtons="auto"
      >
        {Object.keys(tabs).map((tab, index) => (
          <Tab key={index} label={moment(tab).format('DD/MM/YYYY')} />
        ))}
      </Tabs>
      {/* <Divider /> */}
      {/* {Object.keys(tabs).map((tab, index) => (
         <TabPanel key={index} value={currentTab} index={index} overflow="auto"> */}
      <Card variant="outlined" className={clsCard}>
        {tabs[Object.keys(tabs)[currentTabAdjusted]] && (
          <WhatsappDialog content={formattedContent} />
        )}
        {
          /* tabs[Object.keys(tabs)[currentTab]].map(
            (date, index) => { */
          // switch (type) {
          //   case 'bot':
          //     return <BotDetail key={index} index={index} bot={detail} />;
          //   case 'journey': {
          //     return (
          //       <JourneyStoryDetail
          //         key={index}
          //         index={index}
          //         detail={detail}
          //       />
          //     );
          //   }
          // }
        }
        {/* )} */}
        {/* {currentTab === Object.keys(tabs).length - 1 && currentBot && (
          <BotDetail current bot={currentBot} />
        )} */}
        {messenger === 'api' &&
          permissions['pix-snd'] &&
          currentTabAdjusted === Object.keys(tabs).length - 1 && (
            <FormSendMsg />
          )}
      </Card>
    </>
  );
}

Story.propTypes = {};
export default Story;
