'use strict';
import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import { formatValue } from '../../helpers/formatter';

const useStyles = makeStyles((theme) => ({
  current: {
    borderColor: theme.palette.primary.main,
  },
  detail: {
    minWidth: '10rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: {
    maxWidth: 900,
  },
  cardContent: {
    flexGrow: 1,
  },
  clsSecret: {
    borderColor: 'red',
    backgroundColor: theme.palette.whatsapp.background,
    color: 'red',
  },
}));

const DetailField = ({ label, value }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Box flexBasis="40%">
      <Typography variant="caption" color="textSecondary" component="span">
        {label} :{' '}
      </Typography>
    </Box>
    <Box flexBasis="60%">
      <Typography variant="body1" color="textPrimary" component="span">
        {value ?? ''}
      </Typography>
    </Box>
  </Box>
);
DetailField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
function BotDetail({
  bot: {
    id,
    botId,
    startedAt,
    finishedAt,
    currentStateId,
    currentPhase,
    currentCloudTask,
    retryCountNoInput,
    retryCountNoMatch,
    result: { resultId, resultDescription, userinputs } = {},
  } = {},
  index = 999,
}) {
  const { clsSecret } = useStyles();

  const botStatus = `${currentStateId} - ${currentPhase}${
    retryCountNoInput > 0 ? ` (${retryCountNoInput + 1})` : ''
  }${retryCountNoMatch > 0 ? ` (${retryCountNoMatch + 1})` : ''}`;
  return (
    <Box
      paddingTop={index === 0 ? 2 : 0}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Paper variant="outlined" className={clsSecret}>
        <Box padding={2}>
          <Typography variant="caption" component="p" color="textSecondary">
            {id}
          </Typography>
          <DetailField
            label="Início"
            value={formatValue(startedAt, 'dateTime')}
          />
          <DetailField label="bot" value={botId} />
          <DetailField label="Estado atual" value={botStatus} />
          {!!currentCloudTask?.topic && (
            <DetailField
              label="Próxima ativação"
              value={`${currentCloudTask?.topic}.${
                currentCloudTask?.event
              } - ${formatValue(
                currentCloudTask?.createdAt +
                  currentCloudTask?.inSeconds * 1000,
                'dateTime'
              )}`}
            />
          )}
          {resultId && (
            <>
              <DetailField label="Resultado" value={resultDescription} />
              {userinputs && (
                <DetailField
                  label="Comentários"
                  value={userinputs
                    .filter(({ type }) => type === 'text')
                    .map(({ content }) => content)
                    .join(' - ')}
                />
              )}
            </>
          )}
          {finishedAt && (
            <DetailField
              label="Finalizado em"
              value={formatValue(finishedAt, 'dateTime')}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}

BotDetail.propTypes = {
  bot: PropTypes.shape({
    botId: PropTypes.string.isRequired,
    startedAt: PropTypes.number.isRequired,
    currentStateId: PropTypes.string,
    dialog: PropTypes.array,
  }),
  current: PropTypes.bool,
  index: PropTypes.number,
};
export default BotDetail;
