import { createSlice } from '@reduxjs/toolkit';

export const waStatusSlice = createSlice({
  name: 'wa-status',
  initialState: {
    sessions: [],
    sessionsDetails: {},
    isConnecting: {},
    isDisconnecting: {},
    isLoggingOut: {},
    isLoading: true,
  },
  reducers: {
    loadSessions: (state) => {
      state.isLoading = true;
    },
    loadSessionsSuccess: (state, { payload }) => {
      state.sessions = payload;
      state.sessions.sort((a, b) => a.id.localeCompare(b.id));
    },
    loadSessionsFail: (state) => {
      state.sessions = [];
    },
    loadSessionDetails: () => {},
    loadSessionDetailsSuccess: (state, { payload }) => {
      state.sessionsDetails = payload;
      state.isLoading = false;
    },
    loadSessionDetailsFail: (state) => {
      state.isLoading = false;
      state.sessionsDetails = {};
    },
    connect: (state, { payload }) => {
      state.isConnecting[payload] = true;
    },
    disconnect: (state, { payload }) => {
      state.isDisconnecting[payload] = true;
    },
    logout: (state, { payload }) => {
      state.isLoggingOut[payload] = true;
    },
    stopConnecting: (state, { payload }) => {
      state.isConnecting[payload] = false;
    },
    stopDisconnecting: (state, { payload }) => {
      state.isDisconnecting[payload] = false;
    },
    stopLoggingOut: (state, { payload }) => {
      state.isLoggingOut[payload] = false;
    },
    sendTest: () => {},
  },
});

export const waStatusActions = waStatusSlice.actions;

export default waStatusSlice.reducer;
