import { createSlice } from '@reduxjs/toolkit';

export const versionsSlice = createSlice({
  name: 'versions',
  initialState: {
    data: [],
  },
  reducers: {
    load: () => {},
    loadSuccess: (state, { payload }) => {
      state.data = payload;
    },
    loadFail: (state) => {
      state.data = [];
    },
  },
});

export const versionsActions = versionsSlice.actions;

export default versionsSlice.reducer;
