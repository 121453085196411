/* eslint-disable no-unused-vars */
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SubaccountDisplayForm from './SubaccountDisplayForm';

const useStyles = makeStyles((theme) => ({
  clsWarning: {
    color: theme.palette.warning.main,
  },
}));

const SubaccountFormConfirmation = ({ formData }) => {
  const { clsWarning } = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <WarningIcon fontSize="large" className={clsWarning} />
        </Box>
        <Typography variant="h5">Atenção!</Typography>
        <Box marginTop={2}>
          <Typography variant="body1">
            Confira todos os dados antes de confirmar, esta operação NÃO PODE
            SER CANCELADA DEPOIS!
          </Typography>
        </Box>
        <Box paddingTop={2} width={1}>
          <Divider />
        </Box>
        <Box marginTop={2} width={1}>
          <SubaccountDisplayForm {...formData} />
        </Box>
      </Box>
      <Box paddingTop={2}>
        <Divider />
      </Box>
    </>
  );
};
SubaccountFormConfirmation.propTypes = {
  formData: PropTypes.object,
};
export default SubaccountFormConfirmation;
