'use strict';
import StopIcon from '@mui/icons-material/Stop';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormField from '../../components/EditForm/EditFormField';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';

const reasons = [
  {
    id: 'CANCEL',
    description: 'Cliente cancelou',
  },
  {
    id: 'PAID_OTHER',
    description: 'Cliente fez o pagamento por outro canal',
  },
  {
    id: 'NOTICE',
    description: 'Pagamento em protesto (sem notificação)',
  },
  {
    id: 'OTHER',
    description: 'Outro motivo',
  },
];

export default function FormCancelPix({ onCancelClick, onConfirmClick }) {
  const {
    monitor: { isAwaitingCommand, selected },
  } = useSelector((state) => state);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const onSubmit = ({ id, comment }) => {
    const { description } = reasons.find((r) => r.id === id);
    onConfirmClick({
      reason: {
        reasonId: id,
        userComment: comment,
        description,
      },
    });
  };

  return (
    <form noValidate autoComplete="off">
      <EditForm>
        <EditFormHeader title="Cancelar" onCloseClick={onCancelClick} />
        <EditFormContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Escolha o motivo
          </Typography>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EditFormField
                  field={{
                    name: 'id',
                    label: 'Motivo',
                    type: 'select',
                    options: reasons.map(({ id, description }) => ({
                      value: id,
                      label: description,
                    })),
                  }}
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <EditFormField
                  field={{
                    name: 'comment',
                    label: 'Comentário (Opcional)',
                    type: 'text',
                  }}
                  defaultValue={''}
                  control={control}
                  rules={{
                    required: false,
                  }}
                  multiline
                />
              </Grid>
            </Grid>
          </Box>
        </EditFormContent>
        <EditFormFooter>
          <EditFormMessage>
            {Object.keys(errors).length > 0 && (
              <Typography color="error" variant="caption">
                Os erros devem ser corrigidos antes de salvar
              </Typography>
            )}
          </EditFormMessage>
          <EditFormAction>
            <ButtonWithProgress
              tooltipTitle="Confirmar"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={!isDirty || isAwaitingCommand[selected.id]}
              startIcon={<StopIcon />}
            >
              Confirmar
            </ButtonWithProgress>
          </EditFormAction>
        </EditFormFooter>
      </EditForm>
    </form>
  );
}

FormCancelPix.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};
