import { createSlice } from '@reduxjs/toolkit';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    jid: undefined,
    chid: undefined,
    initialDate: Date.now(),
    finalDate: Date.now(),
    serviceDate: Date.now(),
    data: [],
    reportType: undefined,
    selected: undefined,
  },
  reducers: {
    setReportType: (state, { payload }) => {
      state.reportType = payload;
      state.data = [];
    },
    setJid: (state, { payload }) => {
      state.jid = payload;
    },
    setChid: (state, { payload }) => {
      state.chid = payload;
    },
    setInitialDate: (state, { payload }) => {
      state.initialDate = payload;
    },
    setFinalDate: (state, { payload }) => {
      state.finalDate = payload;
    },
    setServiceDate: (state, { payload }) => {
      state.serviceDate = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    load: () => {},
    loadSuccess: (state, { payload }) => {
      state.data = payload;
    },
    loadFail: (state) => {
      state.data = [];
    },
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    export: () => {},
  },
});

export const reportsActions = reportsSlice.actions;

export default reportsSlice.reducer;
