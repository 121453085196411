import firebase from '../helpers/firebase';

const usersDao = {
  get: async () => {
    const db = firebase.firestore();

    let usersSnapshot = await db.collection('users').get();
    let users = usersSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return users;
  },

  getByEnterprise: async (eid) => {
    const db = firebase.firestore();

    let usersSnapshot = await db
      .collection('users')
      .where('enterpriseId', '==', eid)
      .get();
    let users = usersSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return users;
  },

  set: async (key, data) => {
    // console.log('Saving');
    // console.log(key + ' => ');
    // console.log(data);

    const db = firebase.firestore();
    await db
      .collection('users')
      .doc(key)
      .set({ ...data, createdAt: Date.now(), lastUpdate: Date.now() });
  },

  update: async (key, data) => {
    // console.log('Saving');
    // console.log(key + ' => ');
    // console.log(data);

    const db = firebase.firestore();
    await db
      .collection('users')
      .doc(key)
      .update({ ...data, lastUpdate: Date.now() });
  },

  delete: async (key) => {
    // console.log(`Deleting ${key}`);
    const db = firebase.firestore();

    await db.collection('users').doc(key).delete();
  },
};
export default usersDao;
