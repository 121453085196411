'use strict';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';

function CustomerSearchForm({ onSearch }) {
  const {
    monitor: { searchState },
  } = useSelector((state) => state);
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    onSearch(data);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <EditFormField
        id="keywords"
        field={{
          name: 'keywords',
          label: '',
          type: 'text',
        }}
        defaultValue={searchState.inputs?.keywords}
        control={control}
        variant="standard"
        rules={{ required: false }}
        placeholder="Digite aqui para pesquisar - ENTER para retornar tudo"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}

CustomerSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
export default CustomerSearchForm;
