import { Container, Fade, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { appActions } from '../app/app-slice';

const useStyles = makeStyles((_theme) => ({
  root: {
    height: '100vh',
  },
  flexGrow: {
    flexGrow: 1,
  },
}));
function NewUserScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  useEffect(() => {
    setTimeout(() => setStep(2), 2000);
    setTimeout(() => dispatch(appActions.showWelcome(false)), 3000);
  }, [dispatch]);

  return (
    <Container>
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
      >
        <Grid item>
          <Fade timeout={2000} in={step === 1}>
            <Typography variant="h3">Tudo pronto! Vamos começar...</Typography>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewUserScreen;
