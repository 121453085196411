'use strict';

import { h } from 'hastscript';
import { visit } from 'unist-util-visit';

export default function htmlDirectives() {
  return transform;

  function transform(tree) {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      ondirective
    );
  }

  function ondirective(node) {
    var data = node.data || (node.data = {});
    var hast = h(node.name, node.attributes);

    data.hName = node.name;
    data.hProperties = hast.properties;
  }
}
