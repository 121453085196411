import CloseIcon from '@mui/icons-material/Close';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { green } from '@mui/material/colors';

import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import EditUser from './EditUser';
import NewUser from './NewUser';
import { usersActions } from './users-slice';

import moment from 'moment';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import removeSpecialChars from '../../helpers/remove-special-chars';
import { levels } from '../auth/permissions';
import InvitationDetail from './InvitationDetail';

// import { formatValue } from '../../helpers/formatter';

// const Check = ({ checked }) => {
//   if (checked) return <CheckIcon style={{ color: green[500] }} />;
//   else return <CloseIcon color="error" />;
// };

const Email = ({ email, emailVerified }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box>{email}</Box>
      <Box marginLeft={1}>
        {emailVerified && (
          <VerifiedUserIcon fontSize="small" style={{ color: green[500] }} />
        )}
        {!emailVerified && <CloseIcon fontSize="small" color="error" />}
      </Box>
    </Box>
  );
};
Email.propTypes = {
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
};

const Role = ({ role, roleActivated } = {}) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box>
        {levels.find(({ role: roleToFind }) => role === roleToFind)?.label ??
          '<nenhum>'}
      </Box>
      <Box marginLeft={1}>
        {roleActivated && (
          <VerifiedUserIcon fontSize="small" style={{ color: green[500] }} />
        )}
        {!roleActivated && <CloseIcon fontSize="small" color="error" />}
      </Box>
    </Box>
  );
};
Role.propTypes = {
  role: PropTypes.string,
  roleActivated: PropTypes.bool,
};

const columns = [
  {
    id: 'displayName',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Criado em',
    formatter: (v) =>
      v.value ? moment(v.value).format('DD/MM/YYYY HH:mm') : '-',
  },
  {
    id: 'emailObj',
    numeric: false,
    disablePadding: true,
    label: 'E-mail',
    formatter: (email) => Email(email),
  },
  {
    id: 'enterpriseList',
    numeric: false,
    disablePadding: true,
    label: 'Empresas',
  },
  {
    id: 'roleObj',
    numeric: false,
    disablePadding: true,
    label: 'Perfil',
    formatter: (role) => Role(role),
  },
];

export default function Users() {
  const {
    users: { data, invitations, selected, selectedInvitation, selectedContext },
  } = useSelector((state) => state);

  const enhancedData = [
    ...data.map((row) => ({
      ...row,
      createdAt: {
        value: row.createdAt,
        props: {
          sortValue: row.createdAt ?? 0,
        },
      },
      enterpriseList: row.enterprises
        ? row.enterprises.map((e) => e.name).join(', ')
        : '<Nenhuma>',
      emailObj: {
        email: row.email,
        emailVerified: row.emailVerified,
        props: {
          sortValue: row.email,
        },
      },
      roleObj: {
        role: row.role,
        roleActivated:
          row.updatedClaimsCheck !== undefined &&
          row.lastUpdate === row.updatedClaimsCheck,
        props: {
          sortValue: row.role,
        },
      },
      type: 'user',
      keywords: removeSpecialChars(
        [
          row.displayName,
          row.email,
          (row.enterprises ?? []).map(({ name }) => name),
        ].join(' ')
      ),
    })),
    ...invitations.map((row) => ({
      ...row,
      displayName: '<convite enviado>',
      enterpriseList: row.enterprises
        ? row.enterprises.map((e) => e.name).join(', ')
        : '<Nenhuma>',
      emailObj: {
        email: row.email,
        emailVerified: false,
        props: {
          sortValue: row.email,
        },
      },
      type: 'invitation',
      keywords: removeSpecialChars(
        [
          row.displayName,
          row.email,
          row.enterprises.map(({ name }) => name),
        ].join(' ')
      ),
    })),
  ];
  // console.log(enhancedData);

  const dispatch = useDispatch();
  // const classes = useStyles();

  // const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    dispatch(usersActions.load());
    dispatch(usersActions.getEnterprises());
    dispatch(usersActions.loadInvitations());
    document.getElementById('keyword-filter').focus();
  }, [dispatch]);

  const handleClose = (reload) => {
    if (reload) dispatch(usersActions.load());
    dispatch(
      usersActions.selectUser({
        selected: undefined,
        selectedContext: undefined,
      })
    );
    dispatch(usersActions.selectInvitation());
  };

  const handleUserClick = (selected, context) => {
    if (selected.type === 'user')
      dispatch(
        usersActions.selectUser({
          selected: selected,
          selectedContext: context,
        })
      );
    else dispatch(usersActions.selectInvitation(selected));
  };
  const handleDeleteConfirm = (selected) => {
    dispatch(usersActions.delete(selected));
    // dispatch(
    //   usersActions.selectUser({ selected: null, selectedContext: null })
    // );
    // setIsDeleteConfirmationOpen(false);
  };
  // const handleDeleteClick = () => {
  //   setIsDeleteConfirmationOpen(true);
  // };

  const filters = {
    keywords: {
      type: 'keywords',
      label: 'Palavras-chave',
      currentValue: '',
    },
  };

  return (
    <div>
      <EnhancedTable
        title="Usuários"
        onRowClick={(d) => handleUserClick(d, 'edit')}
        data={enhancedData}
        columns={columns}
        filters={filters}
        orderBy="displayName"
        orderByDirection="asc"
        rowsPerPage={100}
        onDeleteConfirm={handleDeleteConfirm}
        onNewClick={() => handleUserClick({ type: 'user' }, 'new')}
      />
      <ResponsiveDialog
        open={selected !== undefined && selectedContext === 'edit'}
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
      >
        {selected && <EditUser data={selected} onClose={handleClose} />}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={selected !== undefined && selectedContext === 'new'}
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        {selected && <NewUser onClose={handleClose} />}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={selectedInvitation !== undefined}
        onClose={() => handleClose(false)}
        maxWidth="sm"
        fullWidth
      >
        {selectedInvitation && <InvitationDetail onClose={handleClose} />}
      </ResponsiveDialog>
    </div>
  );
}
