import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((_theme) => ({
  buttonProgress: {
    color: 'secondary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
}));

const ButtonWithProgress = ({
  Component = Button,
  children,
  isLoading: isButtonLoading,
  disabled,
  tooltipTitle: _unused,
  ...otherProps
}) => {
  const classes = useStyles();
  const { isLoading: isAppLoading } = useSelector((state) => state.app);
  const isLoading = isButtonLoading || isAppLoading;

  return (
    // <Tooltip title={tooltipTitle}>
    <div className={classes.wrapper}>
      <Component {...otherProps} disabled={isLoading || disabled}>
        {children}
      </Component>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
    // </Tooltip>
  );
};

ButtonWithProgress.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  Component: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};
ButtonWithProgress.displayName = 'ButtonWithProgress';
export default ButtonWithProgress;
