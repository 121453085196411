import { Box, Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../../helpers/firebase';
import './firebaseui-styling.global.css';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

const ContainerComponent = ({ children }) => {
  return (
    <Paper
      variant="elevation"
      sx={{
        borderRadius: 8,
        maxWidth: '500px',
        m: 2,
        boxShadow: `
            3.8px 3.1px 7.9px rgba(0, 0, 0, 0.073),
            13.8px 11px 21.9px rgba(0, 0, 0, 0.108),
            39.1px 31.1px 52.8px rgba(0, 0, 0, 0.142),
            138px 110px 175px rgba(0, 0, 0, 0.23)
          `,
      }}
    >
      {children}
    </Paper>
  );
};
ContainerComponent.propTypes = {
  children: PropTypes.node,
};

function SignInScreen() {
  return (
    <Stack
      direction="column"
      sx={{
        minHeight: '100dvh',
        backgroundImage: 'url(images/bg04.jpg)',
        backgroundSize: 'cover',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <ContainerComponent>
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          sx={{ '& img': { maxWidth: '200px' } }}
        >
          <img src="images/logo.png" alt="Logo" />
        </Box>
        <Box p={2}>
          <Typography variant="body1" textAlign="center">
            Escolha como quer entrar:
          </Typography>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </Box>
      </ContainerComponent>
    </Stack>
  );
}

export default SignInScreen;
