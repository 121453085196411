'use strict';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FlareIcon from '@mui/icons-material/Flare';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Chip, Menu, MenuItem, Tooltip } from '@mui/material';
import { NavLink /*, useLocation*/ } from 'react-router-dom';
import FirebaseConsoleLink from '../../components/FirebaseConsoleLink';
import getDomain from '../../helpers/domains';
import firebase from '../../helpers/firebase';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import BackgroundTasksList from '../bg-tasks/BackgroundTasksList';
import NotificationsList from '../notifier/NotificationsList';
import { settingsActions } from '../settings/settings-slice';

// const locations = {
//   '/': 'Cobranças',
//   '/monitor2': 'Dashboard',
//   '/monitor': 'Cobranças',
//   '/journeys': 'Jornadas',
//   '/customers': 'Clientes',
// };

const drawerWidth = 240;
const useRouterLinkStyles = makeStyles((theme) => ({
  activeRoute: {
    backgroundColor: theme.palette.action.selected,
    '& *': { color: theme.palette.primary.main },
  },
}));

const RouterLink = React.forwardRef(
  ({ children, className, ...other }, ref) => {
    const classes = useRouterLinkStyles();
    return (
      <NavLink
        ref={ref}
        {...other}
        className={({ isActive }) =>
          clsx(className, isActive && classes.activeRoute)
        }
      >
        {children}
      </NavLink>
    );
  }
);
RouterLink.displayName = 'RouterLink';
RouterLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  clsEnterpriseButton: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textTransform: 'Capitalize',
  },
  clsMenuButton: {
    marginRight: theme.spacing(2),
  },
  clsMenuButtonHidden: {
    display: 'none',
  },
  clsAppBar: {
    //boxShadow: `${theme.shadows[0]}`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: 'url(images/bg04-appbar.jpg)',
    backgroundSize: 'cover',
  },
  clsAppBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  clsAvatarError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  clsAvatar: {
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
}));
function MainAppBar({
  onProfileClick,
  onEnterpriseSelectClick,
  onDrawerClick,
  isDrawerOpen,
}) {
  const {
    clsEnterpriseButton,
    clsMenuButton,
    clsMenuButtonHidden,
    clsAppBar,
    clsAppBarShift,
    clsAvatarError,
    clsAvatar,
  } = useStyles();
  const dispatch = useDispatch();
  useDocumentTitle(getDomain(window.location.hostname).name);
  // const location = useLocation();

  const { isSecretActive } = useSelector((state) => state.app);

  const {
    permissions,
    userData: { enterprises, displayName, photoUrl, emailVerified } = {},
  } = useSelector((state) => state.auth);

  const { enterprise, theme, configRequired, adminRequired, trialDaysLeft } =
    useSelector((state) => state.settings);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpenClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSettingsClick = () => {
    handleMenuClose();
    dispatch(settingsActions.openSettings());
  };
  const handleProfileClick = () => {
    handleMenuClose();
    onProfileClick();
  };
  const handleEnterpriseSelectClick = () => {
    handleMenuClose();
    onEnterpriseSelectClick();
  };
  const handleThemeChange = (theme) => {
    dispatch(settingsActions.changeSettings({ theme }));
  };

  const avatarError =
    !emailVerified ||
    configRequired ||
    (permissions['cfg-act'] && adminRequired);
  return (
    <AppBar
      position="absolute"
      className={clsx(clsAppBar, isDrawerOpen && clsAppBarShift)}
      enableColorOnDark
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onDrawerClick}
          className={clsx(clsMenuButton, isDrawerOpen && clsMenuButtonHidden)}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        {enterprises && enterprises.length > 1 ? (
          <Button
            className={clsEnterpriseButton}
            size="large"
            endIcon={<ArrowDropDownIcon />}
            onClick={handleEnterpriseSelectClick}
          >
            <Typography variant="h6">{enterprise.name}</Typography>
            <Box marginLeft={1}>
              <Chip
                sx={{ color: 'white' }}
                label={enterprise.pixKeyDestinationType}
                size="small"
                variant="outlined"
              />
            </Box>
          </Button>
        ) : (
          <>
            <Typography variant="h6">{enterprise.name}</Typography>
            <Box marginLeft={1}>
              <Chip
                label={enterprise.pixKeyDestinationType}
                size="small"
                variant="outlined"
              />
            </Box>
          </>
        )}
        <Box flexGrow={1} />
        {isSecretActive && (
          <FirebaseConsoleLink doc={`/enterprises/${enterprise.id}`} />
        )}
        {enterprise.inTrial && trialDaysLeft >= 0 && (
          <Typography>Em avaliação - restam {trialDaysLeft} dias</Typography>
        )}
        {enterprise.inTrial && trialDaysLeft === 0 && (
          <Typography>Em avaliação - termina hoje</Typography>
        )}
        {enterprise.inTrial && trialDaysLeft < 0 && <>Avaliação terminou</>}

        <Box flexGrow={1} />
        <BackgroundTasksList />
        <NotificationsList />
        <Tooltip title={theme === 'dark' ? 'Acender a luz' : 'Apagar a luz'}>
          <IconButton
            color="inherit"
            onClick={() =>
              handleThemeChange(theme === 'dark' ? 'light' : 'dark')
            }
            size="large"
          >
            {theme === 'dark' ? <FlareIcon /> : <Brightness3Icon />}
          </IconButton>
        </Tooltip>
        <IconButton
          aria-label="profile"
          color="inherit"
          onClick={handleMenuOpenClick}
          size="large"
        >
          <Badge
            invisible={!avatarError}
            overlap="circular"
            badgeContent="!"
            color="error"
          >
            <Avatar
              className={clsx(avatarError ? clsAvatarError : clsAvatar)}
              color="inherit"
              alt={displayName}
              src={photoUrl}
            />
          </Badge>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleMenuClose}
        >
          <MenuItem disabled>{displayName}</MenuItem>
          <MenuItem onClick={handleProfileClick}>
            <ListItemIcon>
              <Badge invisible={emailVerified} badgeContent="!" color="error">
                <AccountCircleIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Perfil" />
          </MenuItem>
          <MenuItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <Badge
                invisible={
                  !(configRequired || (permissions['cfg-act'] && adminRequired))
                }
                badgeContent="!"
                color="error"
              >
                <SettingsIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Configurações" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              firebase.auth().signOut();
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

MainAppBar.propTypes = {
  onProfileClick: PropTypes.func,
  onEnterpriseSelectClick: PropTypes.func,
  onDrawerClick: PropTypes.func,
  isDrawerOpen: PropTypes.bool,
};

export default MainAppBar;
