import { Box } from '@mui/material';
import PropTypes from 'prop-types';

function EditFormFooter({ children, ...props }) {
  return (
    <Box
      width={1}
      marginTop={1}
      display="flex"
      flexDirection="row"
      alignItems="center"
      {...props}
    >
      {children}
    </Box>
  );
}

EditFormFooter.propTypes = {
  children: PropTypes.node,
};

export default EditFormFooter;
