'use strict';
import { Box, Typography } from '@mui/material';

import JourneyPix from '../monitor/JourneyPix';

export default function RenewalAlertWizard() {
  return (
    <>
      <Typography variant="body2">
        Esta pendência é apenas para deixar você ciente que existem algumas
        faturas próximas do término.
        <br />
        Quando fizer a renovação, basta marcar a pendência como resolvida.
      </Typography>
      <JourneyPix />
      <Box flexGrow={1} />
    </>
  );
}

RenewalAlertWizard.propTypes = {};
